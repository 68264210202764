import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText,
  Slide, Grid, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { parseJson } from '../../../affiliates/utils';
import ProductItem from './ProductItem';
import currencyFormatter from '../../../../utils/currencyFormatter';

import {
  DialogTitleText, DialogTitleMainGrid, DialogTitleWrapper,
  DialogContentHeader, ContentHeaderSubText, ContentHeaderID,
  ContentDivider, HeaderBar, HeaderBarText, HeaderPaper,
  HeaderPaperGrid, HeaderPaperText, SupplierPaper, SupplierPaperGrid, SupplierPaperText,
  SupplierPaperHeader, SupplierSubTotal, SubTotalText, SubTotalGridWrapper, BottomText,
  BottomTextGrid, LinkTag, CloseIconGrid, CheckIcon, CheckButton, ContentHeaderItem,
  WideContentHeaderItem, TotalsWraper, AmountWrapper, AmountBreakdown, SpacedWideContentHeaderItem,
  SignatureWrapper, TermsAndConditions, OgaImage
} from './labelPrintDialog.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const LabelPrintDialog = ({
  dialogOpen, closeDialog, state
}) => {
  const [contactsInfo, setContactsInfo] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const componentRef = useRef();

  const {
    id, cart, serviceFee, deliveryFee, selectedDeliveryLocation, deliveryLocations, dateCreated
  } = state;
  const supplierCategories = _.groupBy(cart, 'supplier');

  useEffect(() => {
    const supplierSubTotals = Object.entries(supplierCategories)
      .map(([, value]) => value.filter((p) => p.recieved)
        .reduce((sum, obj) => sum + (Number(obj.quantity) * Number(obj.resolvedPriceInUseValue)), 0));

    const totals = supplierSubTotals.reduce((sum, sub) => sum + sub, 0);
    setOrderTotal(totals);
    setGrandTotal(totals + serviceFee + deliveryFee);
  }, [supplierCategories]);

  useEffect(() => {
    const selectedDeliveryLocationInfo = deliveryLocations.find((deliveryLocation) => deliveryLocation.name === selectedDeliveryLocation);
    if (selectedDeliveryLocationInfo) {
      let { contacts } = selectedDeliveryLocationInfo;
      try {
        contacts = parseJson(contacts.replace(/'/g, '"'));
        setContactsInfo(contacts);
      } catch (e) {
        setContactsInfo({});
      }
    }
  }, [deliveryLocations]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: closeDialog
  });

  const renderDeliveryLocationInfo = () => {
    const {
      address_line_1: addressLineOne, city, country
    } = contactsInfo;
    return (
      <ContentHeaderSubText>
        {`${addressLineOne},`}
        <br />
        {`${city || 'Lagos'}, ${country}.`}
      </ContentHeaderSubText>
    );
  };

  const renderSupplierContainers = () => Object.entries(supplierCategories).map(([key, value]) => {
    const supplierSubTotal = value.filter((p) => p.recieved)
      .reduce((sum, obj) => sum + (Number(obj.quantity) * Number(obj.resolvedPriceInUseValue)), 0);
    // if (supplierSubTotal < 0) return '';
    return (
      <SupplierPaper>
        <SupplierPaperGrid container>
          <SupplierPaperHeader item xs={12}>
            <SupplierPaperText>
              Supplier:
              &nbsp;
              {key.split(' ')[0]}
            </SupplierPaperText>
          </SupplierPaperHeader>
          {value.filter((p) => p.quantity > 0 && p.recieved)
            .map((productItem) => (
              <ProductItem product={productItem} />
            ))}
          <SubTotalGridWrapper item container xs={12} justifyContent="space-between">
            <SupplierSubTotal item xs={6}>
              <SubTotalText>Sub Total:</SubTotalText>
            </SupplierSubTotal>
            <SupplierSubTotal item xs={2}>
              <SubTotalText>
                {currencyFormatter(supplierSubTotal)}
              </SubTotalText>
            </SupplierSubTotal>
          </SubTotalGridWrapper>
        </SupplierPaperGrid>
      </SupplierPaper>
    );
  });

  const {
    primary_email_address: primaryEmail,
    mobile_number: mobileNumber
  } = contactsInfo;

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      fileslimit={1}
      style={{ zIndex: 100000000 }}
    >
      <DialogTitle id="scroll-dialog-title">
        <DialogTitleMainGrid container>
          <DialogTitleWrapper container item xs={10}>
            <DialogTitleText> Invoice preview </DialogTitleText>
          </DialogTitleWrapper>
          <CloseIconGrid item xs={2}>
            <IconButton aria-label="close" onClick={closeDialog} size="large">
              <CloseIcon />
            </IconButton>
          </CloseIconGrid>
        </DialogTitleMainGrid>
      </DialogTitle>
      <DialogContent ref={componentRef} dividers>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          <DialogContentHeader container>
            <Grid item direction="column" xs={8}>
              <OgaImage
                alt=""
                src="https://res.cloudinary.com/health-id/image/upload/v1611208198/Lifestores%20Healthcare%20Website/OGApharmacy_Logo.png"
              />
              <ContentHeaderSubText>
                <ContentHeaderItem>
                  <span>Email: </span>
                  OGApharmacy@gmail.com
                </ContentHeaderItem>
                <ContentHeaderItem>
                  <span>Phone: </span>
                  (0)806 129 5352
                </ContentHeaderItem>
                <ContentHeaderItem>
                  <span>Address: </span>
                  <div>
                    273 Bornu Way, by Adekunle Bustop,
                    <br />
                    Ebute Metta,Yaba, Lagos, Nigeria.
                  </div>
                </ContentHeaderItem>
              </ContentHeaderSubText>
            </Grid>
            <Grid item container xs={4} alignContent="center">
              <ContentHeaderID>
                Invoice 2345
              </ContentHeaderID>
            </Grid>
          </DialogContentHeader>
          <ContentDivider />
          <DialogContentHeader container>
            <Grid item direction="column" xs={8}>
              <WideContentHeaderItem>
                <span>Supplied to: </span>
                {selectedDeliveryLocation}
              </WideContentHeaderItem>
              <WideContentHeaderItem>
                <span>Email: </span>
                {primaryEmail}
              </WideContentHeaderItem>
              <WideContentHeaderItem>
                <span>Phone: </span>
                {mobileNumber}
              </WideContentHeaderItem>
              <WideContentHeaderItem>
                <span>Address: </span>
                <div>
                  {renderDeliveryLocationInfo()}
                </div>
              </WideContentHeaderItem>
            </Grid>
          </DialogContentHeader>
          <ContentDivider style={{ marginBottom: '1.5rem' }} />
          <HeaderBar container>
            <Grid item>
              <HeaderBarText>Order number:</HeaderBarText>
              <ContentHeaderSubText>
                {String(id).padStart(6, 0)}
              </ContentHeaderSubText>
            </Grid>
            <Grid item>
              <HeaderBarText>Placed on:</HeaderBarText>
              <ContentHeaderSubText>
                {moment(dateCreated).format('dddd do MMMM, YYYY')}
              </ContentHeaderSubText>
            </Grid>
            <Grid item>
              <HeaderBarText>Delivery method:</HeaderBarText>
              <ContentHeaderSubText>
                Kwik Delivery
              </ContentHeaderSubText>
            </Grid>
            <Grid item>
              <HeaderBarText>Payment due date:</HeaderBarText>
              <ContentHeaderSubText>
                {moment(dateCreated).add(15, 'days').format('dddd do MMMM, YYYY')}
              </ContentHeaderSubText>
            </Grid>
          </HeaderBar>
          <ContentDivider style={{ marginTop: '1.5rem' }} />
          <HeaderPaper variant="outlined">
            <HeaderPaperGrid container>
              <Grid item xs={5}>
                <HeaderPaperText>
                  {cart.length > 1 ? 'Product Items' : 'Product Item'}
                  {` (${cart.length})`}
                </HeaderPaperText>
              </Grid>
              <Grid item xs={2}>
                <HeaderPaperText>
                  Qty
                </HeaderPaperText>
              </Grid>
              <Grid item xs={2}>
                <HeaderPaperText>
                  Unit Cost
                </HeaderPaperText>
              </Grid>
              <Grid item xs={2}>
                <HeaderPaperText>
                  Price
                </HeaderPaperText>
              </Grid>
            </HeaderPaperGrid>
          </HeaderPaper>
          {renderSupplierContainers()}
          <TotalsWraper>
            <AmountWrapper>
              <span>Amount in words</span>
              <div>
                {/* { `${numWords.toWords(grandTotal)} Naira only`} */}
              </div>
            </AmountWrapper>
            <AmountBreakdown>
              <SpacedWideContentHeaderItem>
                <span>Order Total: </span>
                {currencyFormatter(orderTotal)}
              </SpacedWideContentHeaderItem>
              <SpacedWideContentHeaderItem>
                <span>Tax Rate: </span>
                0
              </SpacedWideContentHeaderItem>
              <SpacedWideContentHeaderItem>
                <span>Tax Due: </span>
                0
              </SpacedWideContentHeaderItem>
              <SpacedWideContentHeaderItem style={{ fontWeight: 'bold' }}>
                <span>Total: </span>
                {currencyFormatter(grandTotal)}
              </SpacedWideContentHeaderItem>
            </AmountBreakdown>
          </TotalsWraper>
          <ContentDivider style={{ marginBottom: '1.5rem' }} />
          <SignatureWrapper>
            <span>
              Received by (Name & Sign):
            </span>
            <span>
              Supplied by (Name & Sign):
            </span>
          </SignatureWrapper>
          <ContentDivider style={{ marginBottom: '1.5rem' }} />

          <TermsAndConditions>
            <span>
              Terms & Conditions
            </span>
            <div>
              Please note that payment/credit terms are noted in the OGApharmacy Formulary List.
              <br />
              Please send notification within 24 hours to the OGApharmacy representative in the case of any supply discrepancies or other concerns. No refunds or product returns for products received in good condition.
              <br />
              <br />
              Payment details are:
              <br />
              Account name:
              <b> Banyi Lifestores Pharmacy Limited </b>
              <br />
              Bank name:
              <b> Zenith Bank Plc. Account number: 1016914552.</b>
            </div>
          </TermsAndConditions>

          <ContentDivider style={{ marginBottom: '1.5rem' }} />
          <Grid container item justifyContent="center">
            Thank you for your business!
          </Grid>
          <BottomTextGrid item>
            <BottomText>
              If you feel that you have received this email in error, please send an email to
              &nbsp;
              <LinkTag href="mailto:contact@ogapharmacy.com">ogapharmacy@gmail.com</LinkTag>
                &nbsp; or call us &nbsp;
              <LinkTag href="tel:+2348061295352">(0)806 129 5352</LinkTag>
            </BottomText>
          </BottomTextGrid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CheckButton onClick={handlePrint}>
          <CheckIcon>check</CheckIcon>
        </CheckButton>
      </DialogActions>
    </Dialog>
  );
};

LabelPrintDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object)
};

LabelPrintDialog.defaultProps = {
  state: {}
};

export default LabelPrintDialog;
