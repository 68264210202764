import React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContentText, Slide, Grid, Box
} from '@mui/material';
import { Close } from '@mui/icons-material';
import MapCsvDialog from '../sales/mapCsvDialog';
import {
  CardTitle,
  DialogContentStyled,
  TitleDesc,
  DialogContainer
} from './categoriesCSVUpload.style';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CategoriesCSVUpload = ({ openDialog, toggleHandler }) => {
  const fields = [
    [
      'Category Name',
      {
        required: false,
        unique: false,
        value: null,
        empty: true,
        touched: false,
        hover: false
      }
    ],
    [
      'VAT Status',
      {
        required: false,
        unique: false,
        value: null,
        empty: true,
        touched: false,
        hover: false
      }
    ],
    [
      'Markup',
      {
        required: true,
        unique: true,
        value: null,
        empty: true,
        touched: false,
        hover: false
      }
    ],
    [
      'Loyalty Weight',
      {
        required: false,
        unique: false,
        value: null,
        empty: true,
        touched: false,
        hover: false
      }
    ],
  ];

  return (
    <DialogContainer
      data-testid="customer-dialog"
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={toggleHandler}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <DialogContentText>
          <Grid style={{ marginTop: '1rem' }}>
            <Box display="flex" justifyContent="space-between">
              <CardTitle>Add Categories</CardTitle>
              <Close
                fontSize="small"
                onClick={toggleHandler}
                style={{ cursor: 'pointer' }}
              />
            </Box>
            <TitleDesc data-testid="uploadCsvText">Upload CSV file to add multiple categories</TitleDesc>
            <MapCsvDialog
              fields={fields}
              model="categories"
              endpoint="categories"
              backRoute="categories"
            />
          </Grid>
        </DialogContentText>
      </DialogContentStyled>
    </DialogContainer>
  );
};

CategoriesCSVUpload.propTypes = {
  openDialog: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired
};

CategoriesCSVUpload.defaultProps = {
  openDialog: false
};

export default CategoriesCSVUpload;
