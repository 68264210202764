import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import {
  Grid, Box, TableContainer, Table, TableBody, TableRow
} from '@mui/material';
import toast from 'react-hot-toast';
import { UPDATE_PRODUCTS_STATUS_MUTATION } from '../../../mutations/products';
import {
  HeaderWrapper, ContactGrid, TableRowStyled, TableCellBodyStyled, ContainerFlex, ColorField, Status,
  SmallHeading, NormalText2, ImgContainer, CardTitle, TableHeadStyled, TableCellStyled
} from './productInfo.style';
import { BoxStyled } from './productDetailsContainer.style';
import currencyAmount from '../../../utils/currencyAmount';
import { useStateValue } from '../../../providers/stateProvider';
import ActionPopper from './actionPopper';

const ProductInfo = ({ info, refetch }) => {
  const {
    id, brandName, genericName, resolvedPriceInUseValue, dateCreated, status, quantityInStock,
    description, manufacturer, packSize, qpc, image, barcode, unitOfMeasurement,
    orderCost, reorderPoint, reorderMax, reorderUnit, therapeuticType,
    priceInUse, batches, meta, minimumOrderQuantity, productVariant, safetyStock, maxLeadTime
  } = info;
  const [{ user: { platform } }, dispatch] = Object.values(useStateValue());
  const {
    categoryName, loyaltyWeight, markup, vatStatus
  } = info?.category || {};
  const { name: supplierName = 'none', id: supplierId = 'none' } = info.supplier || {};
  const isErp = platform === 'erp';
  // const obj = JSON.parse(meta);
  // const {
  //   pricing_group: {
  //     erp: {
  //       minimumOrderQuantity, productVariant, safetyStock, maxLeadTime

  //     }
  //   }
  // } = obj;
  const [updateProductStatus] = useMutation(UPDATE_PRODUCTS_STATUS_MUTATION);
  const updateProductStatusHandler = () => {
    updateProductStatus({
      variables: {
        productId: id,
        status: status === 'ACTIVE' ? 'IN_ACTIVE' : 'ACTIVE'
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateProductStatus || {};
        toast.success(message);
        refetch();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const nearestExpDate = () => {
    const dt = batches?.reduce((date, batch) => {
      const { expiryDate = '' } = batch;
      if (moment(expiryDate).isBefore(date)) return expiryDate;
      return date;
    }, '3050-01-01');
    return moment(dt).format('DD/MM/YYYY');
  };

  // eslint-disable-next-line consistent-return
  const getStatus = () => {
    // if (quantityInStock <= 0) return <ColorGrid item container status="soldOut">sold out</ColorGrid>;
    if (status === 'ACTIVE') {
      return (
        <ColorField>
          <Status active />
          Enabled
        </ColorField>
      );
    }
    return (
      <ColorField>
        <Status />
        Disabled
      </ColorField>
    );
  };

  const handleAction = (setOpen) => {
    updateProductStatusHandler();
    setOpen(false);
  };

  const datePlaced = moment(dateCreated).format('DD/MM/YYYY');
  return (
    <HeaderWrapper container spacing={6}>
      <ContactGrid item xs={12} md={12}>
        <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px">
          <ContainerFlex>
            <ImgContainer item md={6}>
              <img src={image} alt={genericName} style={{ width: '50%' }} />
            </ImgContainer>
            <Grid item md={6}>
              <SmallHeading variant="caption">Generic Name</SmallHeading>
              <NormalText2>{genericName || 'N/A'}</NormalText2>
              <SmallHeading variant="caption">Description</SmallHeading>
              <NormalText2>{description}</NormalText2>
              <SmallHeading variant="caption">Pack size</SmallHeading>
              <NormalText2>{packSize}</NormalText2>
            </Grid>
          </ContainerFlex>
        </BoxStyled>
        <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px" mt={4}>
          <CardTitle className="erp-uat-product-info">Product Information</CardTitle>
          <TableContainer style={{ marginTop: '1rem' }}>
            <Table style={{ width: '100%' }}>
              <TableHeadStyled>
                <TableRowStyled>
                  <TableCellStyled>Created Date</TableCellStyled>
                  <TableCellStyled>PID</TableCellStyled>
                  <TableCellStyled>Qty/Ctn</TableCellStyled>
                  <TableCellStyled>Pack Size</TableCellStyled>
                  <TableCellStyled>Category</TableCellStyled>
                  <TableCellStyled>UOM</TableCellStyled>
                  <TableCellStyled>UPC</TableCellStyled>
                  <TableCellStyled>UPC2</TableCellStyled>
                  <TableCellStyled>Manufacturer</TableCellStyled>
                  <TableCellStyled>Expiry Date</TableCellStyled>
                  <TableCellStyled>Status</TableCellStyled>
                  <TableCellStyled>Action</TableCellStyled>
                </TableRowStyled>
              </TableHeadStyled>
              <TableBody>
                <TableRow>
                  <TableCellBodyStyled>{datePlaced || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{id || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{qpc || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{packSize || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{categoryName || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{unitOfMeasurement || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{barcode || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{barcode || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{manufacturer || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{'Add expiry' || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{getStatus()}</TableCellBodyStyled>
                  <TableCellBodyStyled>
                    <ActionPopper handleAction={handleAction} row={info} />
                  </TableCellBodyStyled>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </BoxStyled>
        <BoxStyled p={4} bgcolor="background.paper" borderRadius="8px" mt={4}>
          <CardTitle className="erp-uat-stock-details">Stock Details</CardTitle>
          <TableContainer style={{ marginTop: '1rem' }}>
            <Table style={{ width: '100%' }}>
              <TableHeadStyled>
                <TableRowStyled>
                  <TableCellStyled>Supplier</TableCellStyled>
                  <TableCellStyled>Order Cost</TableCellStyled>
                  <TableCellStyled>Markup</TableCellStyled>
                  <TableCellStyled>Price in use</TableCellStyled>
                  <TableCellStyled>Sales Price</TableCellStyled>
                  <TableCellStyled>VAT Status</TableCellStyled>
                  <TableCellStyled>Product Type</TableCellStyled>
                  <TableCellStyled>Pricing Category</TableCellStyled>
                  <TableCellStyled>Loyalty Weight</TableCellStyled>
                  <TableCellStyled>Nearest Exp. Date</TableCellStyled>
                  <TableCellStyled>Reorder Unit</TableCellStyled>
                  <TableCellStyled>Reorder Point</TableCellStyled>
                  <TableCellStyled>Reorder Max</TableCellStyled>
                  <TableCellStyled>Product Variant</TableCellStyled>
                  <TableCellStyled>Min Order Quantity</TableCellStyled>
                  <TableCellStyled>Max Lead Time</TableCellStyled>
                  <TableCellStyled>Safety Stock</TableCellStyled>
                </TableRowStyled>
              </TableHeadStyled>
              <TableBody>
                <TableRow>
                  <TableCellBodyStyled>{supplierName || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{`₦ ${currencyAmount(orderCost)}` || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{markup || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{priceInUse || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{`₦ ${currencyAmount(resolvedPriceInUseValue)}` || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{vatStatus || 'VAT'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{therapeuticType?.therapeuticType || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{categoryName || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{loyaltyWeight || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{nearestExpDate()}</TableCellBodyStyled>
                  <TableCellBodyStyled>{reorderUnit || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{reorderPoint}</TableCellBodyStyled>
                  <TableCellBodyStyled>{reorderMax}</TableCellBodyStyled>
                  <TableCellBodyStyled>{productVariant || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{minimumOrderQuantity || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{maxLeadTime || 'N/A'}</TableCellBodyStyled>
                  <TableCellBodyStyled>{safetyStock || 'N/A'}</TableCellBodyStyled>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </BoxStyled>
      </ContactGrid>

      {/* {
        isErp ? (
          <>
            <Grid item md={2}>
              <SmallHeading variant="caption">Product Variant</SmallHeading>
              <NormalText2>{productVariant || 'N/A'}</NormalText2>
            </Grid>
            <Grid item md={2}>
              <SmallHeading variant="caption">Min Order Quantity</SmallHeading>
              <NormalText2>{minimumOrderQuantity || 'N/A'}</NormalText2>
            </Grid>
            <Grid item md={2}>
              <SmallHeading variant="caption">Max Lead Time</SmallHeading>
              <NormalText2>{maxLeadTime || 'N/A'}</NormalText2>
            </Grid>
            <Grid item md={2}>
              <SmallHeading variant="caption">Safety Stock</SmallHeading>
              <NormalText2>{safetyStock || 'N/A'}</NormalText2>
            </Grid>
          </>
        ) : ''
      } */}

    </HeaderWrapper>
  );
};

ProductInfo.propTypes = {
  refetch: PropTypes.func.isRequired,
  info: PropTypes.instanceOf(Object).isRequired,

};

export default ProductInfo;
