import React, { useEffect, useState } from 'react';
import Hidden from '@mui/material/Hidden';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import CustomerDropdown from './uploadModel/modelCreatePopper';
import {
  TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderButton, OrderButtonText, PlusIcon, ButtonsGrid,
} from '../../toBeDeleted/titleGrid.styles';
import { useStateValue } from '../../../providers/stateProvider';
import ModelActionTypes from '../../../providers/reducers/model/modelTypes';
import CategoriesCSVUpload from '../../categories/categoriesCSVUpload';

export default function TitleGrid({
  renderIndividualModel, modelName, modelNamePlural, endpoint, fileName, categoriesList, reloadFunc
}) {
  const [buttonEl, setButtonEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const openDialogHandler = () => {
    reloadFunc();
    setOpenDialog(!openDialog);
  };

  const [{
    model: { addModelDialogOpen }, user: { allowedPermissionsMap: { userPermissions } },
  }, dispatch] = Object.values(useStateValue());

  const handleCustomerButtonClick = (event) => {
    setButtonEl(buttonEl ? null : event.currentTarget);
  };
  const toggleIndividualDialog = () => {
    dispatch({ type: ModelActionTypes.TOGGLE_DIALOG_OPEN });
  };

  useEffect(() => {
    if (
      (modelName === 'Product type' && userPermissions?.includes('product_type_add_product_type'))
      || (modelName === 'Category' && userPermissions?.includes('pricing_categories_add_category'))
      || userPermissions?.includes(`${endpoint}_add_${fileName}`)) {
      setDisableButton(false);
    }
  }, [modelName, userPermissions]);

  return (
    <TitleGridContainer container item>
      <TitleTextGridContainer item>
        <PrimaryTitle categoriesList={categoriesList}>
          {`${modelNamePlural}`}
        </PrimaryTitle>
        <SecondaryTitle categoriesList={categoriesList}>
          Business
          {' '}
          {`${modelNamePlural?.toLowerCase()}`}
          {' '}
          on portal
        </SecondaryTitle>
      </TitleTextGridContainer>
      <ButtonsGrid item>
        <Button
          variant="contained"
          style={{ width: '12rem' }}
          onClick={(e) => setButtonEl(e.currentTarget)}
          disabled={disableButton}
          data-testid="addButton"
        >
          {!categoriesList && (
            <Hidden mdDown>
              <PlusIcon />
            </Hidden>
          )}
          <OrderButtonText>
            Add
            {' '}
            {modelName}
          </OrderButtonText>
        </Button>
      </ButtonsGrid>

      <CategoriesCSVUpload
        openDialog={openDialog}
        toggleHandler={openDialogHandler}
      />

      <CustomerDropdown
        customerButton={buttonEl}
        handleCustomerButtonClick={handleCustomerButtonClick}
        individualDialogHandler={toggleIndividualDialog}
        modelName={modelName}
        openDialogHandler={openDialogHandler}
      />
      {renderIndividualModel(addModelDialogOpen, toggleIndividualDialog)}
    </TitleGridContainer>
  );
}
TitleGrid.propTypes = {
  renderIndividualModel: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
  modelNamePlural: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  categoriesList: PropTypes.bool,
  reloadFunc: PropTypes.func
};

TitleGrid.defaultProps = {
  categoriesList: false,
  reloadFunc: () => null
};
