import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import currencyFormatter from '../../../shared/currencyFormatter';
import CTablePagination from '../../../customComponents/cTablePagination';
import {
  MobileContainer, DetailText, HeaderText, TextBox, ColorGrid, DateText,
  ContentBox, StatusBox, FooterGrid
} from './mobileReturnRow.styles';
import { APPROVEORDERTOXERO } from '../../../../mutations/orders';
import { useStateValue } from '../../../../providers/stateProvider';
import {
  ActionButton, DialogContentStyled, NoteContainer, NoteDialog, NoteTextField, NoteTitle, SaveNoteButton, TextWrapper
} from './returnRow.styles';

export default function MobileReturnRow({
  row, displayFilter, refetch
}) {
  const navigate = useNavigate();
  const {
    id, businessName, datePlaced: date, orderTotal, metaStatus, approved
  } = row;
  const datePlaced = moment(date).format('DD/MM/YY (h:mm)');
  const amount = currencyFormatter(orderTotal);
  const deliveryDate = moment(date).add(3, 'days').format('DD/MM/YY');
  const [open, setOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [approveOrderToXero] = useMutation(APPROVEORDERTOXERO);

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const getColorGrid = (color, bkg, name) => (
    <StatusBox>
      <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
        {name}
      </ColorGrid>
    </StatusBox>
  );

  const getStatus = () => {
    switch (metaStatus) {
      case 'ORDER_CLOSED':
        return getColorGrid('#07DE10', '#DCFEDD', 'Closed');
      case 'ORDER_CANCELED':
        return getColorGrid('#FA3636', '#FFDAD6', 'Cancelled');
      case 'ORDER_RETURNED':
        return getColorGrid('#FA3636', '#FFDAD6', 'Returned');
      case 'ORDER_RECEIVED':
        return getColorGrid('#07DE10', '#DCFEDD', 'Received');
      case 'ORDER_SORTED':
        return getColorGrid('#606060', '#FBF777', 'Sorted');
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#FBF777', 'Order Sent');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#FBF777', 'Dispatched');
      case 'ORDER_QUEUED_FOR_DISPATCH':
        return getColorGrid('#606060', '#FBF777', 'Ready for disp...');
      default:
        return getColorGrid('#606060', '#FBF777', `${metaStatus}`);
    }
  };
  const handleClick = (_id) => {
    navigate(`/orders-admin/dispatch/${_id}`);
  };

  const handleToggleNotes = () => {
    if (!userPermissions?.includes('manage_orders_approve_order')) return toast.error('Not Authorized');
    return setOpen(!open);
  };

  const handleApproveOrder = () => {
    approveOrderToXero({
      variables: {
        orderId: id,
        note: noteText,
      }
    }).then(({ data: approveData }) => {
      const { message } = approveData?.approveOrderXero ?? '';
      toast.success(message);
      refetch();
      setNoteText('');
    }).catch((err) => {
      toast.error(err?.message);
    });
    handleToggleNotes();
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setNoteText(value);
  };
  return (
    <>
      {!displayFilter && (
        <MobileContainer>
          <DateText>
            {datePlaced}
          </DateText>
          <hr />
          <ContentBox>
            <Grid data-testid="routeBtn" onClick={() => handleClick(id)}>
              <TextBox container>
                <HeaderText xs={6}>Order Number</HeaderText>
                <DetailText xs={6}>
                  {`#${id}`}
                </DetailText>
              </TextBox>
              <TextBox container>
                <HeaderText xs={6}>Value</HeaderText>
                <DetailText xs={6}>
                  {`₦${amount}`}
                </DetailText>
              </TextBox>
              <TextBox container>
                <HeaderText xs={6}>Affiliates</HeaderText>
                <DetailText xs={6}>
                  {businessName}
                </DetailText>
              </TextBox>
              <TextBox container>
                <HeaderText xs={6}>Estimated Delivery</HeaderText>
                <DetailText xs={6}>
                  {deliveryDate}
                </DetailText>
              </TextBox>
              <TextBox container>
                <HeaderText xs={5}>Order Status</HeaderText>
                <DetailText xs={7}>
                  {getStatus()}
                </DetailText>
              </TextBox>
            </Grid>
            <Grid>
              <ActionButton
                data-testid="create-product"
                onClick={handleToggleNotes}
                disabled={metaStatus !== 'ORDER_SENT' || approved === 'True'}
              >
                <TextWrapper>
                  {metaStatus !== 'ORDER_SENT' || approved === 'True' ? 'Approved' : 'Approve'}
                </TextWrapper>
              </ActionButton>
            </Grid>
          </ContentBox>
        </MobileContainer>
      )}
      <NoteDialog
        open={open}
        onClose={handleToggleNotes}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContentStyled>
          <NoteTitle>Leave a Comment</NoteTitle>
          <Grid>
            <NoteContainer>
              <NoteTextField
                value={noteText}
                multiline
                minRows={10}
                placeholder="Write your comment here"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ backgroundColor: '#f0f0f0', marginBottom: '2em' }}
              />
            </NoteContainer>
            <SaveNoteButton
              variant="contained"
              fullWidth
              onClick={handleApproveOrder}
            >
              Proceed
            </SaveNoteButton>
          </Grid>
        </DialogContentStyled>
      </NoteDialog>
    </>
  );
}

MobileReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  displayFilter: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired
};

MobileReturnRow.defaultProps = {
  row: {},
};
