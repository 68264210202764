import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickAwayListener, Fade, List, ListItemText, Grid, Typography,
} from '@mui/material';
import {
  CPopper, CListItem, PaperWrapper
} from './rangePopper.styles';

const StoresPopper = ({
  rangeEl, setRangeEl, filter, handleFilter
}) => {
  const open = Boolean(rangeEl);

  const handleChange = (range) => {
    handleFilter('range', range);
    setRangeEl(null);
  };

  return (
    <CPopper
      open={open}
      anchorEl={rangeEl}
      transition
      disablePortal
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <PaperWrapper>
            <ClickAwayListener onClickAway={() => setRangeEl(null)}>
              <List style={{ width: '100%', padding: 0 }}>
                {['Weekly', 'Monthly'].map((range) => (
                  <CListItem
                    disableGutters
                    dense
                    button
                    key={range}
                    onClick={() => handleChange(range)}
                  >
                    <ListItemText
                      primary={(
                        <Grid container item alignItems="center">
                          <Typography
                            variant="caption"
                            noWrap
                            style={{ fontSize: '1rem', color: '#606060', marginLeft: '.5rem' }}
                          >
                            {range}
                          </Typography>
                        </Grid>
                      )}
                    />
                  </CListItem>
                ))}
              </List>
            </ClickAwayListener>
          </PaperWrapper>
        </Fade>
      )}
    </CPopper>
  );
};

StoresPopper.propTypes = {
  rangeEl: PropTypes.shape(Object),
  setRangeEl: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape(Array),
};

StoresPopper.defaultProps = {
  filter: {},
  rangeEl: {},
};

export default StoresPopper;
