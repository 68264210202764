import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Grid, Typography, Button
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Back } from '../return-details.style';
import ReceiptPopper from './receiptPopper';
import { Return } from '../../../../../assets/svgs';
import ReturnDialog from './returnDialog';
import { useStateValue } from '../../../../../providers/stateProvider';

const Header = ({ row, isReturn, refetch }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const openReturnDialogHandler = () => {
    setOpenDialog(!openDialog);
  };

  const {
    sale: {
      id: saleId, receiptNumber, saleItems, transactionDate
    }
  } = row;

  const getCartInfo = () => {
    let subTotal = 0;
    const cart = saleItems.map((item) => {
      const {
        id,
        name: brandName,
        quantity,
        meta,
        unitCost: itemPrice,
        packSize,
        productDiscount,
        total,
        product: { id: productId },
        saleitembatchSet: itemBatches
      } = item;
      subTotal += itemPrice * quantity;
      return {
        id,
        brandName,
        image: '',
        itemPrice,
        packSize,
        productId,
        quantity,
        meta,
        total,
        itemBatches,
        productDiscount
      };
    });
    return { cart, subTotal };
  };
  const { cart, subTotal } = getCartInfo();
  // ^ subTotal

  return (
    <>
      <Back onClick={() => navigate('/history')}>
        <ArrowBackIos fontSize="small" />
        back
      </Back>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Box display="flex" alignItems="center" gap={8}>
            {transactionDate ? moment(transactionDate).format('lll') : 'N/A'}
          </Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {receiptNumber}
          </Typography>
        </Grid>
        {isReturn ? null : (
          <Grid item style={{ display: 'flex', gap: '.6rem' }}>
            <Button
              variant="outlined"
              onClick={openReturnDialogHandler}
              disabled={!userPermissions?.includes('affiliate_dashboard_return')}
            >
              <Return />
              Return
            </Button>
            <ReceiptPopper row={row} />
          </Grid>
        )}
      </Grid>

      <ReturnDialog
        row={row}
        refetch={refetch}
        cart={cart}
        subTotal={subTotal}
        openDialog={openDialog}
        openReturnDialogHandler={openReturnDialogHandler}
      />
    </>
  );
};

Header.propTypes = {
  refetch: PropTypes.func.isRequired,
  isReturn: PropTypes.string,
  row: PropTypes.instanceOf(Object).isRequired
};
Header.defaultProps = {
  isReturn: ''
};

export default Header;
