import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { useQuery } from '@apollo/client';
import NewOrdersLoader from '../../customComponents/loaders/newOrdersLoader';
import ModelActionTypes from '../../../providers/reducers/model/modelTypes';
import paginationShowing from '../../../utils/pagination';
import { useStateValue } from '../../../providers/stateProvider';
import GoToTop from '../../customComponents/scrollToTop';
// import CTablePagination from '../../customComponents/cTablePagination';
import TablePagination from '../tablePagination';
import {
  ModelCardsContainer, ModelCardGrid,
  FooterGrid, OrderCountText, DivFullWidth
} from './models.styles';

export default function Models({
  state,
  handleChangePage,
  handleChangeRowsPerPage,
  findModelsQuery, extraModelQueryParam,
  modelTotalNumber,
  modelQueryName, categoriesList,
  renderModelCard, modelCardOnclickAction, modelName, modelNamePlural, loop = true,
  reload
}) {
  const [{ model: { reFetch } }, dispatch] = Object.values(useStateValue());

  const {
    searchValue, currentPage, currentPageCount, orderBy
  } = state;

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const updateCustomerState = (models, modelsCount) => {
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: {
        models,
        modelsCount,
      }
    });
  };

  const {
    loading, error, data, refetch
  } = useQuery(findModelsQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      search: searchValue,
      pageCount,
      pageNumber,
      orderBy,
      ...extraModelQueryParam
    },
  });
  let modelsData = [];
  let totalModelNumber = 0;
  useEffect(() => {
    refetch();
    if (data && data[modelQueryName]) {
      modelsData = (data ? data[modelQueryName] : []) ?? [];
      state.model = modelsData;
      totalModelNumber = (data ? data[modelTotalNumber] : 0) ?? 0;
      updateCustomerState(modelsData, totalModelNumber);
    }
  }, [data, reFetch, reload]);

  if (loading) return <NewOrdersLoader />;
  if (error) return <div>{error.message}</div>;
  modelsData = (data ? data[modelQueryName] : []) ?? [];
  totalModelNumber = (data ? data[modelTotalNumber] : 0) ?? 0;
  const showing = paginationShowing(modelsData, pageCount);
  return (
    <>
      {!categoriesList && (
        <OrderCountText>
          Showing
          <b>
            {/* {` ${showing()} - ${showing('end')} `} */}
            {' 1 - '}
            {` ${pageCount} `}
          </b>
          of
          <b>
            {` ${totalModelNumber} `}
          </b>
          {`${totalModelNumber <= 1 ? `${modelName}` : `${modelNamePlural}`}`}
        </OrderCountText>
      )}
      <ModelCardsContainer container spacing={3}>
        {renderModelCard(modelsData, refetch)}
        {/* { !loop
          ? renderModelCard(modelsData)
          : modelsData.map((model) => (
            <ModelCardGrid
              onClick={() => modelCardOnclickAction(model)}
              key={model.id}
              item
              xs
            >
              {renderModelCard(model)}
            </ModelCardGrid>
          ))} */}
      </ModelCardsContainer>

      {categoriesList ? (
        <Paper elevation={0} style={{ borderRadius: '0 0 12px 12px', padding: '1.7rem' }}>
          <DivFullWidth>
            {totalModelNumber > 0 && (
            <TablePagination
              total={totalModelNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </DivFullWidth>
        </Paper>
      )
        : (
          <DivFullWidth>
            {totalModelNumber > 0 && (
            <TablePagination
              total={totalModelNumber}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
            )}
          </DivFullWidth>
        )}

      {/* <FooterGrid item container> */}
      {/* <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={totalModelNumber}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      {/* </FooterGrid> */}
      {
        !categoriesList && (modelsData.length > 8)
          ? <GoToTop /> : ''

      }
    </>
  );
}

Models.propTypes = {
  state: PropTypes.instanceOf(Object).isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  currentPageCount: PropTypes.number.isRequired,
  findModelsQuery: PropTypes.instanceOf(Object).isRequired,
  extraModelQueryParam: PropTypes.instanceOf(Object),
  modelTotalNumber: PropTypes.string.isRequired,
  modelQueryName: PropTypes.string.isRequired,
  renderModelCard: PropTypes.instanceOf(Object).isRequired,
  modelCardOnclickAction: PropTypes.func.isRequired,
  modelName: PropTypes.string.isRequired,
  modelNamePlural: PropTypes.string.isRequired,
  loop: PropTypes.bool.isRequired,
  reload: PropTypes.bool,
  categoriesList: PropTypes.bool
};
Models.defaultProps = {
  extraModelQueryParam: {},
  reload: false,
  categoriesList: false
};
