import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { validatePassword } from './utils';
import PharmIQLogo from '../../assets/images/PharmIQBig.png';
import {
  SideWrapper, MainGrid, Logo, ContentsGrid, ContentsGrid2, ForgotTypo,
  ForgotSubTypo, ReturnTextField, SendButton, BackButton, CircularProgressLoader,
  ButtonText, BackGrid, Divide, Divide2, PasswordRules
} from './resetPassword.styles';

import { useStateValue } from '../../providers/stateProvider';
import { OgaToken } from '../../graphql/token';

const ResetPassword = () => {
  const initialPasswords = {
    password: '',
    confirmPassword: '',
  };

  const [passwordError, setPasswordError] = useState('');
  const [passwords, setPasswords] = useState(initialPasswords);
  const [validateState, setValidateState] = useState('');
  const [loading, setLoading] = useState(false);

  const [, dispatch] = Object.values(useStateValue());

  const { password, confirmPassword } = passwords;

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, []);

  const { uidb64, token } = useParams();
  const navigate = useNavigate();

  const checkPasswords = () => (
    password !== confirmPassword
      ? 'Passwords do not match'
      : ''
  );

  useEffect(() => {
    const same = password && password === confirmPassword;
    setValidateState(!passwordError && same);
  }, [passwordError, password, confirmPassword]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'password') setPasswordError(validatePassword(value));
    setPasswords({
      ...passwords,
      [name]: value
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const link = OgaToken.APP_LINK.split('pharmiq')[0];
    const resetUrl = `${link}password_reset/${uidb64}/${token}`;

    const user = { password: confirmPassword };

    const config = { headers: { 'Content-Type': 'application/json' } };

    axios.put(resetUrl, { user }, config)
      .then((res) => {
        if (res.status === 200) {
          setPasswords(initialPasswords);
          toast.success(res.data.message);
        }
      }).catch(() => {
        toast.error('This verification link is corrupted or expired');
      }).finally(() => {
        setLoading(false);
        setTimeout(() => navigate('/'), 3000);
      });
  };

  const renderHelperText = (name) => {
    switch (name) {
      case 'password':
        return password && passwordError
          ? 'Should be atleast 8 characters. UPPERCASE, lowercase, letters & numbers'
          : '';
      case 'confirmPassword':
        return confirmPassword ? checkPasswords() : '';
      default:
        return '';
    }
  };

  const renderError = (name) => {
    switch (name) {
      case 'password':
        return password ? passwordError : false;
      case 'confirmPassword':
        return confirmPassword ? !!checkPasswords() : false;
      default:
        return false;
    }
  };

  const returnTextField = (label, name) => (
    <ReturnTextField
      variant="filled"
      name={name}
      label={label}
      value={passwords[name]}
      type="password"
      size="small"
      fullWidth
      error={renderError(name)}
      helperText={renderHelperText(name)}
      onChange={handleChange}
    />
  );

  return (
    <Grid container>
      <MainGrid container item xs={7}>
        <ContentsGrid>
          <Logo alt="PharmIQ" src={PharmIQLogo} />
          <ForgotTypo variant="h5">
            Reset Password
          </ForgotTypo>
          <ForgotSubTypo variant="body1">
            Enter the new password you want to access your account with
          </ForgotSubTypo>
          {returnTextField('New Password', 'password')}
          {returnTextField('Confirm New Password', 'confirmPassword')}
          <Grid container item justifyContent="center">
            <SendButton
              variant="contained"
              size="small"
              color="primary"
              disabled={!validateState}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : (
                <ButtonText>
                  Reset
                </ButtonText>
              )}
            </SendButton>
          </Grid>
          <Divide variant="middle" light />
          <BackGrid container item justifyContent="center">
            <p>Remember your password?</p>
            <BackButton onClick={() => navigate('/')}>
              Try Logging in
            </BackButton>
          </BackGrid>
        </ContentsGrid>
      </MainGrid>
      <SideWrapper container item xs={5}>
        <Divide2 light orientation="vertical" />
        <ContentsGrid2>
          <ForgotSubTypo variant="body2">
            To protect your account the password must contain at least:
          </ForgotSubTypo>
          <PasswordRules>
            <li>1 uppercase letter (A-Z)</li>
            <li>1 lowercase letter (a-z)</li>
            <li>1 number (0-9)</li>
            <li>8 characters</li>
          </PasswordRules>
        </ContentsGrid2>
      </SideWrapper>
    </Grid>
  );
};

export default ResetPassword;
