import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import SideNav from '../shared/sideNav/sideNav';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import NotificationDialog from '../../shared/notificationDialog';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import TitleGrid from './titleGrid';
import BusinessSettingCard from './businessInformation/businessSettingCard';

import {
  TitleGridWrapper, OutletContentWrapper
} from './business.styles';
import { JSONParse } from '../../../utils/json';
import { UPDATE_PREFERENCE_SETTINGS_MUTATION } from '../../../mutations/business';
import { useStateValue } from '../../../providers/stateProvider';

const PreferencesContainer = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [preferences, setPreferences] = useState({
    automaticOrdering: 'automatic',
    orderCostFormat: 'average_order_cost',
    orderEmailingPolicy: 'suppliers',
    productPriceFormat: 'default',
    centralProcurementEmail: null,
    localCurrency: 1,
    loyaltyPoints: null,
    topMovingProduct: null,
    slowMovingProduct: null,
    automaticSales: null,
    zeroSalesFormat: null,
    dateRangeField: 'Last 1 months'
  });

  const [{ user: { getUserInfoRefetch } }] = Object.values(useStateValue());

  const [updatePreferencesSetting] = useMutation(UPDATE_PREFERENCE_SETTINGS_MUTATION);

  const {
    dataLoading, error, data, refetch
  } = useQuery(GET_MY_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(localStorage.getItem('ogarx_business_id'))
    },
  });

  useEffect(() => {
    if (!dataLoading && data && data.business) {
      const {
        settings, productSalesFormat, automaticOrderingFormat, loyaltyPointValue: loyaltyPoints, orderCostFormat,
        automaticSales, zeroSalesFormat
      } = data?.business ?? {};
      const parsedSettings = JSONParse(settings);

      const {
        orderEmailingPolicy, centralProcurementEmail, productPriceFormat, localCurrency
      } = parsedSettings || {};

      const {
        topMovingProductLimit: topMovingProduct,
        slowMovingProductLimit: slowMovingProduct
      } = productSalesFormat || {};

      setPreferences({
        ...preferences, orderCostFormat, orderEmailingPolicy, centralProcurementEmail, productPriceFormat,
        automaticSales, zeroSalesFormat, localCurrency, loyaltyPoints, topMovingProduct, slowMovingProduct,
        dateRangeField: `Last ${automaticOrderingFormat} months`,
        automaticOrdering: automaticOrderingFormat === 3 ? 'automatic' : 'date_range'
      });
    }
  }, [dataLoading, data]);

  const returnRange = (range) => {
    const val = range.split(' ')[1];
    return Number(val);
  };

  const handleUpdatePreference = () => {
    const {
      orderCostFormat, loyaltyPoints, topMovingProduct, slowMovingProduct, dateRangeField,
      automaticSales, zeroSalesFormat
    } = preferences;

    updatePreferencesSetting({
      variables: {
        automaticOrderingRangeInMonths: returnRange(dateRangeField),
        loyaltyPointValue: loyaltyPoints,
        orderCostFormat,
        slowMovingProductLimit: slowMovingProduct,
        topMovingProductLimit: topMovingProduct,
        zeroSalesFormat,
        automaticSales
      }
    }).then(() => {
      setOpenDialog(true);
      getUserInfoRefetch();
    });
  };

  if (dataLoading || !data) return <Loader />;
  if (error) return <div>{error.message}</div>;
  return (
    <MainContent>
      <TitleGridWrapper>
        <TitleGrid handleUpdatePreference={handleUpdatePreference} />
      </TitleGridWrapper>
      <OutletContentWrapper container spacing={3}>
        <SideNav />
        <BusinessSettingCard
          preferences={preferences}
          setPreferences={setPreferences}
        />
      </OutletContentWrapper>

      <NotificationDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title="Preferences Updated!"
        desc="Your preferences have been successfully updated!"
      />
    </MainContent>
  );
};

export default PreferencesContainer;
