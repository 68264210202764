import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment } from '@mui/material';
import { BodyCell, MainTableRow, CustomInputNumberBase } from './returnRow.styles';
import { CaretDownIcon, CaretUpIcon, DeleteIcon } from '../../../assets/svgs';
import CustomInputBase from '../../customComponents/customInputBase';

const ReturnRow = ({
  row, index, loading, handleSearch, removeRowHandler, counterHandler, updateCostPriceHandler, handleSearchByPId, poLoading, currentRowSearch
}) => {
  const {
    sn, productId, productName, supplier, quantity, newUnitCost, price
  } = row;
  const current = currentRowSearch === sn;

  return (
    <MainTableRow>
      <BodyCell>{index}</BodyCell>
      <BodyCell>
        <CustomInputBase
          data-testid="productID"
          value={productId}
          onChange={(e) => handleSearchByPId(e, sn)}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '150px' }}
          loading={current ? loading : false}
          type="number"
        />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          value={productName}
          variant="filled"
          size="small"
          step={0.01}
          onChange={(e) => handleSearch(e, sn)}
          style={{ width: '250px' }}
          loading={current ? loading : false}
          data-testid="productName"
        />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          value={supplier}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '250px' }}
          readOnly
        />
      </BodyCell>
      <BodyCell>
        <CustomInputNumberBase
          type="number"
          placeholder="0"
          min={1}
          size="medium"
          required
          value={quantity}
          onChange={(e) => counterHandler('change', row, e.target.value)}
          endAdornment={(
            <InputAdornment position="end">
              <Grid item container direction="column">
                <CaretUpIcon
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => counterHandler('increment', row, 0)}
                />
                <CaretDownIcon
                  style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                  onClick={() => counterHandler('decrement', row, 0)}
                />
              </Grid>
            </InputAdornment>
          )}
          style={{ width: '100px' }}
        />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          value={newUnitCost}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '150px' }}
          onChange={(e) => updateCostPriceHandler(e.target.value, row)}
          type="number"
          data-testid="poUnitCost"
        />
      </BodyCell>
      <BodyCell>
        <CustomInputBase
          value={price}
          variant="filled"
          size="small"
          step={0.01}
          style={{ width: '150px' }}
          readOnly
          data-testid="priceInput"
          ariaReadonly
        />
      </BodyCell>
      <BodyCell>
        <DeleteIcon data-testid="deleteRow" style={{ cursor: 'pointer', pointerEvents: poLoading ? 'none' : '' }} onClick={() => removeRowHandler(sn)} />
      </BodyCell>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  removeRowHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  index: PropTypes.number.isRequired,
  handleSearch: PropTypes.func,
  handleSearchByPId: PropTypes.func,
  counterHandler: PropTypes.func,
  updateCostPriceHandler: PropTypes.func,
  poLoading: PropTypes.bool,
  currentRowSearch: PropTypes.bool
};

ReturnRow.defaultProps = {
  loading: false,
  handleSearch: () => null,
  handleSearchByPId: () => null,
  counterHandler: () => null,
  updateCostPriceHandler: () => null,
  poLoading: false,
  currentRowSearch: false,
};

export default ReturnRow;
