import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Grid, ListItemIcon } from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  SetupIcon, LogoutIcon, CList, CListItem, CListItemButton, CListItemText
} from './affiliatesPopper.styles';
import { LOGOUT_MUTATION, SWITCH_ROLE_MUTATION } from '../../mutations/auth';
import { RenderPopper } from '../../utils/utils';

import settingsLogo from '../../assets/images/Setup Icon.png';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import { useStateValue } from '../../providers/stateProvider';
import { STORAGE_KEYS } from '../../utils/constants';
import { goToModule } from '../shared/utils';

const menuItems = [
  [settingsLogo, 'Account Setup', 'setup'],
  // [settingsLogo, 'Help desk', 'help-desk'],
];

const AffiliatesPopper = ({
  affiliatesLogoEl, handleAffiliateLogoClick,
}) => {
  const open = Boolean(affiliatesLogoEl);
  const [{ user: { allowedPermissionsMap } }, dispatch] = Object.values(useStateValue());

  const [logoutUser] = useMutation(LOGOUT_MUTATION);
  const [switchRole] = useMutation(SWITCH_ROLE_MUTATION);
  const navigate = useNavigate();

  const handleAccountNavigate = (item) => {
    handleAffiliateLogoClick();
    goToModule(navigate, switchRole, allowedPermissionsMap, item);
  };

  const handleLogout = () => {
    const authToken = localStorage.getItem('oga_auth_token');
    const restToken = localStorage.getItem('oga_rest_token');
    logoutUser({
      variables: {
        restToken,
        authToken
      }
    }).then(({ data }) => {
      dispatch({
        type: AffiliateActionTypes.UPDATE_AFFILIATE,
        payload: { isBusinessVirtualStore: false }
      });
      dispatch({ type: 'closeSideDrawer' });
      const { status, message } = data.logoutUser;
      const storageKeys = [...STORAGE_KEYS];
      if (status) {
        storageKeys.forEach((storageKey) => {
          localStorage.removeItem(storageKey);
        });
        toast.success(message);
        navigate('/');
      }
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  return (
    <RenderPopper
      showArrow
      open={open}
      anchorEl={affiliatesLogoEl}
      onClickAway={() => handleAffiliateLogoClick()}
      popperPlacement="bottom-end"
      modifiers={[
        { name: 'offset', options: { offset: [15, 15] } },
      ]}
    >
      <Grid container item>
        <CList>
          {menuItems.map((menuItem) => (
            <CListItem key={menuItem[2]} disableGutters>
              <CListItemButton onClick={() => handleAccountNavigate(menuItem[2])}>
                <ListItemIcon>
                  <SetupIcon />
                </ListItemIcon>
                <CListItemText primary={menuItem[1]} />
              </CListItemButton>
            </CListItem>
          ))}
          <CListItem disableGutters>
            <CListItemButton onClick={() => handleLogout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <CListItemText primary="Logout" />
            </CListItemButton>
          </CListItem>
        </CList>
      </Grid>
    </RenderPopper>
  );
};

AffiliatesPopper.propTypes = {
  affiliatesLogoEl: PropTypes.instanceOf(Object),
  handleAffiliateLogoClick: PropTypes.func.isRequired
};

AffiliatesPopper.defaultProps = {
  affiliatesLogoEl: {},
};

export default AffiliatesPopper;
