import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import {
  TableBody, Grid
} from '@mui/material';
import { JSONParse } from '../../utils/json';
import MainContent from '../customComponents/mainContent';
import { DATA_ANALYSIS_MUTATION } from '../../mutations/dataAnalysis';
import Loader from '../shared/loader';
import CTablePagination from '../customComponents/cTablePagination';
import paginationShowing from '../../utils/pagination';
import {
  MainContainer, TableWrapper, TableContainer, TableColumnHeaders, TableColumnHeaderTitle, FooterGrid,
  HeaderWrapper, PageTitleText, DialogOkButton, DialogActionButtonText, TitleTextGridContainer, MenuButton, DataCountText,
  DropDownArrow, DropUpArrow
} from './singleDataAnalysis.styles';
import SingleDataAnalysisRow from './singleDataAnalysisRow';
import { OgaToken } from '../../graphql/token';
import { useStateValue } from '../../providers/stateProvider';

const SingleDataAnalysis = (props) => {
  const param = props?.location?.state;
  const { id } = useParams();
  const navigate = useNavigate();
  const [header, setHeader] = useState([]);
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState('');
  const [rowCount, setRowCount] = useState();
  const [sorting, setSorting] = useState('false');
  const [singleHeader, setSingleHeader] = useState('');
  const [sortParam, setSortParam] = useState({});

  const [dataAnalysis] = useMutation(DATA_ANALYSIS_MUTATION);
  const pageCount = sessionStorage.getItem('rowsPerPage') || 50;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
  };

  const [state, setState] = useState(initialState);
  const {
    currentPage, currentPageCount
  } = state;
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };
  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };
  const sortObj = JSON.stringify(sortParam);
  useEffect(() => {
    dataAnalysis({
      variables: {
        queryId: id,
        params: param,
        pageCount: currentPageCount,
        pageNumber: currentPage,
        sortBy: sortObj
      }
    })
      .then(({ data }) => {
        setHeader(data.sqlQuery.header);
        setRows(data.sqlQuery.data);
        setTitle(data.sqlQuery.tableName);
        setRowCount(data.sqlQuery.totalRow);
      })
      .catch((err) => err);
  }, [currentPage, pageCount, sortObj]);

  const showing = paginationShowing(rows, currentPageCount);

  const handleExport = () => {
    const paramObject = JSONParse(param);
    const paramValues = Object.entries(paramObject).map(([key, value]) => `&${key}=${value}`);
    window.open(`${OgaToken.SERVER_LINK}export_query_data/?id=${id}${paramValues}`);
  };

  const handleSorting = (e, item) => {
    setSingleHeader(e.target.textContent);
    setSortParam({ name: item, desc: sorting });
    setSorting(sorting === 'false' ? 'true' : 'false');
  };
  if (!title) return <Loader />;
  return (
    <MainContent>
      <br />
      <MainContainer item container xs>
        <HeaderWrapper item container>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate('/data-analysis')}>
              <KeyboardArrowLeft fontSize="large" />
            </MenuButton>
            <PageTitleText>{title}</PageTitleText>
          </TitleTextGridContainer>
          <DialogOkButton
            onClick={() => handleExport()}
            disabled={!userPermissions?.includes('data_analysis_export')}
          >
            <DialogActionButtonText>
              Export
            </DialogActionButtonText>
          </DialogOkButton>
        </HeaderWrapper>
        <Grid container>
          <DataCountText>
            Showing
            <b>
              {` ${showing()} - ${showing('end')} `}
            </b>
            of
            <b>
              {` ${rowCount}`}
            </b>
          </DataCountText>
          <TableWrapper container>
            <TableContainer item countainer>
              <TableColumnHeaders style={{ padding: 0 }}>
                {header.length ? header.map((item, index) => (
                  <TableColumnHeaderTitle
                    key={index}
                    onClick={(e) => handleSorting(e, item[1])}
                  >
                    {(item.length === 2) ? item[0] : item }
                    {(sorting === 'true' && item[0] === singleHeader) ? <DropUpArrow /> : (sorting === 'false' && item[0] === singleHeader) ? <DropDownArrow /> : ''}
                  </TableColumnHeaderTitle>
                )) : '' }
              </TableColumnHeaders>
              <TableBody>
                {rows.map((_row) => (
                  <SingleDataAnalysisRow
                    key={_row.id}
                    row={_row}
                  />
                ))}
              </TableBody>
            </TableContainer>
          </TableWrapper>
          <FooterGrid item container>
            <CTablePagination
              rowsPerPageOptions={[50, 100, 150, 200]}
              component="div"
              count={rowCount}
              page={currentPage - 1}
              onChangePage={handleChangePage}
              rowsPerPage={currentPageCount}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </FooterGrid>
        </Grid>
      </MainContainer>
    </MainContent>
  );
};

export default SingleDataAnalysis;
