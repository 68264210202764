import React from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery, Paper, Hidden, Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  BodyCell, MainTableRow, ItemGrid, ItemContent, ItemLogo, ItemTypo, VisibleImg, TrackOrderButton,
  DetailContainer, TypeImg, DetailButton, OpenButton, DetailContent, ImgContent, ColorGrid, DetailsContent
} from './returnRow.styles';
import SavingsLogo from '../../../assets/images/marketOrder/savings.png';
import TotalLogo from '../../../assets/images/marketOrder/newTotal.png';
import DeliveryLogo from '../../../assets/images/marketOrder/deliveryDate.png';
import VisibilityLogo from '../../../assets/images/marketOrder/visibility.png';
import TrackOrderLogo from '../../../assets/images/marketOrder/whiteLocationTrack.png';

const ReturnRow = ({ orderItem, index }) => {
  const navigate = useNavigate();

  // const isLarge = useMediaQuery('(min-width:992px)');

  const {
    id, total, business, status, savings, dateCreated: date
  } = orderItem;
  const { logo } = business;
  const dateFormat = 'l h:mm';
  // const dateFormat = isLarge ? 'MMMM Do YY h:mm a' : 'l';
  const datePlaced = moment(date).format(dateFormat);
  const amount = currencyFormatter(total);
  const deliveryDate = moment(datePlaced).add(3, 'days').format('DD/MM/YY');

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container color={color} background={bkg} size="medium" variant="contained">
      {name}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (status) {
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#FBF777', 'Open Orders');
      case 'ORDER_RECEIVED':
        return getColorGrid('#07DE10', '#E8FFE9', 'Confirmed Orders');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#FBF777', 'Dispatched Orders');
      case 'ORDER_CLOSED':
        return getColorGrid('#606060', '#FBF777', 'Closed Orders');
      default:
        return getColorGrid('#606060', '#FBF777', 'N/A');
    }
  };
  const handleClick = (_id) => {
    navigate(`/orders/${_id}/affiliate`);
  };

  const handleTrackOrder = (_id) => {
    navigate({
      pathname: '/track-order',
      state: { orderId: _id }
    });
  };

  return (
    // <MainTableRow>
    //   {renderBodyCells()}
    // </MainTableRow>
    <ItemGrid container component={Paper} className={`mpFe-uat-orders-cards-${index}`}>
      <ItemContent item xs={12} md={2} onClick={() => handleClick(id)}>
        <ImgContent>
          <ItemLogo src={logo} alt="logo" />
        </ImgContent>
      </ItemContent>
      <ItemContent item xs={12} md={6} onClick={() => handleClick(id)}>
        <Hidden mdUp>
          <DetailContent>
            { getStatus() }
          </DetailContent>
        </Hidden>
        <ItemTypo>
          Placed on &nbsp;
          {datePlaced}
        </ItemTypo>
        <ItemTypo>
          #
          {id}
        </ItemTypo>
        <DetailContainer>
          <TypeImg src={DeliveryLogo} alt="delivery date" />
            &nbsp; &nbsp;
          <ItemTypo>
            Estimated Delivery Date: &nbsp;
            {deliveryDate}
          </ItemTypo>
        </DetailContainer>
        <DetailContainer>
          <TypeImg src={TotalLogo} alt="total" />
            &nbsp; &nbsp;
          <ItemTypo>
            New Total: &nbsp;
            <Hidden mdUp>
              <b>{`₦${amount}`}</b>
            </Hidden>
            <Hidden mdDown>
              {`₦${amount}`}
            </Hidden>
          </ItemTypo>
            &nbsp; &nbsp;
          <TypeImg src={SavingsLogo} alt="savings" />
            &nbsp; &nbsp;
          <ItemTypo>
            Savings: &nbsp;
            <Hidden mdDown>{`₦${savings}`}</Hidden>
            <Hidden mdUp><b>{`₦${savings}`}</b></Hidden>
          </ItemTypo>
        </DetailContainer>
      </ItemContent>
      <ItemContent item xs={12} md={4}>
        <Hidden lgDown>
          <DetailContent onClick={() => handleClick(id)}>
            { getStatus() }
          </DetailContent>
        </Hidden>
        <DetailsContent container spacing={3}>
          <Grid item sm={12} md={6}>
            <DetailButton
              size="medium"
              variant="outlined"
              startIcon={<VisibleImg src={VisibilityLogo} alt="visible" />}
              onClick={() => handleClick(id)}
            >
              View Order Details
            </DetailButton>
          </Grid>
          <Grid item sm={12} md={6}>
            <TrackOrderButton
              size="medium"
              variant="outlined"
              startIcon={<VisibleImg src={TrackOrderLogo} alt="visible" />}
              onClick={() => handleTrackOrder(id)}
            >
              Track Order
            </TrackOrderButton>
          </Grid>
        </DetailsContent>
      </ItemContent>
    </ItemGrid>
  );
};

ReturnRow.propTypes = {
  orderItem: PropTypes.shape(Object),
};

ReturnRow.defaultProps = {
  orderItem: {},
};

export default ReturnRow;
