import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import toast from 'react-hot-toast';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  ProductCardsContainer, ProductCardGrid, SimilarProductsBox, NextImage, TextHeader, Loading, PrevImage
} from './similarProducts.styles';
import { SIMILAR_PRODUCTS_QUERY } from '../../../queries/products';
import ProductCard from '../../orders/marketplaceOrder/productCard';

const SimilarProducts = ({
  therapeuticTypes,
  similarProductsName,
  cart
}) => {
  const [similarProduct, setSimilarProducts] = useState();
  const swiperRef = useRef();
  const isSmallScreen = useMediaQuery('(max-width: 991px)');
  const similarAction = true;

  const handleAlertOpen = (msg) => toast(msg);

  const variables = {
    searchProductNames: similarProductsName,
    therapeuticTypes
  };

  const { data, loading } = useQuery(SIMILAR_PRODUCTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.similarProducts) {
      const { similarProducts } = data;
      setSimilarProducts(similarProducts);
    }
  }, [data]);

  return (
    <SimilarProductsBox>
      <TextHeader>Similar Products</TextHeader>
      <PrevImage onClick={() => swiperRef.current?.slidePrev()}>
        <KeyboardArrowLeft fontSize="large" />
      </PrevImage>
      <Swiper
        slidesPerView={isSmallScreen ? 1 : 4}
        spaceBetween={3}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        className="mySwiper"
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <ProductCardsContainer container>
          {similarProduct?.length
            ? similarProduct.map((product, index) => (
              <SwiperSlide item lg={3} md={12} xs={12} spacing={1}>
                <ProductCardGrid key={product.id}>
                  <ProductCard
                    product={product}
                    openAlert={handleAlertOpen}
                    isSimilarProduct={similarProduct?.length}
                    cart={cart}
                    index={index}
                    // similarAction={similarAction}
                  />
                </ProductCardGrid>
              </SwiperSlide>
            )) : (loading
              ? <Loading>Loading...</Loading>
              : <Loading>No similar product</Loading>
            )}
        </ProductCardsContainer>
      </Swiper>
      <NextImage onClick={() => swiperRef.current?.slideNext()}>
        <KeyboardArrowRight fontSize="large" />
      </NextImage>
    </SimilarProductsBox>
  );
};

SimilarProducts.propTypes = {
  therapeuticTypes: PropTypes.instanceOf(Object),
  similarProductsName: PropTypes.instanceOf(Object),
  cart: PropTypes.bool
};

SimilarProducts.defaultProps = {
  therapeuticTypes: [],
  similarProductsName: [],
  cart: false
};

export default SimilarProducts;
