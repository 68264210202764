import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { InputAdornment, Grid, Hidden } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Search } from '@mui/icons-material';
import filter from '../../../assets/images/filter-icon.png';
import joySpot from '../../../assets/images/spot.png';
import NewOrder from './newOrder';
import NewOrderFilter from './filters/newOrderFilter';
import MainContent from '../../customComponents/mainContent';
import { ALL_SUPPLIERS_QUERY } from '../../../queries/suppliers';
import { ALL_THERAPEUTIC_TYPES_QUERY } from '../../../queries/therapeuticType';
import SearchIcon from '../../../assets/images/Search Icon.png';
import {
  OrderGridContainer, TitleGridContainer, TitleTextGridContainer, PrimaryTitle,
  SecondaryTitle, OrderSubHeader, ProductItemsTotal,
  TextWrapper, OrderItemsGrid, OrderCountText, MenuButton, ClearIcon,
  SearchImage, KeyboardArrow, SearchContainer, SearchTextField, SearchButton,
  ProductItemsContainer, StyledTag, FilterImg, RightDrawer, TutorialTextIcon
} from './newOrderContainer.styles';
import paginationShowing from '../../../utils/pagination';
import { useStateValue } from '../../../providers/stateProvider';
import AffiliateActionTypes from '../../../providers/reducers/affiliate/affiliateTypes';
import FilterTag from './filters/filterTag';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';

import ManualOrderFilter from './filters/manualOrderFilter';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NewOrderContainer = ({
  history, location, primaryTitle, onlyMarketProducts, backRoute
}) => {
  const [joyControl, setJoyControl] = useState({
    run: false,
    stepIndex: 0
  });
  const [joyState, setJoyState] = useState([
    {
      target: '.offset',
      content: '',
    },
    {
      target: '.joy-welcome-to-new-order',
      content: 'Welcome to the OGApharmacy marketplace, you are few clicks away from learning how to place your order, click next to start the tutorial',
    },
    {
      target: '.joy-search-product',
      content: 'You can search products by name',
    },
    {
      target: '.joy-update-quantity',
      content: 'Specify the quantity you want to order',
    },
    {
      target: '.joy-add-to-cart',
      content: 'Click here to add items to your cart',
    },
    {
      target: '.joy-cart-link',
      content: 'After adding all your products to the cart, you can click here to review your order cart and place your order',
    },
    {
      target: '.joy-filter-supplier',
      content: 'Here you can select a supplier to view only their range of products - we call this a filter, you can filter by one or more suppliers',
    },
    {
      target: '.joy-filter-text',
      content: 'Here you can search for a supplier that you want to view their product range',
    },
    {
      target: '.joy-filter-view',
      content: 'You will see a list of filters you have applied here, you can remove anyone you no longer want',
    },
    {
      target: '.joy-new-order-pagination',
      content: 'You can view more product pages here',
    }
  ]);
  const navigate = useNavigate();

  const [priceRange, setPriceRange] = useState([
    {
      priceMin: '0.00',
      priceMax: '5000.00',
      displayPrice: '0 - 5,000'
    },
    {
      priceMin: '5000.00',
      priceMax: '10000.00',
      displayPrice: '5,000 - 10,000'
    },
    {
      priceMin: '10000.00',
      priceMax: '15000.00',
      displayPrice: '10,000 - 15,000'
    },
    {
      priceMin: '15000.00',
      priceMax: '200000.00',
      displayPrice: '15,000 Above'
    }
  ]);
  // const { pathname } = location;

  const { data: allTherapeuticTypesData } = useQuery(ALL_THERAPEUTIC_TYPES_QUERY);
  const { allTherapeuticTypes } = allTherapeuticTypesData || {};

  const { data: allSuppliersData } = useQuery(ALL_SUPPLIERS_QUERY, {
    variables: {
      onlyProductVendorSuppliers: true
    }
  });

  const updatedSuppliers = allSuppliersData?.allSuppliers?.map((newSupplier) => {
    const { name, paymentTerm, providerPromo } = newSupplier;
    return {
      name, call: name, creditDays: paymentTerm || 'N/A', providerPromo
    };
  });
  const promoMap = new Map([['FIRST_ORDER', 'First Order Promo']]);
  const suppliers = [
    ...(updatedSuppliers || [])
  ];

  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    searchText: '',
    searchValue: '',
    filteredSupplier: new Map(),
    filteredPromo: new Map(),
    currentPage: 1,
    currentPageCount: pageCount,
    stateProducts: [],
    productType: '',
    therapyIds: [],
    drawer: false,
    priceMin: false,
    priceMax: false
  };
  const [state, setState] = useState(initialState);
  const {
    searchText, filteredSupplier, filteredPromo, currentPage, drawer
  } = state;

  const [{ catalogue: { products, totalCount } }, dispatch] = Object.values(useStateValue());
  useLayoutEffect(() => {
    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        addAffiliateDialogOpen: true
      }
    });
    dispatch({
      type: CartActionTypes.UPDATE_IS_MANUAL,
      payload: location?.pathname?.startsWith('/manual-order')
    });
    if (searchText && searchText.length >= 3) {
      setState({ ...state, searchValue: searchText });
    } else if (!searchText) {
      setState({ ...state, searchValue: '' });
    }
  }, [searchText]);

  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const handleProductsOption = (item) => {
    setState({ ...state, productType: item.key });
  };

  const handleTherapyOption = (item) => {
    const { therapyIds } = state;
    const index = therapyIds.findIndex((_item) => _item.id === item.id);
    if (index !== -1) therapyIds.splice(index, 1);
    else therapyIds.push(item);
    setState({ ...state, therapyIds });
  };

  const handlePriceFilter = (price) => {
    setState({ ...state, priceMin: price.priceMin, priceMax: price.priceMax });
  };

  const handleFilteredSupplier = (supplier) => {
    if (filteredSupplier.get(supplier)) {
      filteredSupplier.delete(supplier);
    } else {
      filteredSupplier.set(supplier, supplier);
    }
    setState({ ...state, filteredSupplier });
  };
  const handleFilteredPromo = (promo) => {
    if (filteredPromo.get(promo)) {
      filteredPromo.delete(promo);
    } else {
      filteredPromo.set(promo, promo);
    }
    setState({ ...state, filteredPromo });
  };
  const toggleFilter = (tag, filterType) => {
    switch (filterType) {
      case 'supplier':
        handleFilteredSupplier(tag);
        break;
      case 'promo':
        handleFilteredPromo(tag);
        break;
      default:
        break;
    }
  };
  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const toggleDrawer = () => {
    setState((_state) => ({ ..._state, drawer: !_state.drawer }));
  };

  const handleClearSearch = () => {
    setState({ ...state, searchText: '' });
  };

  const renderSearchField = (placeholder, searchValue, handleChange, ClearSearch, small = false, joyClass = 'joy-search-product') => (
    <SearchContainer className={joyClass} container item xs={12} md={small ? 12 : 6}>
      <SearchTextField
        variant="outlined"
        label=""
        autoFocus={placeholder === 'Search product item'}
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        fullWidth
        InputProps={{
          endAdornment:
  <InputAdornment position="end">
    <SearchButton
      aria-label={placeholder}
      edge="end"
      disabled={!searchValue}
      small={small}
      onClick={ClearSearch}
    >
      {searchValue ? (
        <ClearIcon small={small} />
      ) : (
        <Search />
      )}
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const showing = paginationShowing(products, totalCount);
  const renderPromoFilter = () => {
    const selectedPromos = [];
    filteredPromo.forEach((promo) => {
      selectedPromos.push(promo);
    });
    return selectedPromos.map((promo) => (
      <StyledTag item key={promo}>
        <FilterTag
          toggleFilter={toggleFilter}
          filterType="promo"
          filterKey={promo}
          tag={promoMap.get(promo)}
        />
      </StyledTag>
    ));
  };
  const handleStartTutorial = () => {
    setJoyControl({ run: false });
    setTimeout(() => setJoyControl({ run: true, stepIndex: 1 }), 0);
  };
  const handleJoyrideCallback = (data) => {
    const {
      action, index, status, type
    } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setJoyControl({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyControl({ run: false });
    }
  };
  const renderFilters = () => {
    const selectedSuppliers = [];
    filteredSupplier.forEach((supplier) => {
      selectedSuppliers.push(supplier);
    });
    return (
      <Grid item container className="joy-filter-view" justifyContent="flex-end">
        {selectedSuppliers.map((supplier) => (
          <StyledTag item key={supplier}>
            <FilterTag
              toggleFilter={toggleFilter}
              filterType="supplier"
              filterKey={supplier}
              tag={supplier}
            />
          </StyledTag>
        ))}
        {renderPromoFilter()}
      </Grid>
    );
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGridContainer container item>
          <TitleTextGridContainer item>
            <MenuButton onClick={() => navigate(`${backRoute}`)}>
              <KeyboardArrow />
            </MenuButton>
            <TextWrapper>
              <PrimaryTitle variant="h5">
                {primaryTitle}
              </PrimaryTitle>
              <SecondaryTitle>
                Add items to cart
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
        </TitleGridContainer>
        <Grid>
          <Hidden mdUp>
            {renderSearchField('Search product item', searchText, handleSearch, handleClearSearch, false, 'joy-search-product')}
          </Hidden>
        </Grid>
        <OrderSubHeader container item>
          <ProductItemsContainer container item xs={6} md={3} xl={3}>
            <ProductItemsTotal>
              {`${totalCount} Items`}
            </ProductItemsTotal>
          </ProductItemsContainer>
          <Hidden mdUp>
            <MenuButton onClick={() => toggleDrawer()}>
              <FilterImg src={filter} alt="filter button" />
            </MenuButton>
          </Hidden>
          <Hidden mdDown>
            {renderSearchField('Search product item', searchText, handleSearch, handleClearSearch)}
          </Hidden>
          <Hidden mdDown>
            <Grid item md={3} xl={3} style={{ textAlign: 'right' }}>
              <Grid
                style={{ display: 'inline-flex', cursor: 'pointer', fontWeight: '600' }}
                onClick={handleStartTutorial}
                className="joy-welcome-to-new-order"
              >
                <img alt="joy-icon" src={joySpot} />
                <TutorialTextIcon style={{ display: 'inline-block', marginTop: 7, marginLeft: 5 }}>Open Tutorial</TutorialTextIcon>
              </Grid>
            </Grid>
          </Hidden>
        </OrderSubHeader>
        <RightDrawer anchor="right" open={drawer} onClose={() => toggleDrawer()}>
          <NewOrderFilter
            className="order-area"
            promos={promoMap}
            suppliers={suppliers}
            filteredSupplier={filteredSupplier}
            therapeuticTypes={allTherapeuticTypes}
            priceRange={priceRange}
            containerState={state}
            handleProductsOption={handleProductsOption}
            handleTherapyOption={handleTherapyOption}
            filteredPromo={filteredPromo}
            handleFilteredPromo={handleFilteredPromo}
            handleFilteredSupplier={handleFilteredSupplier}
            renderSearchField={renderSearchField}
            handlePriceFilter={handlePriceFilter}
          />
        </RightDrawer>

        <OrderItemsGrid container direction="row" justifyContent="space-between">
          <Grid container>
            <Grid container direction="row" justifyContent="space-between">
              <OrderCountText item sm={6} md={8}>
                Showing
                <b>
                  {' '}
                  {showing()}
                  -
                  {showing('end')}
                  {' '}
                </b>
                of
                <b>
                  {' '}
                  {totalCount}
                  {' '}
                </b>
                products
              </OrderCountText>
              <Grid item sm={6} md={4}>
                <Hidden mdUp>
                  <Grid item style={{ textAlign: 'right' }}>
                    <Grid
                      style={{ display: 'inline-flex', cursor: 'pointer', fontWeight: '600' }}
                      onClick={handleStartTutorial}
                      className="joy-welcome-to-new-order"
                    >
                      <img alt="joy-icon" src={joySpot} />
                      <TutorialTextIcon style={{ display: 'inline-block', marginTop: 0, marginLeft: 5 }}>Open Tutorial</TutorialTextIcon>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} container>
              {renderFilters()}
            </Grid>
          </Grid>
        </OrderItemsGrid>
        <Grid container>
          <Grid item md={3} style={{ paddingRight: '15px' }}>
            <Hidden mdDown>
              <NewOrderFilter
                promos={promoMap}
                checkedIcon={checkedIcon}
                icon={icon}
                suppliers={suppliers}
                filteredSupplier={filteredSupplier}
                therapeuticTypes={allTherapeuticTypes}
                priceRange={priceRange}
                containerState={state}
                handleProductsOption={handleProductsOption}
                handleTherapyOption={handleTherapyOption}
                filteredPromo={filteredPromo}
                handleFilteredPromo={handleFilteredPromo}
                handleFilteredSupplier={handleFilteredSupplier}
                renderSearchField={renderSearchField}
                handlePriceFilter={handlePriceFilter}
              />
            </Hidden>
          </Grid>
          <Grid item md={9}>
            <NewOrder
              state={state}
              handleChangePage={handleChangePage}
              page={currentPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              onlyMarketProducts={onlyMarketProducts}
              handleSearch={handleSearch}
            />
          </Grid>
        </Grid>
      </OrderGridContainer>
      <Joyride
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: '#F0F0F0',
            backgroundColor: '#F0F0F0',
            primaryColor: '#000',
            textColor: '#424242',
            width: 450,
          },
          tooltipContent: {
            fontSize: 22,
          }
        }}
        run={joyControl.run}
        stepIndex={joyControl.stepIndex}
        continuous
        showSkipButton
        showProgress
        scrollOffset={360}
        steps={joyState}
      />
    </MainContent>
  );
};

NewOrderContainer.propTypes = {
  history: PropTypes.instanceOf(Object),
  primaryTitle: PropTypes.string,
  onlyMarketProducts: PropTypes.bool,
  backRoute: PropTypes.string,
};

NewOrderContainer.defaultProps = {
  history: {},
  primaryTitle: 'New Order',
  onlyMarketProducts: true,
  backRoute: '/orders'
};

export default NewOrderContainer;
