import {
  Grid,
  Card,
  Box,
  ImageListItem,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Button,
  Paper
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const FormsGridContainer = styled(Grid)`
  margin-bottom: 50px;
  margin-top: 20px;

  @media (max-width: 991px) {
    justify-content: center;
  }
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
`;

export const Footer = styled('div')`
  padding: 0 1.5rem;
  width: 100%;
`;

export const TCustomButton = styled(Button)`
  width: 100%;
  height: 48px;
  background: #303030 !important;
  box-shadow: 0px 4px 14px rgba(151, 151, 151, 0.19);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
`;

export const MainTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const FilterGrid = styled(Grid)`
  padding: 2rem 1.5rem 0.5rem;
  align-items: center;
`;

export const ButtonStyled = styled(Button)`
  min-width: 107px;
  min-height: 48px;
  background: #000 !important;
  border-radius: 10px;
  color: #fff;
`;

export const Header = styled(TableCell)`
  text-transform: uppercase;
  border-bottom: none;
  padding: 15px;
  font-weight: 500;
  font-size: 14px;
  background: #f4f7fc;
  white-space: nowrap;
  text-align: center;
`;

export const TableCellStyled = styled(TableCell)`
  text-transform: capitalize;
  color: #24292e;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding: 20px 10px;
  border-bottom: none;
`;

export const TableContainerWrapper = styled(TableContainer)`
  border-radius: 0;
  margin: 1rem 1.5rem;
  box-shadow: 0 0 0 0;
`;

export const TableComponent = styled(Table)`
  min-width: 960px;
`;

export const PageTitleText = styled(Typography)`
  color: #303030;
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
  padding-top: 1rem;
  @media (min-width: 992px) {
    font-size: 25px;
    padding-top: 0;
  }
`;

export const SupplierOrderFormsGridListContainer = styled('div')`
  margin-bottom: 50px;
  margin-top: 20px;
`;

export const ViewSupplierOrderFormLink = styled(Link)`
  color: #424242;
`;

export const SupplierOrderFormsGridItem = styled(Card)`
  width: 100%;
  height: 20rem;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin: 15px 0px;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 992px) {
    width: 95%;
    height: 18rem;
  }
`;

export const SupplierOrderFormsDateCheckContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SupplierOrderFormsBottomWrapper = styled(
  SupplierOrderFormsDateCheckContainer
)`
  margin: 30px 0px 10px;
`;

export const SupplierOrderPharmacyDetailsContainer = styled(
  SupplierOrderFormsDateCheckContainer
)`
  margin-bottom: unset;
`;

export const DateCreatedText = styled('span')`
  color: #82849f;
  font-size: 20px;

  @media (min-width: 992px) {
    font-size: 15px;
  }
`;

export const OrderIdText = styled(DateCreatedText)`
  margin-bottom: 15px;
`;

export const OrderQuantityText = styled(DateCreatedText)`
  margin-bottom: 20px;
`;

export const SupplierNameText = styled('span')`
  color: #424242;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: auto;
`;

export const PharmacyNameText = styled(DateCreatedText)`
  color: #424242;
  font-size: 27px;
  font-weight: 600;
  margin-bottom: 5px;
  height: 40px;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const OrderAmountText = styled(PharmacyNameText)`
  font-weight: 600;
  height: unset;
  margin-bottom: unset;
`;

export const SupplierNameTextBold = styled(Typography)`
  color: #4d4f5c;
  font-size: 24px;
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const HeaderSection = styled(Grid)`
  align-items: center;
  justify-content: space-between;
`;

export const SupplierOrderFormGridListTile = styled(ImageListItem)`
  width: unset !important;
  height: unset !important;
  padding: unset !important;

  margin-right: 50px;
`;

export const SlideWrapper = styled(Grid)`
  align-items: center;
  margin-top: 0;
  margin-bottom: 3rem;
`;

export const ButtonGrid = styled(Grid)`
  width: 5%;

  @media (min-width: 992px) {
    width: 3%;
  }
`;

export const SlideButton = styled('button')`
  width: 52px;
  height: 50px;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

export const Container = styled('div')`
  white-space: nowrap;
  overflow: auto;
  width: 90%;

  @media (min-width: 992px) {
    width: 94%;
  }
`;

export const CheckBoxButton = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  font-size: 1.5rem;
  font-family: Montserrat, medium, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #424242;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 992px) {
    width: 9rem;
    font-size: 1rem;
  }
`;

export const CheckBoxLogo = styled('img')`
  width: 19px;
  height: 19px;
  margin-right: 8px;
`;

export const FormsHeaderGrid = styled(Grid)`
  position: relative;
  padding: 1rem;
  margin: 2rem 0;
  border-top: 3px solid #cccccc;
  border-bottom: 3px solid #cccccc;

  @media (min-width: 992px) {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
`;

export const BusinessToggleGrid = styled(Grid)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

export const ButtonsGrid = styled(Grid)`
  justify-content: space-between;

  @media (max-width: 991px) {
    margin-top: 2rem;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)`
  font-size: 4rem;
  color: #235a91;

  @media (min-width: 992px) {
    font-size: 2rem;
  }
`;

export const SelectStyled = styled('select')`
  width: 7rem;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #24292e;
  background: none;
  margin-bottom: 2rem;
  &:focus {
    outline: none;
  }
`;

export const OptionStyled = styled('option')`
  padding: 0 2rem;
`;

export const ColorField = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Status = styled('div')`
  background: ${({ active }) => (active ? '#00B588' : '#f39053')};
  border-radius: 50%;
  width: 9px;
  height: 9px;
`;
