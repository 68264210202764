import React from 'react';
import PropTypes from 'prop-types';
import { Grow } from '@mui/material';
import {
  SupplierItem, SearchPaper, ThePopper
} from './searchPopper.styles';

const SearchPopper = ({
  open, anchorEl, searchedSuppliers,
  handleSetSupplier
}) => (
  <ThePopper
    open={open}
    placement="bottom-left"
    anchorEl={anchorEl}
    transition
    disablePortal
    modifiers={{
      flip: {
        enabled: false,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement: 'viewport',
      },
    }}
  >
    {({ TransitionProps, placement }) => (
      <Grow
        {...TransitionProps}
        id="menu-list-grow"
        style={{ transformOrigin: placement === 'bottom' ? 'top' : 'bottom-start' }}
      >
        <SearchPaper elevation={0} square>
          {searchedSuppliers?.map((s) => (
            <SupplierItem onClick={() => handleSetSupplier(s)}>{s.name}</SupplierItem>
          ))}
        </SearchPaper>
      </Grow>
    )}
  </ThePopper>
);

SearchPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.string.isRequired,
  searchedSuppliers: PropTypes.instanceOf(Array).isRequired,
  handleSetSupplier: PropTypes.func.isRequired
};

export default SearchPopper;
