import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import {
  MainContainer, HeaderTypo, HeaderWrapper, HeaderButton, NNotificationBody, HeaderRight, BellBg,
  NotificationIcon, DescTypo, CogStyle, BellNotifyIconStyled, ArchiveStyled, IconWrapper, Tooltip,
  ListN, NotRead, NotifyTextContainer, NotifyTextHeader, NotifyTextBody, NotifyContainer, Track,
  FooterWrapper, ArrowRightIcon
} from './notificationsPopper.styles';
import { CHANGE_NOTIFICATION_STATUS, UPDATE_ALL_NOTIFICATION_STATUS } from '../../mutations/notifications';
import { RenderPopper } from '../../utils/utils';

const NotificationsPopper = ({
  notifications, notificationsEl, handleBellClick, refetch
}) => {
  const open = Boolean(notificationsEl);
  const navigate = useNavigate();

  const handleArchive = (tab) => {
    handleBellClick();
    navigate(`/notifications/${tab}`);
  };

  const handleNavigate = () => {
    handleBellClick();
    navigate('/notification-settings');
  };

  const today = moment().startOf('day').format('DD-MM-YYYY');

  const [updateNotification] = useMutation(CHANGE_NOTIFICATION_STATUS);
  const updateNotificationHandler = (notificationId, status) => {
    updateNotification({
      variables: { notificationId, status }
    }).then(({ data }) => {
      const { message } = data?.updateErpNotification ?? '';
      toast.success(message);
      refetch();
    })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const [updateAllNotification, { loading }] = useMutation(UPDATE_ALL_NOTIFICATION_STATUS);
  const updateAllNotificationHandler = (status) => {
    updateAllNotification({
      variables: { status }
    }).then(({ data }) => {
      const { message } = data?.updateAllErpNotification ?? '';
      toast.success(message);
      refetch();
    })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const changeNotificationHandler = (item) => {
    const {
      id, status, productId, productName, batchNo
    } = item;
    if (status === 'NOT_READ') {
      updateNotificationHandler(id, 'read');
    } else updateNotificationHandler(id, 'deleted');
    handleBellClick();
    if (!batchNo) {
      navigate(`/inventory/products/all/${today}/${today}/${productId}/${productName}`);
    } else {
      navigate(`/batch-details/${batchNo}/${productName}`);
    }
  };

  return (
    <RenderPopper
      showArrow
      open={open}
      anchorEl={notificationsEl}
      onClickAway={() => handleBellClick()}
      popperPlacement="bottom-end"
      modifiers={{
        offset: {
          enabled: true,
          offset: '1vw, 2vh',
        }
      }}
    >
      <MainContainer container>
        <HeaderWrapper container item>
          <HeaderTypo variant="caption">OGApharmacy Notifications</HeaderTypo>
          <HeaderRight container item xs={4}>
            <HeaderButton disabled={loading} onClick={() => updateAllNotificationHandler('deleted')}>{ loading ? 'Loading  ...' : 'Clear All' }</HeaderButton>
            <IconWrapper item onClick={() => handleArchive('archive')}>
              <ArchiveStyled aria-label="Archive" />
              <Tooltip variant="caption">Archive</Tooltip>
            </IconWrapper>
            <IconWrapper item onClick={handleNavigate}>
              <CogStyle aria-label="Settings" />
              <Tooltip variant="caption">Settings</Tooltip>
            </IconWrapper>
          </HeaderRight>
        </HeaderWrapper>
        <NotifyContainer container item>
          {!notifications.length ? (
            <NNotificationBody item>
              <NotificationIcon />
              <DescTypo variant="caption">No Notifications Yet!</DescTypo>
              All notifications will be displayed here.
            </NNotificationBody>
          ) : notifications?.map((item) => {
            const {
              id, title, message, dateCreated, status
            } = item;
            return (
              <ListN
                key={id}
                onClick={() => changeNotificationHandler(item)}
              >
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <BellBg>
                    {status === 'NOT_READ' && <NotRead />}
                    <BellNotifyIconStyled />
                  </BellBg>
                  <NotifyTextContainer>
                    <NotifyTextHeader>{title}</NotifyTextHeader>
                    <NotifyTextBody>{message}</NotifyTextBody>
                  </NotifyTextContainer>
                </div>
                <Track>{moment.utc(dateCreated).local().startOf('seconds').fromNow()}</Track>
              </ListN>
            );
          })}
        </NotifyContainer>
        <FooterWrapper container item>
          <HeaderButton onClick={() => handleArchive('all')}>
            View More
            <ArrowRightIcon />
          </HeaderButton>
        </FooterWrapper>
      </MainContainer>
    </RenderPopper>
  );
};

NotificationsPopper.propTypes = {
  notifications: PropTypes.shape(Array),
  notificationsEl: PropTypes.shape(Object),
  handleBellClick: PropTypes.func.isRequired,
  refetch: PropTypes.func
};

NotificationsPopper.defaultProps = {
  notifications: [],
  notificationsEl: {},
  refetch: () => null
};

export default NotificationsPopper;
