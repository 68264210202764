import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import currencyFormatter from '../../shared/currencyFormatter';

import {
  ProductContainer, ProductDetail, ProductDetailImage, ProductDetailBrandNameText,
  ProductDetailPackSizeText, HideMd, ShowMd, ProductDetailSavingsText,
  ProductDetailItemPriceText, ProductCounter, ProductCounterMobileContainer,
  ProductDetailMainContainer, ProductDetailPriceMobileContainer, ProductDetailNameMainContainer,
  EditImg, TickImg, ProductDetailBrandTypo, ValuesGrid
} from '../../cart/product-item/styles';

import EditIcon from '../../../assets/images/Edit Batch.png';
import DeleteIcon from '../../../assets/images/Delete.png';
import AddBatch from '../../../assets/images/Add Batch.png';
import InBatch from '../../../assets/images/Batch Added Successfully.png';
import MarkNotReceived from '../../../assets/images/Mark as not received.png';
import NotReceived from '../../../assets/images/Product not Received.png';
import SingleBatchDialog from './singleBatchDialog';
import AllBatchDialog from './allBatchDialog';
import { useStateValue } from '../../../providers/stateProvider';
import { isERP } from '../../../utils/utils';

import { RECONCILE_MANUAL_SUPPLIER_ORDER_NOT_RECEIVED } from '../../../mutations/orders';

const ProductItem = ({
  product, addToBatch, addToNotReceived, clearNotReceived, status, lastUpdated, allBatches, deleteBatch, totalPrice, setTotalPrice
}) => {
  const {
    id, opSetId, name: brandName, status: oStatus, quantity, resolvedPriceInUseValue,
    orderCost, image, packSize, marketRrp
  } = product || {};
  const [{
    user: { platform }
  }] = Object.values(useStateValue());
  const formatDate = (d, value, date) => new Intl.DateTimeFormat('en', { [d]: value }).format(date);

  const date = lastUpdated ? new Date(lastUpdated) : new Date();
  const unitCost = platform === 'erp' ? orderCost : resolvedPriceInUseValue;
  const { id: orderId } = useParams();

  const orderStatus = oStatus === 'MANUAL_SUPPLIER_ORDER_NOT_RECEIVED' ? 1 : 0;

  const [receivedQty, setReceivedQty] = useState(0);
  const [modal, setModal] = useState();
  const [allBatchModal, setAllBatchModal] = useState();
  const [notReceivedImg, setNotReceivedImg] = useState(orderStatus > 0);
  const [batchAdded, setBatchAdded] = useState(false);
  const [style, setStyle] = useState(orderStatus > 0 ? 0.3 : 1);
  const [editBatch, setEditBatch] = useState(false);
  const [singleBatchNo, setSingleBatchNo] = useState({});

  const [notReceiveManualSupplierOrder] = useMutation(RECONCILE_MANUAL_SUPPLIER_ORDER_NOT_RECEIVED);

  const singleBatchItem = allBatches?.find((item) => item.orderProductId === opSetId);
  const qtyReceivedArray = singleBatchItem?.batches.map(({ quantityReceived }) => quantityReceived);
  const qtyReceived = qtyReceivedArray?.reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (platform === 'erp') {
      if (totalPrice?.find((i) => i.proID === opSetId)) {
        const items = totalPrice?.map(({ proID, itemTotal }) => {
          if (proID === opSetId) return ({ proID, itemTotal: Number(unitCost) * Number(qtyReceived) });
          return ({ proID, itemTotal });
        });
        setTotalPrice(items);
      } else {
        setTotalPrice((t) => ([...t, { proID: opSetId, itemTotal: 0 }]));
      }
    }
  }, [qtyReceived]);

  const submitBatch = (batch, addMore) => {
    clearNotReceived();
    addToBatch(batch);
    if (!addMore) setModal(false);
    toast.success('Batch added to product!');
    setStyle(0.3);
    setBatchAdded(true);
    setNotReceivedImg(false);
    setReceivedQty(receivedQty + Number(batch.quantityReceived));
  };

  const notReceived = async () => {
    const { loading, data } = await notReceiveManualSupplierOrder({
      variables: {
        supplierOrderId: Number(orderId),
        productBatches: [{ orderProductId: opSetId }],
      }
    });
    addToNotReceived();
    if (!loading) {
      setNotReceivedImg(true);
      setStyle(0.3);
      setBatchAdded(false);
      setReceivedQty(0);
    }
  };

  return (
    <ProductContainer>
      <ShowMd container item>
        <Grid item container xs={3} style={{ position: 'relative' }}>
          {batchAdded
            ? <TickImg src={InBatch} alt="add to batch" />
            : <TickImg title="..." src={InBatch} style={{ opacity: 0, width: '17%' }} alt="..." />}
          <ProductDetailImage src={image} alt="" />
        </Grid>
        <Grid item container xs={9} direction="column">
          <Grid item container>
            <ProductDetailBrandTypo style={{ opacity: style }}>
              { brandName }
            </ProductDetailBrandTypo>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item container xs={7} style={{ borderRight: '2px solid #000' }}>
              <ProductDetailPackSizeText>
                Pack Size:&nbsp;
                {packSize}
              </ProductDetailPackSizeText>
            </Grid>
            <Grid item container xs={5} justifyContent="flex-end">
              <ProductDetailPackSizeText style={{ color: '#000' }}>
                {` ${currencyFormatter(unitCost)}`}
              </ProductDetailPackSizeText>
            </Grid>
            <ValuesGrid item container style={{ opacity: style }}>
              {`${Number(quantity)} X ${currencyFormatter(unitCost)}`}
            </ValuesGrid>
            <ProductDetailItemPriceText style={{ opacity: style }}>
              {receivedQty === 0 ? 0 : currencyFormatter(Number(unitCost) * (Number(qtyReceived) ?? Number(receivedQty)))}
            </ProductDetailItemPriceText>
          </Grid>
          {!isERP() && (
            <ProductDetailSavingsText style={{ top: '11rem' }}>
              Savings: ₦&nbsp;
              {(marketRrp - unitCost) < 0
                ? currencyFormatter(0)
                : currencyFormatter((Number(marketRrp) || Number(unitCost)) - Number(unitCost))}
            </ProductDetailSavingsText>
          )}
        </Grid>
      </ShowMd>
      <HideMd>
        <ProductDetail style={{ width: '40%' }}>
          { batchAdded
            ? <TickImg src={InBatch} alt="add to batch" />
            : <TickImg src={InBatch} style={{ opacity: 0 }} alt="..." />}
          <ProductDetailImage src={image} alt="" />

          <ProductDetailNameMainContainer style={{ opacity: style }}>
            <ProductDetailBrandNameText>
              { brandName }
            </ProductDetailBrandNameText>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp;
              { packSize }
            </ProductDetailPackSizeText>
          </ProductDetailNameMainContainer>
        </ProductDetail>
        <ProductCounter
          style={{
            opacity: style, width: '16%', margin: 0, justifyContent: 'center'
          }}
        >
          <p style={{ color: '#424242', fontSize: '20px' }}>
            {Number(quantity)}
          </p>
        </ProductCounter>
        <ProductCounter
          style={{
            opacity: style, width: '16%', margin: 0, justifyContent: 'center'
          }}
        >
          <p style={{ color: '#424242', fontSize: '20px' }}>
            {qtyReceived ?? receivedQty}
          </p>
        </ProductCounter>
        <ProductDetailMainContainer style={{ width: '15%', margin: 0 }}>
          <ProductDetailItemPriceText style={{ opacity: style }}>
            {currencyFormatter(unitCost)}
          </ProductDetailItemPriceText>
          {!isERP() && (
            <ProductDetailSavingsText>
              Savings: ₦&nbsp;
              {(marketRrp - unitCost) < 0
                ? currencyFormatter(0)
                : currencyFormatter((Number(marketRrp) || Number(unitCost)) - Number(unitCost))}
            </ProductDetailSavingsText>
          )}
        </ProductDetailMainContainer>
        <ProductDetailItemPriceText style={{ opacity: style, width: '13%', margin: 0 }}>
          ₦&nbsp;
          { receivedQty === 0 ? 0 : currencyFormatter(Number(unitCost) * (Number(qtyReceived) ?? Number(receivedQty)))}
        </ProductDetailItemPriceText>
        { status === 'MANUAL_SUPPLIER_ORDER_RECEIVED'
          ? (
            <>
              { batchAdded
                ? <EditImg title="View All Batches" onClick={() => setAllBatchModal(true)} src={EditIcon} style={{ width: '1%', cursor: 'pointer' }} alt="added to batch" />
                : <EditImg title="Add batch" onClick={() => setModal(true)} src={AddBatch} style={{ width: '1%', cursor: 'pointer' }} alt="add to batch" />}
              { notReceivedImg
                ? <EditImg onClick={() => notReceived()} src={NotReceived} alt="mark as not revceived" style={{ width: '1%', marginLeft: '2%', cursor: 'pointer' }} />
                : <EditImg onClick={() => notReceived()} src={MarkNotReceived} alt="Product not revceived" style={{ width: '1%', marginLeft: '2%', cursor: 'pointer' }} />}
            </>
          ) : ''}
      </HideMd>
      <AllBatchDialog
        open={allBatchModal}
        onClose={() => setAllBatchModal(false)}
        product={product}
        unitCost={unitCost}
        submitBatch={submitBatch}
        allBatches={allBatches}
        deleteBatch={deleteBatch}
        editModal={setModal}
        setEditBatch={setEditBatch}
        setSingleBatchNo={setSingleBatchNo}
      />

      <SingleBatchDialog
        open={modal}
        onClose={() => setModal(false)}
        product={product}
        unitCost={unitCost}
        submitBatch={submitBatch}
        editBatch={editBatch}
        singleBatchNo={singleBatchNo}
        setAllBatchModal={setAllBatchModal}
      />
    </ProductContainer>
  );
};

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string,
  addToBatch: PropTypes.func,
  addToNotReceived: PropTypes.func,
  totalPrice: PropTypes.instanceOf(Object)
};

ProductItem.defaultProps = {
  status: '',
  addToBatch: () => {},
  addToNotReceived: () => {},
  totalPrice: []
};

export default ProductItem;
