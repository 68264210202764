import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, Grid, Radio, RadioGroup
} from '@mui/material';
import EmailPopper from './emailPopper';
import CustomInputBase from '../../../customComponents/customInputBase';
import CustomSelectInputBase from '../../../customComponents/customSelectInputBase';
import {
  MainSectionPaper, HeaderText, SubHeaderText, RadioInfoHeaderGrid, RadioInfoGrid,
  OptionsGrid, MainGrid, HelperText, OptionsGridHalf, IOSSwitch
} from './businessSettingCard.styles';
import { useStateValue } from '../../../../providers/stateProvider';

const BusinessSettingCard = ({ preferences, setPreferences }) => {
  const [popperEl, setPopperEl] = useState(null);
  const [editEmail, setEditEmail] = useState(false);

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const {
    automaticOrdering, orderCostFormat, centralProcurementEmail, localCurrency,
    loyaltyPoints, topMovingProduct, slowMovingProduct, dateRangeField, automaticSales, zeroSalesFormat
  } = preferences;

  const handlePopper = (event) => {
    setPopperEl(popperEl ? null : event.currentTarget);
    if (popperEl && centralProcurementEmail) {
      setPreferences({ ...preferences, centralProcurementEmail });
    } else {
      setPreferences({ ...preferences, centralProcurementEmail: '' });
    }
  };

  const handleMaxValue = (name, value) => {
    let val = value;
    if (value > 100) val = 100;
    setPreferences({ ...preferences, [name]: val });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'topMovingProduct':
      case 'slowMovingProduct':
        handleMaxValue(name, value);
        break;
      case 'automaticOrdering': setPreferences({
        ...preferences,
        [name]: value,
        dateRangeField: 'Last 3 months'
      });
        break;
      case 'automaticSales': setPreferences({
        ...preferences,
        automaticSales: value === 'true'
      });
        break;
      case 'zeroSalesFormat': setPreferences({
        ...preferences,
        zeroSalesFormat: event.target.checked
      });
        break;
      default: setPreferences({ ...preferences, [name]: value });
        break;
    }
  };

  const handleAddedEmail = () => {
    if (preferences && preferences.centralProcurementEmail) {
      setPreferences({
        ...preferences, orderEmailingPolicy: 'central_procurement'
      });
      setPopperEl(null);
    }
    setEditEmail(true);
  };

  const dateRange = {
    name: 'dateRangeField',
    label: 'Date Range',
    placeholder: 'Enter range',
    options: ['Last 1 months', 'Last 6 months', 'Last 9 months', 'Last 12 months']
  };

  return (
    <Grid item container justifyContent="flex-end" xs={9}>
      <MainSectionPaper elevation={0}>
        <MainGrid container item direction="column">
          <HeaderText>Automatic Ordering Settings</HeaderText>
          <SubHeaderText>
            Select how you want the system to help you make orders.
          </SubHeaderText>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ marginTop: '1.5rem' }}
          >
            <RadioGroup
              row
              defaultValue={automaticOrdering}
              name="automaticOrdering"
              value={automaticOrdering}
              onChange={handleChange}
            >
              <Grid container>
                <OptionsGrid item sm={12}>
                  <FormControlLabel
                    value="automatic"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Automatic</RadioInfoHeaderGrid>
                        <RadioInfoGrid>Orders will be automatically filled by your last 3 months order trend.</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
                <OptionsGrid item sm={12}>
                  <FormControlLabel
                    value="date_range"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Date Range</RadioInfoHeaderGrid>
                        <RadioInfoGrid>Orders will be automatically filled by the date range your selected</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
                {automaticOrdering === 'date_range' ? (
                  <Grid item xs={5} style={{ marginBottom: '15px' }}>
                    <CustomSelectInputBase
                      field={dateRange}
                      value={dateRangeField}
                      placeholder="Enter Date Range"
                      handleChange={handleChange}
                      showCheckBox={false}
                    />
                  </Grid>
                ) : ''}
              </Grid>
            </RadioGroup>
          </FormControl>
        </MainGrid>
        <MainGrid container item direction="column">
          <HeaderText>Sales Settings</HeaderText>
          <SubHeaderText>
            Select how you want the system to help you with sales.
          </SubHeaderText>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ marginTop: '1.5rem' }}
          >
            <RadioGroup
              row
              aria-label="automatic-sales"
              defaultValue={automaticSales}
              name="automaticSales"
              value={String(automaticSales)}
              onChange={handleChange}
            >
              <Grid container>
                <OptionsGridHalf item>
                  <FormControlLabel
                    value="true"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Automatic</RadioInfoHeaderGrid>
                        <RadioInfoGrid>Make sales automatically using first expired first out (FEFO)</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGridHalf>
                <OptionsGrid item sm={6}>
                  <FormControlLabel
                    value="false"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Manual</RadioInfoHeaderGrid>
                        <RadioInfoGrid>Make sales manually by selecting batches to sell from</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
              </Grid>
            </RadioGroup>
            <OptionsGrid item container sm={12} style={{ marginBottom: 0 }}>
              <FormControlLabel
                control={(
                  <IOSSwitch
                    checked={zeroSalesFormat}
                    name="zeroSalesFormat"
                    onChange={handleChange}
                    disabled={!userPermissions?.includes('setup_sell_zero_quantity_products')}
                  />
                )}
                style={{ marginLeft: 'unset' }}
                label={(
                  <>
                    <RadioInfoHeaderGrid sx={{ marginLeft: '1rem' }}>Products with Zero Quantity</RadioInfoHeaderGrid>
                    <RadioInfoGrid sx={{ marginLeft: '1rem' }}>Sell products with no quantity on sale</RadioInfoGrid>
                  </>
                )}
              />
            </OptionsGrid>
          </FormControl>
        </MainGrid>
        <MainGrid container item direction="column">
          <HeaderText>Order cost</HeaderText>
          <SubHeaderText>
            Select how you want the system to calculate your order cost, this value is used in the pricing
            formula alongside markup to calculate the selling price of a product
          </SubHeaderText>
          <FormControl
            variant="standard"
            component="fieldset"
            style={{ marginTop: '1.5rem' }}
          >
            <RadioGroup
              row
              aria-label="everything"
              defaultValue={orderCostFormat}
              name="orderCostFormat"
              value={orderCostFormat}
              onChange={handleChange}
            >
              <Grid container>
                <OptionsGrid item sm={12}>
                  <FormControlLabel
                    value="average_order_cost"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Average order cost</RadioInfoHeaderGrid>
                        <RadioInfoGrid>The average order cost of all batches added for a product</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
                <OptionsGrid item sm={12}>
                  <FormControlLabel
                    value="most_recent_order_cost"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Most recent order cost</RadioInfoHeaderGrid>
                        <RadioInfoGrid>The most recent order cost value for batches added or received for a product</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
                <OptionsGrid item sm={12}>
                  <FormControlLabel
                    value="highest_order_cost"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Highest order cost</RadioInfoHeaderGrid>
                        <RadioInfoGrid>The highest order cost value for all batches in stock for a product</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
                <OptionsGrid item sm={12}>
                  <FormControlLabel
                    value="order_cost_from_csv"
                    control={<Radio style={{ color: '#086ccb' }} />}
                    label={(
                      <>
                        <RadioInfoHeaderGrid>Order cost from CSV</RadioInfoHeaderGrid>
                        <RadioInfoGrid>The last order cost from product csv upload or previous order cost settings</RadioInfoGrid>
                      </>
                    )}
                  />
                </OptionsGrid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </MainGrid>
        <MainGrid container item direction="column">
          <HeaderText>Loyalty Point</HeaderText>
          <SubHeaderText>
            Set up your loyalty point system.
          </SubHeaderText>
          <OptionsGrid item container style={{ marginTop: '20px', padding: '30px' }}>
            <Grid item xs={4} style={{ marginRight: '16px' }}>
              <CustomInputBase
                label="Local Currency"
                value={localCurrency}
                size="small"
                type="number"
                placeholder="Enter local currency value"
                onWheel={(e) => { e.target.blur(); }}
                name="localCurrency"
                disabled
                onChange={handleChange}
                cSize="lg"
              />
            </Grid>
            <Grid item xs={4}>
              <CustomInputBase
                label="Loyalty Points"
                value={loyaltyPoints}
                size="small"
                type="number"
                placeholder="e.g 500"
                onWheel={(e) => { e.target.blur(); }}
                name="loyaltyPoints"
                onChange={handleChange}
                cSize="lg"
              />
            </Grid>
          </OptionsGrid>
        </MainGrid>
        <MainGrid container item direction="column" style={{ marginBottom: '20px' }}>
          <HeaderText>Product Sales</HeaderText>
          <SubHeaderText>
            Select the amount of product you want to display as Top Moving and Slow moving
          </SubHeaderText>
          <OptionsGrid item container style={{ marginTop: '20px', padding: '30px' }}>
            <Grid container item xs={5} style={{ marginRight: '16px', justifyContent: 'flex-end' }}>
              <CustomInputBase
                label="Top Moving Product"
                value={topMovingProduct}
                size="small"
                type="number"
                placeholder="Enter Product Count"
                onWheel={(e) => { e.target.blur(); }}
                name="topMovingProduct"
                onChange={handleChange}
                cSize="lg"
              />
              <HelperText>Max. of 100 Products</HelperText>
            </Grid>
            <Grid container item xs={5} style={{ marginRight: '16px', justifyContent: 'flex-end' }}>
              <CustomInputBase
                label="Slow Moving Product"
                value={slowMovingProduct}
                size="small"
                type="number"
                placeholder="Enter Product Count"
                onWheel={(e) => { e.target.blur(); }}
                name="slowMovingProduct"
                onChange={handleChange}
                cSize="lg"
              />
              <HelperText>Max. of 100 Products</HelperText>
            </Grid>
          </OptionsGrid>
        </MainGrid>
      </MainSectionPaper>

      <EmailPopper
        popperEl={popperEl}
        email={centralProcurementEmail}
        handleChange={handleChange}
        handlePopper={handlePopper}
        handleAddedEmail={handleAddedEmail}
      />
    </Grid>
  );
};

BusinessSettingCard.propTypes = {
  preferences: PropTypes.instanceOf(Object).isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default BusinessSettingCard;
