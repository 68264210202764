import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, Paper
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import ReturnSelectField from './returnSelectField';
import InitialImage from './initialImage';
import {
  ButtonText, TitleText, TitleWrapper, CircularProgressLoader, UploadGrid,
  GridWrapper, FieldGrid, SupplierTextField, FormLabel, EditButton,
  MessageBoxTextField, MessageBoxContainer, ReasonGrid, TableContainerWrapper, ButtonGrid, MiddleGrid, CancelButton
} from './individualAffiliate.styles';
import {
  UPDATE_BUSINESS_MUTATION
} from '../../../../mutations/business';
import { validateEmail } from '../../../auth/utils';
import { parseAffiliateFields } from '../../../affiliates/utils';

import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import currencyFormatter from '../../../shared/currencyFormatter';
import AffiliatesPaymentHistory from './affiliatesPaymentHistory';
import { useStateValue } from '../../../../providers/stateProvider';

const IndividualAffiliate = ({
  affiliate, refetch, loadingData
}) => {
  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());
  const initialState = {
    legalName: '',
    contactName: '',
    businessType: 'affiliate',
    mobile: '',
    email: '',
    secondaryEmailAddress: '',
    tax: '',
    status: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    country: 'Nigeria',
    id: '',
    logo: '',
    reasonForUpdate: '',
    creditDays: '',
    license: [],
    grossOrderAmount: 0,
    orderVolume: 0,
    creditScore: 0,
    salesRepresentative: ''
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    reasonError: false,
  };

  const [state, setState] = useState(initialState);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [licenseFile, setLicenceFile] = useState([]);
  const [finalLicenceImage, setFinalLicenceImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isloading, setIsLoading] = useState(false);

  // const [{
  //   affiliate: { affiliate, refetch, affiliateDetailRefetch }
  // }, dispatch] = Object.values(useStateValue());
  const role = localStorage.getItem('oga_user_role');
  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';

  const [updateBusiness] = useMutation(UPDATE_BUSINESS_MUTATION);

  const { nameError, reasonError } = errorState;
  useLayoutEffect(() => {
    if (affiliate) {
      const {
        id, legalName, logo, city, country, mobile, addressLine1, addressLine2,
        contactName, email, secondaryEmailAddress, tax, status, creditDays, license,
        grossOrderAmount, orderVolume, creditScore, salesRepresentative
      } = parseAffiliateFields(affiliate);
      setState((_state) => ({
        ..._state, legalName, contactName, email, secondaryEmailAddress, status,
        mobile, addressLine1, addressLine2, city, country, id, logo, tax, creditDays, license,
        grossOrderAmount, orderVolume, creditScore, salesRepresentative
      }));
      console.log(salesRepresentative);
      const filterLicense = license?.map(({ name, url, size }) => ({ name, url, size }));
      if (license?.length) setFinalLicenceImage([...filterLicense]);
    }
  }, [affiliate]);

  const handleEditing = () => {
    setEditing(true);
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const handleLicenseUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          setIsLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        setIsLoading(false);
        return resolve(response.secure_url);
      });
    });
  };

  const handleStatusOptions = () => {
    const { status } = state;
    const options = ['VERIFIED', 'SUSPENDED'];
    const filter = options.filter((val) => val !== status);
    return filter;
  };

  // const editAffiliate = (logo, license) => {
  const handleFileEdit = () => {
    setEditing(true);
  };
  const handleFile = (filesData) => {
    setEditing(true);
    setFiles(filesData);
  };
  useEffect(() => {
    if (licenseFile?.length) {
      setIsLoading(true);
    }
  }, [licenseFile]);
  const handleLicence = async (filesData) => {
    setEditing(true);
    setLicenceFile(filesData);

    if (filesData?.length) {
      const license = await handleLicenseUpload(filesData[0]?.data);
      setFinalLicenceImage([
        ...finalLicenceImage,
        { name: filesData[0]?.file.name, url: license, size: filesData[0]?.file.size }
      ]);
    }
  };

  const handleUpload = () => {
    setEditing(false);
  };
  const handleCancle = () => {
    setLicenceFile([]);
  };
  const handleDelete = (data) => {
    if (data) {
      const filterFile = finalLicenceImage.filter((file) => file.name !== data);
      return setFinalLicenceImage([...filterFile]);
    }
  };
  const editAffiliate = (logo) => {
    const {
      id, legalName, contactName, mobile, email, secondaryEmailAddress, creditScore,
      addressLine1, addressLine2, city, country, tax, status, reasonForUpdate, creditDays, salesRepresentative
    } = state;
    updateBusiness({
      variables: {
        id,
        legalName,
        contactName,
        logo,
        mobile,
        email,
        secondaryEmailAddress,
        tax: parseFloat(tax),
        status,
        addressLine1,
        addressLine2,
        city,
        country,
        terms: false,
        reasonForUpdate,
        creditDays,
        license: finalLicenceImage,
        creditScore: parseFloat(creditScore),
        salesRepresentative
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateBusiness || {};
        toast.success(message);
        setFiles(null);
        refetch();
        setEditing(false);
        setState((_state) => ({
          ..._state, reasonForUpdate: ''
        }));
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancleEdit = () => {
    setEditing(false);
  };
  const handleSave = async () => {
    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594134434/Placeholders/Affliate_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    return editAffiliate(logo);
  };

  const handleSaveButtonClick = () => {
    const { legalName, reasonForUpdate } = state;
    if (reasonForUpdate.length < 1) {
      return setErrorState({
        ...errorState,
        reasonError: true
      });
    }
    if (legalName.length > 1 && reasonForUpdate.length > 1) {
      return handleSave();
    }
    return setErrorState({
      ...errorState,
      nameError: true,
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 1)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'reasonForUpdate':
        return setErrorState({
          ...errorState,
          reasonError: !(value.length > 1)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const nameFields = [
    {
      name: 'legalName',
      label: 'Affiliate Name',
      helperText: 'Affiliate name required'
    },
  ];
  const topFields = [
    { name: 'contactName', label: 'Name' },
    { name: 'mobile', label: 'Mobile' },
    { name: 'email', label: 'Email' },
    { name: 'secondaryEmailAddress', label: 'Secondary Email Address' },
  ];
  const middleFields = [
    { name: 'orderVolume', label: 'Total order number' },
    { name: 'grossOrderAmount', label: 'Total order value' },
  ];
  const bottomFields = [
    { name: 'creditScore', label: 'Credit Limit' },
    { name: 'city', label: 'City' },
    { name: 'creditDays', label: 'Credit Days' },
    { name: 'status', label: 'Status', options: handleStatusOptions() },
    { name: 'tax', label: 'Tax', endIcon: '%' },
  ];
  const addressFields = [
    { name: 'addressLine1', label: 'Address Line 1' },
    { name: 'addressLine2', label: 'Address Line 2' },
    { name: 'country', label: 'Country', options: ['Nigeria', 'Uganda', 'Kenya'] },
  ];
  const viewAddressField = [
    { name: 'addressLine1', label: 'Address' }
  ];

  const reasonField = [
    { name: 'reasonForUpdate', label: 'Reason For Update', helperText: 'This field is required' },
  ];

  const accountHandlerFields = [
    { name: 'salesRepresentative', label: 'Full Name' },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, endIcon
    } = field;
    const value = fieldName === 'grossOrderAmount' ? currencyFormatter(state[fieldName]) : state[fieldName];
    const disableStatus = (fieldName === 'status' && !affiliate) || !isAdmin || !editing;
    if (fieldName === 'country' || fieldName === 'status') {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          disabled={disableStatus}
        />
      );
    }
    if (fieldName === 'reasonForUpdate') {
      return (
        <Grid>
          <FormLabel>Reason For Update*</FormLabel>
          <MessageBoxContainer>
            <MessageBoxTextField
              name={fieldName}
              value={value}
              multiline
              minRows={5}
              error={reasonError}
              helperText={reasonError && helperText}
              required={editing}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ backgroundColor: '#f0f0f0', marginBottom: '4em' }}
            />
          </MessageBoxContainer>
        </Grid>
      );
    }
    if (fieldName === 'creaditDays' && isAdmin) {
      return (
        <SupplierTextField
          variant="filled"
          size="small"
          label={label}
          value={value}
          type="number"
          name={fieldName}
          onChange={handleChange}
        />
      );
    }
    return (
      <SupplierTextField
        variant="filled"
        size="small"
        label={label}
        value={value}
        type={fieldName === 'tax' ? 'number' : 'text'}
        error={fieldName === 'legalName' ? nameError : false}
        helperText={nameError && helperText}
        name={fieldName}
        onChange={handleChange}
        disabled={(fieldName === 'tax' && !isAdmin) || !editing}
        InputProps={{
          endAdornment: (<InputAdornment position="end">{endIcon}</InputAdornment>)
        }}
      />
    );
  };

  return (
    <>
      <TableContainerWrapper component={Paper}>
        { loadingData ? <ProductsTableLoader /> : (
          <Grid container>
            <UploadGrid container item xs={12} md={6}>
              <InitialImage
                image={state.logo}
                name={state.legalName}
                files={files}
                editing={editing}
                handleFile={handleFile}
                handleLicence={handleLicence}
                handleFinalImage={setFinalImage}
                licenseFile={licenseFile}
                handleUpload={handleUpload}
                isLoading={isloading}
                finalLicenceImage={finalLicenceImage}
                handleCancle={handleCancle}
                handleDelete={handleDelete}
                handleFileEdit={handleFileEdit}
              />
            </UploadGrid>
            <GridWrapper container item xs={12} md={6}>
              <TitleWrapper container>
                <TitleText>
                  Account handler
                </TitleText>
              </TitleWrapper>
              <Grid container item>
                <MiddleGrid container item>
                  {accountHandlerFields.map((field) => returnTextField(field))}
                </MiddleGrid>
              </Grid>
              <TitleWrapper container>
                <TitleText>
                  Affiliate Information
                </TitleText>
              </TitleWrapper>
              <Grid container item>
                {editing ? (
                  <MiddleGrid container item>
                    {nameFields.map((field) => returnTextField(field))}
                  </MiddleGrid>
                ) : ''}
                <FieldGrid item>
                  {topFields.map((field) => returnTextField(field))}
                </FieldGrid>
                {editing ? (
                  <MiddleGrid container item>
                    {addressFields.map((field) => returnTextField(field))}
                  </MiddleGrid>
                ) : (
                  <MiddleGrid container item>
                    {viewAddressField.map((field) => returnTextField(field))}
                  </MiddleGrid>
                )}
                {!editing ? (
                  <MiddleGrid container item>
                    {middleFields.map((field) => returnTextField(field))}
                  </MiddleGrid>
                ) : ''}
                <FieldGrid item>
                  {bottomFields.map((field) => returnTextField(field))}
                </FieldGrid>
                {editing ? (
                  <ReasonGrid container item>
                    {reasonField.map((field) => returnTextField(field))}
                  </ReasonGrid>
                ) : ''}
              </Grid>

              <ButtonGrid container item>
                {affiliate && !editing
                  ? (
                    <EditButton
                      onClick={handleEditing}
                      disabled={!userPermissions?.includes('manage_affiliates_edit')}
                    >
                      <ButtonText>
                        Edit
                      </ButtonText>
                    </EditButton>
                  ) : ''}

                {editing
                  ? (
                    <>
                      <CancelButton
                        onClick={handleCancleEdit}
                      >
                        <ButtonText>
                          Cancel
                        </ButtonText>
                      </CancelButton>
                      <EditButton
                        onClick={handleSaveButtonClick}
                        disabled={!userPermissions?.includes('manage_affiliates_save')}
                      >
                        {loading ? (
                          <CircularProgressLoader
                            disableShrink
                            size={22}
                            thickness={5}
                          />
                        ) : (
                          <ButtonText>
                            Save
                          </ButtonText>
                        )}
                      </EditButton>
                    </>
                  ) : ''}
              </ButtonGrid>
            </GridWrapper>

          </Grid>
        )}
      </TableContainerWrapper>
      <AffiliatesPaymentHistory data-testid="mobile-row" />
    </>
  );
};

IndividualAffiliate.propTypes = {
  affiliate: PropTypes.instanceOf(Object),
  refetch: PropTypes.func.isRequired,
  loadingData: PropTypes.bool.isRequired,

};

IndividualAffiliate.defaultProps = {
  affiliate: []
};
export default IndividualAffiliate;
