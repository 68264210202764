import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  Dialog, InputLabel, MenuItem, Paper, Select, Slide, TableBody, TableRow
} from '@mui/material';
import toast from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import { GET_ACCESS_CONTROL_BUSINESS_USERS } from '../../queries/user';
import Loader from '../shared/loader';
import {
  BodyCell,
  HeaderCell,
  MainTableHead,
  TableComponent,
  TableContainerWrapper
} from '../categories/categoryCard.styles';
import ReturnRow from './returnRow';
import { JSONParse } from '../../utils/json';
import { UpdateButton } from '../shared/uploadProduct/productServiceProviderUpdatePopper.styles';
import { UPDATE_ROLE_BUSINESS_USER_AND_PERMISSIONS } from '../../mutations/auth';
import { CardContainer, LabelText, SelectionArea } from './accessControl.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

function AccessControl() {
  const {
    loading, error, data
  } = useQuery(GET_ACCESS_CONTROL_BUSINESS_USERS, {
    fetchPolicy: 'no-cache',
  });
  const [updateRolePermissionMutation] = useMutation(UPDATE_ROLE_BUSINESS_USER_AND_PERMISSIONS);
  const [state, setState] = useState({
    currentBusinessUser: null,
    dialogOpen: false,
    selectedPermissions: new Map(),
    userRole: null,
  });
  if (loading) return <Loader />;
  if (error) return <div>{error.message}</div>;
  const { allBusinessUsers, permissions, allRoles } = data;
  const permissionsMap = new Map(permissions.map((permission) => ([`${permission.id}`, `${permission.name}`])));
  const handleClickRow = (rowId) => {
    const currentBusinessUser = allBusinessUsers.find((busUser) => busUser.id === rowId);
    let { permissions: businessUserPermissions } = currentBusinessUser;
    businessUserPermissions = JSONParse(businessUserPermissions);
    const derivedSelectedPermission = new Map(Array.isArray(businessUserPermissions)
      ? businessUserPermissions.map((perm) => ([`${perm}`, `${perm}`])) : []);
    setState({
      ...state, dialogOpen: true,
      currentBusinessUser,
      userRole: currentBusinessUser.role.id,
      selectedPermissions: derivedSelectedPermission
    });
  };
  const handleChangeRole = (e) => {
    const { target: { value } } = e;
    const defaultRole = allRoles.find((role) => role.id === value);
    const defaultRolePermissions = JSONParse(defaultRole.permissions);
    const derivedSelectedPermission = new Map(defaultRolePermissions && Array.isArray(defaultRolePermissions)
      ? defaultRolePermissions.map((perm) => ([`${perm}`, `${perm}`])) : []);
    setState({
      ...state,
      userRole: value,
      selectedPermissions: derivedSelectedPermission
    });
  };
  const toggleSelectedPermission = (permissionKey) => {
    const permKey = `${permissionKey}`;
    const { selectedPermissions } = state;
    if (selectedPermissions.get(permKey)) {
      selectedPermissions.delete((permKey));
    } else {
      selectedPermissions.set(permKey, permKey);
    }
    setState({ ...state, selectedPermissions });
  };
  const updateRoleAndPermission = () => {
    const perms = [];
    state.selectedPermissions.forEach((permission) => perms.push(+permission));
    updateRolePermissionMutation({
      variables: {
        businessUserId: +state.currentBusinessUser.id,
        roleId: +state.userRole,
        permissions: perms
      }
    }).then(({ data: { updateBusinessUserRolePermission: { message } } }) => {
      toast.success(message);
      setState({ ...state, dialogOpen: false });
    }).catch((err) => {
      toast.error(err?.message);
    });
  };
  const renderBusinessUser = () => {
    const { selectedPermissions, currentBusinessUser } = state;
    if (!currentBusinessUser) {
      return;
    }
    const {
      id, permissions: userPermissions, user: { username }, business: { name: businessName }, role: { name: roleName }
    } = state.currentBusinessUser;
    const buffer = [];
    permissionsMap.forEach((value, permissionKey) => {
      buffer.push(
        <Grid item>
          <Grid
            container
            onClick={() => toggleSelectedPermission(permissionKey)}
            alignItems="center"
            style={{ marginRight: 20, cursor: 'pointer' }}
          >
            <Grid item>
              <Checkbox
                checked={!!selectedPermissions.get(permissionKey)}
                name="terms"
                color="primary"
              />
            </Grid>
            <Grid item>{value}</Grid>
          </Grid>
        </Grid>
      );
    });
    return (
      <>
        <Grid>
          <Grid>
            <LabelText>
              Email:
            </LabelText>
            {username}
          </Grid>
          <Grid>
            <LabelText>
              Business:
            </LabelText>
            {businessName}
          </Grid>
        </Grid>
        <SelectionArea>
          <Grid container>
            {buffer}
          </Grid>
        </SelectionArea>
      </>
    );
  };

  return (
    <MainContent>
      <Grid>
        <TableContainerWrapper component={Paper}>
          <TableComponent aria-label="supplier products table">
            <MainTableHead>
              <TableRow>
                <HeaderCell>S/N</HeaderCell>
                <HeaderCell>Email</HeaderCell>
                <HeaderCell>Business</HeaderCell>
                <HeaderCell>Role</HeaderCell>
                <HeaderCell>Permissions</HeaderCell>
              </TableRow>
            </MainTableHead>
            { allBusinessUsers.length > 0
              ? (
                <TableBody>
                  {allBusinessUsers.map((businessUser, index) => (
                    <ReturnRow handleClickRow={handleClickRow} key={businessUser.id} row={businessUser} index={index} defaultPermissions={permissionsMap} />
                  ))}
                </TableBody>
              )
              : (
                <TableBody>
                  <BodyCell />
                  <BodyCell>No Business user.</BodyCell>
                  <BodyCell />
                  <BodyCell />
                </TableBody>
              )}
          </TableComponent>
        </TableContainerWrapper>
      </Grid>
      <Dialog
        open={state.dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setState({ ...state, dialogOpen: false })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xs"
        fullWidth
      >
        <CardContainer>
          <h2>Update user permissions</h2>
          <Grid container alignItems="center">
            <Grid item>
              <InputLabel id="select-buser-label">
                <LabelText>Role: </LabelText>
                {' '}
              </InputLabel>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Select
                variant="standard"
                labelId="select-role-label"
                id="select-role-label"
                value={state.userRole}
                onChange={handleChangeRole}
                name="businessUserRole"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
              >
                {allRoles?.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    { role.name }
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          {renderBusinessUser()}
          <Grid>
            <UpdateButton
              onClick={updateRoleAndPermission}
            >
              Update
            </UpdateButton>
          </Grid>
        </CardContainer>
      </Dialog>
    </MainContent>
  );
}

export default AccessControl;
