import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Affiliates from './affiliate/affiliate';
import MainContent from '../customComponents/mainContent';
import {
  OrderGridContainer
} from './affiliatesContainer.styles';

const ManageAffiliates = () => {
  const [active, setActive] = useState('');
  return (
    <MainContent>
      <OrderGridContainer container>
        <Grid item container xs={12}>
          <Affiliates />
        </Grid>
      </OrderGridContainer>
    </MainContent>
  );
};

export default ManageAffiliates;
