import React, { useState } from 'react';
import {
  Paper, TableRow, TableBody, useMediaQuery
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import GoToTop from '../../../customComponents/scrollToTop';
import CTablePagination from '../../../customComponents/cTablePagination';
import { BUSINESS_HISTORY_QUERY } from '../../../../queries/businesses';
import {
  TableContainerWrapper, TableComponent, ErpHeader, FooterGrid,
  MainTableHead, MobileWrapper,
} from './affiliateHistory.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import MobileReturnRow from './mobileReturnRow';
import { addHyphen } from '../../../shared/methods';

const headers = [
  'date', 'action done', 'edited by', 'reasons',
];

const initialState = {
  currentPage: 1,
  currentPageCount: 20,
};

const AffiliatesHistory = () => {
  const [state, setState] = useState(initialState);
  const { id } = useParams();

  const {
    currentPage, currentPageCount
  } = state;

  const viewPort = useMediaQuery('(min-width:992px)');

  const {
    loading, error, data,
  } = useQuery(BUSINESS_HISTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
      pageCount: currentPageCount,
      pageNumber: currentPage
    },
  });
  const businessHistory = data?.businessHistory;
  const businessHistoryTotalNumber = data?.businessHistoryTotalNumber;
  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const returnHeaders = () => headers.map((header) => (
    <ErpHeader className={`mpAdmin-uat-manageAffiliateDetailsPage-${addHyphen(header)}`}>{header}</ErpHeader>
  ));

  if (error) return <div>{error.message}</div>;

  return (
    <>
      {viewPort ? (
        <TableContainerWrapper component={Paper}>
          {loading ? <ProductsTableLoader /> : (
            <TableComponent aria-label="affiliate history table">
              <MainTableHead>
                <TableRow>
                  <ErpHeader />
                  {returnHeaders()}
                  <ErpHeader />
                </TableRow>
              </MainTableHead>
              <TableBody>
                {businessHistory?.map((business, index) => (
                  <ReturnRow
                    key={business}
                    row={business}
                    index={index}
                  />
                ))}
              </TableBody>
            </TableComponent>
          )}
        </TableContainerWrapper>
      ) : (
        <MobileWrapper>
          {businessHistory?.map((business) => (
            <MobileReturnRow
              key={business}
              row={business}
            />
          ))}
        </MobileWrapper>
      )}
      <FooterGrid item container>
        <CTablePagination
          rowsPerPageOptions={[20, 50, 100, 150, 200]}
          component="div"
          count={businessHistoryTotalNumber}
          page={currentPage - 1}
          onChangePage={handleChangePage}
          rowsPerPage={currentPageCount}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </FooterGrid>
      {businessHistoryTotalNumber ? <GoToTop /> : ''}
    </>
  );
};

export default AffiliatesHistory;
