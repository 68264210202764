import axios from 'axios';
import moment from 'moment';

const vendorId = process.env.KWIKS_VENDOR_ID;

/**
 * function to get the status of a job
*/
export const getJobStatus = async (orderId) => {
  try {
    const data = axios.get(`${process.env.KWIKS_API_BASE}/getJobStatus?unique_order_id=${orderId}&customer_id=${vendorId}`);
    return data;
  } catch (err) {
    console.log(err);
    return '';
  }
};

/**
 * Function to loginto kwiks api and generate token to be used to make the dispatch
 */
const kwiksLogin = async () => {
  const { data: vendorDetails } = await axios.post(`${process.env.KWIKS_API_BASE}/vendor_login`, {
    domain_name: 'staging-client-panel.kwik.delivery',
    email: 'ogapharmacy@gmail.com',
    password: process.env.KWIKS_PASSWORD,
    api_login: 1
  });
  const { data: { access_token: accessToken } } = vendorDetails;
  return { accessToken, vendorId };
};

/**
 * Functoin to dispatch a delivery and pickup request using the kwiks API
 * @param {*} deliveryInformation an array of the form [{address, name, number}]
 */
export const kwiksDispatchOrder = async (deliveryInformation) => {
  console.log({ deliveryInformation });
  try {
    const {
      accessToken
    } = await kwiksLogin();
    const { data: orderResponse } = await axios.post(`${process.env.KWIKS_API_BASE}/create_task_via_vendor`, {
      domain_name: 'staging-client-panel.kwik.delivery',
      access_token: accessToken,
      vendor_id: vendorId,
      is_multiple_tasks: 1,
      fleet_id: '',
      latitude: 0,
      longitude: 0,
      timezone: -330,
      has_pickup: 1,
      has_delivery: 1,
      pickup_delivery_relationship: 0,
      layout_type: 0,
      auto_assignment: 1,
      team_id: '',
      pickups: [
        {
          address: '273 Borno Way, Lagos Mainland, Lagos, Nigeria',
          name: 'Chukwuebuka Ejiofor',
          time: moment().add(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
          phone: '+2348061295352',
          ref_images: '',
          email: 'ogapharmacy@gmail.com',
          latitude: 6.494424599999999,
          longitude: 3.3785686,
        }
      ],
      deliveries: deliveryInformation.map(({ recipientName, number, address: deliveryAddress }) => (
        {
          address: deliveryAddress,
          name: recipientName,
          latitude: 6.494,
          longitude: 3.37,
          time: moment().add(2, 'days').format('YYYY-MM-DD HH:mm:ss'),
          phone: number,
          ref_images: '',
          has_return_task: false,
          is_package_insured: 0,
          hadVairablePayment: 1,
          hadFixedPayment: 0
        }
      )),
      insurance_amount: 0,
      total_no_of_tasks: 1,
      total_service_charge: 23,
      payment_method: 8,
      amount: '841.62'
    });
    const orderId = orderResponse.data.pickups[0].order_id;
    return orderId;
  } catch (err) {
    return '';
  }
};
