import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import OverviewCard from './overviewCard';
import {
  GridContainer, GridWrapper, InnerContainer, TableTitle
} from './dashboard.styles';
import DateFilter from './dateFilter';
import TopSellingProduct from './topSellingProduct/topSellingProduct';
import { ALL_TOP_SELLING_PRODUCTS } from '../../queries/dashboard';
import { useStateValue } from '../../providers/stateProvider';

const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
const initialState = {
  currentPage: 1,
  currentPageCount: 20,
  dateFrom: '',
  dateTo: '',
  newDateFrom: '',
  newDateTo: '',
  filterBy: ''
};
const AdminDashboard = () => {
  const [state, setState] = useState(initialState);
  const {
    currentPage, currentPageCount, filterBy, dateFrom, dateTo, newDateFrom, newDateTo
  } = state;
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const handleSetFilter = () => {
    setState({
      ...state,
      newDateFrom: dateFrom,
      newDateTo: dateTo
    });
  };

  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };
  const variables = {
    pageCount: currentPageCount,
    pageNumber: currentPage,
    dateFrom: newDateFrom,
    dateTo: newDateTo,
    filterBy
  };

  const {
    loading, error, data
  } = useQuery(ALL_TOP_SELLING_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  const viewPort = useMediaQuery('(min-width:992px)');

  return (
    <MainContent style={{ backgroundColor: '#F5F5F7' }}>
      <GridWrapper>
        <InnerContainer>
          <GridContainer container>
            {viewPort ? (
              <>
                <DateFilter
                  state={state}
                  handleFilterChange={handleFilterChange}
                  handleSetFilter={handleSetFilter}
                />
                <TableTitle>Overview</TableTitle>
              </>
            ) : ''}
            <OverviewCard />

          </GridContainer>
          <TopSellingProduct
            state={state}
            loading={loading}
            data={data}
            error={error}
            setState={setState}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </InnerContainer>
      </GridWrapper>
    </MainContent>
  );
};

export default AdminDashboard;
