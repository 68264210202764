import { styled } from '@mui/material/styles';
import {
  Grid, Typography, TextField, Button, CircularProgress,
  Divider
} from '@mui/material';

export const SideWrapper = styled(Grid)`
  background-color: #424242;
  width: 100vw;
  height: 100vh;

  @media (min-width: 992px) {

  }
`;

export const MainGrid = styled(Grid)`
  background-color: #424242;
  width: 100vw;
  height: 100vh;

  @media (min-width: 992px) {

  }
`;

export const ContentsGrid = styled(Grid)`
  padding: 5rem;

  @media (min-width: 992px) {

  }
`;

export const ContentsGrid2 = styled(Grid)`
  margin: auto;
  width: 65%;
  text-align: center;

  @media (min-width: 992px) {

  }
`;

export const ForgotTypo = styled(Typography)`
  color: #FAF33E;
  font-weight: 500;
  margin-bottom: 2rem;
`;

export const ForgotSubTypo = styled(Typography)`
  text-align: left;
  color: #A3A3A3;
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

export const Logo = styled('img')`
  width: 25%;
  margin-bottom: 5rem;

  @media (max-width: 991px) {
    width: 40%;
  }
`;

export const ReturnTextField = styled(TextField)`
  margin-bottom: 2rem;

  .MuiFilledInput-inputMarginDense {
    padding-top: 18px;
  }
  .MuiInputLabel-filled.MuiInputLabel-marginDense {
    transform: translate(12px, 13px) scale(1);
    color: #696969;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
    transform: translate(12px, 4px) scale(.65);
    color: #696969;
  }
  .MuiFormHelperText-marginDense {
    margin-top: 0;
  }
  .MuiFilledInput-root {
    background-color: #424242;
  }
  .MuiInputBase-input {
    color: #696969;
  }
  .MuiFilledInput-underline:before {
    border-bottom: 1px solid #696969;
  }
`;

export const SendButton = styled(Button)`
  margin: 2rem 0 2.5rem;
  width: 12rem;
  box-shadow: 0 0 black;
  padding: 8px 10px;

  &.MuiButton-contained.Mui-disabled {
    background-color: #FAF33E;
  }

  @media (min-width: 992px) {

  }
`;

export const BackGrid = styled(Grid)`
  margin-top: 2rem;
  font-size: .85rem;
  color: #CCCCCC;

  @media (max-width: 991px) {
    font-size: 1.6rem;
  }
`;

export const BackButton = styled(Button)`
  text-decoration: underline;
  font-size: .8rem;
  color: #FCED50;

  @media (max-width: 991px) {
    font-size: 1.3rem;
  }
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #616161;
`;

export const Divide = styled(Divider)`
  background-color: #616161;
  margin-left: 6rem;
  margin-right: 6rem;
`;

export const Divide2 = styled(Divider)`
  background-color: #616161;
  height: 60%;
  margin-top: 10rem;
`;

export const ButtonText = styled(Typography)`
  font-size: 0.95rem;
  color: #616161;
  font-weight: 500;
`;

export const PasswordRules = styled('ul')`
  text-align: left;
  font-size: 0.95rem;
  color: #A3A3A3;
  padding-inline-start: 20px;

  & > * {
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 1rem;
  }
`;
