import {
  DialogContent, Button, Dialog, Typography, Box
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CloseNewIcon } from '../../../assets/svgs';

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    box-shadow: none;
    border-radius: 12px;
  }
`;

export const CloseStyled = styled(CloseNewIcon)`
  cursor: pointer;
`;

export const DialogContentStyled = styled(DialogContent)`
  width: 650px;
  height: auto;
  padding: 2rem 1.5rem;
`;

export const HeaderText = styled(Typography)`
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 700;
`;

export const ContentHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const FlexStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
`;

export const NameStyled = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 600;
`;

export const TimeStyled = styled(Typography)`
  color: #82849F;
  font-size: 14px;
  font-weight: 600;
`;

export const NoteBody = styled('div')`
  width: 100%;
  height: auto;
  padding: 12px 16px;
  border-radius: 8px;
  background: #F3F9FF;
  margin: 0.7rem 0 1.2rem 0;
  color: #606060;
  cursor: pointer;
`;
