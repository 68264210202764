import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GridWrapper, Image, UploadWrapper, AffiliateName, EditButton, LogoWrapper, ImageWrapper, LicenceWrapper,
  LicenceHeader, DocumentsButton, Delete, FileWrapper, FileContainer, FileUploading, FileText, LinearProgressLoader, ProgressWrapper, FileName,
} from './initialImage.styles';
import CropImageContainer from '../../../shared/logoProcess/cropImageContainer';

import DropZone from './fileDropzone';
import DeleteIcon from '../../../../assets/images/DeleteFile.png';
import CancelIcon from '../../../../assets/images/CancelFile.png';

const InitialImage = ({
  image, name, files, handleFile, handleFinalImage, editing, handleLicence, licenseFile,
  handleUpload, isLoading, finalLicenceImage, handleCancle, handleDelete, handleFileEdit
}) => {
  const [show, setShow] = useState(true);
  const [clicked, setClicked] = useState(false);

  const handleOver = () => setShow(false);
  const handleMouseLeave = () => setShow(true);

  const handleUploadClick = () => {
    setClicked(true);
    handleUpload();
  };

  useEffect(() => {
    if (!editing) setShow(true);
  }, [editing]);
  return (
    <GridWrapper container item>
      <LogoWrapper>
        { show && !editing
          ? (
            <ImageWrapper container>
              <Image
                onMouseOver={() => handleOver()}
                src={image}
                alt="image"
              />
              <AffiliateName>{name}</AffiliateName>
            </ImageWrapper>
          ) : (files && files.length ? (
            <CropImageContainer
              files={files}
              handleFile={handleFile}
              handleFinalImage={handleFinalImage}
            />
          ) : (
            <EditButton
              component="label"
              onClick={() => handleUploadClick()}
              onMouseLeave={() => (clicked ? '' : handleMouseLeave())}
            >
              <input
                type="file"
                onChange={(e) => handleFile(e.target.files)}
                accept="image/*"
                multiple
                hidden
                className="mpAdmin-uat-manageAffiliateDetailsPage-uploadImage"
              />
              Upload image
            </EditButton>
          )
          ) }
      </LogoWrapper>
      <LicenceWrapper elevation={2} container item onMouseEnter={() => (clicked ? '' : handleMouseLeave())}>
        <LicenceHeader>Business Documents</LicenceHeader>
        <UploadWrapper>
          <DropZone
            handleFile={handleLicence}
            handleCancle={handleCancle}
            className="mpAdmin-uat-manageAffiliateDetailsPage-uploadDocs"
          />
        </UploadWrapper>
        {isLoading ? (
          <FileContainer>
            {licenseFile?.length
              ? (
                licenseFile.map(({ file }) => (
                  <>
                    <FileText>
                      Uploading -
                      {' '}
                      {finalLicenceImage?.length}
                      /
                      {finalLicenceImage?.length}
                      files
                    </FileText>
                    <FileUploading container>
                      {file?.name}
                      <Delete alt="Delete" src={CancelIcon} onClick={() => handleCancle()} />
                    </FileUploading>
                    <ProgressWrapper container>
                      <LinearProgressLoader
                        disableShrink
                        size={22}
                        thickness={5}
                        color="inherit"
                      />
                    </ProgressWrapper>
                  </>
                ))
              ) : ''}
          </FileContainer>
        ) : '' }
        {editing
          ? (
            <FileContainer container>
              <FileText>Uploaded</FileText>
              {finalLicenceImage?.length
                ? (
                  finalLicenceImage.map((file) => (
                    <FileWrapper container>
                      <FileName href={file?.url} target="_blank">
                        {file?.name}
                      </FileName>
                      <Delete
                        alt="Delete"
                        src={DeleteIcon}
                        onClick={() => handleDelete(file?.name)}
                        className="mpAdmin-uat-manageAffiliateDetailsPage-deleteUpload"
                      />
                    </FileWrapper>
                  ))
                ) : ''}
            </FileContainer>
          ) : ''}
        {!editing
          ? (
            <DocumentsButton
              component="label"
              onClick={() => handleFileEdit()}
              disabled={licenseFile}
            >
              Upload Files
            </DocumentsButton>
          ) : ''}
      </LicenceWrapper>
    </GridWrapper>
  );
};
InitialImage.propTypes = {
  image: PropTypes.string.isRequired,
};
export default InitialImage;
