import {
  Dialog, Grid, Typography, IconButton, CircularProgress,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 20px);
    border-radius: 12px;
  }
`;

export const GridWrapper = styled(Grid)`
  & > * {
    padding: 0.5rem 1.5rem;
    width: 100%;

    &:first-child {
      padding: 1.5rem;
    }
    &:last-child {
      padding: 2.5rem 1.5rem;
    }
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const DialogTitleText = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
`;

export const DialogTitleSubText = styled(Typography)`
  font-size: 14px;
  color: #858383;
`;

export const DialogContentWrapper = styled(Grid)`
`;

export const DividerLine = styled('hr')`
  border: 1px solid #CCCCCC;
  width:96%;
  min-width: 300px;
`;

export const SampleCsvWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 20px 20px;
  justify-content: flex-start;
  text-align: left;
`;

export const SampleCsvDownload = styled(Grid)`
  width: 100%;
  background: #E0E0E0;
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
`;

export const CsvTextWrapper = styled(Grid)`
  display: flex;
`;

export const CsvFileImage = styled('img')`
  height: 30px;
  margin-right: 25px;
`;
export const CsvDescription = styled(Grid)`

`;
export const CsvTitle = styled(Typography)`
  font-size: 16px;
`;
export const CsvSubtitle = styled(Typography)`
  font-size: 12px;
`;

export const DownloadIconWrapper = styled(IconButton)`
`;
export const DownloadIconImage = styled('img')`
  height: 30px;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #424242;
  margin-top: 4px;
`;

export const UploadGrid = styled(Grid)`
  background-color: #E0E0E0;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;

  & > * {
    border: none;
    width: 100%;
  }
`;

export const SelectNameTypo = styled(Typography)`
  font-size: 1rem;
`;
