import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Grid, Button, CircularProgress, useMediaQuery
} from '@mui/material';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { SIGNUP_MUTATION } from '../../mutations/auth';
import CustomInputBase from '../customComponents/customInputBase';
import PasswordTextField from './passwordTextField';
import { HaveAccountTypo, FooterGrid } from './register.styles';
import {
  validateEmail, validateMobile, validatePassword
} from './utils';
import { OgaToken } from '../../graphql/token';
import TermsAndConditionsPopUp from './alerts/TermsAndConditionsPopUp';

const Register = () => {
  const initialState = {
    email: '',
    mobile: '',
    password: '',
    validated: false,
    showPassword: false
  };
  const initialErrorState = {
    emailError: false,
    mobileError: false,
    passwordError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const [createUser, { loading }] = useMutation(SIGNUP_MUTATION);
  const isSmall = useMediaQuery('(max-width: 991px)');

  const {
    email, mobile, password, validated
  } = state;
  const { emailError, passwordError, mobileError } = errorState;

  useEffect(() => {
    setState({
      ...state,
      validated: !emailError && !passwordError && !mobileError
    });
  }, [email, password, mobile]);

  const validateState = (name, value) => {
    switch (name) {
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: validateMobile(value)
        });
      case 'password':
        return setErrorState({
          ...errorState,
          passwordError: validatePassword(value)
        });
      default:
        return true;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const handleMobileChange = (value) => {
    setState({ ...state, mobile: value });
  };

  const handleSignup = () => {
    createUser({
      variables: {
        email,
        mobile,
        password
      }
    }).then(({ data }) => {
      const {
        message, token, user, restToken
      } = data?.createUser ?? {};
      toast.success(message);
      localStorage.setItem('oga_auth_token', token);
      OgaToken.authToken = token;
      localStorage.setItem('oga_rest_token', restToken);
      setTimeout(() => navigate('/business-setup', [user]), 1500);
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  return (
    <form autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInputBase
            label="Email Address"
            name="email"
            value={email}
            type="email"
            error={email ? emailError : false}
            helperText={emailError ? 'Invalid email' : ' '}
            required
            disabled={loading}
            onChange={handleChange}
            cSize="lg"
            className="uat-register-email"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInputBase
            label="Phone Number"
            name="mobile"
            value={mobile}
            type="number"
            error={mobile ? mobileError : false}
            helperText={mobileError ? 'Invalid mobile' : ' '}
            required
            disabled={loading}
            onChange={handleChange}
            cSize="lg"
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordTextField
            password={password}
            handleChange={handleChange}
            passwordError={passwordError}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ margin: '2rem 0 0' }}>
        <Button
          data-testid="auth-button"
          disabled={!validated}
          onClick={() => setOpen(true)}
          variant="contained"
          style={{
            width: '100%', height: isSmall ? '5.7rem' : '', fontSize: isSmall ? '2rem' : '', borderRadius: isSmall ? '1rem' : ''
          }}
          className="signin-button"
          id="signin-button"
        >
          {loading ? <CircularProgress size={18} /> : 'Sign Up'}
        </Button>
      </Grid>

      <FooterGrid item container xs={12}>
        <HaveAccountTypo variant="subtitle2" display="inline" style={{ color: 'black', fontWeight: isSmall ? '800' : '' }}>
          Already have an account?
          &nbsp;
          &nbsp;
          <Link to="/" style={{ color: '#235A91', fontWeight: 800 }}>
            Sign In
          </Link>
        </HaveAccountTypo>
      </FooterGrid>

      <TermsAndConditionsPopUp
        handleContinue={handleSignup}
        open={open}
        setOpen={setOpen}
      />
    </form>
  );
};

export default Register;
