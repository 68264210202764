import {
  Grid, Typography, Paper, TableCell, TableRow, TableContainer, Table, TableHead
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const CardContainer = styled(Paper)`
  padding: 10px;
`;

export const LabelText = styled(Typography)`
    color: #A3A3A3;
    display: inline-block;
    padding-right: 15px;
    padding-bottom: 10px;`;

export const SelectionArea = styled(Grid)`
  max-height: 80px;
  overflow-y: scroll;
`;
