import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, TableRow, TableBody, Grid, useMediaQuery
} from '@mui/material';
import {
  TableContainerWrapper, TableComponent, TableHeader,
  MainTableHead, FiltersGrid, CustomButton, TableTitle, MobileContainer, MobileFiltersGrid, FooterGrid
} from './topSellingProduct.styles';
import ReturnRow from './returnRow';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MobileReturnRow from './mobileReturnRow';
import CTablePagination from '../../customComponents/cTablePagination';

const headers = [
  'Product name', 'Unit Price', 'Pack Size', 'Total value', 'Qty in Stock', 'Qty Sold Pcks ',
];

const TopSellingProduct = ({
  loading, error, data, setState, state, handleChangePage, handleChangeRowsPerPage
}) => {
  const [active, setActive] = useState('count');
  const returnHeaders = () => headers.map((header) => (
    <TableHeader>{header}</TableHeader>
  ));

  const {
    currentPage, currentPageCount
  } = state;
  const handleSetValue = () => {
    setActive('value');
    setState({
      ...state,
      filterBy: 'value'
    });
  };

  const handleSetCount = () => {
    setActive('count');
    setState({
      ...state,
      filterBy: 'count'
    });
  };

  const viewPort = useMediaQuery('(min-width:992px)');
  const filterGrid = () => (
    <>
      <CustomButton
        onClick={handleSetValue}
        active={(active === 'value') && viewPort}
      >
        By Value
      </CustomButton>
      <CustomButton
        onClick={handleSetCount}
        active={active === 'count' && viewPort}
      >
        By Count
      </CustomButton>
    </>
  );

  if (error) return <div>{error.message}</div>;
  const {
    allTopSellingProducts = [], allTopSellingProductsTotalNumber = 0
  } = data || {};

  const pagination = () => (
    <FooterGrid item container>
      <CTablePagination
        rowsPerPageOptions={[20, 50, 100, 150, 200]}
        component="div"
        count={allTopSellingProductsTotalNumber}
        page={currentPage - 1}
        onChangePage={handleChangePage}
        rowsPerPage={currentPageCount}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </FooterGrid>
  );

  return viewPort ? (
    <TableContainerWrapper component={Paper}>
      <Grid item container xs={12}>
        <FiltersGrid container item justifyContent="flex-end">
          {filterGrid()}
        </FiltersGrid>

      </Grid>
      <TableTitle>Top Selling Products</TableTitle>
      {loading ? <ProductsTableLoader /> : (
        <TableComponent aria-label="affiliates table">
          <MainTableHead>
            <TableRow>
              {returnHeaders()}
            </TableRow>
          </MainTableHead>
          <TableBody>
            {allTopSellingProducts.map((product, index) => (
              <ReturnRow
                key={product.totalSales}
                row={product}
                index={index}
              />
            ))}
          </TableBody>
        </TableComponent>
      )}
      {pagination()}
    </TableContainerWrapper>
  ) : (
    <MobileContainer data-testid="mobile-row">
      <Grid item xs={12}>
        <TableTitle>Top Selling Products</TableTitle>
        <MobileFiltersGrid container item>
          {filterGrid()}
        </MobileFiltersGrid>

      </Grid>
      {loading ? <ProductsTableLoader /> : (
        allTopSellingProducts.map((business, index) => (
          <MobileReturnRow
            key={business.id}
            row={business}
          />
        ))
      )}
      {pagination()}
    </MobileContainer>
  );
};

TopSellingProduct.propTypes = {
  state: PropTypes.instanceOf(Object),
  setState: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  error: PropTypes.string.isRequired,
};

TopSellingProduct.defaultProps = {
  state: {},
  data: {},
  setState: {},
  loading: false,
};

export default TopSellingProduct;
