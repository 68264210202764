import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, TableBody } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../../customComponents/mainContent';
import { PRODUCT_BATCH_LOGS_QUERY } from '../../../../queries/products';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import ReturnRow from './returnRow';
import TablePagination from '../../../shared/tablePagination';
import {
  TitleGrid, TitleTextGridContainer, PrimaryTitle, SecondaryTitle, PaperWrapper, TableGrid, TableHeader,
  TableComponent, TableColumnHeaders, FooterWrapper, MenuButton
} from './singleBatchDetails.styles';

const headers = [
  { name: 'S/N', width: '70px' }, { name: 'Date', width: '230px' }, { name: 'Order cost', width: '150px' }, { name: 'Expiry Date', width: '150px' },
  { name: 'Quantity', width: '100px' }, { name: 'Updated Field', width: '175px' }, { name: 'Updated By', width: '150px' },
  { name: 'Updated Date', width: '230px' }, { name: 'Previous (Dynamic) Info', width: '230px' }, { name: 'Current (Dynamic) Info', width: '220px' }
];

const SingleBatchDetails = () => {
  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();
  const { batchId } = useParams();

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader style={{ width }}>
      {name}
    </TableHeader>
  ));

  const {
    loading, error, data
  } = useQuery(PRODUCT_BATCH_LOGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { batchId }
  });

  if (error) return <div>{error.message}</div>;
  const {
    allProductBatchLogs = [], allProductBatchLogsTotalNumber = 0
  } = data || {};
  const { batchNo, productName } = allProductBatchLogs[0] ?? '';
  return (
    <MainContent>
      <Grid container direction="column" style={{ padding: '30px' }}>
        <TitleGrid container item>
          <TitleTextGridContainer container item xs={12}>
            <Grid item>
              <MenuButton onClick={() => navigate(-1)}>
                <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
              </MenuButton>
            </Grid>
            <Grid item>
              <PrimaryTitle variant="h5">{productName || 'xxxxxxxxx'}</PrimaryTitle>
              <SecondaryTitle variant="h5">{batchNo ? `#${batchNo}` : 'xxxxx'}</SecondaryTitle>
            </Grid>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TableGrid item container>
            {loading
              ? <ProductsTableLoader />
              : (
                <TableComponent item container>
                  <TableColumnHeaders item container>
                    {returnHeaders()}
                  </TableColumnHeaders>
                  <TableBody>
                    {allProductBatchLogs.map((_row, indx) => (
                      <ReturnRow
                        key={_row.id}
                        row={_row}
                        rowIndx={indx}
                      />
                    ))}
                  </TableBody>
                </TableComponent>
              )}
          </TableGrid>
          <FooterWrapper item container>
            {allProductBatchLogsTotalNumber > 0 && (
              <TablePagination
                total={allProductBatchLogsTotalNumber}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            )}
          </FooterWrapper>
        </PaperWrapper>
      </Grid>
    </MainContent>
  );
};

export default SingleBatchDetails;
