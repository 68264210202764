import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
  Slide, Box, Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import success from '../../assets/images/Checksuccesful.gif';
import warning from '../../assets/images/Warning.gif';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const CartSaved = ({
  cartSaved,
  handleClose
}) => (
  <Dialog
    open={cartSaved}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleClose}
    aria-describedby="alert-dialog-slide-description"
  >
    <DialogContent>
      <Close
        fontSize="large"
        style={{ position: 'absolute', right: '1.5rem', cursor: 'pointer' }}
        onClick={handleClose}
      />
      <DialogContentText style={{ marginTop: '1rem' }}>
        <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
          <Box marginBottom={4}>
            <img src={success} alt="success" width={155} />
          </Box>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Cart Saved!
          </Typography>
          <Typography variant="subtitle1" style={{ paddingTop: '1rem' }}>
            Hi Pharm, Your Cart has been saved successfully!
          </Typography>
        </Box>
      </DialogContentText>
      <DialogActions style={{ marginBottom: '2rem' }}>
        <Button
          fullWidth
          onClick={handleClose}
          variant="contained"
          style={{
            background: '#303030',
            color: '#FFF',
            padding: '1rem 2rem'
          }}
          size="large"
        >
          ok
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

CartSaved.propTypes = {
  cartSaved: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CartSaved;
