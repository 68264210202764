import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-hot-toast';
import { AUTOMATIC_ORDERING_MUTATION, BULK_BUY_MUTATION, CREATE_ORDER_MUTATION } from '../../mutations/orders';
import Products from './products/products';
import Orders from './orders/orders';
import MainContent from '../customComponents/mainContent';
import { EXPORT_PRODUCT_MUTATION } from '../../mutations/reports';
import Filter from './filter';
import BatchDetails from './batchDetails/batchDetails';
import {
  OrderGridContainer, TitleTextGridContainer, PrimaryTitle, TitleGrid, PaperWrapper,
  TCustomButton
} from './inventoryContainer.styles';
import IndividualProductDialog from '../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../providers/reducers/product/productTypes';
import { useStateValue } from '../../providers/stateProvider';
import SuppliersModel from '../shared/orderDropdown/suppliersModel/suppliersModel';
import ProductButtonsPopper from './productButtonsPopper';
import TherapeuticPopper from './therapeuticPopper';
import CreateProduct from '../sales/createProduct';
import CreateBatches from '../sales/createBatches';
import TherapeuticTypes from './therapeuticTypes/therapeuticType';
import SubCategoryDialog from './therapeuticTypes/categoryDialog';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import OrderButtonsPopper from './orderButtonsPopper';
import ConfirmDialog from '../shared/confirmDialog';
import { ORDER_QUERY } from '../../queries/cart';
import { DELETE_CART_ORDER } from '../../mutations/cart';

const InventoryContainer = () => {
  const [{
    cart: { isManualOrder },
    user: { businessId: bizId, allowedPermissionsMap: { userPermissions } },
    product: { addProductDialogOpen }
  }, dispatch] = Object.values(useStateValue());

  const navigate = useNavigate();
  const { tab } = useParams() || '';
  const [tabVal, setTabVal] = useState('products');
  const [openSuppliersModal, setOpenSuppliersModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadCsvDialog, setUploadCsvDialog] = useState(false);
  const [uploadBatchesCsvDialog, setUploadBatchesDialog] = useState(false);
  const [productState, setProductState] = useState([]);
  const [select, setSelect] = useState([]);
  const [subCategoryOpenDialog, setSubCategoryOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [autoProducts, setAutoProducts] = useState([]);
  const [orderId, setOrderId] = useState('');
  const location = useLocation();

  const handleTabChange = (val) => {
    navigate(`/inventory/${val}`);
    setTabVal(val);
  };

  useEffect(() => {
    if (tab) setTabVal(tab);
  }, [tab]);

  const handleSuppliersModal = () => {
    setOpenSuppliersModal(true);
  };

  const hybrid = () => {
    if (selected.length === 1 && selected.includes('YES')) return 'YES';
    if (selected.length === 1 && selected.includes('NO')) return 'NO';
    if ((selected.length < 1) || (selected.length > 1 && selected.includes('YES') && selected.includes('NO'))) return 'ALL';
  };

  useEffect(() => { hybrid(); }, [selected]);

  const handleSelected = (e) => {
    const { value, checked } = e.target;
    const exist = selected.find((x) => x === value);
    if (checked && !exist) {
      setSelected([...selected, value]);
    }
    if (!checked && exist) {
      const newVal = selected.filter((x) => x !== value);
      setSelected(newVal);
    }
  };

  const [automaticOrdering] = useMutation(AUTOMATIC_ORDERING_MUTATION);
  const handleAutomaticOrdering = (supplierIds) => {
    setLoading(true);
    automaticOrdering({
      variables: {
        isManualOrder,
        supplierIds,
        hybrid: hybrid()
      }
    })
      .then(() => {
        setLoading(false);
        navigate('/purchase-order', { state: { autoOrder: true } });
      })
      .catch((error) => console.log(error));
  };

  const {
    status, categoryId, dateFrom, dateTo, productId, productName, supplier
  } = productState;

  const [exportMutation, { loading: exportLoading }] = useMutation(EXPORT_PRODUCT_MUTATION);
  const randomNum = Math.floor(Math.random() * 10000);
  const newFileName = `inventory products ${randomNum}`;

  const variables = {
    type: 'inventory',
    name: newFileName,
    category: categoryId,
    status,
    dateFrom,
    dateTo,
    productId,
    productName,
    supplier
  };

  const handleExport = async () => {
    exportMutation({
      variables
    })
      .then(({ data }) => {
        const { message } = data?.exportCsv || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleCsvDialog = () => setUploadCsvDialog(!uploadCsvDialog);
  const handleBatchesCsvDialog = () => setUploadBatchesDialog(!uploadBatchesCsvDialog);

  const updateCartTotal = (currentOrder) => {
    const { supplierorderSet } = currentOrder?.order || {};

    dispatch({
      type: CartActionTypes.CART_COUNTER,
      payload: supplierorderSet
    });
  };

  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const cartStateUpdate = () => {
    const { pathname: pname } = location;
    const role = localStorage.getItem('oga_user_role');
    if (role === 'store-manager' || role === 'chain-manager') {
      createOrder({
        variables: { isManualOrder: true }
      });
    } else if (pname !== '/') createOrder();
  };

  const [bulkBuy] = useMutation(BULK_BUY_MUTATION);
  const handleBulkBuy = () => {
    if (!select.length) return toast.error('Select at least one product');
    const productIds = select.map((prodId) => ({
      productId: prodId,
      quantity: 1
    }));
    bulkBuy({
      variables: { productIds }
    })
      .then(({ data }) => {
        const { message } = data?.bulkBuy || {};
        cartStateUpdate();
        toast.success(message);
      });
  };

  const { data: orderData, loading: orderLoading } = useQuery(ORDER_QUERY, {
    variables: { bizId, isManualOrder: false },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!orderLoading && orderData && orderData.order) {
      const { order: { id, supplierorderSet: supplierSet } } = orderData;
      const autoProductSet = [];
      supplierSet?.forEach((result) => {
        autoProductSet.push(...result.orderproductSet);
      });
      if (autoProductSet.length > 0) setOrderId(id); setAutoProducts(autoProductSet);
    }
  }, [orderLoading, orderData]);

  if (confirmStatus) {
    navigate('/purchase-order', { state: { autoProducts } });
    setConfirmStatus(false);
  }

  const productButtons = [
    {
      name: 'Bulk Buy', label: 'bulk_buy', loading: false, permission: !userPermissions?.includes('inventory_bulk_buy')
    },
    {
      name: 'Export', label: 'export', loading: exportLoading, permission: !userPermissions?.includes('inventory_export')
    },
    {
      name: 'Add Batches', label: 'add_batches', loading: false, permission: !userPermissions?.includes('inventory_add_batches')
    }
  ];

  const actionButtonHandler = (pref) => {
    switch (pref) {
      case 'bulk_buy': return handleBulkBuy();
      case 'add_batches': return handleBatchesCsvDialog();
      case 'export': return handleExport();
      default: break;
    }
  };

  const toggleIndividualDialog = () => {
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const productActionHandler = (action) => {
    switch (action) {
      case 'add_individually':
        return toggleIndividualDialog();
      case 'upload_order_csv':
        return handleCsvDialog();
      default:
        break;
    }
  };

  const toggleSubCategory = () => setSubCategoryOpenDialog(!subCategoryOpenDialog);

  const therapeuticHandler = (action) => {
    switch (action) {
      case 'add_individually':
        return toggleSubCategory();
      default:
        break;
    }
  };

  const currentTab = () => {
    switch (tabVal) {
      case 'products':
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} />;
      case 'orders':
        return <Orders />;
      case 'batch-details':
        return <BatchDetails />;
      case 'therapeutic-types':
        return <TherapeuticTypes />;
      default:
        return <Products setProductState={setProductState} setSelected={setSelect} selected={select} />;
    }
  };

  const [deleteCart] = useMutation(DELETE_CART_ORDER);
  const handleClearCart = () => {
    deleteCart({
      variables: { orderId },
      optimisticResponse: { __typename: 'Mutation' }
    }).then(() => {
      navigate('/purchase-order');
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const actionHandler = (action) => {
    switch (action) {
      case 'create-individually':
        if (autoProducts.length > 0) return setOpenConfirmDialog(true);
        return navigate('/purchase-order');
      case 'automatic_order':
        return handleSuppliersModal();
      default:
        break;
    }
  };

  return (
    <MainContent>
      <OrderGridContainer container>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={3}>
            <PrimaryTitle variant="h5">Inventory</PrimaryTitle>
          </TitleTextGridContainer>
          {tabVal === 'orders' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              <TCustomButton onClick={() => navigate('/manage-purchase-order')}>
                Open PO
              </TCustomButton>
              <OrderButtonsPopper actionHandler={actionHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'products' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={9}>
              {productButtons?.map(({
                name, label, loading: loadStatus, permission
              }) => (
                <Button
                  variant="outlined"
                  key={name}
                  className={`erp-uat-${label}`}
                  disabled={permission || loadStatus}
                  style={{ marginRight: '.8rem' }}
                  onClick={() => actionButtonHandler(label)}
                >
                  {loadStatus ? 'Loading...' : name }
                </Button>
              ))}
              <ProductButtonsPopper actionHandler={productActionHandler} autoLoading={loading} />
            </Grid>
          )}
          {tabVal === 'therapeutic-types' && (
            <Grid container alignItems="center" justifyContent="flex-end" xs={12} md={7}>
              <TherapeuticPopper actionHandler={therapeuticHandler} autoLoading={loading} />
            </Grid>
          )}
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <Filter
            tabVal={tabVal}
            handleTabChange={handleTabChange}
          />
          {currentTab()}
        </PaperWrapper>
      </OrderGridContainer>

      <CreateProduct
        openProdDialog={uploadCsvDialog}
        toggleHandler={handleCsvDialog}
      />
      <CreateBatches
        openDialog={uploadBatchesCsvDialog}
        toggleHandler={handleBatchesCsvDialog}
      />

      <IndividualProductDialog
        dialogOpen={addProductDialogOpen}
        closeDialog={() => toggleIndividualDialog()}
      />

      <SuppliersModel
        open={openSuppliersModal}
        handleClose={() => setOpenSuppliersModal(false)}
        handleAutomaticOrdering={handleAutomaticOrdering}
        handleSelected={handleSelected}
        selected={selected}
      />

      <SubCategoryDialog
        openDialog={subCategoryOpenDialog}
        handleClose={toggleSubCategory}
      />

      <ConfirmDialog
        openDialog={openConfirmDialog}
        setOpenDialog={setOpenConfirmDialog}
        title="You have unsaved products on automatic ordering !"
        desc="To continue purchase order creation individually, please note that your unsaved products from automatic ordering will be added to your individual order list"
        options={['No, clear order list', 'Yes, continue']}
        setStatus={setConfirmStatus}
        loading={false}
        handleClearCart={handleClearCart}
        fromInventory
      />
    </MainContent>
  );
};

export default InventoryContainer;
