import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import TabsButtons from './affiliateTabsButtons/tabsButtons';
import MainContent from '../../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, ButtonsPaper, MenuButton, MainWrapper
} from './styles';
import IndividualAffiliate from './affiliatecontainer/individualAffiliate';
import { AFFILIATE_DETAIL_QUERY } from '../../../queries/affiliates';
import AffiliatesHistory from './affiliateHistory/affiliateHistory';

const IndividualAffiliateContainer = () => {
  const [active, setActive] = useState('homepage');
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    loading, error, data, refetch
  } = useQuery(AFFILIATE_DETAIL_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  if (error) return <div>{error.message}</div>;

  return (
    <MainWrapper>
      <MainContent>
        <OrderGridContainer container>
          <TitleGridContainer container item>
            <Grid item container>
              <MenuButton onClick={() => navigate('/manage-affiliates')}>
                <KeyboardArrowLeft fontSize="large" />
              </MenuButton>
              <Grid item container xs={12}>
                <TabsButtons
                  active={active}
                  setActive={setActive}
                />
              </Grid>
            </Grid>
          </TitleGridContainer>
          <Grid item container xs={12}>
            {active === 'homepage'
              ? (
                <IndividualAffiliate
                  affiliate={data}
                  refetch={refetch}
                  loadingData={loading}
                />
              )
              : (
                <AffiliatesHistory
                  affiliate={data}
                  id={id}
                />
              )}
          </Grid>
        </OrderGridContainer>
      </MainContent>
    </MainWrapper>
  );
};

export default IndividualAffiliateContainer;
