import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { PharmIQSvgLogo } from '../../assets/svgs';

export const AuthGridContainer = styled(Grid)`
  position: relative;
  width: 100vw;
  height: 100vh;

  @media (max-width: 991px) {
    overflow: auto;
  }
`;

export const PharmIQLogo = styled(PharmIQSvgLogo)`
  width: 16rem;
  height: 5rem;
  margin-left: 1rem;

  @media (max-width: 991px) {
    width: 25rem;
    height: 8rem;
    margin-left: 0;
  }
`;

export const BackgroundImage = styled('img')`
  width: 150vw;
  height: 100vh;

  @media (min-width: 992px) {
    width: 65vw;
  }
`;

export const Logo = styled('img')`
  width: 58%;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 65%;
  }

  @media (min-width: 992px) {
    width: 70%;
    margin: 0;
  }
`;

export const InnerGridContainer = styled(Grid)`
  position: absolute;
  top: 0;
  width: 100vw;

  @media (min-width: 992px) {
    top: 9em;
  }
`;
export const LogoGridContainer = styled(Grid)`
  flex-direction: column;
  background-color: #235A91;
  align-items: center;
  justify-content: center;
`;

export const LogoTypo = styled(Typography)`
  color: #FFFFFF;
  font-size: 3.48vw;
  font-weight: 700;
  margin: 3.8vw auto 0;

  @media (min-width: 768px) {
    font-size: 3.82vw;
    margin: 3.8vw auto;
  }

  @media (min-width: 992px) {
    font-size: 2.1vw;
    margin: 4vw 0 0;
  }
`;

export const LogoSubTypo = styled(Typography)`
  color: #FFFFFF;
  font-size: 3.18vw;
  font-weight: 400;
  margin: 1vw auto 0;

  @media (min-width: 768px) {
    font-size: 3.48vw;
    margin: 1vw auto;
  }

  @media (min-width: 992px) {
    font-size: 1.3vw;
    margin: .5vw 0 0;
  }
`;

export const InputsGridContainer = styled(Grid)`
  align-items: center;
  justify-content: center;

  // @media (min-width: 768px) {
  //   flex-basis: 100%;
  // }

  @media (min-width: 992px) {
    flex-basis: 50%;
    margin: 0;
  }
`;

export const InputsGrid = styled(Grid)`
  flex-direction: column;
  padding: 0 9vw;
  width: 75%;

  @media (min-width: 768px) {
    width: 82%;
    padding: 0 9vw ;
  }

  @media (min-width: 992px) {
    width: 78%;
    padding: 0 4vw ;
  }

  @media (max-width: 991px) {
      padding: 6rem 4rem;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }
`;

export const InputsHeaderTypo = styled(Typography)`
  font-weight: 700;
  font-size: 45px;
  margin-bottom: 8vw;

  @media (min-width: 992px) {
    font-size: 32px;
    margin: .8vw 0;
  }

  @media (max-width: 991px) {
    font-size: 3rem;
    margin-bottom: 5vw;
  }
`;

export const InputsSubheaderTypo = styled(Typography)`
  font-weight: 400;
  margin-bottom: 4vw;
  font-size: 25px;

  @media (min-width: 992px) {
    margin-bottom: 3vw;
    font-size: 15px;
  }
`;

export const InputsFooter = styled(Grid)`
  text-align: right;
  background-image: linear-gradient(to right, #424242, #0D0D0D);
  clip-path: polygon(0 100%,100% 0,100% 100%,0 100%);
`;

export const OgaImage = styled('img')`
  width: 16rem;
  padding: 2rem 1.5rem .5rem 0;

  @media (min-width: 992px) {
    width: 10vw;
    padding: 1.6vw 1vw 0 0;
  }
`;
