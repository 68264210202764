import React, { useState, useLayoutEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { GET_OPEN_AND_CLOSED_ORDERS } from '../../../queries/orders';

import { useStateValue } from '../../../providers/stateProvider';
import Orders from './orders/orders';
import MainContent from '../../customComponents/mainContent';
import {
  OrderGridContainer, TitleGridContainer, ButtonsPaper, PrimaryTitle, OrderWrapper
} from './ordersContainer.styles';
import TabsButtons from './ordersTabsButtons/tabsButtons';
import { ButtonsGrid } from './orders/ordersFilter.styles';
import CustomButton from '../../customComponents/customButton';
import { AffiliateModal } from './affiliate-modal';
import OrderDropdown from '../../shared/orderDropdown/orderDropdown';
import { addHyphen } from '../../shared/methods';

const OrdersAdminContainer = () => {
  const [{ order: { ordersStatus } }, dispatch] = Object.values(useStateValue());
  const isSmall = useMediaQuery('(max-width: 991px)');
  const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    status: ordersStatus || 'open',
    currentPage: 1,
    currentPageCount: pageCount,
    statusValue: 0,
    orderNo: '',
    affiliate: '',
    dateFrom: '',
    dateTo: '',
    orderTotalFrom: '',
    orderTotalTo: '',
    productName: '',
    orderStatus: '',
    filters: {},
    approvalStatus: ''
  };
  const [state, setState] = useState(initialState);
  const {
    status, currentPage, currentPageCount, orderNo, affiliate, dateFrom, dateTo,
    orderTotalFrom, orderTotalTo, productName, orderStatus, filters, approvalStatus
  } = state;

  const [active, setActive] = useState(status);
  const [action, setAction] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [orderButtonEl, setOrderButtonEl] = useState(null);

  const role = localStorage.getItem('oga_user_role');
  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';

  const navigate = useNavigate();
  useLayoutEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);
  const [{
    user: { businessUserId, allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleOrderButtonClick = (event) => {
    setAction(true);
    navigate('/new-order');
  };
  const handleSetFilter = () => {
    setState({
      ...state,
      filters: {
        orderNo: Number(orderNo), status, metaStatus: orderStatus === 'All' ? '' : orderStatus, dateFrom, dateTo, businessName: affiliate,
        orderTotalFrom, orderTotalTo, productName
      }
    });
  };

  const handleSortOrder = () => {
    setState({
      ...state, approvalStatus: (approvalStatus && approvalStatus === 'True') ? 'False' : (approvalStatus && approvalStatus === 'False') ? 'True' : 'False',
      filters: {
        approvalStatus: (approvalStatus && approvalStatus === 'True') ? 'False' : (approvalStatus && approvalStatus === 'False') ? 'True' : 'False',
      }
    });
  };
  const handleClearFilter = () => {
    setState(initialState);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const handleDateSubmit = (dateRange) => {
    const { dateFrom: newDateFrom, dateTo: newDateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(newDateFrom).format('YYYY-M-DD'),
      dateTo: moment(newDateTo).format('YYYY-M-DD'),
    }));
  };
  const handleChangePage = (_, newPage) => {
    setState({ ...state, currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    sessionStorage.setItem('rowsPerPage', +event.target.value);
    setState({ ...state, currentPageCount: +event.target.value });
  };

  const handleStatusValueChange = (newValue) => {
    const activeState = newValue === 'open' ? 'open' : 'closed';
    setActive(activeState);
    setState({
      ...state,
      filters: {},
      status: newValue,
    });
  };

  const variables = {
    pageCount: currentPageCount,
    pageNumber: currentPage,
    status,
    ...filters,
  };

  const {
    data, loading, error, refetch
  } = useQuery(GET_OPEN_AND_CLOSED_ORDERS, {
    fetchPolicy: 'no-cache',
    variables
  });

  if (error) return `Error! ${error.message}`;
  const {
    businessOrderTotalNumber = 0
  } = data ?? '';

  const handleSelectAffiliate = () => {
    setOpenModal(true);
  };
  const handleDispatchOrders = () => navigate('/dispatch-orders');
  const buttons = [
    {
      name: 'Dispatch Orders',
      type: 'secondary',
      handleClick: handleDispatchOrders,
      styles: {
        width: '11rem', height: '2.7rem', background: '#fff', color: '#606060'
      },
      disabled: !userPermissions?.includes('manage_orders_dispatch_order')
    },
    {
      name: '+ Create new order',
      type: 'tertiary',
      handleClick: handleSelectAffiliate,
      styles: { width: '12rem', height: '2.7rem' },
      disabled: !userPermissions?.includes('manage_orders_create_new_order')
    },
  ];

  return (
    <MainContent>
      <OrderGridContainer container>
        {!isSmall && (
        <TitleGridContainer container item>
          <PrimaryTitle xs={6}> Manage Orders</PrimaryTitle>
          <ButtonsGrid xs={6} container item justifyContent="flex-end">
            {buttons.map(({
              name, type, styles, handleClick, disabled
            }) => (
              <CustomButton
                key={name}
                type={type}
                style={{ ...styles, marginLeft: '.5rem' }}
                onClick={handleClick}
                disabled={disabled}
                className={`mpAdmin-uat-ordersPage-${addHyphen(name)}`}
              >
                {name}
              </CustomButton>
            ))}
          </ButtonsGrid>
        </TitleGridContainer>
        )}
        <OrderWrapper item container xs={12}>
          {!isSmall && (
            <Grid item container xs={12}>
              <TabsButtons
                active={active}
                setActive={setActive}
                handleStatusValueChange={handleStatusValueChange}
              />
            </Grid>
          )}
          <Orders
            state={state}
            data={data}
            error={error}
            loading={loading}
            handleSetFilter={handleSetFilter}
            handleFilterChange={handleFilterChange}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={businessOrderTotalNumber}
            page={currentPage}
            currentPageCount={currentPageCount}
            viewStatus={status}
            handleClear={handleClearFilter}
            handleDateSubmit={handleDateSubmit}
            refetch={refetch}
            handleSort={handleSortOrder}
          />
        </OrderWrapper>
      </OrderGridContainer>
      <AffiliateModal
        afterMath={handleOrderButtonClick}
        open={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
      <OrderDropdown
        route="/new-order"
        extraAction={action}
        isAdmin={isAdmin}
        orderButtonEl={orderButtonEl}
        setOrderButtonEl={setOrderButtonEl}
        handleProductButtonClick={handleOrderButtonClick}
      />
    </MainContent>
  );
};

export default OrdersAdminContainer;
