import {
  Grid, List, ListItem, ListItemButton, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SetupWheel, Logout } from '../../assets/svgs';

export const CList = styled(List)`
  width: 16rem;
  padding: 24px 0;
`;

export const CListMb = styled(List)`
  width: 35rem;
  padding: 24px 0;
`;

export const CListItem = styled(ListItem)`
  padding: 0;
`;

export const CListItemButton = styled(ListItemButton)`
  padding: 10px 32px;
`;

export const CListItemText = styled(ListItemText)`
  & .MuiListItemText-primary {
    font-size: 16px;
    font-weight: 400;
    color: #606060;
    text-transform: capitalize;
  }

   @media(max-width: 991px) {
       & .MuiListItemText-primary {
         font-size: 25px;
       }
   }
`;

export const GridContainer = styled(Grid)`
    padding: 15px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 8px 12px;
      width: 20rem;
    }
`;

export const SetupIcon = styled(SetupWheel)`
  font-size: 3rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const LogoutIcon = styled(Logout)`
  font-size: 5rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }
`;
