import React from 'react';
import { styled } from '@mui/material/styles';
import { css } from 'styled-components';
import Button from '@mui/material/Button';

const primaryStyles = css`
  background-color: #FCED50;
  color: #424242;

  &:hover {
    background-color: #D2C647;
  }

  &.disabled {
    color: #848483;
    background-color: #CCCCCB;
  }
`;

const secondaryStyles = css`
  background-color: none;
  font-weight: 700;
  color: #235A91;
  border: 1px solid #235A91 !important;

  &.disabled {
    color: #235A91;
    border: 1px solid #CCCCCB;
  }

  @media (min-width: 992px) {
    & .label {
      font-weight: 700;
    }
  }
`;

const tertiaryStyles = css`
  background-color: ${({ header }) => (header ? '#303030' : '#000')};
  color: white;
  border: 1px solid #303030;

  &:hover {
    background-color: #424242;
  }

  &.disabled {
    color: #fff;
    background-color: #C0C0C1;
    border: 1px solid #C0C0C1;
  }
`;

const brandStyles = css`
  background-color: ${({ header }) => (header ? '#235A91' : '#ffffff')};
  color: white;
  &:hover {
    background-color: #235A91;
  }
  &.disabled {
    color: #fff;
    background-color: #C0C0C1;
  }
`;

const getButtonStyles = ({ type }) => {
  switch (type) {
    case 'secondary':
      return secondaryStyles;
    case 'tertiary':
      return tertiaryStyles;
    case 'brand':
      return brandStyles;
    default:
      return primaryStyles;
  }
};

export default styled(({ color, ...otherProps }) => (
  <Button {...otherProps} classes={{ disabled: 'disabled', label: 'label' }} />
))`
  color: ${(props) => props.color};
  border-radius: 1rem;
  width: 24rem;
  height: 6rem;
  white-space: nowrap;

  & .label {
    font-size: 2.5rem;
  }

  @media (min-width: 992px) {
    border-radius: ${({ header }) => (header ? '.4rem' : '.6rem')};
    width: 14rem;
    height: 2.6rem;

    & .label {
      font-size: ${({ header }) => (header ? '.75rem' : '.98rem')};
      font-weight: ${({ header }) => (header ? 600 : 500)};
    }
  }

  ${getButtonStyles}
`;
