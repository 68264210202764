import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Button } from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import MainContent from '../customComponents/mainContent';
import SwitchToggle from '../shared/switchToggle';
import SelectWCheckbox from '../shared/selectWCheckbox';
import ExpiredDialog from '../shared/notifyDialog/expiredDialog';
import LowOnStock from '../shared/notifyDialog/lowStockDialog';
import PrescriptionDialog from '../shared/notifyDialog/prescriptionDialog';
import { GET_NOTIFICATION_SETTINGS } from '../../queries/notifications';
import { UPDATE_NOTIFICATION_SETTINGS } from '../../mutations/notifications';
import {
  TitleGrid, TitleTextContainer, PrimaryTitle, Desc, PaperWrapper, OptionWrapper,
  Title, SaveChanges, FooterSec
} from './notificationSettings.styles';

const NotificationSettings = () => {
  const [expiredProd, setExpiredProd] = useState(false);
  const [selectedExpired, setSelectedExpired] = useState([]);
  const [lowStock, setLowStock] = useState(false);
  const [prescription, setPrescription] = useState(false);
  const [selectedLowOnStock, setSelectedLowOnStock] = useState([]);
  const [seletedPrescription, setSeletedPrescription] = useState([]);
  const [lowOnStockDialog, setLowOnStockDialog] = useState(false);
  const [expiredDialog, setExpiredDialog] = useState(false);
  const [openPrescriptionDialog, setOpenPrescriptionDialog] = useState(false);
  const [lowOnStockAutomatic, setLowOnStockAutomatic] = useState(false);

  const days = [
    { value: '7' },
    { value: '10' },
    { value: '14' },
    { value: '21' },
    { value: '28' },
    { value: '30' },
    { value: 'Custom' }
  ];

  const productAmount = [
    { value: '0' },
    { value: 'Automatic' },
    { value: 'Custom' }
  ];

  const prescriptionOptions = [
    { value: '0' },
    { value: 'Custom' }
  ];

  const [updateSettings, { loading: settingLoading }] = useMutation(UPDATE_NOTIFICATION_SETTINGS);

  const saveHandler = () => {
    const getExpireTimelines = selectedExpired.map(({ value }) => Number(value));
    const getLowOnStockTimelines = selectedLowOnStock.map(({ value }) => Number(value));
    const prescriptionTimelines = seletedPrescription.map(({ value }) => Number(value));
    const variables = {
      expiryActive: expiredProd,
      expiryTimelines: getExpireTimelines,
      lowOnStockActive: lowStock,
      lowOnStockTimelines: getLowOnStockTimelines,
      lowOnStockAuto: lowOnStockAutomatic,
      prescriptionActive: prescription,
      prescriptionTimelines
    };
    updateSettings({ variables })
      .then(({ data }) => {
        const { message } = data?.updateBusinessNotificationSettings ?? '';
        toast.success(message);
      });
  };

  const handleDialog = (val) => {
    if (val === 'expired') {
      if (expiredDialog) setExpiredDialog(false);
      else setExpiredDialog(true);
    }
    if (val === 'lowOnStock') {
      if (lowOnStockDialog) setLowOnStockDialog(false);
      else setLowOnStockDialog(true);
    }
    if (val === 'prescription') {
      setOpenPrescriptionDialog(!openPrescriptionDialog);
    }
  };

  const { loading, data } = useQuery(GET_NOTIFICATION_SETTINGS);
  const {
    expiryActive, lowOnStockActive, expiryTimelines, lowOnStockTimelines, lowOnStockAuto,
    prescriptionActive, prescriptionTimelines
  } = data?.businessNotificationSettings || {};

  useEffect(() => {
    if (!loading && data) {
      const expiredToObj = expiryTimelines?.map((val) => ({ value: val.toString() }));
      const lowOnStockObj = lowOnStockTimelines?.map((val) => ({ value: val.toString() }));
      const prescriptionObj = prescriptionTimelines?.map((val) => ({ value: val.toString() }));
      setExpiredProd(expiryActive);
      setLowStock(lowOnStockActive);
      setPrescription(prescriptionActive);
      setSelectedExpired(expiredToObj);
      setSelectedLowOnStock(lowOnStockObj);
      setLowOnStockAutomatic(lowOnStockAuto);
      setSeletedPrescription(prescriptionObj);
    }
  }, [loading, data]);

  return (
    <>
      <MainContent>
        <Grid container direction="column" style={{ padding: '30px' }}>
          <TitleGrid container item>
            <TitleTextContainer item>
              <PrimaryTitle variant="h5">Notifications Settings</PrimaryTitle>
              <Desc variant="caption">Choose when and how to be notified</Desc>
            </TitleTextContainer>
          </TitleGrid>
          <PaperWrapper elevation={0}>
            <TitleTextContainer item>
              <PrimaryTitle variant="h5">General Settings</PrimaryTitle>
              <Desc variant="caption">
                Select push and email notifications you&apos;d like to receive
              </Desc>
            </TitleTextContainer>
            <Grid container>
              {loading ? <CircularProgress />
                : (
                  <>
                    <OptionWrapper container item>
                      <Grid>
                        <SwitchToggle
                          checkedState={expiredProd}
                          setCheckedState={setExpiredProd}
                        />
                      </Grid>
                      <Grid>
                        <Title>Expired Products</Title>
                        <Desc variant="caption" style={{ marginTop: '5px' }}>
                          Notify me of expired products
                        </Desc>
                        <Desc variant="caption" style={{ marginTop: '15px' }}>
                          When to notify me
                        </Desc>
                        <SelectWCheckbox
                          list={days}
                          placeholder="Select Days"
                          selectedOptions={selectedExpired}
                          setSelectedOptions={setSelectedExpired}
                          handleDialog={() => handleDialog('expired')}
                          disabled={expiredProd}
                          extension="Days"
                        />
                      </Grid>
                    </OptionWrapper>
                    <OptionWrapper container item>
                      <Grid>
                        <SwitchToggle
                          checkedState={lowStock}
                          setCheckedState={setLowStock}
                        />
                      </Grid>
                      <Grid>
                        <Title>Low on Stock Products</Title>
                        <Desc variant="caption" style={{ marginTop: '5px' }}>
                          Notify me of out of stock products
                        </Desc>
                        <Desc variant="caption" style={{ marginTop: '15px' }}>
                          Notify me when product remains
                        </Desc>
                        <SelectWCheckbox
                          list={productAmount}
                          placeholder="Select Product Amount"
                          selectedOptions={selectedLowOnStock}
                          setSelectedOptions={setSelectedLowOnStock}
                          handleDialog={() => handleDialog('lowOnStock')}
                          disabled={lowStock}
                          extension="Products"
                          automatic={lowOnStockAutomatic}
                          setAutomatic={setLowOnStockAutomatic}
                        />
                      </Grid>
                    </OptionWrapper>
                    <OptionWrapper container item>
                      <Grid>
                        <SwitchToggle
                          checkedState={prescription}
                          setCheckedState={setPrescription}
                        />
                      </Grid>
                      <Grid>
                        <Title>Customer Prescription</Title>
                        <Desc variant="caption" style={{ marginTop: '5px' }}>
                          Notify customer of Out of dose
                        </Desc>
                        <Desc variant="caption" style={{ marginTop: '15px' }}>
                          Notify customer when dosage remains
                        </Desc>
                        <SelectWCheckbox
                          list={prescriptionOptions}
                          placeholder="Select Product Amount"
                          selectedOptions={seletedPrescription}
                          setSelectedOptions={setSeletedPrescription}
                          handleDialog={() => handleDialog('prescription')}
                          disabled={prescription}
                          extension="doze"
                        />
                      </Grid>
                    </OptionWrapper>
                    <FooterSec>
                      <Button variant="contained" disabled={settingLoading} data-testId="saveSettings" onClick={saveHandler}>
                        { settingLoading ? <CircularProgress style={{ color: 'white' }} size={30} /> : 'Save' }
                      </Button>
                    </FooterSec>
                  </>
                )}
            </Grid>
          </PaperWrapper>
        </Grid>
      </MainContent>
      <ExpiredDialog
        dialogOpen={expiredDialog}
        handleDialog={() => handleDialog('expired')}
        selectedExpired={selectedExpired}
        setSelectedExpired={setSelectedExpired}
      />
      <LowOnStock
        dialogOpen={lowOnStockDialog}
        handleDialog={() => handleDialog('lowOnStock')}
        selectedLowOnStock={selectedLowOnStock}
        setSelectedLowOnStock={setSelectedLowOnStock}
      />
      <PrescriptionDialog
        dialogOpen={openPrescriptionDialog}
        handleDialog={() => handleDialog('prescription')}
        seletedPrescription={seletedPrescription}
        setSeletedPrescription={setSeletedPrescription}
      />
    </>
  );
};

export default NotificationSettings;
