import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  TableContainer, Table, TableRow, TableBody, TableHead
} from '@mui/material';
import {
  TableBox, MainTableRow, BodyCell, HeaderCell, ProductTypo, Content
} from './productInfo.styles';

export default function ProductInfo({
  product
}) {
  const { batches, marketplaceVersion, qpc } = product;
  const { expiryDate } = batches[0] || {};
  const {
    manufacturer, category, packSize, unitOfMeasurement, barcode,
    supplier
  } = marketplaceVersion;
  const { categoryName } = category;
  const { id } = supplier;
  const headers = [
    'SKU', 'Qty/Ctn', 'Pack Size', 'Category', 'UOM', 'UPC', 'UPC2', 'UPC2', 'Manufacturer', 'Expiry Date'
  ];

  return (
    <Content>
      <ProductTypo>Product Information</ProductTypo>
      <TableBox>
        <TableContainer>
          <Table aria-label="payments table">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <HeaderCell>{header}</HeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <MainTableRow>
                <BodyCell>
                  {id}
                </BodyCell>
                <BodyCell>
                  {qpc || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {packSize || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {categoryName || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {unitOfMeasurement || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {barcode || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {barcode || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {barcode || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {manufacturer?.name || 'N/A'}
                </BodyCell>
                <BodyCell>
                  {moment(expiryDate).format('MMM DD, YYYY') || 'N/A'}
                </BodyCell>
              </MainTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </TableBox>
    </Content>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.instanceOf(Object),
};

ProductInfo.defaultProps = {
  product: {}
};
