import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, InputAdornment } from '@mui/material';
import toast from 'react-hot-toast';
import {
  ProductContainer, ProductDetail, ProductDetailImage, ProductDetailBrandNameText,
  ProductDetailPackSizeText, HideMd, ShowMd, ProductCounter,
  ProductCounterRemoveButton, ProductCounterAddButton,
  ProductCounterMobileContainer, ProductDetailMainContainer, MainTableRow,
  ProductDetailNameMainContainer, ProductCounterQuantityInput, Add, Remove,
  FieldCheckbox, CheckboxImage, QuantityText, ProductDetailBrandTypo, CustomInputBase, ActionButton, TextWrapper
} from './styles';

import CheckboxIcon from '../../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../../assets/images/supplier/Checkbox-resting.png';

import { BodyCell, ImageWrapper } from '../../../cart/product-item/styles';
import { CaretDownIcon, CaretUpIcon } from '../../../../assets/svgs';
import ReturnSelectField from './returnSelectField';
import SelectBatchDialog from './selectBatchDialog';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const conditionSelectBoxField = {
  name: 'Condition', label: 'Select',
  options: ['Good', 'Not Good']
};
const DUMMY_FUNCTION = () => '';
const ProductItem = ({
  product,
  incrementProductQuantity,
  decrementProductQuantity,
  changeInputQuantity,
  handleConditionChange,
  quantityRecieve,
  handleBatchSelection,
  orderStatus,
  handleConfirmedQtyInputValue,
  handleQtyConfirmedIncrement,
  handleQtyConfirmedDecrement,
  qtyConfirmed,
  viewProduct
}) => {
  const {
    id, name: brandName, meta, quantity, recieved, condition, quantityInStock, batches,
    initialQuantity, quantityRecieved, quantityConfirmed, supplierOrderStatus, orderproductSetMeta: { partly_fulfilled: partlyFulfilled, batches: selectedBatches },
  } = product;
  const {
    image, market_rpp: marketRpp, pack_size: packSize
  } = meta;
  const [open, setOpen] = useState(false);
  const supplierOrderRecieved = supplierOrderStatus === 'SUPPLIER_ORDER_RECEIVED';
  useEffect(() => {
    // if the product is not resolved then set it to not recieved
    if (!supplierOrderRecieved) {
      handleConditionChange(id, false, 'recieved');
    }
  }, [supplierOrderStatus, id]);

  const toggleBatchDialog = () => {
    if (!batches?.length) return toast.error('No batches available for this product');
    return setOpen(!open);
  };
  const updatedQuantity = quantityRecieve.find(({ productId }) => productId === id);
  const updatedQtyConfirmed = qtyConfirmed.find(({ productId }) => productId === id);
  return <>
    <Hidden lgDown>

      <MainTableRow
        style={partlyFulfilled ?? ((quantityRecieved === 0) || (updatedQuantity?.quantityReceived === 0)) ? { opacity: 0.4, cursor: 'not-allowed' } : {}}
      >
        <ProductDetail container>
          <ImageWrapper>
            <ProductDetailImage src={image} alt="" />
          </ImageWrapper>
          <ProductDetailNameMainContainer>
            <ProductDetailBrandNameText style={{ color: !quantityInStock ? 'red' : '' }}>
              { brandName }
            </ProductDetailBrandNameText>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp;
              { packSize }
            </ProductDetailPackSizeText>
          </ProductDetailNameMainContainer>
        </ProductDetail>
        <BodyCell>
          <QuantityText variant="h6">
            {quantityInStock}
          </QuantityText>
        </BodyCell>
        <BodyCell>
          <QuantityText variant="h6">
            {initialQuantity}
          </QuantityText>
        </BodyCell>
        <BodyCell>
          <CustomInputBase
            type="number"
            placeholder="0"
            min={1}
            size="medium"
            value={Number(updatedQtyConfirmed?.quantityConfirmed ?? quantityConfirmed ?? initialQuantity)}
            onChange={(event) => handleConfirmedQtyInputValue(id, event.target.value)}
            disabled={viewProduct}
            endAdornment={(
              <InputAdornment position="end">
                <Grid item container direction="column">
                  <CaretUpIcon
                    style={{
                      width: '1rem', height: '1rem', cursor: 'pointer', pointerEvents: viewProduct ? 'none' : ''
                    }}
                    onClick={() => handleQtyConfirmedIncrement(id)}
                  />
                  <CaretDownIcon
                    style={{
                      width: '1rem', height: '1rem', cursor: 'pointer', pointerEvents: viewProduct ? 'none' : ''
                    }}
                    onClick={() => handleQtyConfirmedDecrement(id)}
                  />
                </Grid>
              </InputAdornment>
            )}
          />
        </BodyCell>
        <BodyCell>
          <CustomInputBase
            type="number"
            placeholder="0"
            min={1}
            size="medium"
            disabled={viewProduct || selectedBatches?.length || updatedQuantity?.batches?.length}
            value={Number(updatedQuantity?.quantityReceived ?? quantityRecieved ?? quantity)}
            onChange={(event) => changeInputQuantity(id, event.target.value)}
            endAdornment={(
              <InputAdornment position="end">
                <Grid item container direction="column">
                  <CaretUpIcon
                    style={{
                      width: '1rem', height: '1rem', pointerEvents: (viewProduct || selectedBatches?.length || updatedQuantity?.batches?.length) ? 'none' : '', cursor: 'pointer'
                    }}
                    onClick={() => incrementProductQuantity(id)}
                  />
                  <CaretDownIcon
                    style={{
                      width: '1rem', height: '1rem', pointerEvents: (viewProduct || selectedBatches?.length || updatedQuantity?.batches?.length) ? 'none' : '', cursor: 'pointer'
                    }}
                    onClick={() => decrementProductQuantity(id)}
                  />
                </Grid>
              </InputAdornment>
            )}
          />
        </BodyCell>
        <BodyCell style={{ textAlign: 'center' }}>
          <ProductDetailMainContainer>
            <ReturnSelectField
              field={conditionSelectBoxField}
              value={condition}
              label=""
              handleCreditDaysOpen={() => ({})}
              fullWidth
              handleChange={(e) => handleConditionChange(id, e.target.value, 'condition')}
              showCheckBox={false}
              showLabel={false}
              disabled={viewProduct}
            />
          </ProductDetailMainContainer>
        </BodyCell>

        {orderStatus !== 'ORDER_CANCELED'
          ? (
            <>
              <BodyCell style={{ textAlign: 'center' }}>
                <FieldCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={quantityRecieved === 0 || !recieved}
                  disabled={viewProduct}
                  onChange={(e) => (
                    supplierOrderRecieved
                      ? handleConditionChange(id, !e.target.checked, 'recieved')
                      : DUMMY_FUNCTION
                  )}
                />
              </BodyCell>

              <BodyCell>
                <ActionButton
                  data-testid="create-product"
                  onClick={toggleBatchDialog}
                  disabled={viewProduct}
                >
                  <TextWrapper>
                    Select Batch
                  </TextWrapper>
                </ActionButton>
              </BodyCell>
            </>
          ) : ''}
      </MainTableRow>
    </Hidden>

    <ShowMd container item>
      <ProductContainer
        style={partlyFulfilled ?? ((quantityRecieved === 0) || (updatedQuantity?.quantityReceived === 0)) ? { opacity: 0.4, cursor: 'not-allowed' } : {}}
      >
        <Grid item container xs={3} style={{ position: 'relative' }}>
          <ProductDetailImage src={image} alt="" />
        </Grid>
        <Grid item container xs={9} direction="column">
          <Grid item container>
            <ProductDetailBrandTypo>
              { brandName }
            </ProductDetailBrandTypo>
          </Grid>
          <Grid item container>
            <ProductDetailPackSizeText>
              Pack Size:&nbsp; &nbsp;
              {packSize}
            </ProductDetailPackSizeText>
          </Grid>
          <Grid item container alignItems="center" style={{ margin: '1.5rem 0' }}>
            <Grid item container xs={9}>
              <ReturnSelectField
                field={conditionSelectBoxField}
                value={condition}
                label="Select"
                size="medium"
                handleCreditDaysOpen={() => ({})}
                fullWidth
                handleChange={(e) => handleConditionChange(id, e.target.value, 'condition')}
              />
            </Grid>
            <Grid item container xs={3} justifyContent="flex-end">
              <FieldCheckbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={(updatedQuantity?.quantityReceived === 0 || quantityRecieved === 0) ?? !recieved}
                onClick={(e) => (
                  supplierOrderRecieved
                    ? handleConditionChange(id, !e.target.checked, 'recieved')
                    : DUMMY_FUNCTION
                )}
              />
            </Grid>
          </Grid>
          <ProductCounterMobileContainer>
            <ProductCounter>
              <ProductCounterRemoveButton onClick={() => decrementProductQuantity(id)}>
                <Remove />
              </ProductCounterRemoveButton>
              <ProductCounterQuantityInput
                value={Number(updatedQuantity?.quantityReceived ?? quantityRecieved ?? quantity)}
                onChange={(event) => changeInputQuantity(id, event.target.value)}
              />
              <ProductCounterAddButton onClick={() => incrementProductQuantity(id)}>
                <Add />
              </ProductCounterAddButton>
            </ProductCounter>
          </ProductCounterMobileContainer>
        </Grid>
      </ProductContainer>
    </ShowMd>
    <SelectBatchDialog
      open={open}
      batches={batches}
      toggleBatchDialog={toggleBatchDialog}
      product={product}
      handleBatchSelection={handleBatchSelection}
      selectedBatches={selectedBatches}
      qtyConfirmed={updatedQtyConfirmed?.quantityConfirmed ?? quantityConfirmed ?? initialQuantity}
    />
  </>;
};

ProductItem.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  quantityRecieve: PropTypes.instanceOf(Array).isRequired,
  incrementProductQuantity: PropTypes.func.isRequired,
  decrementProductQuantity: PropTypes.func.isRequired,
  changeInputQuantity: PropTypes.func.isRequired,
  handleConditionChange: PropTypes.func.isRequired,
  handleBatchSelection: PropTypes.func.isRequired,
  orderStatus: PropTypes.string.isRequired,
  handleConfirmedQtyInputValue: PropTypes.func.isRequired,
  handleQtyConfirmedIncrement: PropTypes.func.isRequired,
  handleQtyConfirmedDecrement: PropTypes.func.isRequired,
  qtyConfirmed: PropTypes.instanceOf(Array).isRequired,
};

ProductItem.defaultProps = {
};

export default ProductItem;
