import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import CustomSearchField from '../../shared/CustomSearchField';
import {
  StatusesGrid, UnderlineGrid, Typo
} from './filter.styles';

const Filter = ({
  state, handleSetFilter, handleSearch, searchButtonText, handleSearchButton
}) => {
  const [status, setStatus] = useState('ACTIVE');
  const { searchText } = state ?? '';

  const productStatuses = [
    {
      label: 'My Business', name: 'status', value: 'ACTIVE', width: '15%'
    },
    {
      label: 'Disabled', name: 'status', value: 'IN_ACTIVE', width: '11%'
    }
  ];

  const handleStatusClick = (name, value) => {
    setStatus(value);
    handleSetFilter(name, value);
  };

  return (
    <Grid item container xs={12}>
      <StatusesGrid container item>
        {productStatuses.map(({
          label, name, value, width
        }) => (
          <UnderlineGrid
            className={`erp-uat-${name}`}
            key={value}
            item
            onClick={() => handleStatusClick(name, value)}
            active={value === status}
            width={width}
          >
            <Typo active={value === status}>{label}</Typo>
          </UnderlineGrid>
        ))}
        <UnderlineGrid width="74%" style={{ padding: '10.5px 0', cursor: 'auto' }}>
          <Typo />
        </UnderlineGrid>
      </StatusesGrid>
      <StatusesGrid container item style={{ padding: '0 1.5rem' }}>
        <CustomSearchField
          name="search"
          value={searchText}
          fullWidth
          placeholder={searchButtonText === 'Products' ? 'Search Products' : 'Search Molecules seperated by a comma'}
          handleChange={(e) => handleSearch(e?.target?.value)}
          adornmentButtonText={searchButtonText}
          adornmentButtonFunc={handleSearchButton}
        />
      </StatusesGrid>
    </Grid>
  );
};

Filter.propTypes = {
  state: PropTypes.shape(Object),
  handleSetFilter: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  searchButtonText: PropTypes.string.isRequired,
  handleSearchButton: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  state: {}
};

export default Filter;
