import { JSONParse } from '../../../utils/json';

export function selectForReconciliation(oldReconciliation, { payload: product }) {
  const reconciliation = { ...oldReconciliation };
  const { batches, targetQuantity, saleItemBatchId } = product;
  const modalBatches = new Map();
  if (batches.length) {
    batches.forEach((batch) => {
      const { batchNo, expiryDate, quantityInStock } = batch;
      const qtyToSell = 0;
      const checked = false;
      modalBatches.set(`${batch.id}`, {
        orderProductId: +batch.id, batchNo, expiryDate, qtyInStock: batch.quantityInStock, qtyToSell, checked, targetQuantity
      });
    });
  }
  const { pack_size: packSize = '' } = JSONParse(product.meta);
  reconciliation.batchModalData = {
    saleItemBatchId,
    openedBatchModal: true,
    product: { ...product, packSize },
    batches: modalBatches,
    targetQuantity
  };
  return {
    ...reconciliation,
  };
}

export function closeBatchModal(oldReconciliation) {
  const reconciliation = { ...oldReconciliation };
  reconciliation.batchModalData = {
    ...reconciliation.batchModalData,
    openedBatchModal: false,
  };
  return reconciliation;
}
export function toggleSelectProductBatch(oldReconciliation, { payload: { orderProductId } }) {
  const reconciliation = { ...oldReconciliation };
  const modalBatches = reconciliation.batchModalData.batches;
  const exists = modalBatches.get(`${orderProductId}`);
  if (exists) {
    const checked = !exists.checked;
    modalBatches.set(`${orderProductId}`, { ...exists, checked, qtyToSell: checked ? 1 : 0 });
  }
  reconciliation.batchModalData = {
    ...reconciliation.batchModalData,
    batches: modalBatches,
  };
  return reconciliation;
}

export function updateBatchToSellQuantity(oldReconciliation, { payload: { orderProductId, quantity } }) {
  const reconciliation = { ...oldReconciliation };
  const modalBatches = reconciliation.batchModalData.batches;
  const exists = modalBatches.get(`${orderProductId}`);
  if (exists) {
    if (quantity <= exists.targetQuantity && quantity <= exists.qtyInStock) {
      modalBatches.set(`${orderProductId}`, { ...exists, qtyToSell: +quantity });
    } else if (quantity > exists.targetQuantity && quantity <= exists.qtyInStock) {
      modalBatches.set(`${orderProductId}`, { ...exists, qtyToSell: +exists.targetQuantity });
    }
  }
  reconciliation.batchModalData = {
    ...reconciliation.batchModalData,
    batches: modalBatches,
  };
  return reconciliation;
}
