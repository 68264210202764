import React, {
  useState, useEffect, useLayoutEffect, useRef
} from 'react';
import {
  Grid, Slide, CircularProgress, Button
} from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import toast from 'react-hot-toast';
import moment from 'moment';
import CustomInputBase from '../../customComponents/customInputBase';
import currencyFormatter from '../../shared/currencyFormatter';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import ReturnActionTypes from '../../../providers/reducers/returns/returnsTypes';
import { MAKE_SALE_MUTATION } from '../../../mutations/sales';
import { round } from '../../../utils/utils';
import ChangeDialog from './changeDialog';
import ReceiptDialog from './receiptDialog';
import {
  PaymentDialog, WrapperGrid, HeaderGrid, Header, ItemsGrid, CloseIcon
} from './paymentDialog.styles';

import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const Payment = ({ openDialog, setOpenDialog }) => {
  const [{
    sale: {
      cart, saleTotal, percDiscount, discount, subTotal,
      customerModalData: {
        id: customerId,
        storeCredit: customerStoreCredit,
        loyaltyPoint: customerLoyaltyPoints,
        name: customerName,
        refetchCustomer
      },
    },
    product: { refetch: productsRefetch },
    user: { loyaltyPointValue, automaticSales }
  }, dispatch] = Object.values(useStateValue());

  const [balanceDue, setBalanceDue] = useState(subTotal);
  const [changeDue, setChangeDue] = useState(0);
  const [calDiscount, setCalDiscount] = useState('');
  const [cash, setCash] = useState();
  const [card, setCard] = useState();
  const [bankTransfer, setBankTransfer] = useState();
  const [storeCredit, setStoreCredit] = useState();
  const [loyaltyPointsValue, setLoyaltyPointsValue] = useState();
  const [accruedPoints, setAccruedPoints] = useState(0);
  const [popup, setPopup] = useState(false);
  const [saveChange, setSaveChange] = useState('RETURNED');
  const [saleReturnData, setSaleReturnData] = useState({ businessUser: {}, receiptNumber: '' });
  const [openReceipt, setOpenReceipt] = useState(false);

  const loyaltyValue = () => Math.trunc(customerLoyaltyPoints / loyaltyPointValue);

  useEffect(() => {
    if (percDiscount) setCalDiscount(percDiscount);
  }, []);

  const openId = useRef(1);
  useEffect(() => {
    if (!openDialog) openId.current += 1;
  }, [openDialog]);

  useEffect(() => {
    const _discount = round(subTotal * (percDiscount / 100));
    dispatch({
      type: SaleActionTypes.UPDATE_SALE_STATE,
      payload: {
        discount: _discount,
        saleTotal: subTotal - _discount
      }
    });
  }, [percDiscount, subTotal]);

  useLayoutEffect(() => {
    const methodsSum = (cash || 0) + (storeCredit || 0) + (loyaltyPointsValue || 0)
      + (card || 0) + (bankTransfer || 0);

    if (methodsSum < saleTotal) {
      const balance = saleTotal - methodsSum;
      setBalanceDue(round(balance));
      setChangeDue(0.00);
    } else {
      const change = methodsSum - saleTotal;
      setChangeDue(change);
      setBalanceDue(0.00);
    }
  });

  const cartItems = [];
  cart.forEach((item) => {
    cartItems.push((item));
  });

  const getDiscount = (item) => {
    const {
      discount: prodDiscount, isPercentage, resolvedPriceInUseValue, quantity
    } = item;
    const tot = resolvedPriceInUseValue * quantity;
    if (isPercentage) return tot * (prodDiscount / 100);
    return prodDiscount || 0;
  };

  const handleClose = async () => {
    setOpenDialog(false);
  };

  const [makeSales, { loading, error }] = useMutation(MAKE_SALE_MUTATION);

  useEffect(() => {
    if (error) toast.error(error?.message);
  }, [error]);

  const handleConfirmation = async () => {
    setPopup(false);
    const sale = [];
    cart.forEach((item) => {
      const batches = [];
      item?.batches?.forEach((batch) => {
        batches.push({ batchId: batch.orderProductId, qtyToSell: batch.qtyToSell });
      });
      if (batches?.length === 0) {
        batches.push({ batchId: -1, qtyToSell: item.quantity });
      }
      sale.push({
        productId: item.productId,
        quantity: item.quantity,
        ...(!automaticSales && { batches }),
        missedSale: item.missedSale,
        prodDiscount: getDiscount(item),
        prescription: item.prescription
      });
    });
    const paymentsArr = [];
    if (card > 0) {
      paymentsArr.push({ paymentMethod: 'CARD', amount: card });
    }
    if (bankTransfer > 0) {
      paymentsArr.push({ paymentMethod: 'BANK_TRANSFER', amount: bankTransfer });
    }
    if (storeCredit > 0) {
      paymentsArr.push({ paymentMethod: 'CREDIT', amount: storeCredit });
    }
    if (loyaltyPointsValue > 0) {
      paymentsArr.push({ paymentMethod: 'LOYALTY', amount: loyaltyPointsValue });
    }
    if (cash > 0) {
      paymentsArr.push({ paymentMethod: 'CASH', amount: cash });
    }

    if (balanceDue <= 0.0009) {
      const params = {
        cart: sale,
        receiptNumber: uuid().slice(0, 12),
        customerId: customerId > 0 ? customerId : 0,
        transactionDate: moment().toISOString(),
        paymentsMade: paymentsArr,
        discount,
        changeDue,
        ...(saveChange !== 'RETURNED' && { changeStatus: saveChange })
      };

      const key = 'id';
      delete params[key];

      const sales = [];
      sales.push({
        ...params,
        version: 0
      });
      const variables = {
        sales
      };

      const resp = await makeSales({ variables });
      if (resp) {
        const { message } = resp?.data?.createSales ?? '';
        const { customer, receiptNumber, businessUser } = resp?.data?.createSales?.sale ?? '';
        const points = customer ? customer.loyaltyPoint : 0;
        const oldPoints = customerLoyaltyPoints || 0;
        setAccruedPoints(points - oldPoints);

        setSaleReturnData({ businessUser, receiptNumber, message });
        refetchCustomer();
      }
      handleClose();
      setOpenReceipt(!openReceipt);
    } else {
      toast.error('Amount to be paid not complete!');
    }
  };

  const closeReceiptDialog = () => {
    setOpenReceipt(false);
    toast.success(saleReturnData.message);

    dispatch({
      type: ReturnActionTypes.UPDATE_CART,
      payload: {
        cart: [],
        total: 0.00,
        subTotal: 0.00,
      }
    });
    dispatch({
      type: SaleActionTypes.CLEAR_CART
    });
    dispatch({ type: SaleActionTypes.RESET_CUSTOMER });
    productsRefetch();
    setCash(null);
    setCard(null);
    setBankTransfer(null);
    setStoreCredit(null);
    setLoyaltyPointsValue(null);
    openId.current += 1;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const val = Number(value);
    switch (name) {
      case 'cash': return setCash(val);
      case 'card': return setCard(val);
      case 'bankTransfer': return setBankTransfer(val);
      case 'storeCredit': {
        if (!val || (parseInt(val) <= parseInt(customerStoreCredit))) {
          return setStoreCredit(val);
        }
        return setStoreCredit(customerStoreCredit);
      }
      case 'loyaltyPoints': {
        if (!val || (parseInt(val) <= parseInt(loyaltyValue()))) {
          return setLoyaltyPointsValue(val);
        }
        return setLoyaltyPointsValue(loyaltyValue());
      }
      default:
        return false;
    }
  };

  const getStoreCreditLabel = () => (customerId && customerStoreCredit
    ? `Available Balance: ₦ ${currencyFormatter(customerStoreCredit)}`
    : '');

  const getLoyaltyPointsLabel = () => (customerId && customerLoyaltyPoints
    ? `Available Points: ${customerLoyaltyPoints} = ₦ ${loyaltyValue()}`
    : '');

  const payFields = [
    {
      name: 'cash', value: cash, label: 'Cash', testId: 'payment-cash-input'
    },
    {
      name: 'card', value: card, label: 'Card (POS)', testId: 'payment-card-input'
    },
    {
      name: 'bankTransfer', value: bankTransfer, label: 'Bank Transfer', testId: 'payment-bank-input'
    },
    {
      name: 'storeCredit', value: storeCredit, label: 'Store Credit', sideLabel: getStoreCreditLabel(),
      disable: !(customerId && customerStoreCredit), testId: 'payment-credit-input'
    },
    {
      name: 'loyaltyPoints', value: loyaltyPointsValue, label: 'Loyalty Points', sideLabel: getLoyaltyPointsLabel(),
      disable: !(customerId && customerLoyaltyPoints && loyaltyPointValue), testId: 'payment-loyalty-input'
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, value, label, sideLabel = '', disable, testId
    } = field;
    return (
      <CustomInputBase
        label={label}
        sideLabel={sideLabel}
        value={value}
        size="small"
        type="number"
        name={fieldName}
        placeholder="Enter Amount"
        onChange={handleChange}
        disabled={disable}
        cSize="lg"
        data-testid={testId}
      />
    );
  };

  return (
    <PaymentDialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      key={openId.current}
    >
      <WrapperGrid item container>
        <HeaderGrid item container>
          <Grid item md={8}>
            <Header variant="h5">Payment</Header>
          </Grid>
          <CloseIcon onClick={handleClose} />
        </HeaderGrid>
        <ItemsGrid item container spacing={2}>
          {payFields.map((field) => (
            <Grid item xs={12} key={field.name}>{returnTextField(field)}</Grid>
          ))}
        </ItemsGrid>
        <Button
          variant="contained"
          disabled={loading}
          style={{ width: '100%', marginTop: '1.7rem' }}
          onClick={() => {
            if (changeDue > 0) setPopup(true);
            else handleConfirmation();
          }}
        >
          {loading ? <CircularProgress size={18} /> : 'Confirm Payment' }
        </Button>

        <ChangeDialog
          popup={popup}
          setPopup={setPopup}
          saveChange={saveChange}
          setSaveChange={setSaveChange}
          handleConfirmation={handleConfirmation}
          customerId={customerId}
          loading={loading}
        />

        <ReceiptDialog
          openDialog={openReceipt}
          customer={customerId > 0 ? { name: customerName } : null}
          cart={cartItems}
          saleReturnData={saleReturnData}
          subTotal={subTotal}
          discount={discount}
          total={saleTotal}
          paymentMethod={{
            loyaltyPoints: loyaltyPointsValue, card, cash, bankTransfer, storeCredit
          }}
          saveChange={saveChange}
          changeDue={changeDue}
          onClose={closeReceiptDialog}
        />
      </WrapperGrid>
    </PaymentDialog>
  );
};

Payment.propTypes = {
  openDialog: PropTypes.number,
  setOpenDialog: PropTypes.func,
};

Payment.defaultProps = {
  openDialog: false,
  setOpenDialog: () => {}
};

export default Payment;
