import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { KeyboardArrowLeft } from '@mui/icons-material';

const sm = '960px';

export const ProductCardsContainer = styled(Grid)`
  // @media (min-width: 992px) {
  //   margin: auto;
  // }
`;

export const ProductCardGrid = styled(Grid)`
  margin-right: 25px;
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: 1px;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: center;
  margin-bottom: 30px;

  @media(min-width: 992px) {
    justify-content: flex-end;
  }
`;

export const Loading = styled(Typography)`
  font-size: 20px;
  color: #4D4F5C;
  padding: 2rem;

  @media(max-width: 991px) {
    font-size: 2.2rem;
  }
`;

export const TextHeader = styled(Typography)`
  font-size: 22px;
  color: #000000;
  padding: 20px 0px;
  font-weight: 600;

  @media(max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const SimilarProductsBox = styled(Grid)`
  justify-content: center;
  margin-top: 50px;
  position: relative;

`;

export const NextImage = styled('div')`
  background: #F3F9FF;
  border-radius: 50%;
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -1.8rem;
  top: 10rem;
  z-index: 5;
  cursor: pointer;
  color: #235A91;
`;

export const PrevImage = styled('div')`
  background: #F3F9FF;
  border-radius: 50%;
  position: absolute;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -1.8rem;
  top: 10rem;
  z-index: 5;
  cursor: pointer;
  color: #235A91;
`;
