/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@mui/material';
import {
  SelectNameTypo, SelectFormControl, CSelect, Label
} from './returnSelectField.styles';
import CheckboxIcon from '../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../assets/images/supplier/Checkbox-resting.png';

const ReturnSelectField = ({
  field, value, handleChange, fullWidth, helperText
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    const { value: _val } = event.target;
    setSelected(event.target.value);
    handleChange(event);
  };

  const renderFunc = (val) => val;

  return (
    <SelectFormControl variant="outlined" size="small" error={helperText} ref={selectRef} fullWidth={fullWidth}>
      <Grid item container><Label id="select-outlined">{field.label}</Label></Grid>
      <CSelect
        labelId="select-outlined-label"
        id="select-outlined"
        value={value}
        renderValue={(val) => renderFunc(val)}
        onChange={handleInput}
        disableUnderline
        helperText={helperText}
        inputProps={{
          name: field.name,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              borderRadius: '15px'
            }
          }
        }}
      >
        {field.options.map(
          (option) => (
            <MenuItem key={option} value={option}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <SelectNameTypo>{option}</SelectNameTypo>
              </Grid>
            </MenuItem>
          )
        )}
      </CSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </SelectFormControl>
  );
};

ReturnSelectField.propTypes = {
  field: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string
};

ReturnSelectField.defaultProps = {
  field: {},
  value: '',
  fullWidth: false,
  helperText: ''
};

export default ReturnSelectField;
