import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import moment from 'moment';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Slide,
  Grid,
} from '@mui/material';
import { useMutation, useLazyQuery } from '@apollo/client';
import currencyFormatter from '../../utils/currencyFormatter';
import {
  DialogCancelButton, DialogOkButton, DialogActionButtonText, DialogTitleText,
  DialogTitleSubText, DialogTitleWrapper, CircularProgressLoader, CloseIcon,
  GridWrapper
} from '../suppliers/individual/individualSupplierDialog.styles';
import { useStateValue } from '../../providers/stateProvider';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import { CREATE_CLOSE_TILL } from '../../mutations/categories';
import { STORAGE_KEYS } from '../../utils/constants';
import { LOGOUT_MUTATION } from '../../mutations/auth';
import { EXCESSCHANGE } from '../../queries/sales';
import CustomInputBase from '../customComponents/customInputBase';
import { Notice } from '../../assets/svgs';
import ConfirmDialog from './confirmDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualCategoryDialog = ({
  dialogOpen, closeDialog, navigate
}) => {
  const initialState = {
    cashTotal: 0,
    cardTotal: 0,
    bankTotal: 0,
    saleAmount: 0,
    footfall: 0,
    note: '',
    StoreCreditIn: 0,
    storeCredit: 0,
    businessUserExcessChange: 0,
  };
  const [state, setState] = useState(initialState);
  const [openDialogue, setOpenDialogue] = useState(false);

  const [createCloseTill] = useMutation(CREATE_CLOSE_TILL);
  const [logoutUser] = useMutation(LOGOUT_MUTATION);
  const [, dispatch] = Object.values(useStateValue());

  const loading = false;
  const username = localStorage.getItem('oga_username');
  const dateFrom = moment().startOf('day');
  const dateTo = moment().endOf('day');
  const [getExcess, { loading: excessLoading, data }] = useLazyQuery(EXCESSCHANGE);

  useEffect(() => {
    if (data && data.businessUserExcessChange) {
      const { businessUserExcessChange = 0 } = data;
      setState({ ...state, businessUserExcessChange });
    }
  }, [data, excessLoading]);

  const variables = { search: username, dateFrom, dateTo };
  useEffect(() => {
    if (dialogOpen) {
      getExcess({ fetchPolicy: 'network-only', variables });
    }
  }, [dialogOpen]);

  const handleLogout = () => {
    const authToken = localStorage.getItem('oga_auth_token');
    const restToken = localStorage.getItem('oga_rest_token');
    logoutUser({
      variables: {
        restToken,
        authToken
      }
    }).then((resp) => {
      dispatch({ type: AffiliateActionTypes.UPDATE_AFFILIATE, payload: { isBusinessVirtualStore: false } });
      const { status } = resp.data.logoutUser;
      const storageKeys = [...STORAGE_KEYS];
      if (status) {
        storageKeys.forEach((storageKey) => {
          localStorage.removeItem(storageKey);
        });
        setTimeout(() => navigate('/'), 1500);
        setTimeout(() => { document.location = ''; }, 2000);
      }
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const closeAllDialogues = () => {
    setOpenDialogue();
    closeDialog();
    handleLogout();
  };

  const addCategory = () => {
    createCloseTill({
      variables: { ...state }
    })
      .then(() => {
        closeAllDialogues();
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const Fields = [
    {
      name: 'cashTotal',
      label: 'Cash Total',
      helperText: 'Cash Total required',
      type: 'number'
    },
    {
      name: 'saleAmount',
      label: 'Total Sale Amount',
      helperText: 'Total Sale Amount required',
      type: 'number'
    },
    {
      name: 'cardTotal',
      label: 'Card Total',
      helperText: 'Card Total required',
      type: 'number'
    },
    {
      name: 'bankTotal',
      label: 'Bank Transfer Total',
      helperText: 'Card Total required',
      type: 'number'
    },
    {
      name: 'footfall',
      label: 'Footfall',
      helperText: 'No. of footfall',
      type: 'number'
    },
    {
      name: 'storeCredit',
      label: 'Store Credit',
      helperText: 'Total Sale Amount required',
      type: 'number'
    },
  ];

  const closeConfirmation = () => {
    setOpenDialogue(!openDialogue);
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, type, helperText
    } = field;
    const value = state[fieldName];
    return (
      <CustomInputBase
        label={label}
        value={value}
        size="small"
        type={type}
        name={fieldName}
        onChange={handleChange}
        placeholder={label}
        cSize="lg"
      />
    );
  };
  return (
    <>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
      >
        <Grid container>
          <GridWrapper container item xs={12}>
            <DialogTitle id="alert-dialog-slide-title">
              <Grid container style={{ marginTop: '1rem' }}>
                <DialogTitleWrapper xs={7} container>
                  <DialogTitleText>
                    End Shift
                  </DialogTitleText>
                  <DialogTitleSubText>
                    Enter the sales amount for this shift
                  </DialogTitleSubText>
                </DialogTitleWrapper>
                <Grid item container xs={5} justifyContent="flex-end" style={{ marginTop: '1rem' }}>
                  <CloseIcon onClick={handleCloseDialog} />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item container xs={12} alignItems="center">
                  <Notice style={{ marginRight: '.5rem', width: '1.3rem' }} />
                  {`Cash Sales + (${currencyFormatter(state.businessUserExcessChange)}) Total excess change is Cash Total`}
                </Grid>
                {Fields.map((field) => (
                  <Grid item xs={6}>{returnTextField(field)}</Grid>
                ))}
                <Grid item xs={12}>
                  <CustomInputBase
                    multiline
                    minRows={2}
                    label="Add Note"
                    placeholder="Tell us what we should know"
                    name="note"
                    value={state.note}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <DialogCancelButton
                onClick={handleCloseDialog}
              >
                <DialogActionButtonText>
                  Cancel
                </DialogActionButtonText>
              </DialogCancelButton>

              <DialogOkButton
                onClick={() => setOpenDialogue(true)}
              >
                {loading ? (
                  <CircularProgressLoader
                    disableShrink
                    size={22}
                    thickness={5}
                  />
                ) : (
                  <DialogActionButtonText>
                    Submit
                  </DialogActionButtonText>
                )}
              </DialogOkButton>
            </DialogActions>
          </GridWrapper>
        </Grid>
      </Dialog>
      <ConfirmDialog
        openDialogue={openDialogue}
        handleClose={closeConfirmation}
        handleConfirmation={addCategory}
        loading={loading}
      />
    </>
  );
};

IndividualCategoryDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
};

export default IndividualCategoryDialog;
