import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Box, Grid,
  Typography, useMediaQuery
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import exclamation from '../../assets/images/exclamation.png';
import warning from '../../assets/images/Warning.gif';
import { CDialog, WarningImg } from './cart.styles';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ClearCartDialog = ({
  clearCartDialog,
  handleClose,
  handleDelete,
  loading,
}) => {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const returnText = () => {
    if (isSmall) {
      return (
        <Typography
          variant="subtitle1"
          style={{
            paddingTop: '1rem', fontSize: isSmall ? '1.8rem' : '.9rem'
          }}
        >
          Hi, are you sure you want to Clear
          <br />
          your cart?
        </Typography>
      );
    }
    return (
      <Typography
        variant="subtitle1"
        style={{
          paddingTop: '1rem', fontSize: isSmall ? '1.8rem' : '.9rem'
        }}
      >
        Hi, are you sure you want to Clear your cart?
      </Typography>
    );
  };

  return (
    <CDialog
      open={clearCartDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Close
          fontSize="large"
          onClick={handleClose}
          style={{
            position: 'absolute', right: '1.5rem', cursor: 'pointer',
            width: isSmall ? '40px' : '25px', height: isSmall && '40px',
            marginTop: isSmall && '5rem'
          }}
        />
        <DialogContentText style={{ marginTop: !isSmall && '1rem', marginBottom: '0' }}>
          <Box paddingY={5} sx={{ width: '100%', textAlign: 'center' }}>
            <Box marginBottom={4}>
              <WarningImg src={warning} alt="warning" width={120} />
            </Box>
            <Typography
              variant="h6"
              style={{
                fontWeight: 'bold', marginTop: isSmall && '1rem',
                fontSize: isSmall && '2rem'
              }}
            >
              Clear Cart?
            </Typography>
            {returnText()}
          </Box>
        </DialogContentText>
        <DialogActions style={{ marginBottom: '2rem' }}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item md={6} xs={12}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                style={{
                  padding: '1rem 2rem',
                  borderRadius: '10px',
                  height: isSmall && '6rem',
                  fontSize: isSmall && '1.7rem',
                  fontWeight: isSmall && '600'
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              {loading ? (
                <Button
                  disabled
                  variant="contained"
                  size="large"
                  style={{
                    background: '#303030',
                    color: '#FFF',
                    padding: '1rem 2rem',
                    borderRadius: '10px',
                    height: isSmall && '6rem',
                    fontSize: isSmall && '1.7rem',
                    fontWeight: isSmall && '600'
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <span>Loading...</span>
                  </Box>
                </Button>
              ) : (
                <Button
                  fullWidth
                  onClick={() => handleDelete()}
                  variant="contained"
                  style={{
                    color: '#FFF',
                    padding: '1rem',
                    borderRadius: '10px',
                    height: isSmall && '6rem',
                    fontSize: isSmall && '1.7rem',
                    fontWeight: isSmall && '600'
                  }}
                  size="large"
                >
                  Yes, Clear Cart
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </DialogContent>
    </CDialog>
  );
};

ClearCartDialog.propTypes = {
  clearCartDialog: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ClearCartDialog;
