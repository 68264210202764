import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import toast from 'react-hot-toast';
import currencyFormatter from '../../../shared/currencyFormatter';
import { ORDER_QUERY } from '../../../../queries/dispatch';
import { JSONParse } from '../../../../utils/json';
import {
  BodyCell, MainTableRow, ColorGrid, ActionButton, TextWrapper, NoteDialog, DialogContentStyled, NoteTitle, NoteContainer, NoteTextField, SaveNoteButton
} from './returnRow.styles';
import { APPROVEORDERTOXERO } from '../../../../mutations/orders';
import { useStateValue } from '../../../../providers/stateProvider';

const ReturnRow = ({
  row, index, refetch
}) => {
  const {
    id, businessName, datePlaced: date, orderTotal, metaStatus, status, parentOrderId, approved,
  } = row;
  const navigate = useNavigate();
  const datePlaced = moment(date).format('DD/MM/YY (h:mm a)');
  const amount = currencyFormatter(orderTotal);
  const deliveryDate = moment(date).add(3, 'days').format('DD/MM/YY');
  const [open, setOpen] = useState(false);
  const [noteText, setNoteText] = useState('');
  const [approveOrderToXero] = useMutation(APPROVEORDERTOXERO);
  const orderId = `#${id}`;

  const [{
    user: { allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container color={color} background={bkg} className={`mpAdmin-uat-ordersPage-${name}`}>
      {name}
    </ColorGrid>
  );

  const getStatus = () => {
    if (status === 'CLOSED') {
      switch (metaStatus) {
        case 'ORDER_CLOSED':
          return getColorGrid('#07DE10', '#DCFEDD;', 'CLOSED');
        case 'ORDER_CANCELED':
          return getColorGrid('#FA3636', '#FFDAD6', 'CANCELLED');
        default:
          return getColorGrid('#FA3636', '#FFDAD6', `${metaStatus}`);
      }
    } if (status === 'OPEN') {
      switch (metaStatus) {
        case 'ORDER_RECEIVED':
          return getColorGrid('#07DE10', '#DCFEDD', 'RECEIVED');
        case 'ORDER_DISPATCHED':
          return getColorGrid('#606060', '#FBF777', 'DISPATCHED');
        case 'ORDER_QUEUED_FOR_DISPATCH':
          return getColorGrid('#606060', '#FBF777', 'READY FOR DISP...');
        case 'ORDER_SORTED':
          return getColorGrid('#606060', '#FBF777', 'SORTED');
        case 'ORDER_SENT':
          return getColorGrid('#606060', '#FBF777', 'ORDER SENT');
        case 'ORDER_CLOSED':
        default:
          return getColorGrid('#606060', '#FBF777', `${metaStatus}`);
      }
    }
  };
  const erpCells = [
    datePlaced, orderId, amount, businessName, deliveryDate,
  ];

  const { data } = useQuery(ORDER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { orderId: id },
  });
  let returnData;
  if (metaStatus === 'ORDER_RETURNED') {
    returnData = data;
  }
  let products = [];

  const retrieveProducts = (orderSet) => {
    let cartProducts = [];
    orderSet.forEach((result) => {
      const filterOrder = result.orderproductSet.filter((orderProductObj) => orderProductObj);
      const orderproductSet = filterOrder?.map((orderproduct) => orderproduct);
      cartProducts = orderproductSet;
    });
    products = cartProducts;
  };
  const handleClick = (_id, orderStatus) => {
    if (orderStatus === 'ORDER_RETURNED') {
      const { businessOrder, orderDeliveryLocations } = returnData;
      const { supplierorderSet, dateCreated, business: { name, logo } } = businessOrder;
      const { delivery_location_id: deliveryLocationId } = JSONParse(returnData?.meta);
      const defaultDeliveryLocation = orderDeliveryLocations.find((bo) => String(bo.id) === String(deliveryLocationId));
      retrieveProducts(supplierorderSet);
      navigate({
        pathname: '/return-order',
        state: {
          id,
          name,
          logo,
          cart: products,
          businessDateCreated: moment(dateCreated).format('DD/MM/YYYY'),
          businessDateDelivered: moment(dateCreated).add(2, 'days').format('DD/MM/YYYY'),
          dateCreated,
          deliveryLocations: orderDeliveryLocations || [],
          selectedDeliveryLocation: defaultDeliveryLocation?.name,
          parentOrderId,
          orderIsReturned: true
        }
      });
      return;
    }
    if (orderStatus === 'ORDER_CANCELED' && !userPermissions?.includes('manage_orders_view_cancel_order')) {
      return toast.error('Not Authorized');
    }
    navigate(`/orders-admin/dispatch/${_id}`);
  };

  const returnCell = (val) => val || 'N/A';

  const renderBodyCells = () => erpCells.map((cell) => (
    <BodyCell onClick={() => handleClick(id, metaStatus)} style={{ cursor: 'pointer' }}>{returnCell(cell)}</BodyCell>
  ));

  const handleToggleNotes = () => {
    if (!userPermissions?.includes('manage_orders_approve_order')) return toast.error('Not Authorized');
    return setOpen(!open);
  };
  const handleApproveOrder = () => {
    approveOrderToXero({
      variables: {
        orderId: id,
        note: noteText,
      }
    }).then(({ data: approveData }) => {
      const { message } = approveData?.approveOrderXero ?? '';
      toast.success(message);
      setNoteText('');
      refetch();
    }).catch((err) => {
      toast.error(err?.message);
    });
    handleToggleNotes();
  };
  const handleChange = (event) => {
    const { value } = event.target;
    setNoteText(value);
  };

  return (
    <MainTableRow style={index % 2 ? { backgroundColor: '#F4F7FC' } : { backgroundColor: '#FFF' }}>
      <BodyCell />
      {renderBodyCells()}
      <BodyCell>{getStatus(metaStatus, status)}</BodyCell>
      {status === 'OPEN' ? (
        <BodyCell>
          <ActionButton
            data-testid="create-product"
            onClick={handleToggleNotes}
            disabled={metaStatus !== 'ORDER_SENT' || approved === 'True'}
          >
            <TextWrapper>
              {metaStatus !== 'ORDER_SENT' || approved === 'True' ? 'Approved' : 'Approve'}
            </TextWrapper>
          </ActionButton>
        </BodyCell>
      ) : ''}
      <BodyCell />

      <NoteDialog
        open={open}
        onClose={handleToggleNotes}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
      >
        <DialogContentStyled>
          <NoteTitle>Leave a Comment</NoteTitle>
          <Grid>
            <NoteContainer>
              <NoteTextField
                value={noteText}
                multiline
                minRows={5}
                placeholder="Write your comment here"
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ backgroundColor: '#f0f0f0', marginBottom: '2em' }}
              />
            </NoteContainer>
            <SaveNoteButton
              variant="contained"
              fullWidth
              onClick={handleApproveOrder}
            >
              Proceed
            </SaveNoteButton>
          </Grid>
        </DialogContentStyled>
      </NoteDialog>
    </MainTableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
