import { gql } from '@apollo/client';

export const GET_DASHBOARD_SUMMARY = gql`
  query dashboardSummary(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $period: String!
  ){
    dashboardSummary(
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      period: $period
    ){
      grossOrderVolume
      grossOrderValueChange
      grossOrderValue
      grossOrderVolumeChange
      noOfAffiliates
      noOfSuppliers
      noOfProducts
      weeklyOrders {
        week,
        orderVolume,
        gmv
      }
    }
  }
`;

export const AFFILIATE_QUERY = gql`
  query affiliates(
    $name: String
    $pageCount: Int
    $pageNumber: Int
    $status: String
    $phoneNumber: String
    $dateFrom: String
    $dateTo: String
    $email: String
  ) {
    allBusinesses(
      pageCount: $pageCount,
      pageNumber: $pageNumber,
      name: $name,
      status: $status,
      phoneNumber: $phoneNumber,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      email: $email
    ){
      id
      name
      contacts
      status
      dateCreated
      logo
      orderVolume
      grossOrderAmount
      lastLoggedIn
      lastOrderDate
      receivedOrderVolume
      receivedGrossOrderAmount
      cartTotal
      tax
      salesRepresentative
    }
    businessTotalNumber
  }
`;

export const QUERY_ORDERS_TO_DISPATCH = gql`
query{
  allOrdersToDispatch{
    id
    business{
      name
    }
    lastUpdated
    deliveryOutlet{
      contacts
      name
    }
    supplierorderSet{
      orderproductSet{
        quantityRecieved
        price
      }
    }
  }
}
`;

export const AFFILIATE_DETAIL_QUERY = gql`
  query business($id: Int) {
    business(
      id: $id
    ) {
      id
      name
      logo
      meta
      dateCreated
      grossOrderAmount
      orderVolume
      contacts
      profile
      tax
      status
      license {
        name
        url
        size
      }
      creditScore
      creditDays
      salesRepresentative
      orderSet {
        supplierorderSet {
          id
          dateCreated
          orderproductSet {
            unitCost
            quantityRecieved
            meta
          }
          order {
            id
          }
        }
      }
    }
  }
`;

export const AFFILIATE_PROFILE_QUERY = gql`
  query business($id: Int) {
    business(
      id: $id
    ) {
      id
      name
      logo
      meta
      dateCreated
      grossOrderAmount
      orderVolume
      contacts
      profile
      tax
      status
      license {
        name
        url
        size
      }
      landMark
      state
      creditScore
      creditDays
      salesRepresentative
    }
  }
`;

export const GET_BUSINESS = gql`
  query business($id: Int) {
    business(
      id: $id
    ){
      id,
    }
  }
`;

export const GET_MY_BUSINESS = gql`
  query business($id: Int){
    business(id:$id){
      id,
      name,
      contacts,
      logo,
      dateCreated,
      profile,
      settings,
      productSalesFormat {
        topMovingProductLimit
        slowMovingProductLimit

      },
      automaticOrderingFormat,
      loyaltyPointValue
      orderCostFormat,
      automaticSales
      zeroSalesFormat
      deliveryLocations{
        id
      }
    }
  }
`;

export const GET_SUB_BUSINESSES = gql`
  query affiliates {
    subBusinesses {
      id
      name
      businessuserSet{
        id
        role{
          id
          name
        }
        user{
          id
          username
          profile
        }
      }
      contacts
      logo
      meta
      orderVolume
      grossOrderAmount
      lastLoggedIn
      lastOrderDate
      receivedOrderVolume
      receivedGrossOrderAmount
      cartTotal
    }
    businessTotalNumber
  }
`;

export const GET_BUSINESS_SIBLINGS = gql`
  query businessSiblings {
    businessSiblings {
      id
      name
      businessuserSet{
        id
        role{
          id
          name
        }
        user{
          id
          username
          profile
        }
      }
      contacts
      logo
      meta
      orderVolume
      grossOrderAmount
      lastLoggedIn
      lastOrderDate
      receivedOrderVolume
      receivedGrossOrderAmount
      cartTotal
    }
  }
`;

export const GET_BUSINESS_AND_SIBLINGS = gql`
  query businessAndSiblings {
    businessAndSiblings {
      id
      name
      businessuserSet{
        id
        role{
          id
          name
        }
        user{
          id
          username
          profile
        }
      }
      contacts
      logo
      meta
      orderVolume
      grossOrderAmount
      lastLoggedIn
      lastOrderDate
      receivedOrderVolume
      receivedGrossOrderAmount
      cartTotal
    }
  }
`;

export const GET_PLAIN_SUB_BUSINESSES = gql`
query affiliates {
  subBusinesses {
      id,
      name,
      contacts,
      logo,
      dateCreated,
      profile,
      settings,
      meta
    }
    businessTotalNumber
  }
`;

export const AFFILIATE_ORDER_PAYMENT_QUERY = gql`
  query affiliatePayment($affiliateId: String!) {
    affiliatePayment(affiliateId: $affiliateId) {
      reference,
      amount,
      dateCreated,
      meta,
      orderProducts,
      supplierOrder {
        id
      }
    }
  }
`;

export const AFFILIATE_INVOICE_QUERY = gql`
  query affiliateInvoices($affiliateId: Int, $pageCount: Int, $pageNumber: Int, $status: String,
    $invoiceNo: Int, $orderNo: Int, $affiliateName: String) {
    affiliateInvoices(
      affiliateId: $affiliateId, pageCount: $pageCount, pageNumber: $pageNumber, status: $status,
      invoiceNo: $invoiceNo, orderNo: $orderNo, affiliateName: $affiliateName
      ) {
      id
      total
      tax
      status
      order {
        id
      }
      sale {
        id
        total
      }
      creditDays
      dateCreated
      business {
        name
        id
      }
      amountPaid
    }
    invoicesTotalNumber
  }
`;

export const AFFILIATES_COUNTS_QUERY = gql`
  query affiliateCounts {
    affiliateCounts {
      totalAffiliates
      totalActiveAffiliates
      totalInactiveAffiliates
    }
  }
`;
