import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { PRODUCT_DETAILS_QUERY_MP } from '../../../../queries/products';
import {
  ContentContainer, ProductTypo, ProductNameTypo, MenuButton, BackTypo
} from './detailsContainer.styles';
import MainContent from '../../../customComponents/mainContent';
import ProductDetail from './productDetail';
import ProductInfo from './productInfo';
import SimilarProducts from '../../../cart/similarProducts/similarProduct';
import NewOrdersLoader from '../../../customComponents/loaders/newOrdersLoader';
import withAuth from '../../../../utils/withAuth';

const DetailsContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { loading, data } = useQuery(PRODUCT_DETAILS_QUERY_MP, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id
    },
  });

  if (loading) return <NewOrdersLoader />;

  const { product = {} } = data || {};
  const { brandName } = product;

  return (
    <MainContent>
      <ContentContainer>
        <MenuButton
          variant="outlined"
          onClick={() => navigate('/new-order')}
          startIcon={<KeyboardArrowLeft fontSize="large" />}
        >
          <BackTypo>back</BackTypo>
        </MenuButton>
        <ProductTypo>Product Detail</ProductTypo>
        <ProductNameTypo>
          {brandName}
        </ProductNameTypo>
        <ProductDetail product={product} />
        <ProductInfo product={product} />
        <SimilarProducts similarProductsName={[`${brandName}`]} cart />
      </ContentContainer>
    </MainContent>
  );
};

export default DetailsContainer;
