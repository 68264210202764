export const extractPrices = (rawProduct, platform) => {
  if (platform === 'erp') {
    const {
      erpVersion: {
        priceInUse: priceInUseA
      }
    } = rawProduct;
    return {
      erpPriceInUse: priceInUseA.indexOf('fixed') > -1 ? 'Fixed' : 'Automatic',
    };
  }
  if (platform === 'marketplace') {
    const {
      marketplaceVersion: {
        priceInUse: priceInUseB
      }
    } = rawProduct;
    return {
      marketPlacePriceInUse: priceInUseB.indexOf('fixed') > -1 ? 'Fixed' : 'Automatic'
    };
  }
};
