import { gql } from '@apollo/client';

export const CREATE_STOCK_TRANSFER_MUTATION = gql`
  mutation createStockTransfer(
    $transferLocation: Int
    $transfers: [StockTransferCreateProductBatchType]!
  ) {
    createStockTransfer(
      transferLocation: $transferLocation
      transfers: $transfers
    ) {
      message
      transferLocation {
        id
        name
      }
      transfers {
        id
        receiptNumber
        dateCreated
        businessTo {
          id
          name
        }
        productBatches {
          productId
          brandName
          packSize
          batches {
            id
            transferQty
            batch {
              id
              name
              batchNo
            }
          }
        }
        stocktransferproductbatchSet {
          id
          product {
            id
            brandName
          }
          batch {
            id
            batchNo
          }
          transferQty
        }
        businessUser {
          id
          user {
            id
            username
            profile
          }
        }
      }
    }
  }
`;

export const STOCK_TRANSFER_STATUS_MUTATION = gql`
  mutation changeTransferStockStatus(
    $transferIds: [Int]!, $status: String!, $reason: String
  ) {
    changeStockTransferStatus(
      transferIds: $transferIds, status: $status, reason: $reason
    ){
      message
      noOfProductsCreated
    }
  }
`;

export const SEND_TRANSFER_RECEIPT_EMAIL_MUTATION = gql`
  mutation sendStockTransferReceiptViaEmail($id: Int!) {
    sendStockTransferReceiptViaEmail(id: $id){
      message
    }
  }
`;
