import { gql } from '@apollo/client';
import { PRODUCT_VERSIONS } from './products';

export const SEARCH_PRODUCTS_QUERY = gql`
  query products(
    $search: String!, $suppliers: [String]!, $promos: [String], $pageCount: Int, $pageNumber: Int, $onlyMarketProducts: Boolean
  ){
    products(
      search: $search
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      promos: $promos
      onlyMarketProducts: $onlyMarketProducts
    ){
      id
      batches{
        id
        meta
        quantityInStock
      }
      brandName
      genericName
      suppliers {
        id
        name
      }
      meta
    }
    productsTotalNumber
  }
`;
export const SEARCH_SALE_PRODUCTS_QUERY = gql`
  ${PRODUCT_VERSIONS}
  query orderDetailPage(
    $search: String!, $suppliers: [String]!, $pageCount: Int, $pageNumber: Int, $onlyMarketProducts: Boolean
  ){
    orderDetailPage(
      search: $search
      suppliers: $suppliers
      pageCount: $pageCount
      pageNumber: $pageNumber
      onlyMarketProducts: $onlyMarketProducts
    ){
      id
      brandName
      genericName
      ...productVersions
    }
    productsTotalNumber
  }
`;

export const SALE_QUERY = gql`
  query sales(
    $pageCount: Int, $pageNumber: Int, $search: String, $businesses: [String]
    $businessUserIds: [String], $filterOption: Boolean,
    $dateFrom: DateTime!, $dateTo: DateTime!
  ){
    sales(
      pageCount: $pageCount, pageNumber: $pageNumber, search: $search,
      businesses: $businesses, businessUserIds: $businessUserIds,
      filterOption: $filterOption, dateFrom: $dateFrom, dateTo: $dateTo
    ){
        id
        total
        discount
        totalToPay
        aggregateTotal
        flag
        lastUpdated
        transactionDate
        receiptNumber
        discount
        salesPayment {
          paymentMethod
        }
        business {
          id
          name
        }
        businessUser {
          user {
            username
            profile
          }
        }
        meta
        saleItems {
          name
          quantity
          meta
          total
        }
        customer {
          id
          name
        }
        saleReturns {
          name
          quantity
          meta
          total
        }
      }
      salesTotalNumber
    }
`;
export const GET_SALE_QUERY = gql`
  query sale($id: Int!) {
    sale(id: $id) {
      id
      total
      discount
      totalToPay
      aggregateTotal
      flag
      transactionDate
      previousSale
      nextSale
      dateCreated
      paymentMethod
      receiptNumber
      business {
        id
        name
      }
      salesPayment{
        id,
        paymentMethod,
        amount,
        rawAmount
      }
      customer{
        id
        name
        loyaltyPoint
        storeCredit
      }
      businessUser{
        business{
          contacts
          name
        }
        user {
          contacts
          profile
          username
        }
      }
      meta
      changeDue
      loyaltyAwarded
      saleItems {
        id
        name
        quantity
        total
        packSize
        productDiscount
        promoValue
        meta
        product{
          id
          meta
        }
        unitCost
        saleitembatchSet {
          id
          orderProducts
          quantity
        }
      }
      saleReturns {
        id
        name
        quantity
        total
        meta
        unitCost
        packSize
        sale {
          paymentMethod
          lastUpdated
          receiptNumber
          transactionDate
          customer {
            id
            name
            loyaltyPoint
          }
          businessUser {
            user {
              username
            }
          }
        }
      }
    }
  }
`;

export const GET_CUSTMER_SALE_QUERY = gql`
query customerSale($customerId: Int!, $pageCount: Int, $pageNumber: Int,) {
  customerSale(customerId: $customerId, pageCount: $pageCount
  pageNumber: $pageNumber) {
    dateCreated
    receiptNumber
    totalToPay
    business {
      id
      name
    }
    customer{
      id
      name
      loyaltyPoint
      storeCredit
    }
    businessUser{
      business{
        name
      }
        user {
        username

      }
    }
    changeDue
  loyaltyAwarded
}
}
`;

export const GET_SALES_DASHBOARD_SUMMARY = gql`
  query dashboardSummary(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $filterOption: Boolean
    $businesses: [String]
    $businessUserIds: [String]
  ){
    salesDashboardSummary(
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      filterOption: $filterOption,
      businesses: $businesses,
      businessUserIds: $businessUserIds
    ){
      totalSales
      salesInCash
      salesInCard
      salesInCredit
      salesInLoyalty
      salesInBankTransfer
      totalSaleAmount
      totalExcessChange
    }
  }
`;

export const GET_SALES_RECONCILIATION_SUMMARY = gql`
  query salesReconciliationSummary(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $period: String!
    $businesses: [String]
    $businessUserIds: [String]
  ){
    salesReconciliationSummary(
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      period: $period,
      businesses: $businesses,
      businessUserIds: $businessUserIds
    ){
      totalMissedSaleProduct
      totalQuantityMissed
      totalOutOfStockSales
      totalNoOfTransactions
      totalSaleAmount
      totalMissedSales
      totalOutOfStockTransactions
    }
  }
`;
export const GET_OUT_OF_STOCK_SALES = gql`
  query outOfStockSales($pageCount: Int, $pageNumber: Int, $businesses: [String]
    $businessUserIds: [String],  $search: String, $dateFrom: DateTime!
    $dateTo: DateTime!){
    outOfStockSales(pageCount: $pageCount
      pageNumber: $pageNumber,
      businesses: $businesses,
      businessUserIds: $businessUserIds,
      search: $search,
      dateFrom: $dateFrom
      dateTo: $dateTo
      ){
   id
   quantity
   total
   business {
       name
   }
   saleItem{
       product{
            id
          brandName
          meta
          batches{
            id
            batchNo
            expiryDate
            meta
            quantityInStock
          }
       }
       sale{
          dateCreated
          receiptNumber
          businessUser{
             user{
              username
            }
          }
        }
        name
        packSize
        total
      }
    }
    outOfStockSalesTotalNumber
  }
`;

export const GET_MISSED_SALES = gql`
  query missedSales($pageCount: Int, $pageNumber: Int, $businesses: [String]
    $businessUserIds: [String], $search: String, $dateFrom: DateTime!
    $dateTo: DateTime!){
    missedSales(pageCount: $pageCount
      pageNumber: $pageNumber, businesses: $businesses,
      businessUserIds: $businessUserIds,
      search: $search,
      dateFrom: $dateFrom
      dateTo: $dateTo
      ){
       id
       sale{
          total
          dateCreated
          receiptNumber
          businessUser{
             user{
              username
            }
          }
        }
        name
        packSize
        quantity
        total
      }
    missedSalesTotalNumber
  }
`;
export const GET_LATEST_SALE = gql`
  query latestSale {
    latestSale {
      id
      dateCreated
      version
    }
  }
`;

export const EOD_REPORT_QUERY = gql`
  query eodReport(
    $pageCount: Int, $pageNumber: Int, $search: String,
    $dateFrom: DateTime!, $dateTo: DateTime!, $businesses: [String]
    $businessUserIds: [String]
  ){
    eodReport(
      pageCount: $pageCount, pageNumber: $pageNumber, search: $search,
      dateFrom: $dateFrom, dateTo: $dateTo, businesses: $businesses,
      businessUserIds: $businessUserIds
    ){
        id
        dateCreated
        timeClosed
        cashTotal
        cardTotal
        bankTotal
        saleAmount
        expectedCashTotal
        expectedCardTotal
        expectedBankTotal
        expectedStoreCredit
        expectedStoreCreditIn
        expectedLoyaltyPoints
        footfall
        note
        businessUser {
          fullName
        }
        expectedSaleAmount
        storeCredit
      }
      eodReportTotalNumber
    }
`;

export const CURRENT_SALE_REVENUE_TARGET = gql`
  query currentSaleRevenueTarget {
    currentSaleRevenueTarget {
      weekly
      monthly
      daily
      business{
        id
      }
    }
  }
`;

export const SALE_REVENUE_SUMMARY = gql`
  query saleRevenueSummary(
    $dateFrom: DateTime, $dateTo: DateTime,$interval: String,
    $saleRevenueTargetId: Int
  ) {
    saleRevenueSummary(
      dateFrom: $dateFrom, dateTo: $dateTo, interval: $interval,
      saleRevenueTargetId: $saleRevenueTargetId
    ) {
      percentage
      realizedAmount
      expectedTarget
      interval
      grossProfit
      grossMargin
      salesTotalNumber
      salesTotalAmount
      grossMargin
      revenueTarget{
        monthly
        weekly
        daily
      }
    }
  }
`;

export const FOOT_FALL_SUMMARY = gql`
  query footfallSummary(
    $dateFrom: DateTime!, $dateTo: DateTime!
  ) {
    footfallSummary(
      dateFrom: $dateFrom, dateTo: $dateTo
    ) {
      percentage
      converted
      footfall
    }
  }
`;

export const AVERAGE_BASKET = gql`
  query averageBasket(
    $dateFrom: DateTime!, $dateTo: DateTime!
  ) {
    averageBasket(
      dateFrom: $dateFrom, dateTo: $dateTo
    ) {
      averageBasketSize
      averageBasketValue
    }
  }
`;

export const TOP_SELLING_PRODUCTS = gql`
  query topSellingProducts(
    $dateFrom: DateTime!, $dateTo: DateTime!
  ) {
    topSellingProducts(
      dateFrom: $dateFrom, dateTo: $dateTo,
    ) {
      brandName
      packSize
      frequency
      averageSellingPrice
    }
  }
`;

export const EXCESSCHANGE = gql`
  query businessUserExcessChange(
      $dateFrom: String, $dateTo: String
    ) {
      businessUserExcessChange (
        dateFrom: $dateFrom, dateTo: $dateTo
      )
    }
`;
