import { styled } from '@mui/material/styles';
import {
  Grid, Typography, Button, TableCell, TableContainer,
  Checkbox, Table, TableHead, Paper, Box, TableBody
} from '@mui/material';
import { Check, Close, Description } from '@mui/icons-material';

export const ReturnOrderButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 10px;
  border: 1.5px solid #424242;
  color: #CCCCCC;
  margin-left: auto;
  padding: 2px 15px;
  height: 70px;

  @media(min-width: 992px) {
    height: 3.5rem;
    padding: 0px 15px;
    border-radius: 10px;
    width: 11rem;
  }
}
`;

export const ReturnOrderButtonText = styled(Typography)`
  color: #424242;
  font-size: 27px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: .9rem;
    font-weight: 700;
    margin: auto;
  }
`;

export const CancelReturnButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 10px;
  border: 1.5px solid #424242;
  color: #CCCCCC;
  margin-left: auto;
  padding: 2px 15px;
  height: 70px;

  @media(min-width: 992px) {
    height: 3.5rem;
    padding: 0px 15px;
    border-radius: 0.313rem;
    width: 100%;
  }
}
`;

export const CancelReturnButtonText = styled(Typography)`
  color: #424242;
  font-size: 27px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: .9rem;
    font-weight: 700;
    margin: auto;
  }
`;

export const ButtonBox = styled(Box)`
  display: flex;
  gap: 8px;
  margin-left: auto;
`;

export const ReturnSelectedOrderButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 15px;
  background-color: #303030;
  color: #FFFFFF;
  border-radius: 0;
  border-radius: 15px;
  height: 70px;
  margin-left: auto;

  &:hover {
    background-color: #303030;
  }

  &:disabled {
    color: #FFFFFF;
    background-color: #303030;
  }

  @media(min-width: 992px) {
    height: 3.5rem;
    padding: 0px 15px;
    border-radius: 0.313rem;
    margin-top: 0px;
    width: 100%;
  }
`;

export const ReturnSelectedOrderButtonText = styled(Typography)`
  font-size: 27px;
  font-weight: 500;
  margin-left: 20px;

  @media(min-width: 992px) {
    font-size: .9rem;
    font-weight: 700;
    margin: auto;
  }
`;

export const ReturnProductContainer = styled('div')`
  grid-template-columns: 1fr 3fr;
  display: grid;
  align-items: flex-start;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 2px solid #ECECEC;

  @media (max-width: 992px) {
    display: block;
    font-size: 14px;
    align-items: center;
    padding: 35px 0;
    margin: 0 20px;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const ReturningProductColumnHeaders = styled('div')`
  grid-template-columns: 33% 16% 27% 16%;
  display: grid;
  padding: 28px 35px;
  margin-top: 30px;
`;

export const ReturnProductFooterGrid = styled(Grid)`
  justify-content: space-between;
  padding: 28px 35px;
`;

export const ReturnProductsCardFooterText = styled('p')`
  color: #424242;
  font-size: 30px;
  font-weight: 500;
  margin: 0;

  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const CustomCheckbox = styled(Checkbox)`
  color: #FCED50;
  fill: #FCED50;
`;

export const CustomTableCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorSecondary.Mui-checked{
    color: #F1EB42;
  }
  &.MuiIconButton-colorSecondary{
    color: #303030;
  }
  & .MuiSvgIcon-fontSizeSmall {
    font-size: 1.6rem;
  }
`;

// Icons
export const ReturnSelectedIcon = styled(Check)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const CancelReturnIcon = styled(Close)`
  color: #424242;
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const ArrangeReturnIcon = styled(Description)`
  width: 4rem;
  height: 4rem;

  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const AddDeliveryButton = styled(Button)`
  background-color: #303030;
  color: #FFFFFF;
  border-radius: 15px;
  margin-left: auto;

  &:hover {
    background-color: #303030;
  }

  &:disabled {
    color: #FFFFFF;
    background-color: #303030;
  }

  @media(min-width: 992px) {
    height: 2.5rem;
    border-radius: 5px;
    width: 10rem;
  }
`;

export const AddDeliveryButtonText = styled(Typography)`
  font-size: .7rem;
  font-weight: 600;
  margin: auto;
`;

// export const TableComponent = styled(Table)`
//   min-width: 650;
// `;

// export const MainTableHead = styled(TableHead)`
//   display: none;

//   @media(min-width: 992px) {
//     display: table-header-group;
//   }
// `;

// export const TableColumnHeaders = styled('div')`
//   display: flex;
// `;

// export const TableHeader = styled(TableCell)`
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 26.27px;
//   white-space: nowrap;
//   background: #F4F5F7;
//   padding: 16px 10px;
//   border: 0;
//   text-transform: capitalize;

//   @media(min-width: 992px) {
//     font-size: 16px;
//   }
// `;

export const TableGrid = styled(Grid)`
`;

export const PaperWrapper = styled(Paper)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.5rem;
`;

// export const TableContainerWrapper = styled(Paper)`
//   border-radius: 10px;
//   width: 100%;
//   padding: 30px;
// `;

// export const DivFullWidth = styled('div')`
//   width: 100%;
// `;

// export const HeaderGrid = styled(Grid)`
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 2rem;
// `;

// export const HeaderGridItem = styled(Grid)``;

// export const Header = styled(Typography)`
//   font-size: 2rem;
//   font-weight: 600;
//   text-transform: capitalize;

//   @media(min-width: 992px) {
//     font-size: 1.3rem;
//   }
// `;

export const HeaderCell = styled(TableCell)`
  background-color: #F4F5F7;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 2.5rem;
  padding: 10px;

  @media (min-width: 992px) {
    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 17%;
    }
    &:nth-child(4) {
      width: 17%;
    }
    &:nth-child(5) {
      width: 45%;
    }
  }

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }
`;

export const TableBox = styled(Box)`
  width: 100%;
  padding: 1.5rem;
`;

export const TableContent = styled(TableContainer)`
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 2rem;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const ButtonGrid = styled(Grid)`
  @media (min-width: 992px) {
    & .MuiGrid-item {
      padding: 5px;
    }
  }
`;

export const CartGridContainerHeader = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    margin-bottom: 2.5rem
  }
`;

export const CartGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 3rem;
  width: 100%;
  background-color: #FFFFFF;
  @media (min-width: 992px) {
    padding: 15px 3rem 15px 3rem;
  }
`;

export const DeliveryAddressCard = styled(Grid)`
  border-radius: 15px;
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 2px;
    margin-top: 2rem;
    border-radius: 8px;
  }
`;
