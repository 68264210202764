import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Hidden, Grid, Button } from '@mui/material';
import moment from 'moment';
import toast from 'react-hot-toast';
import currencyFormatter from '../../../shared/currencyFormatter';
import {
  CartGridContainer, ProductColumnHeaderTitle, BackArrowIconContainer, PageTitleText,
  PageTitleSubText, CartItemCountContainer, CartItemCountText, ProductColumnHeaders, GoBack,
  PageTitleContainer, CartGridContainerHeader, KeyboardArrowLeft
} from '../../../cart/cart.styles';
import CustomButton from '../../../customComponents/customButton';
import ReturnRow from './returnRow';
import {
  SupplierDetailCard, SupplierDetailCardWrapper, GridButton, MenuButton, BackText,
  PaperContainer, TableComponent, Header, MainTableHead, MainTableRow, SubtotalGrid, SubtotalText
} from './styles';

import { GET_SINGLE_ORDER } from '../../../../queries/orders';
import {
  JUST_IN_TIME_MUTATION, UPDATE_ORDER_STATUS, APPROVE_ORDER
} from '../../../../mutations/orders';
import Loader from '../../../shared/loader';
import MainContent from '../../../customComponents/mainContent';
import GoToTop from '../../../customComponents/scrollToTop';
// import ProductItem from './product-item';
import { JSONParse } from '../../../../utils/json';
import DeliveryInfoCard from '../../../shared/deliveryInfoCard/deliveryInfoCard';
import { OgaToken } from '../../../../graphql/token';
import OrderInfoCard from '../../../shared/orderInfoCard/orderInfoCard';
import { useStateValue } from '../../../../providers/stateProvider';

const headers = [
  'Product Items', 'Qty Ordered', 'Qty Sent', 'Unit Cost', 'Price',
];

const AffiliateFormDetail = () => {
  const navigate = useNavigate();
  const { id, param } = useParams();
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const handleDownload = () => {
    window.open(
      `${OgaToken.SERVER_LINK}download_order_invoice/${id}`,
      '_blank'
    );
  };

  const [justInTimeOrder] = useMutation(JUST_IN_TIME_MUTATION);
  const [updateOrderStatus] = useMutation(UPDATE_ORDER_STATUS);
  const [approveOrder] = useMutation(APPROVE_ORDER);
  const [active, setActive] = useState(false);
  const serverType = process.env.SERVER;

  const handleJITOrdering = () => {
    justInTimeOrder({
      variables: { orderId: id }
    })
      .then(({ data }) => {
        const { message } = data?.justInTimeOrder || {};
        toast.success(message);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleApproveOrder = () => {
    updateOrderStatus({
      variables: {
        orderId: id,
        status: 'ORDER_RECEIVED',
        changes: []
      }
    })
      .then(() => {
        toast.success('Order successfully approved');
        setActive(true);
      }).catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleOrderApproval = () => {
    approveOrder({
      variables: {
        orderId: id,
      }
    })
      .then(() => {
        toast.success('Order successfully approved');
      }).catch((err) => {
        toast.error(err?.message);
      });
  };

  const {
    loading, error, data
  } = useQuery(GET_SINGLE_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });
  if (loading) return <Loader />;
  if (error) return `Error! ${error.message}`;

  const {
    business, lastUpdated, supplierorderSet, meta, metaStatus
  } = data?.singleOrder ?? '';

  const businessContact = JSONParse(business.contacts.replace(/'/g, '"').replace('None', '"Lagos"'));
  // const prodsCount = supplierorderSet.reduce((sum, obj) => sum + obj?.productCount, 0);
  const prods = [];
  supplierorderSet.forEach((orderSet) => prods.push(...orderSet.orderproductSet));
  const { xero_status: xeroStatus } = JSONParse(meta);
  const { status } = JSONParse(meta);

  const returnHeaders = () => headers.map((header) => (
    <Header>{header}</Header>
  ));

  // const handleGeneratePickList = () => {
  //   window.open(`${OgaToken.SERVER_LINK}download_order_pick_list/${id}.pdf`, '_blank');
  // };

  const handleGeneratePickList = () => {
    window.open(`${OgaToken.SERVER_LINK}download_pick_list/${id}`, '_blank');
  };

  const subtotal = prods.map((item) => {
    const { meta: oMeta, price } = item;
    const { status: oStatus } = JSONParse(oMeta);
    if (oStatus === 'DISPATCHED') return price;
    return 0;
  }).reduce((sum, price) => sum + price, 0);

  return (
    <MainContent>
      <CartGridContainer>
        <GoBack container item alignItems="center">
          <MenuButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft style={{ fontSize: '1.8rem' }} />
          </MenuButton>
          <BackText variant="caption">back</BackText>
        </GoBack>
        <Grid container item>
          <CartGridContainerHeader item container xs={12} md={4}>
            <PageTitleContainer>
              <PageTitleText>Order Detail</PageTitleText>
              <Hidden lgDown>
                <PageTitleSubText>View details of an order</PageTitleSubText>
              </Hidden>
            </PageTitleContainer>
          </CartGridContainerHeader>
          <GridButton xs={12} md={8} gap={1}>
            {serverType !== 'production' && (
              <Button
                variant="outlined"
                onClick={handleOrderApproval}
                disabled={xeroStatus === 'submitted'}
              >
                Xero Approve
              </Button>
            )}
            {xeroStatus === 'submitted' && param !== 'own_orders' && param !== 'closed_orders'
              ? (
                <Button
                  variant="outlined"
                  disabled={status !== 'ORDER_SENT' || !userPermissions?.includes('inventory_approve_order')}
                  onClick={handleApproveOrder}
                >
                  Approve Order
                </Button>
              ) : ''}
            {
              (param === 'own_orders' || param === 'closed_orders') ? null : (
                <Button
                  variant="outlined"
                  onClick={handleJITOrdering}
                  disabled={loading}
                >
                  Add To Cart
                </Button>
              )
            }
            <Button
              variant="outlined"
              onClick={handleGeneratePickList}
              disabled={loading || data?.singleOrder?.status === 'CLOSED'}
            >
              Pick List
            </Button>
            <Button
              variant="contained"
              onClick={handleDownload}
              disabled={loading || data?.singleOrder?.status !== 'CLOSED'}
            >
              Download Invoice
            </Button>
          </GridButton>
        </Grid>
        <Grid container spacing={4}>
          <SupplierDetailCardWrapper item container lg={6}>
            <OrderInfoCard
              id={`ID${id}`}
              business={business}
              dateCreated={moment(lastUpdated).format('DD/MM/YYYY')}
            />
          </SupplierDetailCardWrapper>
          <SupplierDetailCardWrapper item container lg={6}>
            <SupplierDetailCard>
              <DeliveryInfoCard businessContact={businessContact} businessName={business?.name} />
            </SupplierDetailCard>
          </SupplierDetailCardWrapper>
        </Grid>
        <PaperContainer elevation={2}>
          <TableComponent>
            <MainTableHead>
              <MainTableRow>
                {returnHeaders()}
              </MainTableRow>
            </MainTableHead>
            {prods.map((prod, index) => <ReturnRow product={prod} index={index} orderStatus={metaStatus} />)}
          </TableComponent>
          <SubtotalGrid>
            <SubtotalText>
              <span style={{ marginRight: '1rem' }}>Subtotal: </span>
              {`₦${currencyFormatter(subtotal)}`}
            </SubtotalText>
          </SubtotalGrid>
        </PaperContainer>
      </CartGridContainer>
    </MainContent>
  );
};

export default AffiliateFormDetail;
