import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import { getUser } from '../../utils/utils';
import MainContent from '../customComponents/mainContent';
import { Wave } from '../../assets/svgs';
import ActivityThreeColCard from './components/activityThreeColCard';
import ActivityFourColCard from './components/activityFourColCard';
import ProductTable from './components/productTable';
import { ContainerBody } from './dashboardContainer.styles';
// import { dateFormat } from '../shared/methods';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import { useStateValue } from '../../providers/stateProvider';
import DashboardChart from './components/dashboardChart';

const AffiliateDashboard = () => {
  const [{ user: { session } }] = Object.values(useStateValue());

  useEffect(() => {
    window.scroll(1, 1);
  }, []);

  const loggedInUser = getUser(session);

  const startDate = moment().startOf('day');
  const endDate = moment().endOf('day');

  const [dateFilter, setDateFilter] = useState({
    dateFrom: startDate,
    dateTo: endDate
  });

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setDateFilter({
      dateFrom,
      dateTo
    });
  };

  return (
    <MainContent>
      <ContainerBody>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box display="flex" alignItems="center" gap={2}>
              <h1>{`Hi, ${loggedInUser} `}</h1>
              <Wave fontSize="large" />
            </Box>
            <Box sx={{ mb: 2 }}>Sale Activity Analytics</Box>
          </Grid>
          <Grid item>
            <CustomDateRanges handleSubmit={handleSubmit} xs={12} />
          </Grid>
        </Grid>

        <ActivityThreeColCard />

        <ActivityFourColCard />

        <DashboardChart />

        <ProductTable dateFilter={dateFilter} />
      </ContainerBody>
    </MainContent>
  );
};

export default AffiliateDashboard;
