import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {
  SelectNameTypo, FieldCheckbox, CheckboxImage
} from './returnSelectField.styles';
import CheckboxIcon from '../../../assets/images/supplier/Checkbox-selected.png';
import UnCheckboxIcon from '../../../assets/images/supplier/Checkbox-resting.png';

const icon = <CheckboxImage src={UnCheckboxIcon} alt="unchecked" />;
const checkedIcon = <CheckboxImage src={CheckboxIcon} alt="checked" />;

const ReturnSelectField = ({
  fields, value, handleChange
}) => {
  const [selected, setSelected] = useState([value]);
  const selectRef = useRef();

  const handleInput = (event) => {
    setSelected(event.target.value);
    handleChange(event);
  };

  return (
    <FormControl variant="filled" size="small" ref={selectRef}>
      <InputLabel id="select-label">{fields.label}</InputLabel>
      <Select
        variant="standard"
        labelId="select-filled-label"
        id="select-filled"
        value={value}
        renderValue={(val) => val}
        onChange={handleInput}
        size="small"
        inputProps={{
          name: fields.name,
        }}
        style={{ width: '15rem' }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}>
        {fields.options.map(
          (option) => (
            <MenuItem key={option} value={option}>
              <Grid item container justifyContent="space-between" alignItems="center">
                <SelectNameTypo>{option}</SelectNameTypo>
                <FieldCheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  checked={option === value}
                />
              </Grid>
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

ReturnSelectField.propTypes = {
  fields: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

ReturnSelectField.defaultProps = {
  fields: {},
  value: ''
};

export default ReturnSelectField;
