import {
  Grid, Paper, Typography, TableBody, TableCell, InputBase
} from '@mui/material';

import { styled } from '@mui/material/styles';

export const WrapperPaper = styled(Paper)`
  border-radius: 10px;
  box-shadow: 0 0 0 0;
  width: 100%;
  padding: 2rem 1rem;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`;

export const ItemsGrid = styled(Grid)`
  padding-top: 1rem;
`;

export const Header = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;

  @media(min-width: 992px) {
    font-size: 1.3rem;
  }
`;

export const OptionsWrapper = styled(Grid)`
  position: relative;
  text-align: end;
`;

export const TableComponent = styled(Grid)`
  width: 70rem;
`;

export const MainTableHead = styled(Grid)`
`;

export const ItemsBody = styled(TableBody)`
  min-height: 15rem;
  max-height: 40rem;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 1rem;
`;

export const TableHeader = styled(TableCell)`
  /**text-transform: capitalize;**/
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  color: #606060;
  padding: 10px;
  border-bottom: 0;
  background: #F3F9FF;

  @media(min-width: 992px) {
    &:nth-child(1) {
      width: 56%;
    }
    &:nth-child(2), &:nth-child(3) {
      width: 22%;
    }
  }
`;

export const TotalsGrid = styled(Grid)`
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 1.5rem 0;
`;

export const TextGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 600;
  color: #606060;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const CustomInputBase = styled(InputBase)`
  border: 0.5px solid #d8d8d8;
  width: 65%;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #606060;
  line-height: 3.2;

  & .MuiInputBase-input {
    padding: 12px;
    padding-right: 5px;
  }
`;

export const EmptyGrid = styled(Grid)`
  align-items: center;
  padding-top: 2rem;
  color: #606060;
`;

export const EmptyImg = styled('img')`
  width: 8rem;
`;
