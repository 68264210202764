import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  DialogActions, DialogContent, Slide, Grid
} from '@mui/material';
import { useMutation } from '@apollo/client';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';
import CustomInputBase from '../../customComponents/customInputBase';
import CustomButton from '../../customComponents/customButton';
import SavePopper from './savePopper';
import CreditDaysPopper from './creditDaysPopper';
import {
  DialogTitleText, DialogTitleSubText, CircularProgressLoader, GridWrapper,
  CDialog, CloseIcon
} from './individualSupplierDialog.styles';
import {
  CREATE_SUPPLIER_MUTATION, UPDATE_SUPPLIER_MUTATION
} from '../../../mutations/suppliers';
import { validateEmail } from '../../auth/utils';
import { parseSupplierFields } from '../../shared/utils';
import { blockInvalidChar } from '../../../utils/mobileCheck';
import { useStateValue } from '../../../providers/stateProvider';
import ModelActionTypes from '../../../providers/reducers/model/modelTypes';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const IndividualSupplierDialog = ({
  dialogOpen, closeDialog, fromProduct, refetchSuppliers
}) => {
  const initialState = {
    supplierName: '',
    term: 'Cash on delivery',
    mobile: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    country: 'Nigeria',
    supplierId: '',
    providerPromo: '',
    markup: '1'
  };

  const initialErrorState = {
    nameError: false,
    emailError: false,
    mobileError: false,
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [creditDays, setCreditDays] = useState(14);
  const [saveButtonEl, setSaveButtonEl] = useState(null);
  const [creditDaysEl, setCreditDaysEl] = useState(false);
  const [editing, setEditing] = useState(false);

  const [{
    supplier: { supplier, refetch, supplierDetailRefetch }
  }, dispatch] = Object.values(useStateValue());

  const { nameError, mobileError } = errorState;
  useEffect(() => {
    if (supplier) {
      const {
        supplierId, name: supplierName, address,
        terms: term, email, mobile, providerPromo, markup
      } = parseSupplierFields(supplier);
      const [
        addressLine1, addressLine2, city, country
      ] = address;
      setEditing(true);
      setState((_state) => ({
        ..._state, supplierId, supplierName, term,
        addressLine1, addressLine2, city, country,
        email, mobile, providerPromo, markup
      }));
    }
  }, [supplier]);

  const reFetchModel = () => {
    dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { customer: null, reFetch: true }
    });
    setTimeout(() => dispatch({
      type: ModelActionTypes.UPDATE_MODEL,
      payload: { reFetch: false }
    }), 0);
  };

  const handleCloseDialog = () => {
    if (editing) setEditing(false);
    setState(initialState);
    reFetchModel();
    closeDialog();
  };

  const handleCreditDaysOpen = (el) => {
    setCreditDaysEl(creditDaysEl ? null : el);
  };

  const handleCreditDays = (days) => {
    setCreditDays(days);
    handleCreditDaysOpen(null);
  };

  const [createSupplier] = useMutation(CREATE_SUPPLIER_MUTATION);
  const addSupplier = (addMore) => {
    const {
      supplierName, term, mobile, email, addressLine1,
      addressLine2, city, country, providerPromo,
      markup
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    createSupplier({
      variables: {
        supplierName,
        term: newTerm,
        mobile,
        email,
        addressLine1,
        addressLine2,
        city,
        country,
        logo: 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png',
        providerPromo,
        markup
      }
    })
      .then(({ data }) => {
        const { message } = data?.createSupplier || {};
        toast.success(message);
        if (!fromProduct) {
          reFetchModel();
        }
        if (!addMore) {
          handleCloseDialog();
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [updateSupplier] = useMutation(UPDATE_SUPPLIER_MUTATION);
  const editSupplier = () => {
    const {
      supplierId, supplierName, term, mobile, email,
      addressLine1, addressLine2, city, country,
      providerPromo, markup
    } = state;

    const newTerm = term === 'On-credit payment'
      ? `${creditDays} Credit days` : term;

    updateSupplier({
      variables: {
        supplierId,
        supplierName,
        term: newTerm,
        mobile,
        email,
        addressLine1,
        addressLine2,
        city,
        country,
        logo: 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png',
        providerPromo,
        markup
      }
    })
      .then(({ data }) => {
        const { message } = data?.updateSupplier || {};
        toast.success(message);
        supplierDetailRefetch();
        handleCloseDialog();
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSave = async (addMore = false) => {
    setSaveButtonEl(null);
    setLoading(true);
    if (editing) return editSupplier();
    return addSupplier(addMore);
  };

  const handleSaveContinue = async () => {
    const addMore = true;
    await handleSave(addMore);
    setState(initialState);
  };

  const handleSaveButtonClick = () => {
    const { supplierName, mobile } = state;
    if (supplierName.length < 3) {
      return setErrorState({
        ...errorState, nameError: true
      });
    }
    if (mobile?.length <= 10 || mobile?.length > 11) {
      return setErrorState({
        ...errorState, mobileError: true
      });
    }
    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'supplierName':
        return setErrorState({
          ...errorState,
          nameError: !(value.length > 3)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: (value.length <= 10)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    if (name === 'mobile' && value.length > 11) return;
    setState({ ...state, [name]: value });
  };

  const fields = [
    {
      name: 'supplierName', label: "Supplier's Name", error: nameError, helperText: 'Supplier name requires more than 3 letters', required: true,
      placeholder: "Enter Supplier's Name",
    },
    {
      name: 'term', label: 'Payment Term', options: ['Payment before delivery', 'Cash on delivery', 'On-credit payment'], required: true,
      placeholder: 'Select'
    },
    {
      name: 'mobile', label: 'Mobile', error: mobileError, helperText: 'Invalid phone number', required: true, placeholder: 'Enter Mobile Number'
    },
    {
      name: 'providerPromo', label: 'Provider Promo', options: ['GPS'], placeholder: 'Select'
    },
    { name: 'email', label: 'Email Address', placeholder: 'Enter Email Address' },
    // { name: 'markup', label: 'Markup' },
    { name: 'city', label: 'City', placeholder: 'Select' },
    {
      name: 'addressLine1', label: 'Address Line 1', required: true, placeholder: 'Enter Address Line 1'
    },
    {
      name: 'country', label: 'Country', options: ['Nigeria', 'Uganda', 'Kenya'], required: true, placeholder: 'Select'
    },
    { name: 'addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line 2' },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, error, required, placeholder
    } = field;
    const value = state[fieldName];
    const numberFields = ['mobile', 'markup'];
    if (['term', 'country', 'providerPromo'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value}
          placeholder={placeholder}
          handleChange={handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
        />
      );
    }
    return (
      <CustomInputBase
        variant="filled"
        label={label}
        value={value}
        size="small"
        type={numberFields.includes(fieldName) ? 'number' : 'text'}
        error={error}
        helperText={error && helperText}
        required={required}
        name={fieldName}
        placeholder={placeholder}
        onKeyDown={fieldName === 'mobile' && blockInvalidChar}
        onChange={handleChange}
        cSize="lg"
      />
    );
  };

  return (
    <>
      <CDialog
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        fileslimit={1}
        disableEnforceFocus
        data-testid="supplier-dialog"
      >
        <GridWrapper container item>
          <Grid container style={{ paddingTop: '1.5rem' }}>
            <Grid item container xs={6}>
              <DialogTitleText>
                {editing ? 'Edit Supplier' : 'Add Supplier'}
              </DialogTitleText>
              <DialogTitleSubText>
                {editing
                  ? 'Edit the form to update supplier information'
                  : 'Add supplier information to the fields in the form'}
              </DialogTitleSubText>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <CloseIcon onClick={handleCloseDialog} />
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={2} style={{ paddingTop: '8px' }}>
              {fields.map((field) => (
                <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: '2rem' }}>
            <CustomButton
              type="secondary"
              header
              style={{ width: '11rem', height: '2.7rem', marginRight: '1.2rem' }}
              onClick={handleCloseDialog}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="tertiary"
              header
              disabled={loading}
              onClick={handleSaveButtonClick}
              style={{ width: '11rem', height: '2.7rem' }}
            >
              {loading ? (
                <CircularProgressLoader
                  disableShrink
                  size={22}
                  thickness={5}
                />
              ) : editing ? 'Save Changes' : 'Save'}
            </CustomButton>
          </DialogActions>
        </GridWrapper>
      </CDialog>

      <SavePopper
        saveButtonEl={saveButtonEl}
        setSaveButtonEl={setSaveButtonEl}
        handleSave={handleSave}
        handleSaveContinue={handleSaveContinue}
      />

      <CreditDaysPopper
        creditDays={creditDays}
        handleCreditDays={handleCreditDays}
        creditDaysEl={creditDaysEl}
        handleCreditDaysOpen={handleCreditDaysOpen}
      />
    </>
  );
};

IndividualSupplierDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  refetchSuppliers: PropTypes.func,
  fromProduct: PropTypes.bool
};

IndividualSupplierDialog.defaultProps = {
  fromProduct: false,
  refetchSuppliers: () => {}
};

export default IndividualSupplierDialog;
