import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button
} from '@mui/material';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import SearchPopper from './searchPopper';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack
} from './purchaseOrder.styles';
import { GET_PO_ORDERS, SEARCH_PO_QUERY } from '../../../queries/products';
import ReturnRow from './returnRow';
import { BodyCell } from './returnRow.styles';
import TablePagination from '../../shared/tablePagination';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { PLACE_ORDER_PRODUCTS_MUTATION } from '../../../mutations/cart';
import { generateRandomNumber } from '../../../utils/funcs';
import SuccessDialog from '../../shared/successDialog';

const poHeaders = [
  'S/N',
  'Product ID',
  'Product Name',
  'Supplier',
  'Qty',
  'PO Unit Cost',
  'Price',
  'Action'
];

function UpdatePurchaseOrderContainer() {
  const returnHeaders = () => poHeaders.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const localBusinessId = localStorage.getItem('ogarx_business_id');

  const { id } = useParams();

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [reload, setReload] = useState(false);
  // trigger to disable or hide buttons when an update is made on so as to prevent loss of data
  const [trigger, setTrigger] = useState(false);
  const [products, setProducts] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [successState, setSuccessState] = useState({});
  const reloadHandler = () => setReload(!reload);

  const { data, loading: poloader, refetch } = useQuery(GET_PO_ORDERS, {
    variables: { orderId: id, pageCount, pageNumber },
    fetchPolicy: 'network-only'
  });

  const prod = data?.poOrderProducts || [];
  const count = data?.poOrderProductsCount;

  useEffect(() => {
    const result = prod.map((item, index) => {
      const {
        quantity, unitCost, product, supplierOrder
      } = item;
      return {
        sn: index,
        productId: product?.id,
        productName: product?.brandName,
        supplier: supplierOrder?.supplier?.name,
        quantity,
        newUnitCost: unitCost,
        price: quantity * unitCost,
        deleted: false,
      };
    });
    setProducts(result);
  }, [data]);

  const counterHandler = (type, row, val) => {
    setTrigger(true);
    const { sn, quantity, newUnitCost } = row;
    switch (type) {
      case 'increment': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) + 1;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;
        break;
      }
      case 'decrement': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) - 1;
        if (newQuantity < 0) return;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;
        break;
      }
      case 'change': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        if (val <= 0) return;
        newData.quantity = Number(val);
        newData.price = val * newUnitCost;
        break;
      }
      default:
        break;
    }
    reloadHandler();
  };

  const { data: businessData } = useQuery(GET_MY_BUSINESS, {
    variables: { id: localBusinessId }
  });
  const deliveryLocationId = businessData?.business?.deliveryLocations[0]?.id || 0;

  const [search, setSearch] = useState('');
  const [pId, setPId] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [currentRowSearch, setCurrentRowSearch] = useState(0);

  const [getSearchedProducts, { data: poData, loading }] = useLazyQuery(
    SEARCH_PO_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        productId: pId,
        status: 'enabled'
      }
    }
  );

  const createData = (product) => {
    const createProd = new Product(product, 'erp');

    return {
      productId: createProd?.id,
      brandName: createProd?.brandName,
      quantityInStock: createProd?.quantityInStock,
      supplier: createProd?.supplier,
      itemPrice: createProd?.orderCost
    };
  };

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  useEffect(() => {
    if (poData && poData.erpProducts) {
      const sResults = poData.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
  }, [poData]);

  const handleSearch = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set search field
    setSearch(value);
    setPId('');
    // Update the product name for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productName = value;
    setProducts([...products]);

    if (value && value.length > 2) {
      setSavedEl(event.target);
      getSearchedProducts();
    } else {
      handlePopperClose();
      setSavedEl(null);
    }
  };

  const handleSearchByPId = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set product id search field
    setSearch('');
    setPId(value);
    // Update the product id for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productId = value;
    setProducts([...products]);

    if (value) {
      setSavedEl(event.target);
      getSearchedProducts();
    } else {
      handlePopperClose();
      setSavedEl(null);
    }
  };

  const addMoreHandler = () => {
    const next = generateRandomNumber(10);
    const newData = {
      sn: next,
      productId: '',
      productName: '',
      supplier: '',
      quantity: 0,
      newUnitCost: 0,
      price: 0
    };
    setProducts([...products, newData]);
  };

  const [placePoOrder, { loading: poLoading }] = useMutation(
    PLACE_ORDER_PRODUCTS_MUTATION
  );

  const handleProductSelection = (row) => {
    const {
      productId, brandName, supplier, itemPrice
    } = row;

    const exists = products.find(
      ({ productName, deleted }) => productName === brandName && !deleted
    );
    if (exists) return toast.error('Product already exists');

    const newData = products.find(({ sn }) => sn === currentRowSearch);
    newData.productId = productId;
    newData.productName = brandName;
    newData.supplier = supplier.name;
    newData.quantity = 1;
    newData.newUnitCost = itemPrice;
    newData.price = itemPrice;
    newData.deleted = false;
    setProducts([...products]);
    setTrigger(true);
    handlePopperClose();
  };

  useEffect(() => {
    setProducts([...products]);
  }, [reload]);

  const updateCostPriceHandler = (val, row) => {
    const { sn, quantity } = row;
    const newData = products.find(({ sn: sid }) => sn === sid);
    newData.newUnitCost = val;
    newData.price = quantity * val;

    setProducts([...products]);
    setTrigger(true);
    reloadHandler();
  };

  // ================= Save Draft Handler ===================
  const saveDraftHandler = async () => {
    const filterItems = products.filter((item) => item.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((item) => {
      const { productId, quantity, newUnitCost } = item;
      return {
        productId,
        quantity,
        newUnitCost
      };
    });

    if (!cart.length) return toast.error('Purchase order is empty');
    const request = {
      orderId: id,
      cart,
      isManualOrder: false,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: true,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: []
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'PO Saved!', desc: 'Your PO was successfully Saved!' });
        refetch();
        setTrigger(false);
        setTimeout(() => {
          setSuccessDialog(false);
        }, 3000);
      })
      .catch(() => {});
  };

  const removeRowHandler = (sn) => {
    const newData = products.find(({ sn: sid }) => sn === sid);
    newData.quantity = 0;
    newData.newUnitCost = 0;
    newData.deleted = true;
    setProducts([...products]);
    setTrigger(true);
  };

  const createPOHandler = () => {
    // Remove row that does not have product id
    const filterItems = products.filter((item) => item.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((item) => {
      const { productId, quantity, newUnitCost } = item;
      return {
        productId: Number(productId),
        quantity,
        newUnitCost
      };
    });

    if (cart.length < 1) return toast.error('Purchase order is empty');

    const request = {
      orderId: id,
      cart,
      isManualOrder: false,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: false,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: []
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'PO Created!', desc: 'Your PO was successfully Created!' });
        setTimeout(() => {
          setSuccessDialog(false);
          navigate('/manage-purchase-order');
        }, 3000);
      })
      .catch(() => {});
  };

  let rowCounter = 0;
  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={() => navigate('/manage-purchase-order')}>
          <KeyboardArrowLeft
            style={{ fontSize: '1.8rem', cursor: 'pointer' }}
          />
          back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5">Create Purchase Order</PrimaryTitle>
            <ButtonContainer>
              <Button
                variant="outlined"
                disabled={poLoading}
                style={{ width: '8rem', height: '3rem', border: '2px solid' }}
                onClick={saveDraftHandler}
              >
                Save Draft
              </Button>
              <Button
                variant="contained"
                disabled={poLoading}
                style={{ width: '8rem', height: '3rem' }}
                onClick={createPOHandler}
              >
                Create PO
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <TableBody>
                  {poloader ? (
                    <ProductsTableLoader />
                  ) : !products?.length ? (
                    <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                      Purchase order is empty
                    </BodyCell>
                  ) : (
                    products?.map((item) => {
                      const { sn, deleted } = item;
                      if (!deleted) {
                        rowCounter += 1;
                        return (
                          <ReturnRow
                            key={sn}
                            row={item}
                            index={rowCounter}
                            loading={loading}
                            counterHandler={counterHandler}
                            handleSearch={handleSearch}
                            removeRowHandler={removeRowHandler}
                            updateCostPriceHandler={updateCostPriceHandler}
                            handleSearchByPId={handleSearchByPId}
                            poLoading={poLoading}
                            currentRowSearch={currentRowSearch}
                          />
                        );
                      }
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>

          <Button
            variant="outlined"
            onClick={addMoreHandler}
            style={{
              width: '11rem',
              height: '3rem',
              border: '2px solid',
              marginTop: '1rem',
              marginBottom: '1.5rem'
            }}
          >
            Add New Product
          </Button>
          {trigger ? null : (
            <TablePagination
              total={count}
              pageCount={pageCount}
              setPageCount={setPageCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </PaperWrapper>
        <SearchPopper
          searchFieldEl={searchFieldEl}
          handleClose={handlePopperClose}
          searchedProducts={searchedProducts}
          handleProductSelection={handleProductSelection}
        />
        <SuccessDialog
          openDialog={successDialog}
          setOpenDialog={setSuccessDialog}
          title={successState.title}
          desc={successState.desc}
          option="Ok"
        />
      </GridContainer>
    </MainContent>
  );
}

export default UpdatePurchaseOrderContainer;
