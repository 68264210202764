function defineItem(item) {
  const {
    productId,
    brandName,
    itemPrice,
    packSize,
    quantity,
    itemBatches
  } = item;
  const batches = itemBatches.map(
    ({ id: saleItemBatchId, orderProducts, quantity: itemBatchQuantity }) => {
      const orderProductId = Object.keys(JSON.parse(orderProducts.replace(/'/g, '"')))[0];
      return { saleItemBatchId, orderProductId, itemBatchQuantity };
    }
  );
  return {
    productId,
    quantity,
    itemPrice,
    resellable: 'true',
    brandName,
    packSize,
    itemBatches: batches,
    reason: 'Select'
  };
}
function calculateTotal(returnCartItems) {
  let total = 0;
  returnCartItems.forEach((_item) => {
    total -= _item.itemPrice * _item.quantity;
  });
  return total;
}
export const toggleCartItem = (returns, action) => {
  const { returnCartItems } = returns;
  const { payload: item } = action;
  if (returnCartItems.get(item.productId)) {
    returnCartItems.delete(item.productId);
  } else {
    returnCartItems.set(item.productId, defineItem(item));
  }
  return ({ returnCartItems, total: calculateTotal(returnCartItems) });
};
export const toggleSelectAll = (returns, action) => {
  const { selectAll, allSoldItems } = returns;
  const toBeReturned = new Map();
  let total = 0;
  if (selectAll) {
    return ({ returnCartItems: toBeReturned, selectAll: false, total });
  }
  allSoldItems.forEach((item) => {
    toBeReturned.set(item.productId, defineItem(item));
  });
  total = calculateTotal(toBeReturned);
  return ({ returnCartItems: toBeReturned, selectAll: true, total });
};
export const changeQty = (returns, { payload }) => {
  const { returnItem: { productId }, quantity } = payload;
  const { allSoldItems, returnCartItems } = returns;
  const item = returnCartItems.get(productId);
  const originalItemBought = allSoldItems.get(productId);
  if (quantity === 0) {
    returnCartItems.delete(productId);
  } else if (quantity <= originalItemBought.quantity) {
    item.quantity = quantity;
    returnCartItems.set(productId, item);
  }
  return ({ returnCartItems, total: calculateTotal(returnCartItems) });
};
export const saveSoldItems = (returns, { payload: { cart, saleId } }) => {
  const soldItems = new Map();
  cart.forEach((item) => {
    soldItems.set(item.productId, item);
  });
  return ({
    allSoldItems: soldItems, returnCartItems: new Map(), selectAll: false, returnSaleId: saleId
  });
};
export const toggleResellable = (returns, { payload }) => {
  const { returnItem: { productId } } = payload;
  const { returnCartItems } = returns;
  const item = returnCartItems.get(productId);
  item.resellable = item.resellable === 'true' ? 'false' : 'true';
  // item.resellable = !item.resellable;
  returnCartItems.set(productId, item);
  return ({ returnCartItems });
};
export const changeReason = (returns, { payload }) => {
  const { returnItem: { productId }, reason } = payload;
  const { returnCartItems } = returns;
  const item = returnCartItems.get(productId);
  item.reason = reason;
  returnCartItems.set(productId, item);
  return ({ returnCartItems });
};
