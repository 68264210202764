import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContentText,
  Slide,
  Grid,
  Checkbox,
  TableContainer,
  Box,
  Button
} from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import {
  CardTitle,
  DialogContentStyled,
  DefaultTable,
  DefaultTrHead,
  DefaultTd,
  DefaultTr,
  DefaultTdBody,
  FooterButtonStyled,
  CardHeader,
  NotFound,
  CartDesc
} from '../return-popper.style';
import BatchDialog from './batchDialog';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function ReturnDialog({
  row,
  refetch,
  cart,
  subTotal,
  openDialog,
  openReturnDialogHandler
}) {
  const [prodId, setProdId] = useState([]);
  const [batchDialog, setBatchDialog] = useState(false);
  const [cartBatch, setCartBatch] = useState(null);
  const [calDiscount, setProductIds] = useState(null);

  const checkHandler = (event) => {
    if (event.target.checked) {
      setProdId((prevState) => [...prevState, event.target.value]);
    } else {
      setProdId((prevState) => prevState.filter((pid) => pid !== event.target.value));
    }
  };

  const openBatchDialogHandler = () => {
    setBatchDialog(!batchDialog);
  };

  const getCartBatch = [];
  const getProductDiscount = [];
  const continueHandler = () => {
    prodId.map((pid) => cart?.filter((item) => {
      const {
        quantity, itemPrice, productDiscount, total
      } = item;
      // -- Begin extract values to calculate discount for each batch
      const evalDiscount = productDiscount / ((itemPrice * quantity) / 100);
      const discountObj = {
        productId: item?.productId,
        productDiscount: evalDiscount,
        amountEach: total
      };
      getProductDiscount.push(discountObj);
      // -- End extract values to calculate discount for each batch
      if (item?.productId === pid) {
          // eslint-disable-next-line no-unused-expressions
          item?.itemBatches.map((batch) => {
            batch.newQuantity = 0;
          });
          getCartBatch.push(item);
      }
    }));
    setCartBatch(getCartBatch);
    setProductIds(getProductDiscount);
    openReturnDialogHandler();
    setBatchDialog(true);
  };

  let returnable = 0;

  return (
    <>
      <BatchDialog
        row={row}
        calDiscount={calDiscount}
        refetch={refetch}
        subTotal={subTotal}
        batchDialog={batchDialog}
        openBatchDialogHandler={openBatchDialogHandler}
        cartBatch={cartBatch}
        setCartBatch={setCartBatch}
      />
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={openReturnDialogHandler}
      >
        <DialogContentStyled>
          <DialogContentText>
            <Grid style={{ marginTop: '1rem' }}>
              <Box display="flex" justifyContent="space-between">
                <CardHeader>
                  <CardTitle>Product in sale</CardTitle>
                  <CartDesc>Select Item(s) to return</CartDesc>
                </CardHeader>
                <Close
                  fontSize="small"
                  onClick={openReturnDialogHandler}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Grid>
            <TableContainer>
              <DefaultTable>
                <DefaultTrHead>
                  <DefaultTd>
                    <Checkbox style={{ padding: '0px', margin: '0px' }} />
                  </DefaultTd>
                  <DefaultTd>Product Name</DefaultTd>
                  <DefaultTd>Pack Size</DefaultTd>
                </DefaultTrHead>
                {cart?.length
                  && cart?.map((item) => {
                    const {
                      productId, brandName, packSize, quantity
                    } = item;
                    // if (quantity < 1) {
                    //   return null;
                    // }
                    if (quantity > 0) {
                      returnable += 1;
                      return (
                        <DefaultTr>
                          <DefaultTdBody>
                            <Checkbox
                            // disabled={quantity < 1}
                              checked={prodId.includes(productId)}
                              onChange={checkHandler}
                              name={productId}
                              value={productId}
                              style={{ padding: '0px', margin: '0px' }}
                            />
                          </DefaultTdBody>
                          <DefaultTdBody>{brandName}</DefaultTdBody>
                          <DefaultTdBody>{packSize}</DefaultTdBody>
                        </DefaultTr>
                      );
                    }
                  })}
              </DefaultTable>
            </TableContainer>
            {returnable < 1 && (<NotFound>No item to return</NotFound>)}
          </DialogContentText>
          {cart?.length > 0 && (
            <FooterButtonStyled>
              <Button variant="outlined" onClick={openReturnDialogHandler}>
                Cancel
              </Button>
              {prodId.length > 0 && (
                <Button variant="contained" onClick={continueHandler}>
                  Continue
                </Button>
              )}
            </FooterButtonStyled>
          )}
        </DialogContentStyled>
      </Dialog>
    </>
  );
}

ReturnDialog.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired,
  subTotal: PropTypes.number.isRequired,
  cart: PropTypes.instanceOf(Object).isRequired,
  openDialog: PropTypes.isRequired,
  openReturnDialogHandler: PropTypes.func.isRequired
};
