import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Grid, InputAdornment
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import {
  OrderGridContainer, StatisticsGridContainer, OrderText, SearchContainer, SearchTextField, SearchButton,
  StatisticsGrid, OrderItemsGrid, FooterGrid, OrderCountText, MainContainer, FilterContainer, SearchImage,
  HeaderGrid, HeaderTypo, FilterButton, BodyGrid, CTextField, MainTableHead, HeaderCell, NoDataTypo, SearchBox,
  FilterDropButton, FilterDropGrid, FilterImg, GridWrapper, GridContainer, ItemWrapper, ItemTypo, ItemsBox, ScrollBox,
  PaginationBox
} from './order.styles';
import FilterPopper from './filters/filterPopper';
import StatisticCard from '../shared/statisticCard';
import TitleGrid from '../shared/titleGrid';
import MainContent from '../../customComponents/mainContent';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import currencyFormatter from '../../../utils/currencyFormatter';
import { getPagination } from '../../../utils/pagination';
import SearchIcon from '../../../assets/images/Search Icon.png';
import TotalOrderIcon from '../../../assets/images/marketOrder/totalOrder.png';
import ActiveIcon from '../../../assets/images/marketOrder/activeOrder.png';
import GrossSavingsIcon from '../../../assets/images/marketOrder/savingsValue.png';
import GrossOrderIcon from '../../../assets/images/marketOrder/grossOrder.png';
import FilterIcon from '../../../assets/images/marketOrder/filter.png';
import { useStateValue } from '../../../providers/stateProvider';
import ReturnRow from './returnRow';
import ReturnSelectField from './returnSelectField';
import TablePagination from '../../shared/tablePagination';

const Order = ({
  affiliateOrders,
  handleChangeRowsPerPage,
  handleChangePage,
  currentPageCount,
  page,
  totalCount,
  state,
  loading,
  handleFilterChange,
  isFiltering,
  handleClearFilters,
  handleSearch,
  pageNumber,
  pageCount,
  setPageCount,
  setPageNumber
}) => {
  const totalsContainer = useRef(null);
  const [status, setStatus] = useState('');
  const [filter, setFilter] = useState('');
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [, dispatch] = Object.values(useStateValue());

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, []);

  const totalOrderAmount = affiliateOrders.reduce((previous, current) => +previous + +current.total, 0);
  const totalSavingsAmount = affiliateOrders.reduce((previous, current) => +previous + +current.savings || 0, 0);

  const activeOrders = affiliateOrders.filter((order) => order.status === 'ORDER_IN_CART');

  const paginateInfo = getPagination(page, currentPageCount, totalCount);

  const STATISTICS = [
    {
      id: 1, icon: TotalOrderIcon, title: 'Total Orders', value: affiliateOrders.length
    },
    {
      id: 2, icon: ActiveIcon, title: 'Active Orders', value: activeOrders.length
    },
    {
      id: 3, icon: GrossOrderIcon, title: 'Gross Order Value', value: currencyFormatter(totalOrderAmount)
    },
    {
      id: 4, icon: GrossSavingsIcon, title: 'Gross Savings Value', value: currencyFormatter(totalSavingsAmount)
    },
  ];

  const isLarge = useMediaQuery('(min-width:1200px)');

  const role = localStorage.getItem('oga_user_role');
  if (role === 'oga-pharmacy-admin') return <Navigate to="/orders-admin" />;

  const spacing = () => (isLarge ? 8 : 6);

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleStatus = (event, column) => {
    setStatus(column);
    handleFilterButtonClick(event);
    switch (column) {
      case 'Open Orders':
        return setFilter('ORDER_SENT');
      case 'Confirmed Orders':
        return setFilter('ORDER_RECEIVED');
      case 'Dispatched Orders':
        return setFilter('ORDER_DISPATCHED');
      case 'Closed Orders':
        return setFilter('ORDER_CLOSED');
      default:
        break;
    }
    return null;
  };
  const showSupplierOrders = () => {
    if (status !== '') {
      return affiliateOrders.filter((o) => o.orderStatus === `${filter}`);
    }
    return affiliateOrders;
  };

  const headers = [
    'Date Placed', 'Order No', 'Order Status', 'Amount', 'Savings',
  ];

  const FilterFields = [
    { name: 'orderNo', label: 'Order No' },
    { name: 'dateFrom', label: 'Date From' },
    { name: 'dateTo', label: 'Date To' },
    { name: 'orderTotalFrom', label: 'Order Total From' },
    { name: 'orderTotalTo', label: 'Order Total To' },
    { name: 'productName', label: 'Product' },
    { name: 'orderStatus', label: 'Order Status', options: ['OPEN', 'DISPATCHED', 'RECEIVED', 'CLOSED'] }
  ];
  const returnType = (name) => {
    switch (name) {
      case 'dateFrom':
      case 'dateTo':
        return 'date';
      case 'orderTotalFrom':
      case 'orderTotalTo':
        return 'number';
      default:
        return 'text';
    }
  };
  const returnTextField = (field) => {
    const {
      name: fieldName, label
    } = field;
    const _value = state[fieldName];

    return (
      (fieldName === 'orderStatus'
        ? (
          <ReturnSelectField
            name={fieldName}
            label="Order Status"
            field={field}
            renderValue={_value}
            value={_value}
            disabled={false}
            width="100%"
            handleChange={handleFilterChange}
            fullWidth
          />
        )
        : (
          <CTextField
            variant="filled"
            label={label}
            value={_value}
            size={isLarge ? 'small' : 'medium'}
            type={returnType(fieldName)}
            name={fieldName}
            onChange={handleFilterChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        )
      )
    );
  };

  const renderSearchField = () => (
    <SearchContainer item xs={9} lg={11}>
      <SearchTextField
        variant="outlined"
        label=""
        // defaultValue={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search Order"
        fullWidth
        className="mpFe-uat-orders-search-input"
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
    style={{ cursor: 'pointer' }}
  >
    <SearchButton
      aria-label="Search Order"
      edge="start"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchIcon}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const renderFilter = () => (
    <FilterDropGrid item xs={2} lg={1}>
      <FilterDropButton
        variant="outlined"
        onClick={handleFilterButtonClick}
        className="mpFe-uat-orders-create-filter-button"
      >
        <FilterImg src={FilterIcon} alt="filter" />
        <Hidden lgDown>Filter</Hidden>
      </FilterDropButton>
    </FilterDropGrid>
  );

  return (
    <MainContent>
      <MainContainer item container xs>
        <OrderGridContainer container>
          <TitleGrid />

          <StatisticsGridContainer ref={totalsContainer} container spacing={spacing()}>
            {STATISTICS.map((statistic) => (
              <StatisticsGrid key={statistic.id} item xs={12} md={3}>
                <StatisticCard
                  statistic={statistic}
                />
              </StatisticsGrid>
            ))}
          </StatisticsGridContainer>

          <OrderText>All Orders</OrderText>
          <Hidden lgDown>
            <SearchBox container>
              {renderSearchField()}
              {renderFilter()}
            </SearchBox>
          </Hidden>

          <ItemsBox>
            <Hidden mdUp>
              <SearchBox container>
                {renderSearchField()}
                {renderFilter()}
              </SearchBox>
            </Hidden>

            {loading ? <Grid container><ProductsTableLoader /></Grid>
              : (showSupplierOrders()?.map((orderItem, index) => (
                <ReturnRow
                  key={orderItem?.id}
                  orderItem={orderItem}
                  index={index}
                />
              )))}
            <FooterGrid>
              <TablePagination
                total={totalCount}
                pageCount={pageCount}
                setPageCount={setPageCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                hideRowsPerPage
                displayShowResult
              />
            </FooterGrid>
          </ItemsBox>

        </OrderGridContainer>
      </MainContainer>
      <FilterPopper
        filterButtonEl={filterButtonEl}
        status={status}
        handleStatus={handleStatus}
        handleFilterButtonClick={handleFilterButtonClick}
      />
    </MainContent>
  );
};

Order.propTypes = {
  affiliateOrders: PropTypes.instanceOf(Array).isRequired,
  state: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  currentPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  isFiltering: PropTypes.func.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired
};

Order.defaultProps = {
  state: {},
  loading: false,
};

export default Order;
