import { styled } from '@mui/material/styles';
import {
  DialogTitle, DialogContentText, Dialog
} from '@mui/material';

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    max-height: 100%;
    border-radius: 12px;
  }
`;

export const ContainerHead = styled(DialogTitle)`
margin:20px 20px 0px 20px;
padding:0;
   h1{
    font-size:30px;
    font-family:Montserrat;
    font-weight:bold;
    color:#424242;
    text-align: left;
   };
`;
export const Content = styled(DialogContentText)`
margin:10px;
h2{
    color: #A3A3A3;
    opacity: 1;
    font-family:Montserrat;
    font-weight:bold;
}
 p{
     font-family: Montserrat;
     font-size:14px;
     color: #424242;
     opacity:80%;
     padding:0;
 }
        ul { counter-reset: item }
        li{ display: block }
        li:before { content: counters(item, ".") " "; counter-increment: item }
`;
