import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableContainer, Button
} from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import { KeyboardArrowLeft } from '@mui/icons-material';
import MainContent from '../../customComponents/mainContent';
import {
  TableHeader,
  GridContainer,
  PaperWrapper,
  TableGrid,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  MainTableHead,
  ButtonContainer,
  GoBack
} from './purchaseOrder.styles';
import ReturnRow from './returnRow';
import { BodyCell } from './returnRow.styles';
import { SEARCH_PO_QUERY } from '../../../queries/products';
import SearchPopper from './searchPopper';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { PLACE_ORDER_PRODUCTS_MUTATION } from '../../../mutations/cart';
import { GET_MY_BUSINESS } from '../../../queries/affiliates';
import { generateRandomNumber } from '../../../utils/funcs';
import { ORDER_QUERY } from '../../../queries/cart';
import { useStateValue } from '../../../providers/stateProvider';
import { CircularProgressLoader } from '../../customComponents/customInputBase.styles';
import SuccessDialog from '../../shared/successDialog';

const poHeaders = [
  'S/N',
  'Product ID',
  'Product Name',
  'Supplier',
  'Qty',
  'PO Unit Cost',
  'Price',
  'Action'
];

function PurchaseOrderContainer() {
  const location = useLocation();
  const autoOrder = location?.state?.autoOrder;
  const existingProducts = location?.state?.autoProducts;
  const returnHeaders = () => poHeaders.map((header) => <TableHeader key={header}>{header}</TableHeader>);
  const navigate = useNavigate();
  const localBusinessId = localStorage.getItem('ogarx_business_id');

  const [{ user: { businessId } }] = Object.values(useStateValue());

  const adminOrderingForBusiness = localStorage.getItem('oga_ordering_for_affiliate');
  let bizId = 0;
  if (adminOrderingForBusiness) bizId = JSON.parse(adminOrderingForBusiness).id;
  else bizId = businessId;
  const variables = {
    bizId, isManualOrder: false
  };

  const { data: orderData, loading: orderLoading } = useQuery(ORDER_QUERY, {
    variables,
    fetchPolicy: 'no-cache'
  });

  const { data: businessData } = useQuery(GET_MY_BUSINESS, {
    variables: { id: localBusinessId }
  });

  const deliveryLocationId = businessData?.business?.deliveryLocations[0]?.id || 0;

  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [pId, setPId] = useState('');
  const [searchFieldEl, setSearchFieldEl] = useState(null);
  const [savedEl, setSavedEl] = useState(null);
  const [searchedProducts, setSearchedProducts] = useState(null);
  const [currentRowSearch, setCurrentRowSearch] = useState(0);
  const [successDialog, setSuccessDialog] = useState(false);
  const [successState, setSuccessState] = useState({});

  const [reload, setReload] = useState(false);
  const reloadHandler = () => setReload(!reload);

  const [getSearchedProducts, { data: poData, loading }] = useLazyQuery(
    SEARCH_PO_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        search: '',
        productName: search,
        productId: pId,
        status: 'enabled'
      }
    }
  );

  const createData = (product) => {
    const prod = new Product(product, 'erp');
    return {
      productId: prod?.id,
      brandName: prod?.brandName,
      quantityInStock: prod?.quantityInStock,
      supplier: prod?.supplier,
      itemPrice: prod?.orderCost
    };
  };

  const handlePopperClose = () => {
    setSearchedProducts([]);
    setSearchFieldEl(null);
  };

  useEffect(() => {
    if (poData && poData.erpProducts) {
      const sResults = poData.erpProducts.map((product) => createData(product));
      setSearchedProducts(sResults);
      setSearchFieldEl(savedEl);
    }
  }, [poData]);

  const retrieveProducts = (orderSet) => {
    let autoProducts = [];
    orderSet?.forEach((result) => {
      autoProducts.push(...result.orderproductSet);
    });
    autoProducts = autoProducts.map((prod) => {
      const {
        supplierOrder: { supplier: { name: supplierName } },
        product: { id: productId, erpVersion: { orderCost, brandName } }
      } = prod;
      const next = generateRandomNumber(10);
      const prodData = {
        sn: next,
        productId,
        productName: brandName,
        supplier: supplierName,
        quantity: 1,
        newUnitCost: orderCost,
        price: orderCost
      };
      return prodData;
    });
    setProducts(autoProducts);
  };

  useEffect(() => {
    if (autoOrder && orderData && orderData.order) {
      const { order: { supplierorderSet } } = orderData;
      retrieveProducts(supplierorderSet);
    }
  }, [autoOrder, orderData]);

  const retrieveAutoProducts = (autoProd) => {
    const updateAuto = autoProd.map((prod) => {
      const {
        supplierOrder: { supplier: { name: supplierName } },
        product: { id: productId, erpVersion: { orderCost, brandName } }
      } = prod;
      const next = generateRandomNumber(10);
      const prodData = {
        sn: next,
        productId,
        productName: brandName,
        supplier: supplierName,
        quantity: 1,
        newUnitCost: orderCost,
        price: orderCost
      };
      return prodData;
    });
    setProducts(updateAuto);
  };

  const handleSearch = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set search field
    setPId('');
    setSearch(value);
    // Update the product name for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productName = value;
    setProducts([...products]);

    if (value && value.length > 2) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const handleSearchByPId = async (event, sn) => {
    setCurrentRowSearch(sn);
    const { value } = event.target;
    // Set product id search field
    setSearch('');
    setPId(value);
    // Update the product id for a specified product
    const newData = products.find(({ sn: psn }) => psn === sn);
    newData.productId = value;
    setProducts([...products]);

    if (value) {
      getSearchedProducts();
      setSavedEl(event.target);
    } else {
      handlePopperClose();
    }
  };

  const addMoreHandler = () => {
    const next = generateRandomNumber(10);
    const newData = {
      sn: next,
      productId: '',
      productName: '',
      supplier: '',
      quantity: 0,
      newUnitCost: '',
      price: 0
    };
    setProducts([...products, newData]);
  };

  const removeRowHandler = (sn) => {
    const newData = products.filter(({ sn: sid }) => sn !== sid);
    setProducts(newData);
  };

  const handleProductSelection = (row) => {
    const {
      productId, brandName, supplier, itemPrice
    } = row;

    const exists = products.find(
      ({ productName }) => productName === brandName
    );
    if (exists) return toast.error('Product already exists');

    const newData = products.find(({ sn }) => sn === currentRowSearch);
    newData.productId = productId;
    newData.productName = brandName;
    newData.supplier = supplier.name;
    newData.quantity = 1;
    newData.newUnitCost = itemPrice;
    newData.price = itemPrice;

    setProducts([...products]);
    handlePopperClose();
  };

  useEffect(() => {
    setProducts([...products]);
  }, [reload]);

  const counterHandler = (type, row, val) => {
    const { sn, quantity, newUnitCost } = row;
    switch (type) {
      case 'increment': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) + 1;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;

        break;
      }
      case 'decrement': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        const newQuantity = Number(quantity) - 1;
        if (newQuantity < 0) return;
        newData.quantity = newQuantity;
        newData.price = newQuantity * newUnitCost;

        break;
      }
      case 'change': {
        const newData = products.find(({ sn: sid }) => sn === sid);
        if (val <= 0) return;
        newData.quantity = Number(val);
        newData.price = val * newUnitCost;
        break;
      }

      default:
        break;
    }
    reloadHandler();
  };

  const updateCostPriceHandler = (val, row) => {
    const { sn, quantity } = row;
    const newData = products.find(({ sn: sid }) => sn === sid);
    newData.newUnitCost = val;
    newData.price = quantity * val;

    setProducts([...products]);
    reloadHandler();
  };

  const [placePoOrder, { loading: poloading }] = useMutation(PLACE_ORDER_PRODUCTS_MUTATION);

  const saveDraftHandler = async () => {
    const filterItems = products.filter((prod) => prod.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((prod) => {
      const { productId, quantity, newUnitCost } = prod;
      return {
        productId,
        quantity,
        newUnitCost
      };
    });

    if (!cart.length) return toast.error('Purchase order is empty');

    const request = {
      cart,
      isManualOrder: false,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: true,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: []
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'PO Saved!', desc: 'Your PO was successfully Saved!' });
        setTimeout(() => {
          setSuccessDialog(false);
          navigate('/manage-purchase-order');
        }, 3000);
      })
      .catch(() => {});
  };

  const createPOHandler = () => {
    // Remove row that does not have product id
    const filterItems = products.filter((prod) => prod.productId !== '');

    // Select only the required items from the filtered list
    const cart = filterItems.map((prod) => {
      const { productId, quantity, newUnitCost } = prod;
      return {
        productId: Number(productId),
        quantity,
        newUnitCost
      };
    });

    if (cart.length < 1) return toast.error('Purchase order is empty');

    const request = {
      cart,
      isManualOrder: false,
      deliveryLocationId: Number(deliveryLocationId),
      creditScoreBusiness: localBusinessId,
      isADraft: false,
      extraActions: [],
      extraProductsMeta: [],
      affectedSuppliers: []
    };

    placePoOrder({
      variables: { ...request }
    })
      .then(() => {
        setSuccessDialog(true);
        setSuccessState({ title: 'PO Created!', desc: 'Your PO was successfully Created!' });
        setTimeout(() => {
          setSuccessDialog(false);
          navigate('/manage-purchase-order');
        }, 3000);
      })
      .catch(() => {});
  };

  let rowCounter = 0;

  useEffect(() => {
    if (existingProducts && existingProducts.length > 0) {
      retrieveAutoProducts(existingProducts);
    }
  }, [existingProducts]);

  return (
    <MainContent>
      <GridContainer container>
        <GoBack onClick={() => navigate('/inventory/orders')}>
          <KeyboardArrowLeft style={{ fontSize: '1.8rem', cursor: 'pointer' }} />
          back
        </GoBack>
        <TitleGrid container item>
          <TitleTextGridContainer item xs={12} md={12}>
            <PrimaryTitle variant="h5" data-testid="createPurchaseOrderText">Create Purchase Order</PrimaryTitle>
            <ButtonContainer>
              <Button
                variant="outlined"
                disabled={poloading}
                style={{ width: '8rem', height: '3rem', border: '2px solid' }}
                onClick={saveDraftHandler}
              >
                Save Draft
              </Button>
              <Button
                onClick={createPOHandler}
                variant="contained"
                disabled={poloading}
                style={{ width: '8rem', height: '3rem' }}
              >
                Create PO
              </Button>
            </ButtonContainer>
          </TitleTextGridContainer>
        </TitleGrid>
        <PaperWrapper elevation={0}>
          <TableGrid item container>
            <TableContainer>
              <Table>
                <MainTableHead>
                  {returnHeaders()}
                  <TableHeader />
                </MainTableHead>
                <TableBody>
                  {(autoOrder && orderLoading) ? (
                    <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                      <CircularProgressLoader
                        disableShrink
                        size={40}
                        thickness={5}
                      />
                    </BodyCell>
                  )
                    : !products?.length ? (
                      <BodyCell colSpan={9} style={{ textAlign: 'center' }}>
                        Purchase order is empty
                      </BodyCell>
                    ) : (
                      products?.map((item) => {
                        rowCounter += 1;
                        const { sn } = item;
                        return (
                          <ReturnRow
                            key={sn}
                            row={item}
                            index={rowCounter}
                            loading={loading}
                            counterHandler={counterHandler}
                            handleSearch={handleSearch}
                            removeRowHandler={removeRowHandler}
                            updateCostPriceHandler={updateCostPriceHandler}
                            handleSearchByPId={handleSearchByPId}
                            currentRowSearch={currentRowSearch}
                          />
                        );
                      })
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </TableGrid>
          <Button
            variant="outlined"
            onClick={addMoreHandler}
            style={{
              width: '11rem',
              height: '3rem',
              border: '2px solid',
              marginTop: '1rem'
            }}
          >
            Add New Product
          </Button>
        </PaperWrapper>
      </GridContainer>
      <SearchPopper
        searchFieldEl={searchFieldEl}
        handleClose={handlePopperClose}
        searchedProducts={searchedProducts}
        handleProductSelection={handleProductSelection}
      />
      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={setSuccessDialog}
        title={successState.title}
        desc={successState.desc}
        option="Ok"
      />
    </MainContent>
  );
}

export default PurchaseOrderContainer;
