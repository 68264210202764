import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPDATE_BUSINESS_MUTATION } from '../../../../mutations/business';
import { useStateValue } from '../../../../providers/stateProvider';
import NotificationDialog from '../../../shared/notificationDialog';
import InitialImage from '../../../shared/imageUpload/initialImage';
import ImageUploadDialog from '../../../shared/imageUpload/imageUploadDialog';
import { JSONParse } from '../../../../utils/json';
import Loader from '../../../shared/loader';

import {
  MainSectionPaper, HeaderGrid, MainHeaderText, UploadGrid, SectionHeaderText, SectionContent,
  BusinessDetail, SubTextContent
} from './businessinfoCard.styles';
import ValidText from '../../../../utils/validText';

const BusinessInfoCard = () => {
  const [updateBusiness] = useMutation(UPDATE_BUSINESS_MUTATION);
  const [finalImage, setFinalImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(true);
  const [openImageUpload, setOpenImageUpload] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openDeleteSuccessDialog, setOpenDeleteSuccessDialog] = useState(false);

  const placeholder = 'https://res.cloudinary.com/health-id/image/upload/v1668676148/Placeholders/user_placeholder.png';

  const [{ affiliate, affiliate: { loggedInAffiliateRefetch } }] = Object.values(useStateValue());
  if (!affiliate.loggedInAffiliate) return <Loader />;

  const { business: biz } = affiliate.loggedInAffiliate || {};
  const {
    name: legalName, id, contacts, profile
  } = biz || {};

  const { logo: businessLogo } = JSONParse(profile);
  const parsedContacts = JSONParse(contacts);
  const { region, city } = parsedContacts;

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.AFFILIATES_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        console.log('upload error', response.error);
        return resolve(response.secure_url);
      });
    });
  };

  const handleCloseDialog = () => {
    setOpenImageUpload(false);
  };

  const handleFinalImage = (image) => {
    setFinalImage(image);

    handleImageUpload(image)
      .then((data) => {
        const business = localStorage.getItem('ogarx_business_id');
        updateBusiness({
          variables: {
            id: business,
            logo: data
          }
        }).then(() => {
          setLoading(false);
          setShowInitialImage(true);
          handleCloseDialog(true);
          setOpenSuccessDialog(true);
        }).finally(() => {
          setShowInitialImage(true);
          loggedInAffiliateRefetch();
        });
      });
  };

  const handleDeleteInitialImage = () => {
    const business = localStorage.getItem('ogarx_business_id');
    updateBusiness({
      variables: {
        id: business,
        logo: placeholder
      }
    }).then(() => {
      setFinalImage(null);
      handleCloseDialog(true);
      setOpenDeleteSuccessDialog(true);
      loggedInAffiliateRefetch();
    });
  };

  const image = finalImage || businessLogo;

  return (
    <Grid item container justifyContent="flex-end" xs={9}>
      <MainSectionPaper elevation={0}>
        <HeaderGrid item container>
          <MainHeaderText>Business Profile</MainHeaderText>
          <Grid item container>
            <UploadGrid container item xs={2}>
              {showInitialImage ? (
                <InitialImage
                  image={image}
                  handleImageChange={() => setOpenImageUpload(true)}
                />
              ) : ''}
            </UploadGrid>
          </Grid>
        </HeaderGrid>
        <SectionContent item container xs={6}>
          <BusinessDetail item container>
            <SectionHeaderText>Legal Name</SectionHeaderText>
            <SubTextContent>
              {legalName}
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>Business ID</SectionHeaderText>
            <SubTextContent>
              {`BU${id?.padStart(3, 0)}`}
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>Primary Email Address</SectionHeaderText>
            <SubTextContent>
              {parsedContacts.primary_email_address}
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>Address Line 1</SectionHeaderText>
            <SubTextContent>
              <ValidText text={parsedContacts.address_line_1} fallBackText="Line1: N/A" />
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>State</SectionHeaderText>
            <SubTextContent>
              <ValidText text={city} />
            </SubTextContent>
          </BusinessDetail>
        </SectionContent>

        <SectionContent item container xs={6}>
          <BusinessDetail item container>
            <SectionHeaderText>Primary Contact Name</SectionHeaderText>
            <SubTextContent>
              {parsedContacts.primary_contact_name}
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>User Type</SectionHeaderText>
            <SubTextContent>
              {localStorage.getItem('oga_user_role')?.replaceAll('-', ' ',)}
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>Secondary Email Address</SectionHeaderText>
            <SubTextContent>
              {parsedContacts.secondary_email_address || 'N/A'}
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>Address Line 2</SectionHeaderText>
            <SubTextContent>
              <ValidText text={parsedContacts.address_line_2} fallBackText="Line2: N/A" />
            </SubTextContent>
          </BusinessDetail>

          <BusinessDetail item container>
            <SectionHeaderText>City</SectionHeaderText>
            <SubTextContent>
              <ValidText text={region} />
            </SubTextContent>
          </BusinessDetail>
        </SectionContent>
      </MainSectionPaper>

      <ImageUploadDialog
        dialogOpen={openImageUpload}
        image={image}
        handleFinalImage={handleFinalImage}
        handleImageDelete={handleDeleteInitialImage}
        closeDialog={handleCloseDialog}
        setLoading={setLoading}
        loading={loading}
        placeholder={placeholder}
      />

      <NotificationDialog
        openDialog={openSuccessDialog}
        setOpenDialog={setOpenSuccessDialog}
        title="Profile Photo Saved"
        desc="Your profile photo has been updated successfully"
      />

      <NotificationDialog
        openDialog={openDeleteSuccessDialog}
        setOpenDialog={setOpenDeleteSuccessDialog}
        title="Photo Deleted!"
        desc="Your profile photo has been deleted!"
      />
    </Grid>
  );
};

export default BusinessInfoCard;
