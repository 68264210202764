import React from 'react';
import { useQuery } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';
import { GET_USER_INFO } from '../queries/user';
import { useStateValue } from '../providers/stateProvider';
import { connectionCheck } from './utils';

export const withAuth = (Component) => (props) => {
  const location = useLocation();
  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: {
      fetchPolicy: 'network',
    },
  });
  const [
    {
      affiliate: { isBusinessVirtualStore },
    },
  ] = Object.values(useStateValue());

  const role = localStorage.getItem('oga_user_role');
  const { pathname } = location;
  const { id, redirectTo } = props?.match?.params || '';

  const storeManagerWhitelist = [
    '/sales',
    `/sale-details/${id}`,
    '/returns',
    '/history',
    '/orders',
    '/cart',
    '/new-order',
    `/orders/${id}/affiliate`,
    '/sales/summary',
    '/manual-order',
    '/manual-cart',
    '/manual-order-dashboard',
    '/open-orders',
    `/receive-manual-order/${id}`,
    `/suppliers-orders/${id}/${redirectTo}`,
    '/products',
    '/map-product-csv',
    '/suppliers',
    '/map-supplier-csv',
    `/suppliers/${id}/details`,
    `/customers/${id}/details`,
    `/products/${id}/details`,
    '/sales-reconciliation',
    '/customers',
    '/map-customer-csv',
    '/categories',
    `/categories/${id}/details`,
    '/stock-count-report',
    '/open-reports',
    '/manual-stock-report',
    `/reports/${id}/details`,
    `/categories/${id}/details`,
    '/map-product-reconcile-csv',
    `/categories/${id}/details`,
    '/map-product-reconcile-csv',
    '/therapeutic-types',
    `/therapeutic-type/${id}/details`,
    'invoices',
    `invoices/${id}/details`,
    '/roles',
    '/business-users',
    '/downloads',
    '/stock-transfer',
    '/batch-details'
  ];
  const affiliateWhitelist = [
    '/orders',
    '/cart',
    '/new-order',
    `/orders/${id}/affiliate`,
    `/products/${id}/details`,
    `/categories/${id}/details`,
  ];
  const supplierWhitelist = [
    '/orders-supplier',
    `/orders/${id}/detail`,
    '/products',
    '/products-details',
    `/products/${id}/details`,
    `/categories/${id}/details`,
  ];

  const ogaWhitelist = [
    '/dashboard',
    '/new-order',
    '/orders-admin',
    '/dispatch-orders',
    `/orders-admin/dispatch/${id}`,
    '/data-analysis',
    `/data-analysis/${id}`,
    '/manage-affiliates',
    `/manage-affiliates/${id}/details`,
    '/suppliers',
    '/payments',
    `/orders/${id}/detail`,
    '/cart',
    '/therapeutic-types',
    `/suppliers/${id}/details`,
    '/downloads',
    '/roles',
    '/business-users',
    `/new-order/${id}/details`
  ];

  if (loading) return null;
  if (error && connectionCheck()) return <Navigate to="/" />;
  if (data && data.userInfo) {
    const outletId = data?.definedUser?.deliveryLocations[0]?.id || {};
    localStorage.setItem('delivery_location_id', outletId);
    const { username } = data.userInfo;
    localStorage.setItem('oga_username', username);

    if (
      (role === 'affiliate-admin'
        && affiliateWhitelist.indexOf(pathname) !== -1)
      || (role === 'supplier-admin'
        && supplierWhitelist.indexOf(pathname) !== -1)
      || (role === 'store-manager'
        && storeManagerWhitelist.indexOf(pathname) !== -1)
      || (role === 'oga-pharmacy-admin'
        && ogaWhitelist.indexOf(pathname) !== -1)
      || role === 'chain-manager'
      || isBusinessVirtualStore
    ) {
      return <Component {...props} />;
    }
    return <Navigate to="/" />;
  }
  if (!connectionCheck()) {
    if (
      (role === 'affiliate-admin'
        && affiliateWhitelist.indexOf(pathname) !== -1)
      || (role === 'supplier-admin'
        && supplierWhitelist.indexOf(pathname) !== -1)
      || (role === 'store-manager'
        && storeManagerWhitelist.indexOf(pathname) !== -1)
      || role === 'oga-pharmacy-admin'
      || isBusinessVirtualStore
    ) {
      return <Component {...props} />;
    }
    return <Navigate to="/" />;
  }
  return null;
};

export default withAuth;
