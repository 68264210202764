import React, { useState } from 'react';
import Fade from '@mui/material/Fade';
import { TextField, InputAdornment, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { CaretUpIcon, CaretDownIcon } from '../../../assets/svgs';
import {
  BatchDialog, DialogWrapper, SubText, ProductName, DialogHeader, DialogBody, DialogActions,
  CloseIcon, AddButton, InfoText, OutOfStockText, TableHeader, HeaderGrid, MainTableRow,
  BodyCell, CustomCheckbox, CustomInputBase
} from './selectBatchDialog.styles';
import SaleActionTypes from '../../../providers/reducers/sales/salesTypes';
import { RenderPopper } from '../../../utils/utils';

import { useStateValue } from '../../../providers/stateProvider';

const Transition = React.forwardRef((props, ref) => (
  <Fade ref={ref} {...props} />
));

export default function SelectBatchDialog() {
  const [state, setState] = useState({
    sellOutOfStockEl: null,
    outOfStockQty: 0,
    openPopper: false
  });

  const [{
    sale: {
      batchModalData: { openedBatchModal, batches, product },
    }
  }, dispatch] = Object.values(useStateValue());
  const {
    sellOutOfStockEl, outOfStockQty, openPopper
  } = state;

  const clearPopper = () => setState({
    ...state, openPopper: false, sellOutOfStockEl: null, outOfStockQty: 0
  });

  const closeDialog = () => dispatch({
    type: SaleActionTypes.OPEN_BATCH_MODAL,
    payload: { open: false, product }
  });

  const addOutOfStockToBatch = () => {
    if (+outOfStockQty <= 0) {
      return;
    }
    dispatch({
      type: SaleActionTypes.ADD_OUT_OF_STOCK_BATCH, payload: {
        quantity: outOfStockQty
      }
    });
    clearPopper();
  };

  const handleSelect = (batch) => dispatch({
    type: SaleActionTypes.TOGGLE_SELECT_PRODUCT_BATCH,
    payload: { orderProductId: batch?.orderProductId }
  });

  const handleChange = (e, batch) => {
    const { value } = e?.target ?? '';
    if (value && +value < 0) return;
    dispatch({
      type: SaleActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY,
      payload: {
        orderProductId: batch.orderProductId,
        quantity: value
      }
    });
  };

  const handleIncrement = (batch) => {
    const { qtyToSell = 0 } = batch;
    if (qtyToSell === batch.qtyInStock) return;
    dispatch({
      type: SaleActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY,
      payload: {
        orderProductId: batch.orderProductId,
        quantity: qtyToSell + 1,
      }
    });
  };

  const handleDecrement = (batch) => {
    const { qtyToSell = 0 } = batch;
    if (qtyToSell <= 0) return;
    dispatch({
      type: SaleActionTypes.UPDATE_BATCH_TO_SELL_QUANTITY,
      payload: {
        orderProductId: batch.orderProductId,
        quantity: qtyToSell - 1,
      }
    });
  };

  const handleDone = () => dispatch({
    type: SaleActionTypes.AGGREGATE_BATCHES_TO_CART,
    payload: { product }
  });

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
    />
  );

  const headers = [renderCheckbox(), 'S/N', 'Batch #', 'Expiry Date', 'Qty in Stock', 'Qty to Sell'];

  const renderBatchItems = () => {
    const batchList = [];
    batches.forEach((batch) => { batchList.push(batch); });
    return (
      <DialogBody item container>
        <HeaderGrid item container>
          {headers.map((header) => <TableHeader>{header}</TableHeader>)}
        </HeaderGrid>
        {batchList?.map((batch, indx) => (
          <MainTableRow key={batch.orderProductId} item container>
            <BodyCell>
              <CustomCheckbox
                size="small"
                onChange={() => handleSelect(batch)}
                name="terms"
                checked={batch.checked}
                defaultChecked={false}
              />
            </BodyCell>
            <BodyCell>{indx + 1}</BodyCell>
            <BodyCell>{batch?.batchNo}</BodyCell>
            <BodyCell>{moment(batch?.expiryDate).format('YYYY-MM-DD')}</BodyCell>
            <BodyCell>{batch?.qtyInStock}</BodyCell>
            {batch.checked && (
              <BodyCell>
                <CustomInputBase
                  type="number"
                  placeholder="0"
                  min={1}
                  size="medium"
                  value={batch.qtyToSell}
                  onChange={(e) => handleChange(e, batch)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <Grid item container direction="column">
                        <CaretUpIcon
                          style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                          onClick={() => handleIncrement(batch)}
                        />
                        <CaretDownIcon
                          style={{ width: '1rem', height: '1rem', cursor: 'pointer' }}
                          onClick={() => handleDecrement(batch)}
                        />
                      </Grid>
                    </InputAdornment>
                  )}
                />
              </BodyCell>
            )}
          </MainTableRow>
        ))}
      </DialogBody>
    );
  };
  return (
    <BatchDialog
      open={openedBatchModal}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fileslimit={1}
    >
      <DialogWrapper item container>
        <DialogHeader item container>
          <Grid item container xs={8}>
            <ProductName>{product?.brandName}</ProductName>
            <Grid item container>
              <SubText variant="caption">
                Pack Size:
                &nbsp;
                <b style={{ fontWeight: '600' }}>{product?.packSize}</b>
              </SubText>
              <SubText variant="caption">
                Quantity in Stock:
                &nbsp;
                <b style={{ fontWeight: '600' }}>{product?.quantityInStock}</b>
              </SubText>
            </Grid>
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <CloseIcon onClick={closeDialog} />
          </Grid>
        </DialogHeader>
        {renderBatchItems()}
        <DialogActions item container>
          <Button variant="outlined" style={{ width: '11rem', marginRight: '1.2rem' }} disabled>
            Add New Batch
          </Button>
          <Button variant="contained" onClick={handleDone} style={{ width: '11rem' }}>
            Done
          </Button>
        </DialogActions>
      </DialogWrapper>

      <RenderPopper
        showArrow
        open={openPopper}
        anchorEl={sellOutOfStockEl}
        onClickAway={clearPopper}
        popperPlacement="bottom"
      >
        <Grid item container>
          <OutOfStockText>
            Out of stock batch
          </OutOfStockText>
          <Grid>
            <Grid container alignItems="center" style={{ padding: '10px' }}>
              <Grid item xs={7}>
                <TextField
                  label="Quantity Required"
                  value={outOfStockQty}
                  placeholder="0"
                  onChange={(event) => setState({ ...state, outOfStockQty: +event.target.value || 0 })}
                  id="outlined-start-adornment-x"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5}>
                <AddButton onClick={addOutOfStockToBatch}>
                  ADD
                </AddButton>
              </Grid>
            </Grid>
          </Grid>
          <InfoText>
            This quantity must be reconciled later
          </InfoText>
        </Grid>
      </RenderPopper>
    </BatchDialog>
  );
}

SelectBatchDialog.propTypes = {
};
