import React from 'react';
import PropTypes from 'prop-types';
import {
  TabsGrid, UnderlineGrid, Typo
} from './filter.styles';

const Filter = ({
  tabVal, handleTabChange
}) => {
  const inventoryTab = [
    {
      label: 'Orders', value: 'orders', width: '8%', testid: 'orderVal'
    },
    {
      label: 'Products', value: 'products', width: '8%'
    },
    {
      label: 'Batch Details', value: 'batch-details', width: '10%'
    },
    {
      label: 'Product Type', value: 'therapeutic-types', width: '10%'
    }
  ];

  return (
    <TabsGrid container item>
      {inventoryTab.map(({
        label, value, width, testid
      }) => (
        <UnderlineGrid
          key={value}
          item
          container
          className={`erp-uat-${value}`}
          onClick={() => handleTabChange(value)}
          active={value === tabVal}
          width={width}
          data-testid={testid}
        >
          <Typo active={value === tabVal} style={{ whiteSpace: 'nowrap' }}>
            {label}
          </Typo>
        </UnderlineGrid>
      ))}
      <UnderlineGrid width="64%" style={{ padding: '10.5px 0', cursor: 'auto' }}>
        <Typo />
      </UnderlineGrid>
    </TabsGrid>
  );
};

Filter.propTypes = {
  tabVal: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
};

export default Filter;
