import { gql } from '@apollo/client';
import { string } from 'prop-types';
import { PRODUCT_VERSIONS } from './products';
import supplier from '../providers/reducers/supplier/supplierState';

export const GET_SUPPLIER_ORDERS = gql`
  query supplierOrders(
    $pageCount: Int, $pageNumber: Int,
    $deliveryLocation: Int
  ){
    supplierOrders(
    pageCount: $pageCount,
    pageNumber: $pageNumber,
    deliveryLocation: $deliveryLocation
    ) {
      id
      lastUpdated
      supplier{
        name
        id
        logo
      }
      deliveryDate
      total
      savings
      status
      orderStatus
      dateCreated
    }
    supplierOrderTotalNumber
  }
`;
export const GET_AFFILIATE_ORDERS = gql`
query affiliateOrders(
  $status: String, $metaStatus: String, $pageCount: Int, $pageNumber: Int, $orderNo: Int,
  $totalFrom: String, $totalTo: String, $dateFrom: String, $dateTo: String,
  $productName: String
){
  affiliateOrders(
    status: $status,  metaStatus: $metaStatus, pageCount: $pageCount, pageNumber: $pageNumber,
    orderNo: $orderNo, totalFrom: $totalFrom, totalTo: $totalTo,
    dateFrom: $dateFrom, dateTo: $dateTo, productName: $productName
  ){
    id,
    status,
    metaStatus,
    dateCreated,
    lastUpdated,
    datePlaced,
    meta,
    business{
      name,
      logo
    }
    paymentSummary,
    supplierorderSet{
      supplier{
        name
        logo
      }
    }
  }
  businessOrderTotalNumber
}
`;

export const GET_ALL_SUPPLIER_ORDERS = gql`
  query allSupplierOrders(
    $pageCount: Int, $pageNumber: Int, $status: String, $search: String
  ){
    allSupplierOrders(
      pageCount: $pageCount
      pageNumber: $pageNumber
      status: $status
      search: $search
    ) {
      id
      meta
      total
      savings
      deliveryDate
      lastUpdated
      dateCreated
      status
      orderStatus
      supplier {
        id
        name
        logo
      }
      order {
        id
        business {
          id
          name
          logo
        }
      }
      orderproductSet {
        id
        quantity
        unitCost
      }
    }
    supplierOrderTotalNumber
  }
`;

export const GET_UNRECONCILED_MANUAL_SUPPLIER_ORDERS = gql`
  query  unreconciledManualSupplierOrders(
    $businesses: [String], $businessUserIds: [String]
  ) {
    unreconciledManualSupplierOrders(
      businesses: $businesses, businessUserIds: $businessUserIds
    ) {
      aWeek {
        id
        meta
        total
        savings
        deliveryDate
        dateCreated
        lastUpdated
        status
        supplier {
          id
          name
          logo
        }
        order {
          id
          business {
            id
            name
            logo
          }
        }
        orderproductSet {
          id
          quantity
          unitCost
        }
      }
      twoWeeks {
      id
        meta
        total
        savings
        deliveryDate
        dateCreated
        lastUpdated
        status
        supplier {
          id
          name
          logo
        }
        order {
          id
          business {
            id
            name
            logo
          }
        }
        orderproductSet {
          id
          quantity
          unitCost
        }
      }
      otherWeeks {
        id
        meta
        total
        savings
        deliveryDate
        dateCreated
        lastUpdated
        status
        supplier {
          id
          name
          logo
        }
        order {
          id
          business {
            id
            name
            logo
          }
        }
        orderproductSet {
          id
          quantity
          unitCost
        }
      }
    }
  }
`;

export const UPDATED_UNRECONCILED_MANUAL_SUPPLIER_ORDERS = gql`
  query unreconciledManualSupplierOrders(
    $dateFrom:String, 
    $dateTo:String, 
    $pageCount:Int, 
    $pageNumber:Int, 
    $supplier: String,
    $poNumber: String,
    $parentId: String,
    $orderType: String
  )
  {
    unreconciledManualSupplierOrders(
      dateFrom:$dateFrom, dateTo:$dateTo, pageCount:$pageCount,pageNumber:$pageNumber,
      supplier: $supplier, poNumber: $poNumber, parentId: $parentId, orderType: $orderType
    ){
      id
      meta
      total
      savings
      deliveryDate
      dateCreated
      lastUpdated
      status
      supplier {
        id
        name
        logo
      }
      order {
        id
        business {
          id
          name
          logo
        }
      }
      orderproductSet {
        id
        quantity
        unitCost
      }
    }
    supplierOrderTotalNumber
  }
`;

export const GET_COMPLETED_MANUAL_SUPPLIER_ORDERS = gql`
  query reconciledManualSupplierOrders(
    $pageCount: Int, $pageNumber: Int, $businesses: [String], $businessUserIds: [String]
  ){
    reconciledManualSupplierOrders(
      pageCount: $pageCount,
      pageNumber: $pageNumber,
      businesses: $businesses,
      businessUserIds: $businessUserIds
    ) {
      id
      meta
      total
      savings
      deliveryDate
      dateCreated
      lastUpdated
      status
      supplier {
        id
        name
        logo
      }
      order {
        id
        business {
          id
          name
          logo
        }
      }
      orderproductSet {
        id
        quantity
        unitCost
      }
    }
    supplierOrderTotalNumber
  }
`;
export const GET_SINGLE_SUPPLIER_ORDER = gql`
    ${PRODUCT_VERSIONS}
  query supplierOrder (
    $id: Int
  ) {
    supplierOrder(
      id: $id
    ) {
      id
      total
      savings
      deliveryDate
      lastUpdated
      status
      orderStatus
      deliveryId
      orderproductSet {
        id
        name
        price
        partiallyFulfilled
        paid
        unitCost
        quantity
        quantityRecieved
        savings
        meta
        status
        supplierOrder {
          supplier {
            name
            settings
          }
        }
        product {
          id
          meta
          business{
            id
          }
          ...productVersions
        }
      }
      order {
        id
        meta
        business {
          name
          logo
          contacts
          settings
          profile
        }
      }
      supplier {
        name
        logo,
        paymentTerm
      }
    }
  }
`;

export const GET_ORDERS_BY_STATUS = gql`
  query ordersByStatus(
    $pageCount: Int, $pageNumber: Int, $status: String, $dateFrom: String, $dateTo: String
    $orderNo: Int, $affiliateName: String
  ){
    ordersByStatus(
      pageCount: $pageCount, pageNumber: $pageNumber, status: $status, dateFrom: $dateFrom,
      dateTo: $dateTo, orderNo: $orderNo, affiliateName: $affiliateName
    ){
      id
      status
      dateCreated
      datePlaced
      lastUpdated
      metaStatus
      xeroStatus
      business {
        id
        name
        logo
        contacts
        profile
        meta
      }
      supplierorderSet {
        id
        total
        supplier {
          name
          contacts
        }
        productCount
        orderproductSet {
          id
          name
          quantity
          price
        }
      }
    }
    ordersTotalNumber
  }
`;

export const GET_ALL_ORDERS = gql`
  query orders(
    $pageCount: Int, $pageNumber: Int, $status: String, $metaStatus: String,
    $xeroStatus: [String]
  ){
    orders(
      pageCount: $pageCount, pageNumber: $pageNumber, status: $status, metaStatus:$metaStatus,
      xeroStatus: $xeroStatus
    ){
      id
      status
      lastUpdated
      business {
        id
        name
        logo
        contacts
        profile
        meta
      }
      supplierorderSet {
        id
        total
        supplier {
          name
          contacts
        }
        productCount
        orderproductSet {
          id
          name
          quantity
          price
        }
      }
    }
    ordersTotalNumber
  }
`;

export const GET_SINGLE_ORDER = gql`
  query singleOrder($id: Int){
    singleOrder(id: $id){
      id
      status
      lastUpdated
      metaStatus
      meta
      deliveryInfo
      dateCreated
      business {
        id
        name
        logo
        contacts
        profile
        meta
      }
      supplierorderSet {
        id
        total
        supplier {
          name
          contacts
        }
        productCount
        orderproductSet {
          id
          name
          quantity
          meta
          quantityRecieved
          unitCost
          price
          product {
            meta
          }
        }
      }
    }
  }
`;

export const GET_OPEN_AND_CLOSED_ORDERS = gql`
query openAndClosedOrders(
  $status: String, $metaStatus: String, $pageCount: Int, $pageNumber: Int, $orderNo: Int,
  $businessName: String, $totalFrom: String, $totalTo: String, $dateFrom: String, $dateTo: String,
  $productName: String, $approvalStatus: String,
){
  openAndClosedOrders(
    status: $status,  metaStatus: $metaStatus, pageCount: $pageCount, pageNumber: $pageNumber,
    orderNo: $orderNo, businessName: $businessName, totalFrom: $totalFrom, totalTo: $totalTo,
    dateFrom: $dateFrom, dateTo: $dateTo, productName: $productName, approvalStatus: $approvalStatus
  ){
    id,
    status,
    metaStatus,
    dateCreated,
    lastUpdated,
    datePlaced,
    returned,
    parentOrderId,
    meta,
    business{
      name,
      logo
    }
    paymentSummary,
    supplierorderSet{
      supplier{
        name
        logo
      }
    }
  }
  businessOrderTotalNumber
}
`;

export const GET_WEEKLY_ORDERS = gql`
query aggregatedWeeklyOrder{
   aggregatedWeeklyOrder{
      businessNames
      graphs{
          year
        orders
      }
    }
}
`;

export const ORDER_PRODUCTS_TOTAL_QUERY = gql`
  query orderProducts {
    orderProducts {
      id
    }
    orderProductsTotalNumber
  }
`;

export const ORDER_PRODUCTS_QUERY = gql`
  query orderProducts ($pageCount: Int, $pageNumber: Int){
    orderProducts(pageCount: $pageCount, pageNumber: $pageNumber){
      id,
      name,
      supplierOrder {
        id
      }
      product {
        id
      }
      status
      quantity
      quantityRecieved
      unitCost
      price
      savings
      meta
      quantityInStock
    }
  }
`;

export const ORDERS_REPORT_QUERY = gql`
  query ordersReport (
    $business: String, $dateFrom: DateTime, $dateTo: DateTime,
    $pageCount: Int, $pageNumber: Int
  ){
    ordersReport(dateFrom: $dateFrom, dateTo: $dateTo, business: $business){
      id
      business {
        id
        name
        grossOrderAmount
        orderVolume
        cartTotal
      }
      totalCost
      totalSales
      startingInventory
      purchases
      closingInventory
      meta
      dateFrom
      dateTo
      dateCreated
      lastUpdated
    }
    allProductBatches(
      business: $business, dateFrom: $dateFrom, dateTo: $dateTo,
      pageCount: $pageCount, pageNumber: $pageNumber
    ){
      id
      name
      batchNo
      dateRecieved
      quantityRecieved
      quantityInStock
      expiryDate
      status
      orderCost
      orderPrice
      supplier {
        id
        name
      }
      product {
        id
        erpVersion {
          brandName
          packSize
        }
      }
      stockreportinventorySet {
        id
      }
      business {
        id
        name
      }
    }
    allProductBatchesTotalNumber
  }
`;

export const GET_ORDER_INVOICE = gql`
  query orderInvoices ($orderId: Int) {
    orderInvoices(orderId: $orderId) {
      invoice
    }
  }

`;

export const ORDER_INVOICE_TO_ERP = gql`
  query linkOrderInvoicesToErp ($orderId: Int) {
    linkOrderInvoicesToErp(orderId: $orderId) {
      invoice
    }
  }
`;

export const ORDER_COUNTS_QUERY = gql`
  query orderCounts {
    orderCounts {
      totalOrders
      totalOpenOrders
      totalClosedOrders
    }
  }
`;

export const SUPPLIER_ORDER = gql`
query supplierOrder (
    $id: Int
  ) {
    supplierOrder(
      id: $id
    ) {
      id
      dateCreated
      deliveryDate
      supplier{
        name
        logo
      }
      order{
        business{
          deliveryLocations{
            contacts
          }
        }
      }
      orderproductSet{
        product{
          erpVersion{
            brandName
            packSize
            image
          }
        }
        quantity
        quantityRecieved
        unitCost
        price
      }
    }
  }
`;

export const PO_LIST = gql`
  query poList($supplierOrderId: String!) {
    supplierOrderPurchaseOrders(supplierOrderId: $supplierOrderId)
  }
`;

export const UNRECONCILED_AUTOMATIC_SUPPLIER_ORDERS = gql`
  query unreconciledAutomaticSupplierOrders($dateFrom: String, $dateTo: String, $pageCount: Int, $pageNumber: Int, $poNumber: String, $parentId: String, $status: String) {
    unreconciledAutomaticSupplierOrders(
      dateFrom: $dateFrom
      dateTo: $dateTo
      pageCount: $pageCount
      pageNumber: $pageNumber
      poNumber: $poNumber
      parentId: $parentId
      status: $status
    ) {
      id
      meta
      total
      savings
      deliveryDate
      dateCreated
      lastUpdated
      status
      productCount
      supplier {
        id
        name
        logo
        __typename
      }
      order {
        id
        metaStatus
        business {
          id
          name
          logo
          __typename
        }
        __typename
      }
      orderproductSet {
        id
        quantity
        unitCost
        __typename
      }
      __typename
    }
    supplierOrderTotalNumber
  }
`;

// export const GET_ORDER_INVOICES = gql`
//   query orderInvoices (
//     $businesses: [String], $businessUserIds: [String], $dateFrom: DateTime
//     $dateTo: DateTime, $pageCount: Int, $pageNumber: Int, $searchInvoiceId: String
//   ) {
//     orderInvoices(
//       businesses: $businesses, businessUserIds: $businessUserIds, dateFrom: $dateFrom
//       dateTo: $dateTo, pageCount: $pageCount, pageNumber: $pageNumber, searchInvoiceId: $searchInvoiceId
//     ) {
//       id
//       status
//       total
//       business {
//         id
//         name
//       }
//       dateReceived
//       dateCreated
//       supplierAddress
//       supplier{
//         id
//         name
//       }
//       invoiceImage
//       invoiceNumber
//       business{
//         id
//         name
//       }
//       orderinvoiceproductbatchSet {
//         id
//         product {
//           id
//           meta
//           business{
//             id
//           }
//           ...productVersions
//         }
//         batchNo
//         expiryDate
//         quantityReceived
//       }
//     }
//     orderInvoicesTotalNumber
//   }
//   ${PRODUCT_VERSIONS}
// `;

// export const GET_ORDER_INVOICE = gql`
//   query orderInvoice ($id: Int) {
//     orderInvoice(id: $id) {
//       id
//       status
//       business {
//         id
//         name
//       }
//       dateReceived
//       dateCreated
//       supplierAddress
//       supplier{
//         id
//         name
//       }
//       invoiceImage
//       invoiceNumber
//       business{
//         id
//         name
//       }
//       total
//       orderinvoiceproductbatchSet {
//         id
//         product {
//           id
//           meta
//           business{
//             id
//           }
//           ...productVersions
//         }
//         batchNo
//         expiryDate
//         quantityReceived
//       }
//     }
//   }
//   ${PRODUCT_VERSIONS}
// `;
