import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import moment from 'moment';
import CustomNewTextField from '../../shared/CustomNewTextField';
import {
  FiltersGrid, SetFilter, FilterContainer, MenuItemStyled
} from './ordersFilter.styles';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import { useStateValue } from '../../../providers/stateProvider';

const OrdersFilter = ({
  state, handleFilterChange, handleSetFilter, setState
}) => {
  const [
    { user: { allowedPermissionsMap: { userPermissions } } },
  ] = Object.values(useStateValue());

  const orderStatus = [
    { name: 'All Orders', value: 'all' },
    { name: 'Open Orders', value: 'open' },
    { name: 'Closed Orders', value: 'closed' }
  ];

  const FilterFields = [
    { name: 'supplier', label: 'Supplier' },
  ];

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD')
    }));
  };

  return (
    <FilterContainer item container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        <CustomNewTextField
          field={{ name: 'poNumber', label: 'PO Number' }}
          state={state}
          handleChange={handleFilterChange}
        />
        <CustomNewTextField
          field={{ name: 'parentId', label: 'Parent ID' }}
          state={state}
          handleChange={handleFilterChange}
        />
        <CustomNewTextField
          field={{ name: 'orderType', label: 'Order Status' }}
          state={state}
          select
          handleChange={handleFilterChange}
          style={{ width: '10rem' }}
        >
          {orderStatus.map(({ name, value }) => (
            <MenuItemStyled key={value} value={value}>
              {name}
            </MenuItemStyled>
          ))}
        </CustomNewTextField>
        {FilterFields.map((field) => (
          <CustomNewTextField
            key={field?.name}
            field={field}
            state={state}
            handleChange={handleFilterChange}
          />
        ))}
        <Grid item>
          <CustomDateRanges
            handleSubmit={handleSubmit}
            xs={12}
            styles={{ height: '47px', width: '15rem', border: '1px solid #bababa' }}
          />
        </Grid>
        <Button
          variant="contained"
          onClick={handleSetFilter}
          disabled={!userPermissions?.includes('inventory_set_filter')}
          style={{ width: '7rem', marginLeft: '8px' }}
        >
          Set Filter
        </Button>
      </FiltersGrid>
    </FilterContainer>
  );
};

OrdersFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  setState: PropTypes.isRequired
};

OrdersFilter.defaultProps = {
  state: {},
};

export default OrdersFilter;
