import React from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, Fade, ClickAwayListener
} from '@mui/material';

import {
  ListItemStyled, CListItemText, TextGrid, CPopper, PaperWrapper, Typo
} from './statusDropdown.styles';

const CartDropdown = ({
  options, dotsButtonEl, setDotsButtonEl, action
}) => {
  const open = Boolean(dotsButtonEl);

  return (
    <CPopper
      open={open}
      anchorEl={dotsButtonEl}
      transition
      disablePortal
      placement="bottom-start"
      modifiers={{
        offset: {
          enabled: true,
          offset: '0vw, 1vh',
        }
      }}
    >
      {({ TransitionProps, placement }) => (
        <Fade
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <PaperWrapper>
            <ClickAwayListener onClickAway={() => setDotsButtonEl(null)}>
              <List style={{ width: '100%', padding: 0 }}>
                {options.map(({ name, disabled }) => (
                  <ListItemStyled
                    disableGutters
                    dense
                    button
                    style={{ padding: '.5rem 1.5rem' }}
                    key={name}
                    disabled={disabled}
                    onClick={() => action(name)}
                  >
                    <CListItemText
                      primary={(
                        <TextGrid container item>
                          <Typo variant="caption" noWrap>
                            {name}
                          </Typo>
                        </TextGrid>
                      )}
                    />
                  </ListItemStyled>
                ))}
              </List>
            </ClickAwayListener>
          </PaperWrapper>
        </Fade>
      )}
    </CPopper>
  );
};

CartDropdown.propTypes = {
  dotsButtonEl: PropTypes.shape(Object),
  setDotsButtonEl: PropTypes.func.isRequired,
  options: PropTypes.shape(Array),
  action: PropTypes.func.isRequired,
};

CartDropdown.defaultProps = {
  dotsButtonEl: {},
  options: [],
};

export default CartDropdown;
