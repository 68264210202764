export default function register() {
  if ('serviceWorker' in navigator) {
    // window.addEventListener('load', () => {
    //   navigator.serviceWorker.register('/sw.js', { scope: '/' })
    //     .then((registration) => {
    //       console.log('SW PWA registered: ', registration);
    //     })
    //     .catch((registrationError) => {
    //       console.log('SW PWA registration failed: ', registrationError);
    //     });
    // });

    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('SW Firebase registered: ', registration);
        })
        .catch((registrationError) => {
          console.log('SW Firebase registration failed: ', registrationError);
        });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
