import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import {
  PaymentSummaryGrandTotalRow, PaymentSummaryRow, PaymentSummaryRowBoldTitle, PayNowButton,
  PaymentSummaryRowTitle, PaymentSummaryCardHeader, PaymentSummaryCardHeaderTitle, ContinueButton
} from './paymentSummary.styles';
import currencyFormatter from '../../shared/currencyFormatter';

const PaymentSummary = ({
  cart, orderTotal, serviceFee, deliveryFee, updateLoading, grandTotal, taxTotal, promo, handleOpenPlaceOrderDialog,
  placeLoading, backRoute, handleDirectPlaceOrder
}) => {
  const navigate = useNavigate();

  const totalPromo = promo;
  return (
    <>
      <PaymentSummaryCardHeader>
        <PaymentSummaryCardHeaderTitle>Payment Summary</PaymentSummaryCardHeaderTitle>
      </PaymentSummaryCardHeader>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Order Total
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(orderTotal)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Delivery Fee
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(deliveryFee)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      <PaymentSummaryRow>
        <PaymentSummaryRowTitle>
          Service Fee
        </PaymentSummaryRowTitle>
        <PaymentSummaryRowBoldTitle>
          {`₦ ${currencyFormatter(serviceFee)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryRow>
      {/* {taxTotal ? (
        <PaymentSummaryRow>
          <PaymentSummaryRowTitle>
            Tax
          </PaymentSummaryRowTitle>
          <PaymentSummaryRowBoldTitle>
            {`₦ ${currencyFormatter(taxTotal)}`}
          </PaymentSummaryRowBoldTitle>
        </PaymentSummaryRow>
      ) : ''} */}
      {/* {totalPromo ? (
        <PaymentSummaryRow>
          <PaymentSummaryRowBoldTitle
            style={{
              color: '#AF1839'
            }}
          >
            Promotional Savings
          </PaymentSummaryRowBoldTitle>
          <PaymentSummaryRowBoldTitle
            style={{
              color: '#AF1839'
            }}
          >
            ₦&nbsp;
            {totalPromo ? `₦ -${currencyFormatter(totalPromo)}` : 'None'}
          </PaymentSummaryRowBoldTitle>
        </PaymentSummaryRow>

      ) : ''} */}
      <PaymentSummaryGrandTotalRow>
        <PaymentSummaryRowBoldTitle
          style={{ color: '#000' }}
        >
          Grand Total
        </PaymentSummaryRowBoldTitle>
        <PaymentSummaryRowBoldTitle style={{ color: '#000' }}>
          {`₦ ${currencyFormatter(grandTotal - totalPromo)}`}
        </PaymentSummaryRowBoldTitle>
      </PaymentSummaryGrandTotalRow>
      <Button
        variant="contained"
        onClick={() => handleDirectPlaceOrder()}
        disabled={placeLoading || updateLoading || !cart.length}
        className="mpAdmin-uat-cartPage-placeOrder"
        style={{ width: '100%', marginTop: '1.3rem' }}
      >
        Place Order
      </Button>
      <Button
        variant="outlined"
        onClick={() => navigate(`${backRoute}`)}
        className="mpAdmin-uat-cartPage-continueShopping"
        style={{ width: '100%', marginTop: '1rem' }}
      >
        Continue Shopping
      </Button>
    </>
  );
};

PaymentSummary.propTypes = {
  cart: PropTypes.instanceOf(Array),
  updateLoading: PropTypes.bool.isRequired,
  handleOpenPlaceOrderDialog: PropTypes.func.isRequired,
  handleDirectPlaceOrder: PropTypes.func.isRequired,
  orderTotal: PropTypes.number,
  serviceFee: PropTypes.number,
  deliveryFee: PropTypes.number,
  taxTotal: PropTypes.number,
  grandTotal: PropTypes.number,
  promo: PropTypes.number,
  placeLoading: PropTypes.bool.isRequired,
  backRoute: PropTypes.string.isRequired,
};

PaymentSummary.defaultProps = {
  cart: [],
  orderTotal: 0,
  serviceFee: 0,
  deliveryFee: 0,
  taxTotal: 0,
  grandTotal: 0,
  promo: 0
};

export default PaymentSummary;
