import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { KeyboardArrowLeft, ArrowBackIos } from '@mui/icons-material';
import { Button } from '@mui/material';
import Loader from '../../shared/loader';
import MainContent from '../../customComponents/mainContent';
import ProductInfo from './productInfo';
import ProductActionTimeline from './productActionTimeline';
import ProductBatchList from './productBatchList';
import GoToTop from '../../customComponents/scrollToTop';
import {
  OrderGridContainer, Back, TitleTextGridContainer, PrimaryTitle,
  // SecondaryTitle, ViewCartButton, ViewCartButtonText,
  // TextWrapper, HeaderIcon, MenuButton, TitleGridContainer
} from './productDetailsContainer.style';
// import EditIcon from '../../../assets/images/Edit Icon.png';
import { useStateValue } from '../../../providers/stateProvider';
import IndividualProductDialog from '../../shared/uploadProduct/individual/individualProductDialog';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';

import { PRODUCT_DETAILS_QUERY } from '../../../queries/products';
import { Product } from '../../../providers/reducers/product/classes/Product';
import { userCanUpdateProduct } from '../../../providers/reducers/user/userUtils';
// import { DELETE_PRODUCT_VERSION_MUTATION } from '../../../mutations/products';

const ProductDetailsContainer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageCount, setCurrentPageCount] = useState(10);
  const { id } = useParams();
  const navigate = useNavigate();
  // const [deleteProductVersion] = useMutation(DELETE_PRODUCT_VERSION_MUTATION);
  const handleChangePage = (_, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setCurrentPageCount(+event.target.value);
  };

  const {
    loading, error, data, refetch
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
      pageCount: currentPageCount,
      pageNumber: currentPage
    },
  });

  const [{ user, product: { addProductDialogOpen } }, dispatch] = Object.values(useStateValue());
  const {
    platform, businessId, role, allowedPermissionsMap: { userPermissions }
  } = user;

  if (loading) return <Loader />;
  // const isChainManager = role === 'chain-manager';
  if (error) return error;
  const {
    product = {}, modelHistory = {}, modelHistoryTotalNumber = 0
  } = data || {};
  const rows = new Product(product, platform);

  // Update erpVersion object
  const manufacturer = product?.manufacturer?.name;
  const erpProductVersion = { ...rows, manufacturer };

  // const { isAVersion } = rows.erpVersion;
  const { brandName } = rows;

  const toggleIndividualDialog = () => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: erpProductVersion, refetch }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };
  // const revertProductConfigurations = () => {
  //   deleteProductVersion({
  //     variables: { productId: product.id }
  //   })
  //     .then(({ data: res }) => {
  //       const { message } = res?.deleteProductVersion || {};
  //       setAlertState({
  //         message,
  //         showAlert: true,
  //         type: 'success'
  //       });
  //       refetch().then();
  //     })
  //     .catch(() => {
  //       setAlertState({
  //         message: 'Something went wrong!',
  //         showAlert: true,
  //         type: 'error'
  //       });
  //     });
  // };

  return (
    <MainContent>
      <OrderGridContainer container>
        <Back onClick={() => navigate(-1)}>
          <ArrowBackIos fontSize="small" />
          back
        </Back>
        <TitleTextGridContainer>
          <PrimaryTitle>{brandName}</PrimaryTitle>
          { userCanUpdateProduct(user) && (
            <Button
              variant="contained"
              style={{ width: '10rem', height: '2.6rem' }}
              onClick={() => toggleIndividualDialog()}
              disabled={!userPermissions?.includes('inventory_edit')}
            >
              Edit Product
            </Button>
          ) }
          {/* { */}
          {/*   !isChainManager && isAVersion && ( */}
          {/*   <ViewCartButton */}
          {/*     onClick={() => revertProductConfigurations()} */}
          {/*   > */}
          {/*     <HeaderIcon alt="cart icon" src={DeleteIcon} /> */}
          {/*     <Hidden smDown> */}
          {/*       <ViewCartButtonText> */}
          {/*         Use Parent Configurations */}
          {/*       </ViewCartButtonText> */}
          {/*     </Hidden> */}
          {/*   </ViewCartButton> */}
          {/*   ) */}
          {/* } */}
        </TitleTextGridContainer>
        <ProductInfo info={erpProductVersion} refetch={refetch} />
        <ProductBatchList info={rows} refetch={refetch} />
        <ProductActionTimeline id={id} />
        {rows.length ? <GoToTop /> : ''}
        <IndividualProductDialog
          dialogOpen={addProductDialogOpen}
          closeDialog={() => toggleIndividualDialog()}
          businessState={rows}
          details
        />
      </OrderGridContainer>
    </MainContent>
  );
};

export default ProductDetailsContainer;
