import React, { useState } from 'react';
import { Grid, Hidden, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainContent from '../customComponents/mainContent';
import DashboardDateRanges from '../shared/dashboardDateRanges/dashboardDateRanges';
import Invoices from './invoices';
import AddInvoiceDialog from './addInvoiceDialog';
import { useStateValue } from '../../providers/stateProvider';
import { dateButtons, iconDateRange } from '../shared/dateRangeConverter';
import {
  TitleGridContainer, TitleTextGridContainer, TextWrapper, PrimaryTitle,
  SecondaryTitle, ButtonsWrapper, SubContentHandler
} from './invoices.styles';
import {
  SearchButton,
  SearchContainer,
  SearchImage,
  SearchTextField
} from '../orders/newOrder/newOrderContainer.styles';
import SearchIcon from '../../assets/images/Search Icon.png';
import { OrderButton, OrderButtonText } from '../orders/shared/titleGrid.styles';
import BusinessActionTypes from '../../providers/reducers/business/businessTypes';

const InvoicesContainer = () => {
  const [{
    business: {
      businessId,
      dashboardState: {
        active,
        startDate,
        endDate,
        range
      }
    }
  }, dispatch] = Object.values(useStateValue());
  const navigate = useNavigate();
  const handleDateButtons = (event) => {
    const { start, end, dateRange } = dateButtons(event);
    dispatch({
      type: BusinessActionTypes.UPDATE_DASHBOARD_STATE,
      payload: {
        active: event,
        startDate: start,
        endDate: end,
        range: dateRange
      }
    });
  };

  const handleIconDateRange = (direction) => {
    const { start, end, dateRange } = iconDateRange(direction, active, startDate, endDate);
    dispatch({
      type: BusinessActionTypes.UPDATE_DASHBOARD_STATE,
      payload: {
        startDate: start,
        endDate: end,
        range: dateRange
      }
    });
  };

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  // const pageCount = sessionStorage.getItem('rowsPerPage') || 20;
  const initialState = {
    currentPage: 1,
    currentPageCount: pageCount,
    text: '',
  };

  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const { currentPage, currentPageCount, text } = state;
  const [searchable, setSearchable] = useState('');

  // const handleChangePage = (_, newPage) => {
  //   setState({ ...state, currentPage: newPage + 1 });
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   sessionStorage.setItem('rowsPerPage', +event.target.value);
  //   setState({ ...state, currentPageCount: +event.target.value });
  // };

  const handleSearchTextUpdate = ({ target: { value } }) => {
    setSearchable(value);
  };

  const handleRowClick = (id) => {
    navigate(`/invoices/${id}/details`);
  };

  return (
    <MainContent>
      <Grid container style={{ padding: '30px 10px 0' }}>
        <TitleGridContainer container item>
          <TitleTextGridContainer item lg={4} md={3} sm={8}>
            <TextWrapper>
              <PrimaryTitle variant="h5">Invoices</PrimaryTitle>
              <SecondaryTitle>
                Order Invoices Added To The Inventory
              </SecondaryTitle>
            </TextWrapper>
          </TitleTextGridContainer>
          <Hidden mdDown>
            <Grid container lg={5} md={7} alignItems="center">
              <DashboardDateRanges
                active={active}
                xs={12}
                range={range}
                handleDateButtons={handleDateButtons}
                handleIconDateRange={handleIconDateRange}
              />
            </Grid>
          </Hidden>
          <Grid item lg={2} md={2} sm={4} justifyContent="flex-end">
            <ButtonsWrapper>
              <OrderButton onClick={() => setInvoiceDialogOpen(true)}>
                <OrderButtonText>Add New Invoice</OrderButtonText>
              </OrderButton>
            </ButtonsWrapper>
          </Grid>
          <Hidden mdUp>
            <Grid item container justifyContent="center" style={{ marginTop: '2rem' }}>
              <Grid container sm={7} alignItems="center">
                <DashboardDateRanges
                  active={active}
                  xs={12}
                  range={range}
                  handleDateButtons={handleDateButtons}
                  handleIconDateRange={handleIconDateRange}
                />
              </Grid>
            </Grid>
          </Hidden>
        </TitleGridContainer>
      </Grid>
      <SubContentHandler container xs={12} alignItems="center">
        <Grid item sm={10}>
          <SearchContainer container item xs={12}>
            <SearchTextField
              variant="outlined"
              label=""
              autoFocus
              value={searchable}
              onChange={(e) => handleSearchTextUpdate(e)}
              placeholder="Search invoices"
              fullWidth
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <SearchButton
                      aria-label="search products"
                      edge="end"
                      onClick={() => setState({ ...state, text: searchable })}
                    >
                      <SearchImage alt="search icon" src={SearchIcon} />
                    </SearchButton>
                  </InputAdornment>
              }}
            />
          </SearchContainer>
        </Grid>
      </SubContentHandler>
      <Invoices
        // handleChangePage={handleChangePage}
        // handleChangeRowsPerPage={handleChangeRowsPerPage}
        setPageCount={setPageCount}
        setPageNumber={setPageNumber}
        handleRowClick={handleRowClick}
        page={pageNumber}
        text={text}
        currentPageCount={pageCount}
      />

      <AddInvoiceDialog
        bizId={businessId || localStorage.getItem('ogarx_business_id')}
        dialogOpen={invoiceDialogOpen}
        closeDialog={() => setInvoiceDialogOpen(false)}
      />
    </MainContent>
  );
};

export default InvoicesContainer;
