import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Hidden } from '@mui/material';
import {
  AuthGridContainer, BackgroundImage, InnerGridContainer, Logo, PharmIQLogo,
  LogoTypo, LogoGridContainer, InputsGridContainer, InputsGrid, LogoSubTypo,
  InputsHeaderTypo, InputsFooter, OgaImage, InputsSubheaderTypo
} from './authContainer.styles';
import BannerImg from '../../assets/images/ogaPharmacyImg.png';
import Register from './register';
import Login from './login';
import PasswordReset from './passwordReset';

import { useStateValue } from '../../providers/stateProvider';

const AuthContainer = () => {
  const { pathname } = useLocation();
  const [, dispatch] = Object.values(useStateValue());

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, [dispatch]);

  const returnHeader = () => {
    switch (pathname) {
      case '/':
        return 'Sign in to OGApharmacy';
      case '/register':
        return 'Sign up on OGApharmacy';
      default:
        return 'Forgot Password?';
    }
  };

  const returnComponent = () => {
    switch (pathname) {
      case '/':
        return <Login />;
      case '/register':
        return <Register />;
      default:
        return <PasswordReset />;
    }
  };

  return (
    <AuthGridContainer container>
      <Hidden mdDown>
        <LogoGridContainer item container xs={6}>
          <Logo alt="PharmIQ" src={BannerImg} />
          <LogoTypo variant="h3">
            Quality Guaranteed
          </LogoTypo>
          <LogoSubTypo variant="caption">
            Your reliable source for trusted pharmaceutical products
          </LogoSubTypo>
        </LogoGridContainer>
      </Hidden>
      <InputsGridContainer item container xs={12} md={6} lg={6}>
        <InputsGrid item container>
          <PharmIQLogo />
          <InputsHeaderTypo variant="h5">
            {returnHeader()}
          </InputsHeaderTypo>
          <InputsSubheaderTypo variant="body2" color="text.secondary">
            {pathname === '/password-reset'
              ? 'Please enter the email address you registered with, you will be sent a link to reset your password.'
              : 'Welcome back to retail POS, make sales with our platform.'}
          </InputsSubheaderTypo>
          {returnComponent()}
        </InputsGrid>
      </InputsGridContainer>
    </AuthGridContainer>
  );
};

export default AuthContainer;
