import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Checkbox, Button, DialogActions, DialogContent, DialogContentText,
  FormControlLabel, FormGroup
} from '@mui/material';
import { ContainerHead, Content, CDialog } from './TermsAndConditions.style';

const TermsAndConditionsPopUp = ({
  open, setOpen, handleContinue
}) => {
  const [checked, setChecked] = useState(false);
  const [terms, setTerms] = useState(true);

  const handleClose = () => {
    setOpen(false);
    handleContinue();
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      setTerms(true);
    } else {
      setTerms(false);
    }
  };
  return (
    <CDialog
      open={open}
      onClose={handleClose}
    >
      <ContainerHead>
        <h1>TERMS AND CONDITIONS</h1>
      </ContainerHead>
      <DialogContent>
        <Content>
          <DialogContentText
            id="scroll-dialog-description"
          >
            <div>
              <h2>OGApharmacy Procurement Marketplace</h2>
              <p>
                Please note that payment/credit terms are noted in the OGApharmacy Formulary List.
                <br />
                Please send notification within 24 hours to the OGApharmacy representative in the case of any supply discrepancies or other cconcers. No refunds or products received in good condition.
              </p>
              <br />
              <span>Payment details are:</span>
              <br />
              <span>
                Account name:
                <b>Banyi Lifestores Pharmacy Limited</b>
              </span>
              <br />
              <span>
                Bank name:
                <b>Zenith Bank Plc.</b>
              </span>
              <br />
              <span>
                Account number:
                <b>1016914552</b>
              </span>
            </div>
            <div>
              <h2>ERP - Pharmacy Management Software</h2>

              <p>
                These terms and conditions create a contract between you (the “Customer”) and Lifestores (the “Agreement”). By using the Services, you agree to comply with and be bound by this Agreement, and agree that you have read and understood them and the documents they refer to. If you do not agree with these Terms and Conditions, you must not access or use the Services.
              </p>
              <ul>
                <li>
                  <b>DEFINITIONS</b>
                  <ul>
                    <li>
                      “Affiliate” means any legal entity in which Lifestores or Customer, directly or indirectly, holds more than fifty percent (50% of the entity’s shares or voting rights. Any legal entity will be considered an Affiliate as long as that interest is maintained.
                    </li>
                    <li>“Agreement” means these terms and conditions as amended, updated and/or substituted from time to time.</li>
                    <li>“Authorized User” means any individual to whom Customer grants access authorization to use the Service that is an employee, agent, contractor or representative of Customer, Customer&apos;s Affiliates, or Customer’s and Customer’s Affiliates’ Business Partners.</li>
                    <li>“Business Day” means a day other than a Saturday, Sunday or public holiday in Nigeria when banks in Lagos are open for business.</li>
                    <li>“Business Partner” means a legal entity that requires use of the Service in connection with Customer’s and its Affiliates’ internal business operations. These may include customers, distributors, service providers and/or suppliers of Customer and its Affiliates.</li>
                    <li>“Customer” means the principal corporate entity, whose authorized representative</li>
                    <li>“Confidential Information” means all information which the disclosing party protects against unrestricted disclosure to others that (i) the disclosing party or its representatives designates as confidential and/or internal and/or proprietary at the time of disclosure, or (ii) should reasonably be understood to be confidential at the time of disclosure given the nature of the information and the circumstances surrounding its disclosure.</li>
                    <li>“Customer Data” means the data inputted by the Customer, Authorised Users, or Lifestores on the Customer&apos;s behalf for the purpose of using the Services or facilitating the Customer&apos;s use of the Services as well as any data that Customer derives from its use of the Service (e.g. Customer-specific reports). Customer Data and its derivatives will not include Lifestores’ Confidential Information.</li>
                    <li>“Documentation” means the document made available to the Customer by Lifestores online which sets out a description of the Services and the user instructions for the Services.</li>
                    <li>“Intellectual Property Rights” means patents of any type, design rights, utility models or other similar invention rights, copyrights and related rights, trade secret, know-how or confidentiality rights, trademarks, trade names and service marks and any other intangible property rights, whether registered or unregistered, including applications (or rights to apply) and registrations for any of the foregoing, in any country, arising under statutory or common law or by contract and whether or not perfected, now existing or hereafter filed, issued, or acquired.</li>
                    <li>“Lifestores” means the OGApharmacy division of Banyi Life Stores Pharmacy Limited, or any successor entity thereto or Affiliate entity thereof, through which the PharmIQ Software is owned, managed and controlled and, which is responsible for providing the Services.</li>
                    <li>“Lifestores Materials” mean any materials (including statistical reports) provided or developed by Lifestores (independently or with Customer’s cooperation) in the course of performance under the Agreement, including in the delivery of any support to Customer. Lifestores Materials do not include the Customer Data, Customer Confidential Information or the Service.</li>
                    <li>“Normal Business Hours” means 8.00 am to 6.00 pm local Nigerian time, each Business Day.</li>
                    <li>“Services” mean the subscription services provided by Lifestores to the Customer under this Agreement, as more particularly described in the Documentation.</li>
                    <li>“Software” means the online software applications provided by Lifestores as part of the Services.</li>
                  </ul>
                </li>
                <li>
                  <b>USAGE RIGHTS AND RESTRICTIONS </b>
                  <ul>
                    <li>
                      Grant of Rights
                      <br />
                      Lifestores grants to Customer a non-exclusive non-transferable right, without the right to grant sublicences, to permit the Authorised Users to use the Services and the Documentation solely for Customer’s and its Affiliates’ internal business operations.
                    </li>
                    <li>
                      Authorized Users
                      <br />
                      Customer may permit Authorized Users to use the Service. Usage is limited to the usage metrics and volumes stated in the Documentation. Access credentials for the Service may not be used by more than one individual but may be transferred from one individual to another if the original user is no longer permitted to use the Service. Customer is responsible for breaches of the Agreement caused by Authorized Users.
                    </li>
                    <li>
                      Suspension of Service
                      <br />
                      Lifestores may suspend or limit use of the Service if continued use may result in material harm to the Service or its users. Lifestores will promptly notify Customer of the suspension or limitation. Lifestores will limit a suspension or limitation in time and scope as reasonably possible under the circumstances.
                    </li>
                    <li>
                      {' '}
                      Third Party Access
                      <br />
                      The Service may include integrations with web services made available by third parties that are accessed through the Service and subject to terms and conditions with those third parties. These third-party services are not part of the Service and the Agreement does not apply to them.
                    </li>
                    <li>
                      Restrictions
                      <br />
                      The Customer shall not:
                      <ul>
                        <li>
                          except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties and except to the extent
                          expressly permitted under this agreement:
                          <ul>
                            <li>
                              attempt to copy, modify, duplicate, create derivative works from, frame, mirror,
                              republish, download, display, transmit, or distribute all or any portion of the Software and/or Documentation (as applicable) in any
                              form or media or by any means; or
                            </li>
                            <li>
                              attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to
                              human-perceivable form all or any part of the Software; or
                            </li>
                          </ul>
                        </li>
                        <li>access all or any part of the Services and Documentation in order to build a product or service which competes with the Services and/or the Documentation; or</li>
                        <li>use the Services and/or Documentation to provide services to third parties; or</li>
                        <li>license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services and/or Documentation available to any third party except the Authorised Users, or</li>
                        <li>attempt to obtain, or assist third parties in obtaining, access to the Services and/or Documentation; or</li>
                        <li>introduce or permit the introduction of, any Virus into Lifestores’ network and information systems.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>SERVICES</b>
                  <ul>
                    <li>Lifestores shall, provide the Services and make available the Documentation to the Customer on and subject to the terms of this agreement.</li>
                    <li>
                      Lifestores shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for
                      <ul>
                        <li>planned maintenance carried out during the maintenance window of [10.00 pm to 2.00 am]; and</li>
                        <li>unscheduled maintenance performed outside Normal Business Hours, provided that Lifestores has used reasonable endeavours to give the Customer at least [[6] Normal Business Hours&apos;] notice in advance.</li>
                      </ul>
                    </li>
                    <li>Lifestores will, as part of the Services provide the Customer with customer support services during Normal Business Hours</li>
                  </ul>
                </li>
                <li>
                  <b>CUSTOMER AND PERSONAL DATA</b>
                  <br />
                  <ul>
                    <li>
                      Customer Data
                      <br />
                      Customer is responsible for the Customer Data and entering it into the Service. Customer grants to Lifestores (and its Affiliates) a non-exclusive right to process and use Customer Data to provide and support the Service and as set out in the Agreement.
                    </li>
                    <li>
                      Personal Data
                      <br />
                      Customer will collect and maintain all personal data contained in the Customer Data in compliance with applicable data privacy and protection laws.
                    </li>
                    <li>
                      Security
                      <br />
                      Customer will maintain reasonable security standards for its Authorized Users’ use of the Service.
                    </li>
                    <li>
                      Access to Customer Data
                      <br />
                      Customer can access its Customer Data at any time. Customer may export and retrieve its Customer Data in a standard format. Export and retrieval may be subject to technical limitations
                    </li>
                    <li>
                      Processing Customer Data
                      <br />
                      <ul>
                        <li>
                          By entering into this agreement, Customer consents to (and shall procure all required consents, from its personnel, representatives and agents, in respect of) all actions taken by Lifestores in connection with the processing of Customer Data.
                        </li>
                        <li>Without prejudice to the generality of clause 4.5.1, Customer will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of Customer Data to Lifestores and lawful collection of the same by Lifestores for the duration and purposes of this Agreement.</li>
                      </ul>
                    </li>

                  </ul>
                </li>
                <li>
                  <b>THIRD PARTY PROVIDERS</b>
                  <br />
                  Customer acknowledges that the Services may enable or assist it to access the website content of, correspond with, third parties via third-party websites and that it does so solely at its own risk. Lifestores makes no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website
                </li>
                <li>
                  <b>LIFESTORES’ OBLIGATIONS</b>
                  <br />
                  <ul>
                    <li>Lifestores undertakes that the Services will be performed substantially in accordance with the Documentation and with reasonable skill and care.</li>
                    <li>The undertaking in 6.1 shall not apply to the extent of any non-conformance which is caused by use of the Services contrary to Lifestores’ instructions, or modification or alteration of the Services by any party other than Lifestores or the Lifestores’ duly authorised contractors or agents.</li>

                  </ul>
                </li>
                <li>
                  <b>CUSTOMER&apos;S OBLIGATIONS</b>
                  <br />
                  <ul>
                    <li>
                      The Customer shall:
                      <br />
                      <ul>
                        <li>
                          provide Lifestores with:
                          <br />
                          <ul>
                            <li>all necessary co-operation in relation to this agreement; and</li>
                            <li>all necessary access to such information as may be required by Lifestores;</li>
                            <br />
                            in order to provide the Services, including but not limited to Customer Data, security access information and configuration services;
                          </ul>
                        </li>
                        <li>without affecting its other obligations under this agreement, comply with all applicable laws and regulations with respect to its activities under this agreement;</li>
                        <li>ensure that the Authorised Users use the Services and the Documentation in accordance with the terms and conditions of this agreement and shall be responsible for any Authorised User&apos;s breach of this agreement;</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>WARRANTIES</b>
                  {' '}
                  <br />
                  <ul>
                    <li>
                      Compliance with Law
                      <br />
                      Each party warrants its current and continuing compliance with all laws and regulations applicable to it in connection with:
                      <ul>
                        <li>in the case of Lifestores, the operation of Lifestores’ business as it relates to the Service, and </li>
                        <li>in the case of Customer, the Customer Data and Customer’s use of the Service. </li>
                      </ul>
                    </li>
                    <li>
                      Disclaimer
                      <br />
                      Except as expressly provided in the Agreement, neither Lifestores nor its subcontractors make any representation or warranties, express or implied, statutory or otherwise, regarding any matter, including the merchantability, suitability, originality, or fitness for a particular use or purpose, non-infringement or results to be derived from the use of or integration with any products or services provided under the Agreement, or that the operation of any products or services will be secure, uninterrupted or error free.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>INTELLECTUAL PROPERTY RIGHTS</b>
                  <br />
                  <ul>
                    <li>
                      Lifestores Ownership
                      {' '}
                      <br />
                      Except for any rights expressly granted to Customer under the Agreement, Lifestores owns all Intellectual Property Rights in and derivative works of:
                      <br />
                      <ul>
                        <li>the Service,</li>
                        <li>Lifestores Materials</li>
                        <li>Documentation, and</li>
                        <li>any design contributions, related knowledge or processes, whether or not developed for Customer. </li>
                      </ul>
                    </li>
                    <li>
                      Customer Ownership
                      <br />
                      Customer retains all rights in and related to the Customer Data. Lifestores may use Customer-provided trademarks solely to provide and support the Service.
                    </li>
                    <li>
                      Acceptable Use Policy
                      <br />
                      With respect to the Service, Customer will not:
                      <ul>
                        <li>enter, store, or transfer any content or data on or via the Service that is unlawful or infringes any Intellectual Property Rights; or</li>
                        <li>remove Lifestores’ copyright and authorship notices </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>CONFIDENTIALITY</b>
                  {' '}
                  <br />
                  <ul>
                    <li>
                      Use of Confidential Information
                      <ul>
                        <li>
                          The receiving party shall:
                          <ul>
                            <li>maintain all Confidential Information of the disclosing party in strict confidence, taking steps to protect the disclosing party&apos;s Confidential Information substantially similar to those steps that the receiving party takes to protect its own Confidential Information, which shall not be less than a reasonable standard of care; </li>
                            <li>not disclose or reveal any Confidential Information of the disclosing party to any person other than its Representatives whose access is necessary to enable it to exercise its rights or perform its obligations under the Agreement and who are under obligations of confidentiality substantially similar to those in clause 10;</li>
                            <li>not use or reproduce any Confidential Information of the disclosing party for any purpose outside the scope of the Agreement;</li>
                            <li>retain any and all confidential, internal, or proprietary notices or legends which appear on the original and on any reproductions.</li>

                          </ul>
                        </li>
                        <li>Customer shall not disclose any information about the Agreement, its terms and conditions, the pricing or any other related facts to any third party. </li>
                        <li>Confidential Information of either party disclosed prior to execution of the Agreement will be subject to Clause 10.</li>
                      </ul>
                    </li>
                    <li>
                      Compelled Disclosure
                      <br />
                      The receiving party may disclose the disclosing party&apos;s Confidential Information to the extent required by law, regulation, court order or regulatory agency; provided, that the receiving party required to make such a disclosure uses reasonable efforts to give the disclosing party reasonable prior notice of such required disclosure (to the extent legally permitted) and provides reasonable assistance in contesting the required disclosure, at the request and cost of the disclosing party. The receiving party and its Representatives shall use commercially reasonable efforts to disclose only
                      that portion of the Confidential Information which is legally requested to be disclosed and shall request that all Confidential Information that is so disclosed is accorded confidential treatment.
                    </li>
                    <li>
                      Exceptions
                      <br />
                      The restrictions on use or disclosure of Confidential Information will not apply to any Confidential Information that:
                      <ul>
                        <li>is independently developed by the receiving party without reference to the disclosing party’s Confidential Information; or </li>
                        <li>has become generally known or available to the public through no act or omission by the receiving party; or </li>
                        <li>at the time of disclosure, was known to the receiving party free of confidentiality restrictions; or</li>
                        <li>is lawfully acquired free of restriction by the receiving party from a third party having the right to furnish such Confidential Information, or </li>
                        <li>the disclosing party agrees in writing is free of confidentiality restrictions.</li>
                      </ul>
                    </li>
                    <li>
                      Destruction and Return of Confidential Information
                      <br />
                      Upon the disclosing party&apos;s request, the receiving party shall promptly destroy or return the disclosing party&apos;s Confidential Information, including copies and reproductions of it. The obligation to destroy or return Confidential Information shall not apply:
                      <ul>
                        <li>if legal proceedings related to the Confidential Information prohibit its return or destruction, until the proceedings are settled or a final judgment is rendered;</li>
                        <li>to Confidential Information held in archive or back-up systems under general systems archiving or backup policies; or </li>
                        <li>to Confidential Information the receiving party is legally entitled or required to retain.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>MISCELLANEOUS</b>
                  <br />
                  Changes to these Terms and Conditions: Lifestores may post changes to these Terms and Conditions via the Services, and such changes shall be effective from the next time you use the Services.

                  <br />
                  <br />
                  Governing law: these terms are governed by Nigerian law

                </li>
              </ul>
            </div>
          </DialogContentText>
        </Content>
      </DialogContent>

      <DialogActions style={{ margin: '1rem' }}>
        <FormGroup>
          <FormControlLabel
            style={{ backgroundColor: '#ECF6FF', fontSize: '14px' }}
            control={(
              <Checkbox
                checked={checked}
                onChange={handleChange}
                className="mpfe-uat-register-checkbox"
              />
            )}
            label="By ticking this box and clicking on agree and continue, you confirm that you have read and understood the terms of the PharmIQ Customer Agreement"
          />
          <Grid item container style={{ margin: '1rem 0 0' }}>
            <Button
              disabled={terms}
              onClick={handleClose}
              type="submit"
              variant="contained"
              style={{ width: '13rem' }}
              className="mpfe-uat-register-submit"
            >
              Agree & Create Account
            </Button>
          </Grid>
        </FormGroup>
      </DialogActions>
    </CDialog>

  );
};

TermsAndConditionsPopUp.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired
};

TermsAndConditionsPopUp.defaultProps = {
  open: true,
};

export default TermsAndConditionsPopUp;
