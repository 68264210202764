import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Button, Dialog, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import { FIND_SUPPLIERS_QUERY, ALL_SUPPLIERS_QUERY } from '../../../queries/suppliers';
import {
  CREATE_BATCH_MUTATION, UPDATE_BATCH_MUTATION
} from '../../../mutations/products';
import {
  HeaderStyled, FooterStyled,
  DialogTitleStyled, GridStyled, DialogContentStyled, CloseStyled
} from '../../shared/uploadProduct/individual/individualProductDialog.styles';
import SearchPopper from './searchPopper';
import FormTemplate from '../../shared/fieldTemplate/template';
import SuccessDialog from '../../shared/successDialog';

const defaultReceived = moment().format('YYYY-MM-DD');
const defaultExp = moment().add(1, 'years').format('YYYY-MM-DD');

const initialState = {
  batchNo: '',
  quantity: 0,
  dateReceived: defaultReceived,
  expiryDate: defaultExp,
  unitCost: 0,
  supplierId: ''
};
const SingleBatchDialog = ({
  open, onClose, brandName, productId, batchToEdit, refetch
}) => {
  const [batch, setBatch] = useState(initialState);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchedSuppliers, setSearchedSuppliers] = useState([]);
  const [openPopper, setOpenPopper] = useState(false);

  const textRef = useRef();

  const { data: allSuppliersData } = useQuery(ALL_SUPPLIERS_QUERY, {
    variables: {
      onlyBusinessSuppliers: true
    }
  });
  const updatedSuppliers = allSuppliersData?.allSuppliers?.map((newSupplier) => {
    const { id, name } = newSupplier;
    return {
      id, name
    };
  });

  useEffect(() => {
    if (batchToEdit) {
      const {
        batchNo, dateRecieved: dR, expiryDate: eD, supplier: { name },
        quantityInStock: quantity, orderCost: unitCost
      } = batchToEdit;
      const dateReceived = moment(dR).format('YYYY-MM-DD');
      const expiryDate = moment(eD).format('YYYY-MM-DD');
      const supplier = name.split(' ')[0];
      setBatch({
        ...batch, batchNo, quantity, dateReceived, expiryDate,
        unitCost, supplier
      });
    }
  }, [batchToEdit]);

  const [findSupplier, { loading, data }] = useLazyQuery(FIND_SUPPLIERS_QUERY);
  useEffect(() => {
    if (data && data.findSupplier) {
      const suppliers = data.findSupplier
        .filter((supplier) => !!supplier)
        .map(({ id, name }) => ({ id, name }));
      setSearchedSuppliers(suppliers);
    }
  }, [data, loading]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setBatch({ ...batch, [name]: value });

    if (name === 'supplier' && value.length > 2) {
      findSupplier({
        fetchPolicy: 'no-cache',
        variables: { search: value }
      });
      setOpenPopper(true);
    }
  };

  const handleClose = () => {
    onClose();
    setBatch(initialState);
  };

  const handleSetSupplier = (sup) => {
    const { id, name } = sup;
    setBatch({ ...batch, supplierId: id, supplier: name });
    setOpenPopper(false);
  };

  const [createBatch] = useMutation(CREATE_BATCH_MUTATION);
  const [updateBatch] = useMutation(UPDATE_BATCH_MUTATION);

  const validateFields = () => {
    const {
      batchNo, quantity, dateReceivedField, expiryDate, unitCost, supplierId
    } = batch;
    if (batchNo === '' || quantity === 0 || quantity === '' || dateReceivedField === ''
      || expiryDate === '' || unitCost === 0 || unitCost === '' || supplierId === '') {
      return true;
    }
    return false;
  };
  const handleBatch = () => {
    if (validateFields()) return toast.error('Kindly validate all input fields');
    setIsLoading(true);
    if (!batchToEdit) {
      createBatch({
        variables: { ...batch, productId }
      }).then(({ data: { createBatch: { message } } }) => {
        // toast.success(message);
        setIsLoading(false);
        setSuccessOpenDialog(true);
        if (refetch) refetch();
        handleClose();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.message);
      });
    } else {
      const { id: batchId } = batchToEdit;
      const {
        batchNo, dateRecieved, expiryDate, quantity, unitCost
      } = batch;
      updateBatch({
        variables: {
          batchId, batchNo, dateRecieved, expiryDate, quantity, unitCost
        }
      }).then(({ data: { updateBatch: { message } } }) => {
        toast.success(message);
        setIsLoading(false);
        if (refetch) refetch();
        handleClose();
      }).catch((err) => {
        toast.error(err?.message);
      });
    }
  };

  const batchNameField = {
    type: 'text', name: 'batchNo', label: 'Batch No', 'data-testid': 'batchNo', required: false, value: batch?.batchNo,
    placeholder: 'Enter Batch No', onChange: handleChange
  };
  const quantityField = {
    type: 'number', name: 'quantity', label: 'Quantity', 'data-testid': 'quantity', required: false, value: batch?.quantity,
    placeholder: 'Enter Quantity', onChange: handleChange
  };
  const orderCostField = {
    type: 'number', name: 'unitCost', label: 'Order Cost', 'data-testid': 'unitCost', required: false, value: batch?.unitCost,
    placeholder: 'Enter Order Cost', onChange: handleChange
  };
  const dateReceivedField = {
    type: 'date', name: 'dateReceived', label: 'Date Received', 'data-testid': 'dateReceived', required: false, value: batch?.dateReceived,
    placeholder: 'Enter Date Received', onChange: handleChange
  };
  const supplierField = {
    type: 'select', name: 'supplierId', label: 'Supplier', 'data-testid': 'supplierId', required: false, value: batch?.supplierId,
    options: updatedSuppliers,
    placeholder: 'Enter Order Cost', onChange: handleChange
  };
  const expireDateField = {
    type: 'date', name: 'expiryDate', label: 'Expire Date', 'data-testid': 'expiryDate', required: false, value: batch?.expiryDate,
    placeholder: 'Enter Expire Date', onChange: handleChange
  };

  return (
    <>
      <Dialog
        open={open}
        data-testid="singleBatchDialog"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentStyled>
          <CloseStyled>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </CloseStyled>
          <HeaderStyled>
            <DialogTitleStyled>{brandName}</DialogTitleStyled>
          </HeaderStyled>
          <GridStyled>
            <Grid item xl={6}>
              <FormTemplate fields={batchNameField} />
            </Grid>
            <Grid item xl={6}>
              <FormTemplate fields={quantityField} />
            </Grid>
          </GridStyled>
          <GridStyled>
            <Grid item xl={6}>
              <FormTemplate fields={orderCostField} />
            </Grid>
            <Grid item xl={6}>
              <FormTemplate fields={dateReceivedField} />
            </Grid>
          </GridStyled>
          <GridStyled>
            <Grid item xl={6}>
              <FormTemplate fields={supplierField} />
            </Grid>
            <Grid item xl={6}>
              <FormTemplate fields={expireDateField} />
            </Grid>
          </GridStyled>
          <FooterStyled>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" data-testid="batch_button" disabled={isLoading} onClick={handleBatch}>
              {batchToEdit ? 'Edit batch' : 'Add batch'}
            </Button>
          </FooterStyled>
        </DialogContentStyled>
      </Dialog>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="New Batch Added!"
        desc="Hi Pharm, Your new batch has been successfully added"
        option="ok"
        refetch={refetch}
      />
      <SearchPopper
        open={openPopper}
        anchorEl={textRef.current}
        handleClose={() => setOpenPopper(false)}
        searchedSuppliers={searchedSuppliers}
        handleSetSupplier={handleSetSupplier}
      />
    </>
  );
};

SingleBatchDialog.propTypes = {
  brandName: PropTypes.string,
  productId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  refetch: PropTypes.func.isRequired,
  batchToEdit: PropTypes.instanceOf(Object).isRequired,
};

SingleBatchDialog.defaultProps = {
  brandName: '',
  productId: '',
  onClose: () => { },
};

export default SingleBatchDialog;
