import React from 'react';
import PropTypes from 'prop-types';
import HomeIcon from '@mui/icons-material/Home';
import { useMediaQuery } from '@mui/material';
import {
  StatisticWrapper, StatisticCard, StatisticGrid, StatisticHeader,
  StatisticContent
} from './smallStatisticCard.styles';

export default function SmallStatisticCard({
  statistic
}) {
  const isSmall = useMediaQuery('(max-width: 991px)');
  const {
    icon, title, value, size
  } = statistic;

  return (
    <StatisticWrapper container item md={size || 3} xs={12}>
      <StatisticCard item container>
        <StatisticGrid item container>
          <statistic.icon style={{ fontSize: isSmall ? '6rem' : '2.2rem' }} />
          <StatisticHeader item>{title}</StatisticHeader>
        </StatisticGrid>
        <StatisticContent item>{value}</StatisticContent>
      </StatisticCard>
    </StatisticWrapper>
  );
}

SmallStatisticCard.propTypes = {
  statistic: PropTypes.instanceOf(Object),
  icon: PropTypes.instanceOf(Object),
  value: PropTypes.number,
};
SmallStatisticCard.defaultProps = {
  statistic: {},
  icon: HomeIcon,
  value: 0,
};
