import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Grid, MenuItem, useMediaQuery, Box
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { KeyboardArrowLeft } from '@mui/icons-material';
import CustomNewTextField from '../../../shared/CustomNewTextField';
import CustomButton from '../../../customComponents/customButton';
import CTablePagination from '../../../customComponents/cTablePagination';
import {
  FiltersGrid, ButtonsGrid, MobileButtonGrid, DispatchButton, CreateButton,
  MobileGrid, FilterBox, FilterImg, FooterGrid, Header, HeaderWraper, MenuButton, ExportIconStyled, ExportText
} from './ordersFilter.styles';
import OrderDropdown from '../../../shared/orderDropdown/orderDropdown';
import AffiliateModal from '../affiliate-modal';
import { OgaToken } from '../../../../graphql/token';
import { useStateValue } from '../../../../providers/stateProvider';
import MobileFilter from './mobileFilter';
import filterImg from '../../../../assets/images/marketOrder/filter-search.png';
import MobileReturnRow from './mobileReturnRow';
import ProductsTableLoader from '../../../customComponents/loaders/productsTableLoader';
import CustomExportButton from '../../../downloads/exportModule/exportComponent';
import CustomDateRanges from '../../../shared/customDateRanges/customDateRanges';

const OrdersFilter = ({
  state, handleFilterChange, handleSetFilter, orderItems, loading, handleClear,
  count, page, onChangePage, rowsPerPage, onChangeRowsPerPage, handleDateSubmit, refetch
}) => {
  const role = localStorage.getItem('oga_user_role');
  const [orderButtonEl, setOrderButtonEl] = useState(null);
  const [action, setAction] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [displayMobileFilter, setDisplayMobileFilter] = useState(false);
  const [exportVariable, setExportVariable] = useState({});
  const isAdmin = role === 'oga-pharmacy-admin' || role === 'chain-manager';
  const isSmall = useMediaQuery('(max-width: 991px)');
  const handleMobileFilter = () => {
    setDisplayMobileFilter(!displayMobileFilter);
  };

  const navigate = useNavigate();
  const [{
    user: { businessUserId, allowedPermissionsMap: { userPermissions } }
  }] = Object.values(useStateValue());

  const handleOrderButtonClick = (event) => {
    setAction(true);
    navigate('/new-order');
    // setOrderButtonEl(orderButtonEl ? null : event.currentTarget);
  };

  useEffect(() => {
    const {
      orderNo, status, orderStatus, dateFrom, dateTo, affiliate,
    } = state;
    const dataFilter = {
      status, metaStatus: orderStatus === 'All' ? '' : orderStatus, dateFrom, orderNo, dateTo, businessName: affiliate
    };
    Object.keys(dataFilter).forEach((key) => {
      if (dataFilter[key] === '') {
        delete dataFilter[key];
      }
    });
  }, [state]);

  useEffect(() => {
    const {
      dateFrom, dateTo,
    } = state;
    setExportVariable({ dateFrom, dateTo });
  }, [state]);

  const handleOrdersDownload = () => {
    const {
      orderNo, status, orderStatus, dateFrom, dateTo, affiliate,
    } = state;
    const dataFilter = {
      status, metaStatus: orderStatus === 'All' ? '' : orderStatus, dateFrom, orderNo, dateTo, businessName: affiliate
    };
    Object.keys(dataFilter).forEach((key) => {
      if (dataFilter[key] === '') {
        delete dataFilter[key];
      }
    });
    const params = Object.entries(dataFilter).map(([key, value]) => {
      const newKey = key.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
      return `&${newKey}=${value}`;
    });
    const newParams = params.join('');
    window.open(
      `${OgaToken.SERVER_LINK}download_orders_csv/?businessuser_id=${businessUserId}${newParams}`,
      '_blank'
    );
  };
  const handleSelectAffiliate = () => {
    setOpenModal(true);
  };
  const handleDispatchOrders = () => navigate('/dispatch-orders');
  const { status } = state;

  const openOrderStatus = [
    { name: 'All Orders', value: 'All' },
    { name: 'Dispatched', value: 'order_dispatch' },
    { name: 'Order Received', value: 'Received' },
    { name: 'Order Sorted', value: 'Sorted' },
    { name: 'Order Sent', value: 'order_sent' },
    { name: 'Ready For Dispatch', value: 'queued' },
  ];

  const closeOrderStatus = [
    { name: 'All Orders', value: 'All' },
    { name: 'Cancelled', value: 'cancel' },
    { name: 'Closed', value: 'closed' },
    { name: 'Returned', value: 'order_returned' },
  ];

  const orderStatus = status === 'open' ? openOrderStatus : closeOrderStatus;

  const FilterFields = [
    { name: 'affiliate', label: 'Enter affiliate' },
  ];

  const buttons = [
    {
      name: 'Export',
      type: 'secondary',
      handleClick: handleOrdersDownload,
      styles: { width: '10%', height: '2.7rem' },
      disabled: !userPermissions?.includes('manage_orders_export')
    },
  ];

  return (
    <>
      {!displayMobileFilter && (
        !isSmall ? (
          <Grid item container xs={12}>
            <FiltersGrid container item justifyContent="space-between">
              <CustomNewTextField
                field={{ name: 'orderNo', label: 'Enter order number' }}
                state={state}
                handleChange={handleFilterChange}
                style={{ width: '19%' }}
              />
              <CustomNewTextField
                field={{ name: 'orderStatus', label: 'Select order status' }}
                state={state}
                select
                handleChange={handleFilterChange}
                style={{ width: '19%' }}
              >
                {orderStatus.map(({ name, value }) => (
                  <MenuItem key={value} value={value} className={`mpAdmin-uat-ordersPageFilter-${name}`}>
                    {name}
                  </MenuItem>
                ))}
              </CustomNewTextField>
              {FilterFields.map((field) => (
                <CustomNewTextField
                  key={field?.name}
                  field={field}
                  state={state}
                  handleChange={handleFilterChange}
                  style={{ width: '19%', height: '2.9rem' }}
                />
              ))}
              <Grid item styles={{ width: '19%' }}>
                <CustomDateRanges
                  handleSubmit={handleDateSubmit}
                  xs={12}
                  styles={{ height: '3rem', width: '100%', border: '1px solid #bababa' }}
                />
              </Grid>
              <CustomButton
                type="tertiary"
                style={{ width: '10%', height: '2.7rem' }}
                onClick={handleSetFilter}
                disabled={!userPermissions?.includes('manage_orders_set_filter')}
              >
                Set Filter
              </CustomButton>
              {buttons.map(({
                name, type, styles, handleClick, disabled
              }) => (
                <CustomButton
                  key={name}
                  type={type}
                  style={{ ...styles, marginLeft: '.5rem', height: '2.7rem' }}
                  onClick={handleClick}
                  disabled={disabled}
                  className={`mpAdmin-uat-ordersPage-dispatch-${name}`}
                >
                  <ExportIconStyled />
                  <ExportText>{name}</ExportText>
                </CustomButton>
              ))}
            </FiltersGrid>
          </Grid>
        )
          : (
            <Box sx={{ width: '100%' }}>
              <HeaderWraper container>
                <Header onClick={() => navigate(-1)}>
                  <MenuButton>
                    <KeyboardArrowLeft fontSize="large" />
                  </MenuButton>
                  Manage Order
                </Header>
                <FilterBox>
                  <FilterImg src={filterImg} alt="filter" onClick={handleMobileFilter} />
                </FilterBox>
              </HeaderWraper>
              <MobileButtonGrid container spacing={3}>
                <MobileGrid item xs={12}>
                  <DispatchButton
                    variant="outlined"
                    onClick={handleDispatchOrders}
                    className="mpAdmin-uat-ordersPage-dispatch-orders"
                  >
                    Dispatch order
                  </DispatchButton>
                </MobileGrid>
                <MobileGrid item xs={12}>
                  <CreateButton
                    variant="contained"
                    startIcon={<AddOutlinedIcon />}
                    onClick={handleSelectAffiliate}
                    className="mpAdmin-uat-ordersPage-create-+-new-order"
                  >
                    Create new order
                  </CreateButton>
                </MobileGrid>
                <MobileGrid item xs={12}>
                  <CustomButton
                    type="secondary"
                    style={{ width: '100%', border: '1px solid #303030', height: '7rem' }}
                    onClick={handleOrdersDownload}
                    disabled={!userPermissions?.includes('manage_orders_export')}
                    className="mpAdmin-uat-ordersPage-Export"
                  >
                    <ExportText style={{ fontSize: '2.5rem', fontWeight: '600' }}>Export</ExportText>
                  </CustomButton>
                </MobileGrid>
              </MobileButtonGrid>
              {loading ? <ProductsTableLoader /> : (
                orderItems?.map((order, index) => (
                  <MobileReturnRow
                    key={order?.id}
                    row={order}
                    index={index}
                    displayFilter={displayMobileFilter}
                    refetch={refetch}
                  />
                ))
              )}
              <FooterGrid container>
                <CTablePagination
                  rowsPerPageOptions={isSmall ? [100] : [20, 50, 100, 150, 200]}
                  component="div"
                  count={count}
                  page={page - 1}
                  onChangePage={onChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              </FooterGrid>
              <AffiliateModal
                afterMath={handleOrderButtonClick}
                open={openModal}
                handleCloseModal={() => setOpenModal(false)}
              />
              <OrderDropdown
                route="/new-order"
                extraAction={action}
                isAdmin={isAdmin}
                orderButtonEl={orderButtonEl}
                setOrderButtonEl={setOrderButtonEl}
                handleProductButtonClick={handleOrderButtonClick}
              />
            </Box>
          )
      )}
      {displayMobileFilter && (
        <MobileFilter
          state={state}
          handleFilterChange={handleFilterChange}
          handleSetFilter={handleSetFilter}
          handleMobile={handleMobileFilter}
          handleClear={handleClear}
        />
      )}
    </>
  );
};

OrdersFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  orderItems: PropTypes.shape(Object).isRequired,
  loading: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleDateSubmit: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired
};

OrdersFilter.defaultProps = {
  state: {},
};

export default OrdersFilter;
