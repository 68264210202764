import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { MainContainer } from './mainContent.styles';

import { useStateValue } from '../../providers/stateProvider';

const useStyles = makeStyles((theme) => ({
  topSpacing: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.appBar,
    minHeight: '135px',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      minHeight: '72px',
    },
  },
}));

const MainContent = ({ children }) => {
  const [{ navbar: { openSideDrawer } }] = Object.values(useStateValue());
  const classes = useStyles();

  return (
    <MainContainer theme={useTheme()} open={openSideDrawer}>
      <div className={classes.topSpacing} />
      {children}
    </MainContainer>
  );
};

MainContent.propTypes = {
  children: PropTypes.instanceOf(Object)
};

MainContent.defaultProps = {
  children: {}
};

export default MainContent;
