import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import {
  DetailGrid, DetailContentGrid, ImgLogo, DetailHeader, DetailTypo, QuantityGrid,
  ActionButton, ActionTextField, ActionImg, Content, AddButton, AddCartTypo, CircularProgressLoader,
  ShoppingCartImg, Typo, QuantityContentGrid, InputButtonGroup, ContentGrid, ProductVariantText
} from './productDetail.styles';
import { useStateValue } from '../../../../providers/stateProvider';
import { Product } from '../../../../providers/reducers/product/classes/Product';
import { UPDATE_ORDER_MUTATION } from '../../../../mutations/orders';
import CartActionTypes from '../../../../providers/reducers/cart/cartTypes';
import downvote from '../../../../assets/images/marketOrder/Downvote.png';
import cart from '../../../../assets/images/marketOrder/white-cart.png';
import upvote from '../../../../assets/images/marketOrder/Upvote.png';
import currencyFormatter from '../../../../utils/currencyFormatter';

export default function ProductDetail({
  product
}) {
  const {
    id: productId, brandName, meta, marketplaceVersion,
    mktPrice: actualPrice, packSize, productVariant, quantityInStock
  } = new Product(product) || {};
  const obj = product ? JSON.parse(meta) : {};
  const {
    savings,
  } = obj || {};
  const { description, genericName, image, } = marketplaceVersion;
  const [{
    cart: { isManualOrder },
    affiliate: { pricePolicyFactor = 1 }
  }, dispatch] = Object.values(useStateValue());
  const itemPrice = actualPrice * pricePolicyFactor;
  const [counter, setCounter] = useState(0);

  const handleActionButtons = (value) => {
    setCounter(counter + value);
  };
  const handleChange = (value) => {
    setCounter(value);
  };

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER_MUTATION);
  const handleAlertOpen = (msg) => toast(msg);

  const handleAddToCart = () => {
    updateOrder({
      variables: {
        productId,
        quantity: counter,
        isManualOrder
      }
    })
      .then(({ data: response }) => {
        const { message, order } = response?.updateOrderProduct || {};
        dispatch({
          type: CartActionTypes.CART_COUNTER,
          payload: order?.supplierorderSet
        });
        handleAlertOpen(message);
      });
  };

  return (
    <DetailGrid component={Paper} container>
      <DetailContentGrid item lg={6} md={12}>
        <ImgLogo src={image} alt={brandName} />
      </DetailContentGrid>
      <ContentGrid item lg={6} md={12}>
        <Content>
          {quantityInStock
            ? (
              <ProductVariantText>Available in 24hrs</ProductVariantText>
            )
            : (
              <ProductVariantText>Available in 72hrs</ProductVariantText>
            )}
          <DetailHeader> Generic Name </DetailHeader>
          <DetailTypo>
            {genericName}
          </DetailTypo>
          <DetailHeader> Description </DetailHeader>
          <DetailTypo>
            {description}
          </DetailTypo>
          <DetailHeader> Pack Size </DetailHeader>
          <DetailTypo>
            {packSize}
          </DetailTypo>

          <QuantityGrid container justifyContent="space-between">
            <QuantityContentGrid>
              <DetailHeader>Quantity</DetailHeader>
              <InputButtonGroup size="small" aria-label="small outlined button group">
                <ActionButton
                  onClick={() => handleActionButtons(-1)}
                  disabled={counter === 0}
                >
                  <ActionImg src={downvote} alt="remove" />
                </ActionButton>
                <ActionTextField
                  InputProps={{ disableUnderline: true, }}
                  variant="standard"
                  placeholder={0}
                  value={counter || ''}
                  onChange={(e) => handleChange(Number(e.target.value))}
                />
                <ActionButton
                  onClick={() => handleActionButtons(1)}
                >
                  <ActionImg src={upvote} alt="add" />
                </ActionButton>
              </InputButtonGroup>
            </QuantityContentGrid>
            <QuantityContentGrid>
              <DetailHeader>Price</DetailHeader>
              <Typo>
                {`${currencyFormatter(itemPrice)}`}
              </Typo>
            </QuantityContentGrid>
            <QuantityContentGrid>
              <DetailHeader>Savings</DetailHeader>
              <Typo>
                {`${currencyFormatter(savings || 0)}`}
              </Typo>
            </QuantityContentGrid>
          </QuantityGrid>
          <AddButton
            fullWidth
            startIcon={<ShoppingCartImg src={cart} alt="cart" />}
            className="joy-add-to-cart"
            variant="outlined"
            disableElevation
            onClick={handleAddToCart}
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={24}
                thickness={4}
              />
            ) : <AddCartTypo> Add to cart </AddCartTypo>}
          </AddButton>
        </Content>
      </ContentGrid>
    </DetailGrid>
  );
}

ProductDetail.propTypes = {
  product: PropTypes.instanceOf(Object),
};

ProductDetail.defaultProps = {
  product: {}
};
