import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Paper, Table, TableBody } from '@mui/material';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import GoToTop from '../../customComponents/scrollToTop';
import TablePagination from '../../shared/tablePagination';
import { ERP_PRODUCT_QUERY } from '../../../queries/products';
import { FIND_CATEGORY_QUERY } from '../../../queries/categories';
import { BULK_BUY_MUTATION } from '../../../mutations/orders';
import {
  TableContainerWrapper, CustomCheckbox, DivFullWidth
} from './products.styles';
import ReturnRow from './returnRow';
import ProductsFilter from './productsFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import ProductActionTypes from '../../../providers/reducers/product/productTypes';
import { useStateValue } from '../../../providers/stateProvider';
import { Product } from '../../../providers/reducers/product/classes/Product';
import {
  TableComponent, TableColumnHeaders, TableHeader, TableColumnRows, TableColumnRowText
} from '../../shared/tableGlobalStyle.styles';

const erpHeaders = [
  { name: 'Created Date', width: '200px' }, { name: 'Product ID', width: '150px' }, { name: 'Product Name', width: '200px' },
  { name: 'Supplier', width: '250px' }, { name: 'Cost Price', width: '150px' }, { name: 'Selling Price', width: '150px' },
  { name: 'Qty', width: '70px' }, { name: 'Status', width: '150px' }, { name: 'Action', width: '100px' },
];

const Products = ({ setProductState, setSelected, selected }) => {
  const { status: pStatus, productId: pProductId, productName: pProductName } = useParams();

  const initialState = {
    searchText: '',
    searchValue: '',
    productName: '',
    status: pStatus || 'enabled',
    productId: '',
    supplier: '',
    dateFrom: '',
    dateTo: '',
    categoryId: '',
    categories: [],
    filters: { status: pStatus || 'enabled' }
  };

  const [pageCount, setPageCount] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [state, setState] = useState(initialState);
  const [stateRows, setStateRows] = useState([]);

  useEffect(() => {
    const filters = {
      ...state, productName: pProductName, productId: pProductId,
      filters: { status: pStatus || 'enabled', productId: pProductId, productName: pProductName }
    };
    setState(filters);
    setProductState(filters);
  }, [pProductName]);

  const [{
    user,
  }, dispatch] = Object.values(useStateValue());
  const { platform } = user;

  const {
    productName, productId, supplier, dateFrom,
    dateTo, categoryId, filters, status
  } = state;

  // Trigger effect if dateFrom or dateTo is changed
  useEffect(() => {
    setProductState(state);
  }, [dateFrom, dateTo]);

  const {
    loading: catLoading, data: allCategoriesData
  } = useQuery(FIND_CATEGORY_QUERY);

  useEffect(() => {
    if (!catLoading && allCategoriesData?.allCategories?.length) {
      const { allCategories } = allCategoriesData ?? {};
      const cats = allCategories.map(({ categoryName, id }) => (
        { categoryName, id }
      ));
      setState({ ...state, categories: cats });
    }
  }, [catLoading, allCategoriesData]);

  const handleSetFilter = () => {
    const filter = {
      ...state,
      filters: {
        status, productName, supplier, productId, dateFrom, dateTo, category: Number(categoryId)
      }
    };
    setState(filter);
    setProductState(filter);
  };

  const [bulkBuy] = useMutation(BULK_BUY_MUTATION);
  const handleBulkBuy = () => {
    const productIds = selected.map((prodId) => ({
      productId: prodId,
      quantity: 1
    }));
    bulkBuy({
      variables: { productIds }
    })
      .then(({ data }) => {
        const { message } = data?.bulkBuy || {};
        toast.success(message);
      });
  };

  const editProduct = (row) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { productRow: row }
    });
    dispatch({ type: ProductActionTypes.TOGGLE_DIALOG_OPEN });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelections = stateRows.map((product) => product.id);
      return setSelected(newSelections);
    }
    return setSelected([]);
  };

  const handleSelect = (_, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const renderCheckbox = () => (
    <CustomCheckbox
      size="small"
      checked={selected.length === stateRows.length}
      onChange={handleSelectAll}
      inputProps={{ 'aria-label': 'select product' }}
      sx={{ color: '#78AADA' }}
    />
  );

  const returnHeaders = () => erpHeaders.map(({ name, width }) => (
    <TableHeader className={`erp-uat-${name.replace(/ /g, '-')}`} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const updateProductState = (products, totalCount, refetch) => {
    dispatch({
      type: ProductActionTypes.UPDATE_PRODUCT,
      payload: { products, totalCount, refetch }
    });
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
    // Set download filter
    setProductState((_state) => ({
      ..._state,
      [name]: value
    }));
  };

  const variables = {
    search: '',
    supplier: '',
    pageCount,
    pageNumber,
    ...filters,
  };

  const {
    loading, error, data, refetch
  } = useQuery(ERP_PRODUCT_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables
  });

  useEffect(() => {
    if (data && data.erpProducts) {
      const { erpProducts, productsTotalNumber } = data;
      updateProductState(erpProducts, productsTotalNumber, refetch);
      setStateRows(erpProducts);
    }
  }, [data]);

  if (error) return <div>{error.message}</div>;
  const {
    erpProducts = [], productsTotalNumber = 0
  } = data || {};

  const rows = erpProducts?.map((product) => new Product(product, platform));

  return (
    <>
      <ProductsFilter
        state={state}
        setState={setState}
        selected={selected}
        handleFilterChange={handleFilterChange}
        handleSetFilter={handleSetFilter}
        handleBulkBuy={handleBulkBuy}
      />
      <TableContainerWrapper component={Paper}>
        {loading ? <ProductsTableLoader /> : (
          <TableComponent>
            <Table>
              <TableColumnHeaders className="erp-uat-product-table-header">
                <TableHeader style={{ minWidth: '50px' }}>
                  {renderCheckbox()}
                </TableHeader>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {rows.map((_row) => (
                  <ReturnRow
                    key={_row.id}
                    row={_row}
                    user={user}
                    selected={selected}
                    handleSelect={handleSelect}
                    editProduct={editProduct}
                    refetchData={refetch}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
      </TableContainerWrapper>
      <DivFullWidth>
        {productsTotalNumber > 0 && (
          <TablePagination
            total={productsTotalNumber}
            pageCount={pageCount}
            setPageCount={setPageCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </DivFullWidth>
      {erpProducts.length ? <GoToTop /> : ''}
    </>
  );
};

Products.propTypes = {
  setProductState: PropTypes.instanceOf(Object).isRequired,
  setSelected: PropTypes.instanceOf(Array).isRequired,
  selected: PropTypes.instanceOf(Array).isRequired,
};

export default Products;
