import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TableRow } from '@mui/material';
import currencyFormatter from '../../shared/currencyFormatter';
import { TableCellBodyStyled } from './productInfo.style';

const ReturnRow = ({ row }) => {
  const dateReceived = moment(row.dateRecieved).format('DD/MM/YYYY');
  const expiryDate = moment(row.expiryDate).format('DD/MM/YYYY');
  const {
    orderCost, orderPrice, batchNo, quantityInStock, supplier
  } = row;

  return (
    <TableRow>
      <TableCellBodyStyled>{dateReceived}</TableCellBodyStyled>
      <TableCellBodyStyled>{supplier?.name || 'N/A'}</TableCellBodyStyled>
      <TableCellBodyStyled>{batchNo}</TableCellBodyStyled>
      <TableCellBodyStyled>{expiryDate}</TableCellBodyStyled>
      <TableCellBodyStyled>{quantityInStock}</TableCellBodyStyled>
      <TableCellBodyStyled>{`₦ ${currencyFormatter(orderCost)}`}</TableCellBodyStyled>
      <TableCellBodyStyled>{`₦ ${currencyFormatter(orderPrice)}`}</TableCellBodyStyled>
    </TableRow>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;
