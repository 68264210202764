import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Grid, Checkbox, Hidden, Paper, TextField, useMediaQuery
} from '@mui/material';
import toast from 'react-hot-toast';
import ReturnSelectField from './returnSelectField';
import PharmIQBig from '../../assets/images/PharmIQ Logo (dark).png';
import DropZone from '../shared/logoDropzone';
import CropImageContainer from '../shared/logoProcess/cropImageContainer';
import InitialImage from '../shared/logoProcess/initialImage';
import { CREATE_BUSINESS_MUTATION } from '../../mutations/business';
import {
  MainGridContainer, Logo, PrimaryTitle, ContinueButton, ButtonText, ContentBox,
  CircularProgressLoader, CheckboxContainer2, ButtonGrid, CreateButton, CustomField,
  AcknowledgeTypo, UploadGrid, UploadMobileGrid, BusinessSetupGridContainer
} from './businessSetupContainer.styles';
import { JSONParse } from '../../utils/json';
import { validateEmail, validateNigerianNumber } from '../auth/utils';
import CustomNewTextField from '../shared/CustomNewTextField';

import { useStateValue } from '../../providers/stateProvider';

const BusinessSetupContainer = () => {
  const initialState = {
    legalName: '',
    contactName: '',
    userType: 'OGApharmacy market place affiliate',
    mobile: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: 'Lagos',
    state: '',
    secondaryEmailAddress: '',
    salesRepresentative: '',
    landmark: '',
    country: 'Nigeria',
    logo: '',
    validated: false
  };

  const initialErrorState = {
    legalNameError: false,
    contactNameError: false,
    addressLine1Error: false,
    mobileError: false,
    emailError: false,
    landmarkError: false,
    stateError: false,
    cityError: false,
    userTypeError: false,
  };

  const nigeriaState = [
    'Abia', 'Adamawa', 'Akwa Ibom', 'Anambra', 'Bauchi', 'Bayelsa', 'Benue', 'Borno', 'Cross River', 'Delta', 'Ebonyi', 'Edo',
    'Ekiti', 'Enugu', 'FCT - Abuja', 'Gombe', 'Imo', 'Jigawa', 'Kaduna', 'Kano', 'Katsina', 'Kebbi', 'Kogi', 'Kwara', 'Lagos',
    'Nasarawa', 'Niger', 'Ogun', 'Ondo', 'Osun', 'Oyo', 'Plateau', 'Rivers', 'Sokoto', 'Taraba', 'Yobe', 'Zamfara'
  ];

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInitialImage, setShowInitialImage] = useState(false);
  const [files, setFiles] = useState(null);
  const [finalImage, setFinalImage] = useState(null);

  const needsLoan = false;
  const [, dispatch] = Object.values(useStateValue());
  const location = useLocation();
  const navigate = useNavigate();
  const isLarge = useMediaQuery('(min-width: 992px)');
  const [createBusiness] = useMutation(CREATE_BUSINESS_MUTATION);

  const {
    legalNameError, contactNameError, addressLine1Error, mobileError, emailError,
    landmarkError, stateError, cityError, userTypeError
  } = errorState;
  const { userType } = state;

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
    const { contacts } = location.state && location.state[0];
    const { email: _email, mobile_number: _mobile } = JSONParse(contacts);
    setState((_state) => ({ ..._state, email: _email, mobile: _mobile }));
  }, []);

  const handleFile = (filesData) => {
    setFiles(filesData);
  };

  const handleChangeInitialImage = () => {
    setShowInitialImage(false);
  };

  const handleDeleteInitialImage = () => {
    setShowInitialImage(false);
    setState({ ...state, logo: '' });
  };

  const handleImageUpload = (file) => {
    const uploadPreset = process.env.SUPPLIER_UPLOAD_PRESET;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', uploadPreset);
    formData.append('api_key', `${process.env.API_KEY}`);
    formData.append('timestamp', (Date.now() / 1000) || 0);

    return new Promise((resolve, reject) => {
      fetch(process.env.CLOUDINARY_URL, {
        method: 'POST',
        body: formData
      }).then(async (res) => {
        const response = await res.json();
        if (response.error) {
          setLoading(false);
          toast.error(response.error?.message);
          return reject(response.error.message);
        }
        return resolve(response.secure_url);
      });
    });
  };

  const redirectUser = (user) => {
    const { name } = user?.businessuserSet[0]?.role ?? '';
    localStorage.setItem('oga_user_role', name);
    const route = name === 'supplier-admin' ? '/orders-supplier' : '/orders';
    setTimeout(() => navigate(route), 1500);
  };

  const handleSave = async () => {
    const {
      legalName, contactName, mobile, email, addressLine1, city, country,
      state: userState, landmark, salesRepresentative, secondaryEmailAddress, userType: accountType
    } = state;

    setLoading(true);
    const logoPlaceholder = 'https://res.cloudinary.com/health-id/image/upload/v1594135325/Placeholders/Supplier_Placeholder.png';
    const alreadyUploadedImage = state.logo || logoPlaceholder;
    const logo = finalImage
      ? await handleImageUpload(finalImage)
      : alreadyUploadedImage;

    createBusiness({
      variables: {
        legalName,
        contactName,
        businessType: userType,
        logo,
        mobile,
        needsLoan,
        email,
        addressLine1,
        state: userState,
        city,
        country,
        landmark,
        salesRepresentative,
        secondaryEmailAddress,
        userType: accountType,
        terms: false
      }
    })
      .then(({ data }) => {
        const { message, user, business } = data?.createBusiness || {};
        toast.success(message);
        localStorage.setItem('affiliateStatus', business?.status);

        redirectUser(user);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleButtonClick = () => {
    const {
      legalName, contactName, mobile, email, addressLine1, landmark, state: userState, city
    } = state;
    if (legalName.length < 3) return setErrorState({ ...errorState, legalNameError: true });
    if (contactName.length < 3) return setErrorState({ ...errorState, contactNameError: true });
    if (addressLine1.length < 3) return setErrorState({ ...errorState, addressLine1Error: true });
    if (landmark.length < 3) return setErrorState({ ...errorState, landmarkError: true });
    if (userType.length < 3) return setErrorState({ ...errorState, userTypeError: true });
    if (userState.length < 3) return setErrorState({ ...errorState, stateError: true });
    if (city.length < 3) return setErrorState({ ...errorState, cityError: true });
    if (validateEmail(email)) return setErrorState({ ...errorState, emailError: true });
    if (validateNigerianNumber(mobile)) return setErrorState({ ...errorState, mobileError: true });

    return handleSave();
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'legalName':
        return setErrorState({
          ...errorState,
          legalNameError: !(value.length >= 3)
        });
      case 'contactName':
        return setErrorState({
          ...errorState,
          contactNameError: !(value.length >= 3)
        });
      case 'addressLine1':
        return setErrorState({
          ...errorState,
          addressLine1Error: !(value.length >= 3)
        });
      case 'mobile':
        return setErrorState({
          ...errorState,
          mobileError: validateNigerianNumber(value)
        });
      case 'email':
        return setErrorState({
          ...errorState,
          emailError: validateEmail(value)
        });
      case 'landmark':
        return setErrorState({
          ...errorState,
          landmarkError: !(value.length >= 3)
        });
      case 'state':
        return setErrorState({
          ...errorState,
          stateError: !(value.length >= 3)
        });
      case 'userType':
        return setErrorState({
          ...errorState,
          userTypeError: !(value.length >= 3)
        });
      case 'city':
        return setErrorState({
          ...errorState,
          cityError: !(value.length >= 3)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const topFields = [
    {
      name: 'legalName',
      label: 'Pharmacy name / Legal Name',
      required: true,
      helperText: 'Legal name should be 3 or more characters'
    },
    {
      name: 'contactName',
      label: 'Affiliate first & Last name',
      required: true,
      helperText: 'Primary Contact name should be 3 or more characters'
    },
    {
      name: 'mobile',
      label: 'Phone number (+234)',
      required: true,
      helperText: 'Please provide a valid mobile number'
    },
    {
      name: 'email',
      label: 'Email Address',
      required: true,
      helperText: 'Please provide a valid email address'
    },
    {
      name: 'secondaryEmailAddress',
      label: 'Secondary Email Addresss',
      required: false,
      helperText: 'Please provide a valid email address'
    },
    {
      name: 'addressLine1',
      label: 'Pharmacy address',
      required: true,
      helperText: 'Address should be 3 or more characters'
    },
    {
      name: 'salesRepresentative',
      label: 'Sales Representative',
      required: false,
      helperText: 'Sales representative should be 3 or more characters'
    },
    {
      name: 'landmark',
      label: 'Landmark',
      required: true,
      helperText: 'Landmark should be 3 or more characters'
    },
    { name: 'city', label: 'City', required: true },
    {
      name: 'state',
      label: 'State',
      required: true,
      helperText: 'Please select a state',
      options: nigeriaState,
    },
    // {
    //   name: 'userType',
    //   label: 'User Type',
    //   required: true,
    //   options: [
    //     'OGApharmacy Marketplace Affiliate', 'ERP - Pharmacy Management', 'OGApharmacy Marketplace Supplier'
    //   ]
    // },
    // { name: 'addressLine2', label: 'Address Line 2' },

  ];

  const bottomFields = [
    {
      name: 'userType',
      label: 'User Type',
      required: true,
      options: [
        'OGApharmacy market place affiliate', 'ERP pharmacy management', 'OGA pharmacy market place supplier'
      ]
    },
    // { name: 'city', label: 'City', required: true },
    // {
    //   name: 'country',
    //   label: 'Country',
    //   required: true,
    //   options: ['Nigeria', 'Uganda', 'Kenya']
    // }
  ];

  const renderError = (name) => {
    switch (name) {
      case 'legalName': return legalNameError;
      case 'contactName': return contactNameError;
      case 'addressLine1': return addressLine1Error;
      case 'mobile': return mobileError;
      case 'email': return emailError;
      case 'landmark': return landmarkError;
      case 'state': return stateError;
      case 'userType': return userTypeError;
      case 'city': return cityError;
      default:
        return false;
    }
  };

  const renderHelperText = (fieldName) => {
    switch (fieldName) {
      case 'legalName': return legalNameError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'contactName': return contactNameError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'addressLine1': return addressLine1Error
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'mobile': return mobileError
        ? bottomFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'email': return emailError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'landmark': return landmarkError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'city': return cityError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'state': return stateError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      case 'userType': return userTypeError
        ? topFields.find(({ name }) => name === fieldName)?.helperText : '';
      default:
        return false;
    }
  };

  const returnTextField = (field) => {
    const {
      name: fieldName, label, helperText, required
    } = field;
    const value = state[fieldName];
    if (fieldName === 'userType' || fieldName === 'state') {
      return (
        <ReturnSelectField
          field={field}
          value={value}
          handleChange={handleChange}
          fullWidth
          required={required || false}
          helperText={renderHelperText(fieldName)}
        />
      );
    }
    return (
      <CustomField
        variant="outlined"
        name={fieldName}
        label={label}
        value={value}
        size={isLarge ? 'small' : 'medium'}
        type="text"
        required={required || false}
        fullWidth
        error={renderError(fieldName)}
        helperText={renderHelperText(fieldName)}
        onChange={handleChange}
        InputProps={{ disableUnderline: true }}
        className={`mpfe-uat-business-setup-${fieldName}`}
      />
    );
  };

  const renderUploadGrid = () => (showInitialImage ? (
    <InitialImage
      image={state.logo}
      handleImageChange={handleChangeInitialImage}
      handleImageDelete={handleDeleteInitialImage}
    />
  ) : (
    files && files.length ? (
      <CropImageContainer
        files={files}
        handleFile={handleFile}
        handleFinalImage={setFinalImage}
      />
    ) : (
      <DropZone
        handleFile={handleFile}
      />
    )
  ));

  return (
    <MainGridContainer container>
      <BusinessSetupGridContainer container item md={12} xs={12}>
        {/* <Grid item container>
          <Logo alt="PharmIQ" src={PharmIQBig} />
        </Grid> */}
        <ContentBox component={Paper}>
          <PrimaryTitle variant="h5">
            Create Affiliate
            {/* Set up your PharmIQ account */}
          </PrimaryTitle>
          <Grid item container spacing={4}>
            {topFields.map((field) => (
              <Grid item md={6} sm={12}>
                {returnTextField(field)}
              </Grid>
            ))}
            {bottomFields.map((field) => (
              <Grid item xs={12}>
                {returnTextField(field)}
              </Grid>
            ))}
          </Grid>
          <CreateButton fullWidth onClick={handleButtonClick}>
            CREATE NEW AFFILIATE
          </CreateButton>
        </ContentBox>
      </BusinessSetupGridContainer>
    </MainGridContainer>
  );
};

export default BusinessSetupContainer;
